import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datepicker-remote-update',
  templateUrl: './datepicker-remote-update.component.html',
  styleUrls: ['./datepicker-remote-update.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }}
  ],
})
export class DatepickerRemoteUpdateComponent implements OnInit {
  @Input() id: string = '';
  @Input() label : string = "Date";
  @Input() dateFormat : string;
  @Input() disabled : boolean = false;
  @Input() minDate : Date;
  @Input() maxDate : Date;
  @Input() name : string = "datepicker";
  @Input() placeholder : string = "DD/MM/YYYY";
  @Input() formControl : FormControl = new FormControl('datepicker');

  @Output() datepickerChangedValueEvent: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() focusOutEvent: EventEmitter<Date> = new EventEmitter<Date>();

  ngOnInit(): void {
  }

  isRequired() {
    const validator = this.formControl && this.formControl.validator && this.formControl.validator({} as AbstractControl);
    return validator && validator.required;
  }
}
