import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/role/role.service';
import { environment } from './../../../../environments/environment';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor(private router: Router,private authService:AuthService,public formBuilder: FormBuilder, private cdr: ChangeDetectorRef, public roleService:RoleService) { }
  isLoad = false;
  public aFormGroup: FormGroup;
  captchaSuccess : boolean = false;

  readonly siteKey = environment.siteKeyCaptcha;

  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  
  ngOnInit(): void {  
    if(this.authService.isCheckLogin()) {
      if (this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3)
        this.router.navigate(['/user-audit']);
      else
        this.router.navigate(['/machine']);

    }else{
      this.isLoad = true;
      this.aFormGroup = this.formBuilder.group({
        recaptcha: ['', Validators.required]
      });
    }
  }

  login() {
    this.router.navigate(['/login']);
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.cdr.detectChanges();
  }
}
