import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, merge, Observable, of, pipe, ReplaySubject, Subject, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, delay, filter, map, mapTo, publishReplay, refCount, startWith, switchMap, take } from 'rxjs/operators';
import { TemplateNameComponent } from '../../components/template-name/template-name.component';
import { MarketCountrySelectionComponent } from '../../components/market-country-selection/market-country-selection.component';
import { DrinkPropertiesComponent, SelectedDrinkProperties } from '../../components/drink-properties/drink-properties.component';
import { DrinksSetupComponent } from '../../components/drink-list/drinks-list.component';
import { SelectedMarketCountry } from '../../components/market-country-selection/selected-market-country';
import { DrinkSetup } from '../../components/drink-list/drinks-setup';
import { DrinkSetupService } from '../../services/drink-setup.service';
import {
  DrinkGroupTemplateDetailDrink,
  RequestEditDrinkGroupTemplate,
  RequestGetDrinkGroupTemplate,
} from '../../services/request-add-drink-group-template';
import { Toaster } from 'ngx-toast-notifications';
import { TemplateNameModel } from '../../components/template-name/template-name.model';
import { CcDialogService } from '../../../../services/ui/cc-dialog.service';

@Component({
  selector: 'app-edit-drinks-setup-page',
  templateUrl: './edit-drinks-setup-page.html',
  styleUrls: ['./edit-drinks-setup-common.css'],
})
export class EditDrinksSetupComponent implements OnInit, OnDestroy {
  protected readonly parentPagePath = '/drinks-setup';
  private readonly snackBarTimeout: number = 8000;

  @ViewChild('templateName') private templateNameComponent: TemplateNameComponent;
  @ViewChild('marketCountrySelection') private marketCountrySelectionComponent: MarketCountrySelectionComponent;
  @ViewChild('drinkProperties') private drinkPropertiesComponent: DrinkPropertiesComponent;
  @ViewChild('drinksList') private drinksSetupComponent: DrinksSetupComponent;

  private saveSubscription: Subscription;

  // Source Streams
  readonly saveClick$ = new Subject<true>();
  readonly templateName$ = new ReplaySubject<TemplateNameModel>(1);
  readonly marketCountry$ = new ReplaySubject<SelectedMarketCountry>(1);
  readonly drinkProperties$ = new ReplaySubject<SelectedDrinkProperties>(1);
  readonly drinkList$ = new ReplaySubject<DrinkSetup[]>(1);
  readonly snackBarClose$ = new Subject<true>();

  // Presentation Streams
  protected loading$: Observable<boolean>;
  protected showSnackBar$: Observable<boolean>;
  protected snackBarHeader$: Observable<string>;
  protected snackBarMessage$: Observable<string>;
  drinkGroupTemplateForEdit: RequestGetDrinkGroupTemplate;

  constructor(
    private drinkSetupService: DrinkSetupService,
    private router: Router,
    private toaster: Toaster,
    private activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dialogService: CcDialogService,
  ) {
  }

  ngOnInit(): void {
    const noError = { header: '', message: '' };
    this.spinner.show();

    // Intermediate Streams
    // --------------------
    this.activeRoute.params.pipe(switchMap((r) => this.drinkSetupService.getDrinkSetup(r.id))).subscribe((dgt) => {
      this.drinkGroupTemplateForEdit = dgt.data;
      this.spinner.hide();
    });

    const validateAll$ = pipe(
      map(() => {
        const isTemplateNameValid = this.templateNameComponent.validate();
        const isMarketCountryValid = this.marketCountrySelectionComponent.validate();
        const isDrinkPropertiesValid = this.drinkPropertiesComponent.validate();
        const isDrinkListValid = this.drinksSetupComponent.validate();

        return isTemplateNameValid && isMarketCountryValid && isDrinkPropertiesValid && isDrinkListValid;
      }),
    );

    const allValues$ = combineLatest([this.templateName$, this.marketCountry$, this.drinkProperties$, this.drinkList$]).pipe(
      map(([tn, mc, dp, dl]) => ({ templateChange: tn, marketCountry: mc, drinkProperties: dp, drinkList: dl })),
    );

    const validatedSave$ = this.saveClick$.pipe(validateAll$);

    const validSave$: Observable<true> = validatedSave$.pipe(
      filter((v) => v),
      mapTo(true),
    );

    const saveResult$ = validSave$.pipe(
      switchMap(() => allValues$.pipe(take(1))),
      map(
        (v) =>
          new RequestEditDrinkGroupTemplate(
            this.drinkGroupTemplateForEdit.drinkGroupTemplateId,
            v.templateChange.name,
            v.templateChange.propositionType,
            v.drinkList.map((drink) => new DrinkGroupTemplateDetailDrink(drink.id)),
            v.drinkProperties.cupSize.map((drink) => drink.id),
            v.drinkProperties.milk.map((drink) => drink.id),
            v.drinkProperties.coffee.map((drink) => drink.id),
            v.drinkProperties.syrup.map((drink) => drink.id),
            v.drinkProperties.coffee.find((drink) => drink.isPrimary)?.id,
            v.drinkProperties.milk.find((drink) => drink.isPrimary)?.id,
            v.templateChange.isActive,
          ),
      ),
      switchMap((v) =>
        this.drinkSetupService.editDrinkSetup(v).pipe(
          map((val) => ({ result: val })),
          catchError((e: HttpErrorResponse) =>
            of(
              'title' in e.error && 'errors' in e.error
                ? {
                  error: {
                    title: e.error.title,
                    message: Object.keys(e.error.errors)
                      .map((k) => e.error.errors[k])
                      .join(', '),
                  },
                }
                : Array.isArray(e.error) && e.error.length > 0 && 'code' in e.error[0]
                  ? { error: { title: e.error[0].code, message: e.error[0].msg } }
                  : { error: { title: 'Unexpected error.', message: 'An unexpected error has occurred.' } },
            ),
          ),
        ),
      ),
      publishReplay(1),
      refCount(),
    );

    const snackBarMessage$ = merge(
      validatedSave$.pipe(
        map((v) =>
          v
            ? noError
            : {
              header: 'Selection error.',
              message: 'Please complete the mandatory fields.',
            },
        ),
      ),
      saveResult$.pipe(
        map((v) =>
          'error' in v
            ? {
              header: v.error.title,
              message: v.error.message,
            }
            : noError,
        ),
      ),
      this.snackBarClose$.pipe(mapTo(noError)),
    ).pipe(
      switchMap((v) => (v !== noError ? of(noError).pipe(delay(this.snackBarTimeout), startWith(v)) : of(v))),
      startWith(noError),
    );

    // Presentation Streams
    // --------------------
    this.loading$ = merge(validSave$.pipe(mapTo(true)), saveResult$.pipe(mapTo(false)));

    this.showSnackBar$ = snackBarMessage$.pipe(map((v) => v !== noError));

    this.snackBarHeader$ = snackBarMessage$.pipe(map((v) => (v !== noError ? v.header : '')));

    this.snackBarMessage$ = snackBarMessage$.pipe(map((v) => (v !== noError ? v.message : '')));

    this.saveSubscription = saveResult$.pipe(filter((v) => !('error' in v))).subscribe(() => {
      this.router.navigate([this.parentPagePath]);

      this.toaster.open({
        text: 'Drinks Setup updated successfully',
        type: 'success',
        position: 'top-right',
        duration: 10000,
      });
    });
  }

  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
    this.dialogService.closeAll();
  }

  public onCancel(): void {
    const { key, component } = this.dialogService.openDialog();
    component.instance.title = 'Cancel Edit Drink Setup';
    component.instance.description = 'Are you sure you want to cancel editing drink setup?';
    component.instance.primary = 'No';
    component.instance.secondary = 'Yes';
    const dialogSubs = new Subscription();
    dialogSubs.add(
      component.instance.primaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
      }),
    );
    dialogSubs.add(
      component.instance.secondaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
        this.router.navigate([this.parentPagePath]);
      }),
    );
  }
}
