import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Toaster } from 'ngx-toast-notifications';
import { Observable, ReplaySubject, Subject, combineLatest, from, iif, merge, of, Subscription } from 'rxjs';
import { GetRemoteUpdateTaskType, Proposition, RemoteUpdateTaskTypeEntities } from 'src/app/components/remote-update-tasks/dtos/get-remote-update-task-type.model';
import { RemoteUpdateTaskTypePropositionGetListService } from 'src/app/components/remote-update-tasks/services/get-list/remote-update-taskType-proposition-get-list.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { RoleService } from 'src/app/services/role/role.service';
import { Item, MultiSelectComponent } from '@costa-coffee/pattern-lib';
import { catchError, filter, first, map, mergeMap, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { CcDialogService } from '../../../../../../services/ui/cc-dialog.service';

@Component({
  selector: 'app-step-task-parameters',
  templateUrl: './step-task-parameters.component.html',
  styleUrls: ['./../new-task-step-common.css', './step-task-parameters.component.css'],
})
export class StepTaskParametersComponent implements OnInit, OnDestroy {
  @ViewChild('msMarket') private msMarket: MultiSelectComponent;
  @ViewChild('msLevel2') private msLevel2: MultiSelectComponent;
  @ViewChild('msLevel3') private msLevel3: MultiSelectComponent;

  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() newTaskParametersEvent = new EventEmitter<any>();

  taskParameterFormGroup: FormGroup = new FormGroup({
    taskType: new FormControl({ value: '', disabled: false }, Validators.required),
    proposition: new FormControl({ value: '', disabled: false }, Validators.required),
    entityDeployLevel: new FormControl({ value: '', disabled: false }),
  });

  private readonly defaultValidVariant = 'default';
  private readonly errorValidVariant = 'error';

  destroyed$ = new Subject();
  tasks$: Observable<GetRemoteUpdateTaskType[]>;
  changeTaskType$ = new ReplaySubject<MatSelect>(1);

  marketEntitiesAll$: Observable<RemoteUpdateTaskTypeEntities[]>;
  marketEntities$: Observable<RemoteUpdateTaskTypeEntities[]>;
  marketEntitiesSelected: RemoteUpdateTaskTypeEntities[] = [];
  marketEntitiesValidVariant = this.defaultValidVariant;
  marketOnChange$ = new ReplaySubject<Item[]>(1);
  isMarketRequired = false;

  level2EntitiesAll$: Observable<RemoteUpdateTaskTypeEntities[]>;
  level2Entities$: Observable<RemoteUpdateTaskTypeEntities[]>;
  level2EntitiesSelected: RemoteUpdateTaskTypeEntities[] = [];
  level2EntitiesValidVariant = this.defaultValidVariant;
  level2OnChange$ = new ReplaySubject<Item[]>(1);
  isLevel2Required = false;

  level3EntitiesAll$: Observable<RemoteUpdateTaskTypeEntities[]>;
  level3Entities$: Observable<RemoteUpdateTaskTypeEntities[]>;
  level3EntitiesSelected: RemoteUpdateTaskTypeEntities[] = [];
  level3EntitiesValidVariant = this.defaultValidVariant;
  level3OnChange$ = new ReplaySubject<Item[]>(1);
  isLevel3Required = false;

  propositions$: Observable<Proposition[]>;
  enableDisableDrinks: string = '';
  entityDeployLevels = ['Machine'];

  showMarketSelection: boolean = false;
  showLevel2Selection: boolean = false;
  showLevel3Selection: boolean = false;
  showEntityDeploy: boolean = false;

  enableGridImprovementsFT: boolean;
  showEntityOrRecordName: string = 'Entity';

  constructor(
    private router: Router,
    private remoteUpdateTaskTypePropositionService: RemoteUpdateTaskTypePropositionGetListService,
    public entityService: EntityService,
    private toaster: Toaster,
    public roleService: RoleService,
    private azureService: AzureService,
    private dialogService: CcDialogService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();

    this.tasks$ = this.remoteUpdateTaskTypePropositionService.getRemoteUpdateTaskTypeList()
      .pipe(shareReplay(1));

    this.propositions$ = this.changeTaskType$.pipe(
      switchMap((it) =>
        this.tasks$.pipe(
          mergeMap((task) => from(task)),
          filter((task) => task.name === it.value),
          map((task) => task.propositions),
        ),
      ),
      tap((propositions) => {
        if (propositions.length === 1) {
          this.taskParameterFormGroup.get('proposition').setValue(propositions[0]);
        }
      }),
      startWith([]),
    );

    this.loadEntities();

    this.marketEntities$ = iif(
      () => this.roleService.objRole.isMarketAdmin,
      this.marketEntitiesAll$,
      this.changeTaskType$.pipe(
        switchMap((taskType) => {
          if (this.requiresMarket(taskType.value)) {
            return this.marketEntitiesAll$;
          } else {
            return of([]);
          }
        }),
      ),
    ).pipe(startWith([]));

    this.level2Entities$ = iif(
      () => this.roleService.objRole.isLevel2Admin,
      this.level2EntitiesAll$,
      merge(
        this.changeTaskType$.pipe(map(() => [])),
        this.marketOnChange$.pipe(
          map((selected) => selected.map((s) => s.id)),
          switchMap((ids) => this.level2EntitiesAll$.pipe(map((all) => all.filter((f) => ids.includes(f.parentEntityId))))),
        ),
      ),
    ).pipe(startWith([]));

    this.level3Entities$ = iif(
      () => this.roleService.objRole.isLevel3Admin,
      this.level3EntitiesAll$,
      merge(
        this.changeTaskType$.pipe(map(() => [])),
        this.marketOnChange$.pipe(map(() => [])),
        this.level2OnChange$.pipe(
          map((selected) => selected.map((s) => s.id)),
          switchMap((ids) => this.level3EntitiesAll$.pipe(map((all) => all.filter((a) => ids.includes(a.parentEntityId))))),
        ),
      ),
    ).pipe(startWith([]));

    combineLatest([this.marketEntitiesAll$, this.marketOnChange$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([allItems, selected]) => {
        this.marketEntitiesSelected = selected.map((s) => allItems.filter((f) => f.id == s.id)[0]);
        this.level2EntitiesSelected = [];
        this.level3EntitiesSelected = [];
        this.msMarket.showMessage = false;
        this.marketEntitiesValidVariant = this.defaultValidVariant;
      });

    combineLatest([this.level2EntitiesAll$, this.level2OnChange$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([allItems, selected]) => {
        this.level2EntitiesSelected = selected.map((s) => allItems.filter((f) => f.id == s.id)[0]);
        this.level3EntitiesSelected = [];
        this.msLevel2.showMessage = false;
        this.level2EntitiesValidVariant = this.defaultValidVariant;
      });

    combineLatest([this.level3EntitiesAll$, this.level3OnChange$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([allItems, selected]) => {
        this.level3EntitiesSelected = selected.map((s) => allItems.filter((f) => f.id == s.id)[0]);
        this.msLevel3.showMessage = false;
        this.level3EntitiesValidVariant = this.defaultValidVariant;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private async SetFeatureFlagsConfigurations() {
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
    this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
  }

  public getEntitiesByLevelAndParent(levelNumber: number, parentEntityId: string): Observable<RemoteUpdateTaskTypeEntities[]> {
    const payload: any = {
      LevelNumber: levelNumber,
    };

    if (parentEntityId) {
      payload.ParentEntityId = parentEntityId;
    }

    return this.entityService.getEntityListV2(payload).pipe(
      map((response) =>
        response.data
          .filter((item) => item.data.isActive === true)
          .map(
            (item) =>
              <RemoteUpdateTaskTypeEntities>{
                id: item.data.entityId,
                name: item.data.name,
                parentEntityId: item.data.parentEntityId,
                levelNumber: item.data.levelNumber,
              },
          ),
      ),
      catchError((e) => {
        this.toaster.open({
          text: e.error.title,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
        return of<RemoteUpdateTaskTypeEntities[]>([]);
      }),
      shareReplay(1),
    );
  }

  public loadEntities() {
    if (this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isSystemAdmin) {
      this.marketEntitiesAll$ = this.getEntitiesByLevelAndParent(1, null);
      this.level2EntitiesAll$ = this.getEntitiesByLevelAndParent(2, null);
      this.level3EntitiesAll$ = this.getEntitiesByLevelAndParent(3, null);
    } else if (this.roleService.objRole.isLevel2Admin) {
      this.marketEntitiesAll$ = of([]);
      this.level2EntitiesAll$ = this.getEntitiesByLevelAndParent(2, null);
      this.level3EntitiesAll$ = this.getEntitiesByLevelAndParent(3, null);
    } else if (this.roleService.objRole.isLevel3Admin) {
      this.marketEntitiesAll$ = of([]);
      this.level2EntitiesAll$ = of([]);
      this.level3EntitiesAll$ = this.getEntitiesByLevelAndParent(3, null);
    }
  }

  public getEntitiesByLevel(levelNumber: number, entities: RemoteUpdateTaskTypeEntities[]) {
    const payload: any = {
      LevelNumber: levelNumber,
      ParentEntitiesId: entities.map((item) => item.id),
    };

    if (!entities.length) return of<RemoteUpdateTaskTypeEntities[]>([]);

    return this.entityService.getByLevel(payload).pipe(
      map<any, RemoteUpdateTaskTypeEntities[]>((result) =>
        result.data.map(
          (i) =>
            <RemoteUpdateTaskTypeEntities>{
              id: i.entityId,
              name: i.name,
              parentEntityId: i.parentEntityId,
              levelNumber: i.levelNumber,
            },
        ),
      ),
      catchError((e) => {
        const text = e?.error?.title ?? 'Unexpected error';
        this.toaster.open({
          text: text,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
        return of<RemoteUpdateTaskTypeEntities[]>([]);
      }),
    );
  }

  public isFieldValid(fieldName: string): boolean {
    const control = this.taskParameterFormGroup.get(fieldName);
    return control.touched && control.invalid;
  }

  entityLevelChange(event: any) {
    this.marketEntitiesSelected.forEach((selected) => this.msMarket.removeItem(selected.id));
    this.marketEntitiesSelected = [];
    this.level2EntitiesSelected = [];
    this.level3EntitiesSelected = [];
    this.changeTaskType$.next(event);

    if (this.requiresMarket(event.value)) {
      this.showEntityDeploy = false;
      if (this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isSystemAdmin) {
        this.showMarketSelection = true;
        this.isMarketRequired = true;
        this.showLevel2Selection = true;
        this.showLevel3Selection = true;
      }

      if (this.roleService.objRole.isMarketAdmin) {
        this.marketEntitiesAll$.subscribe((it) => {
          this.marketEntitiesSelected = it;
          this.msMarket.toggleSelectedItem(this.marketEntitiesSelected[0].id);
        });
      }

      if (this.roleService.objRole.isLevel2Admin) {
        this.showMarketSelection = false;

        this.showLevel2Selection = true;
        this.showLevel3Selection = true;

        this.isLevel2Required = true;
        this.level2EntitiesAll$.subscribe((it) => {
          this.level2EntitiesSelected = it;
          this.msLevel2.toggleSelectedItem(this.level2EntitiesSelected[0].id);
        });
      }

      if (this.roleService.objRole.isLevel3Admin) {
        this.showMarketSelection = false;

        this.showLevel3Selection = true;
        this.isLevel3Required = true;
        this.level3EntitiesAll$.pipe(first()).subscribe((it) => {
          this.level3EntitiesSelected = it;
          this.msLevel3.toggleSelectedItem(this.level3EntitiesSelected[0].id);
        });
      }
    } else {
      this.showEntityDeploy = true;
      this.showMarketSelection = false;
      this.showLevel2Selection = false;
      this.showLevel3Selection = false;
    }
  }

  public onCancel() {
    const { key, component } = this.dialogService.openDialog();
    component.instance.title = 'Cancel New Task';
    component.instance.description = 'Are you sure you want to cancel creating this task?';
    component.instance.primary = 'No';
    component.instance.secondary = 'Yes';
    const dialogSubs = new Subscription();
    dialogSubs.add(
      component.instance.primaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
      }),
    );
    dialogSubs.add(
      component.instance.secondaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
        this.router.navigate(['/task-management-overview']);
      }),
    );
  }

  public onSubmit() {
    if (!this.validate()) {
      this.taskParameterFormGroup.markAllAsTouched();
      const { key, component } = this.dialogService.openDialog();
      component.instance.title = 'Error';
      component.instance.description = 'Please complete all the required fields';
      component.instance.primary = 'Ok';
      const dialogSubs = new Subscription();
      dialogSubs.add(
        component.instance.primaryClick.subscribe(() => {
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }),
      );
      return;
    }

    const proposition = this.taskParameterFormGroup.get('proposition').value as Proposition;

    const markets = this.marketEntitiesSelected.filter((f) => !this.level2EntitiesSelected.some((s) => f.id == s.parentEntityId));
    const level2 = this.level2EntitiesSelected.filter((f) => !this.level3EntitiesSelected.some((s) => f.id == s.parentEntityId));
    const level3 = [...this.level3EntitiesSelected];
    const entityDeployLevel =
      this.taskParameterFormGroup.get('taskType').value === RemoteUpdateTaskType.CertificateManagement ? this.entityDeployLevels[0] : this.taskParameterFormGroup.get('entityDeployLevel').value;
    const taskParameters = {
      taskType: this.taskParameterFormGroup.get('taskType').value,
      propositionTypeId: proposition.id,
      propositionType: proposition.name,
      entityDeployLevel,
      level1: markets,
      level2: level2,
      level3: level3,
      screenSelectedLevel1: this.marketEntitiesSelected,
      screenSelectedLevel2: this.level2EntitiesSelected,
      screenSelectedLevel3: this.level3EntitiesSelected,
    };

    this.newTaskParametersEvent.emit(taskParameters);
    this.nextStepEvent.emit();
  }

  private validate() {
    const VALID: string = 'VALID';
    var entityDeployLevel: any;
    var formGroup: any;

    if (this.requiresMarket(this.taskParameterFormGroup.get('taskType').value)) {
      this.taskParameterFormGroup.controls['entityDeployLevel'].setErrors({ incorrect: false });

      entityDeployLevel = this.taskParameterFormGroup.get('entityDeployLevel');
      entityDeployLevel.setErrors({ incorrect: false });
      entityDeployLevel.status = VALID;

      formGroup = this.taskParameterFormGroup;
      formGroup.status = VALID;

      if (this.isMarketRequired && !this.marketEntitiesSelected.length) {
        this.msMarket.showMessage = true;
        this.marketEntitiesValidVariant = this.errorValidVariant;
        return false;
      } else if (this.isLevel2Required && !this.level2EntitiesSelected.length) {
        this.msLevel2.showMessage = true;
        this.level2EntitiesValidVariant = this.errorValidVariant;
        return false;
      } else if (this.isLevel3Required && !this.level3EntitiesSelected.length) {
        this.msLevel3.showMessage = true;
        this.level3EntitiesValidVariant = this.errorValidVariant;
        return false;
      }

      if (this.taskParameterFormGroup.get('proposition').value == '') {
        this.taskParameterFormGroup.controls['proposition'].setErrors({ incorrect: true });
      }
    } else {
      if (this.taskParameterFormGroup.get('entityDeployLevel').value == '') {
        this.taskParameterFormGroup.controls['entityDeployLevel'].setErrors({ incorrect: true });
        return false;
      }
    }

    return 'VALID' === this.taskParameterFormGroup.status;
  }

  private requiresMarket(value: RemoteUpdateTaskType) {
    return [RemoteUpdateTaskType.EnableDisableDrinks, RemoteUpdateTaskType.CertificateManagement].includes(value);
  }
}
