import { prop, required } from "@rxweb/reactive-form-validators";

export class configurationDiscrepancyModel{
    @required()
    @prop()
    siteId :any[] = [] ;
    @prop()
    waffleCupsShort:string = "";
    @prop()
    waffleCupsSmall:string = "";
    @prop()
    waffleCupsMedium:string = "";
    @prop()
    waffleCupsLarge:string = "";
    @prop()
    plasticCupsMedium : string = "";
    @prop()
    plasticCupsLarge: string = "";
    @prop()
    crockeryCupsShort: string = "";
    @prop()
    crockeryCupsSmall: string = "";
    @prop()
    crockeryCupsMedium: string = "";
    @prop()
    crockeryCupsLarge: string = "";
    @prop()
    glassCupsShort: string = "";
    @prop()
    glassCupsSmall: string = "";
    @prop()
    glassCupsMedium: string = "";
    @prop()
    glassCupsLarge: string = "";
    @required()
    @prop()
    machineType : string;
    @required()
    @prop()
    'l-1' :any[] = [] ;
    @prop()
    'l-2' :any[] = [] ;
    @prop()
    'l-3' :any[] = [] ;
    @prop()
    'l-3_5' :any[] = [] ;
    @prop()
    'l-4' :any[] = [] ;
}
export class discrepancyModel{
    @required()
    @prop()
    discrepancyType : string;    
}

export class priceCardDiscrepancyModel{
    @required()
    @prop()
    siteId :any[] = [] ;
    @prop()
    oldPriceCard : string = '';
    @prop()
    price : string = '';
    @required()
    @prop()
    'l-1' :any[] = [] ;
    @prop()
    'l-2' :any[] = [] ;
    @prop()
    'l-3' :any[] = [] ;
    @prop()
    'l-3_5' :any[] = [] ;
    @prop()
    'l-4' :any[] = [] ;
}

export class DrinkProductDiscrepancyModel{
    @required()
    @prop()
    siteId :any[] = [] ;
    @required()
    @prop()
    'l-1' :any[] = [] ;
    @prop()
    'l-2' :any[] = [] ;
    @prop()
    'l-3' :any[] = [] ;
    @prop()
    'l-3_5' :any[] = [] ;
    @prop()
    'l-4' :any[] = [] ;
    @prop()
    fromDate : string = '';
    @prop()
    toDate : string = '';
    @prop()
    drinkProduct : string = '';
}
export class CupSizeDiscrepancyModel{
    @required()
    @prop()
    siteId :any[] = [] ;
    @prop()
    fromDate : string = '';
    @prop()
    toDate : string = '';
    @prop()
    cupSize : string = '';
    @required()
    @prop()
    'l-1' :any[] = [] ;
    @prop()
    'l-2' :any[] = [] ;
    @prop()
    'l-3' :any[] = [] ;
    @prop()
    'l-3_5' :any[] = [] ;
    @prop()
    'l-4' :any[] = [] ;
}

export class entityIngredientsModel{
    @prop()
    @required()
    coffeeType:any[] = null;
    @prop()
    milkType:any[] = null;
    @prop()
    syrup:any[] = null;
}