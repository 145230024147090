import * as moment from 'moment';

import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GetPackageList, PackageModel } from 'src/app/components/remote-update-tasks/dtos/get-package-list.model';

import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationSelection } from 'src/app/components/remote-update-tasks/dtos/pagination-select-option.model';
import { RemoteUpdatePackageGetListService } from 'src/app/components/remote-update-tasks/services/get-list/remote-update-package-get-list.service';
import { RemoteUpdatePackagesService } from 'src/app/components/remote-update-tasks/services/package/remote-update-packages.service';
import { Toaster } from 'ngx-toast-notifications';
import { DOCUMENT } from '@angular/common';

interface PackageSortableColumn {
  class: string,
  id: string,
  label: string
}

@Component({
  selector: 'app-table-packages',
  templateUrl: './table-packages.component.html',
  styleUrls: ['./table-packages.component.css', './../../../../remote-update-tasks.component.css', '../../file-repository-common.css']
})
export class TablePackagesComponent implements OnInit {
  tableColumns: PackageSortableColumn[] = [
    { class: 'first-column', id: "packageName", label:"Package Name" },
    { class: 'enabled-disabled-column', id: "isEnabled", label:"Enabled" },
    { class: '', id: "taskType", label:"Task Type" },
    { class: '', id: "propositions", label:"Proposition" },
    { class: '', id: "packageVersion", label:"Package Version" },
    { class: '', id: "minimalVersion", label:"Minimal Version" },
    { class: '', id: "user", label:"User" },
    { class: '', id: "validFrom", label:"Valid From" },
    { class: '', id: "uploadedTime", label:"Upload Time" },
  ];

  currentSortedField: string = "uploadedTime";
  currentSortIsAsc: boolean = true;

  tableData: PackageModel[];
  getRemoteUpdatePackageList: GetPackageList = null;

  proposition: string = null;

  showFilterOnTable: boolean = false;

  public slidePanelHeader : string = '';
  public slidePanelBody : string = '';
  public slidePanelShow : boolean = false

  remoteUpdatePackages: GetPackageList;
  page: number = 0;
  limit: number = 10;
  totalPages: number;
  dtOptions = {};
  selectedOption: string;

  options: PaginationSelection[] = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];
  queryParams: Map<string, string> = new Map<string, string>();

  @ViewChild('cardHeight') cardHeight: ElementRef;

  constructor(
    private remoteUpdatePackageListService: RemoteUpdatePackageGetListService,
    private remoteUpdatePackageService: RemoteUpdatePackagesService,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    this.loadRemoteUpdatePackageList();
    this.document.body.classList.add('remote-update-file-repository');
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('remote-update-file-repository');
  }

  private setupTable() {
    this.dtOptions = {
      autoWidth: true,
      scrollX: true,
      lengthMenu: [10, 25, 50, 100],
      pageLength: this.limit,
      ordering: false,
      paging: false,
      search: false,
      dom: 't'
    };
  }

  goToNextPage() {
    if (this.totalPages > (this.page + 1)) {
      this.page++;
      this.loadRemoteUpdatePackageList();
    }
  }

  goToFirstPage() {
    if (this.page != 0) {
      this.page = 0;
      this.loadRemoteUpdatePackageList();
    }
  }

  goToLastPage() {
    const lastPage = this.totalPages - 1;
    if (this.page < lastPage) {
      this.page = lastPage;
      this.loadRemoteUpdatePackageList();
    }
  }

  changePageLimit(limit: string) {
    if (limit) {
      this.limit = Number(limit);
      this.page = 0;
      if (this.limit !== 10) {
        this.cardHeight.nativeElement.classList.add('grid-sliding-panel__card--maxHeight');
      } else if (this.limit === 10) {
        this.cardHeight.nativeElement.classList.remove('grid-sliding-panel__card--maxHeight');
      }
      this.loadRemoteUpdatePackageList();
    }
  }

  goToPreviousPage() {
    if (this.page > 0) {
      this.page--;
      this.loadRemoteUpdatePackageList();
    }
  }

  searchPerColumn(columnId: string, event: Event) {
    if (columnId == 'user')
      columnId = 'createdBy';

    if (columnId == 'uploadedTime')
      columnId = 'createdAt'

    if(!event || !event.target){
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    if (!value) {
      this.queryParams.delete(columnId);
      this.loadRemoteUpdatePackageList();

      return;
    }

    this.queryParams.set(columnId, value.trim());
    this.page = 0;
    this.loadRemoteUpdatePackageList();
  }

  toggleEnabled(currentPackage: PackageModel) : void{
    this.spinner.show();

    this.remoteUpdatePackageService.changeRemoteUpdatePackageStatus(currentPackage.id, !currentPackage.isEnabled).subscribe(
      () => currentPackage.isEnabled = !currentPackage.isEnabled,
      error => {
        this.toaster.open({
          text: error.message,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
      () => this.spinner.hide()
    );
  }

  private loadRemoteUpdatePackageList() {
    this.spinner.show();

    this.remoteUpdatePackageListService.getRemoteUpdatePackageList(this.page, this.limit, this.queryParams).subscribe(
      next => {
        this.remoteUpdatePackages = next;
        this.tableData = next.data.map(value => {
          const validFromDate = moment(value.validFrom);
          value.validFrom = validFromDate.format('DD/MM/YYYY HH:mm');

          const uploadedTimeDate = moment(value.uploadedTime);
          value.uploadedTime = uploadedTimeDate.format('DD/MM/YYYY HH:mm');

          return value
        });

        this.totalPages = Math.ceil(next.params.count / this.limit);
        this.setupTable();
      },
      error => {
        this.toaster.open({
          text: error.message,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
      () => this.spinner.hide()
    );
  }

  getCurrentPageInfo() {
    let text = "";

    if(!this.remoteUpdatePackages)
      return;

    if (this.remoteUpdatePackages.params.start != null) {
      text += (this.remoteUpdatePackages.params.start + 1) + "-" + (this.remoteUpdatePackages.params.end + 1)
    }
    else{
      text+="0-0"
    }

    text += " of " + this.remoteUpdatePackages.params.count;

    return text;
  }

  setOrderByColumn(columnId: string) {
    if (this.currentSortedField == columnId){
      this.currentSortIsAsc = !this.currentSortIsAsc
    }
    else{
      this.currentSortedField = columnId;
      this.currentSortIsAsc = true;
    }

    this.queryParams.set("sortField", columnId);
    this.queryParams.set("order", this.currentSortIsAsc ? "asc" : "desc");
    this.page = 0;
    this.loadRemoteUpdatePackageList();
  }

  public toggleHeaderFilterEvent() : void {
    this.showFilterOnTable = !this.showFilterOnTable;
  }

  public getNotes(remoteUpdatePackage : PackageModel) : void {
    this.spinner.show();
    this.remoteUpdatePackageService.getRemoteUpdatePackageNotes(remoteUpdatePackage.id).subscribe(
      next => {
        this.handleSlidePanel(
          next.data.packageName,
          next.data.notes,
          true
        );
      },
      error => {
        this.toaster.open({
          text: error.message,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
      () => this.spinner.hide()
    );
  }

  /**
   * @param header
   * @param body
   * @param show show = true | hide = false
   */
  public handleSlidePanel(
    header: string = '',
    body: string = '',
    show: boolean = false
  ) : void {
    this.slidePanelHeader = header;
    this.slidePanelBody = body;
    this.slidePanelShow = show;
  }
}
