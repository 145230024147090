import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { AlarmTemplateService } from 'src/app/services/alarm-template/alarm-template.service';
import { RoleService } from 'src/app/services/role/role.service';

@Component({
  selector: 'app-alarm-template-add',
  templateUrl: './alarm-template-add.component.html',
  styleUrls: ['./alarm-template-add.component.css']
})
export class AlarmTemplateAddComponent implements OnInit {

  screenType = 'add';
  templateId: string = '';
  alarmTemplateFormGroup: FormGroup;  
  propositionTypeList: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toaster: Toaster,
    public roleService: RoleService,
    private alarmTemplateService:AlarmTemplateService) {     
    this.activatedRoute.params.subscribe(params => {
      if (params.userId) {
        this.templateId = params.userId;
        this.screenType = 'edit';
      }
    });
  }

  ngOnInit(): void {
    if(!this.roleService.objRole.isSystemAdmin){
      this.router.navigate(['/unauthorized']);
    }
    this.activatedRoute.params.subscribe(params => {   
      if (params.alarmTemplateId){
        this.screenType = 'edit';
        this.templateId = params.alarmTemplateId; 
        this.getTemplateDetailById();
      }
    });
    this.createForm();
    this.propositionTypeLookup();
  }
  createForm(){
    this.alarmTemplateFormGroup = new FormGroup({});
    this.alarmTemplateFormGroup.addControl('name', new FormControl('', [Validators.required,Validators.maxLength(50)]));
    this.alarmTemplateFormGroup.addControl('propositionTypeId', new FormControl('', [Validators.required]));
    if (this.screenType == 'edit') {
      this.alarmTemplateFormGroup.controls['propositionTypeId'].disable();
    }
  }

  propositionTypeLookup() {
    this.alarmTemplateService.propositionTypeLookup().subscribe(response => {
      if (response && response['data'].length) {
        this.propositionTypeList = response['data'];       
      }
    }), err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    };
  }

  getTemplateDetailById() {
    this.spinner.show();
    this.alarmTemplateService.getAlarmTemplateById(this.templateId).subscribe(response => {
      if (response && response['data']) {
        this.alarmTemplateFormGroup.patchValue({
          "name": response['data'].name,
          "propositionTypeId": response['data'].propositionTypeId,      
        });
        this.alarmTemplateFormGroup.markAllAsTouched();      
       }
      this.spinner.hide();
    }), err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if(Array.isArray(errors)){
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    };
  }
  saveTemplate(){
    if(this.alarmTemplateFormGroup.status === 'INVALID'){
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });    
      this.alarmTemplateFormGroup.markAllAsTouched();  
    }else{
      this.spinner.show();
      if(this.templateId){
        this.alarmTemplateService.updateAlarmTemplateById(this.templateId,this.alarmTemplateFormGroup.value).subscribe(response => {
          localStorage.setItem("fromEditTemplateScreen",this.templateId);
          this.spinner.hide();
          this.router.navigate(['/alarm-template']);
          this.toaster.open({
            text: "Alarm Template has been updated Successfully",
            type: 'success',
            position: 'top-right',
            duration: 10000
          });
         }, err => {
          if (err) {
            this.spinner.hide();
            let errors = err.error;
            if(Array.isArray(errors)){
              errors.forEach(element => {
                this.toaster.open({
                  text: element.msg,
                  type: 'danger',
                  position: 'top-right',
                  duration: 10000
                });              
              });
            }
          }
        })
      }else{
        this.alarmTemplateService.addAlarmTemplate(this.alarmTemplateFormGroup.value).subscribe(response => {
          this.spinner.hide();
          this.router.navigate(['/alarm-template']);
          this.toaster.open({
            text: "Alarm Template has been added Successfully",
            type: 'success',
            position: 'top-right',
            duration: 10000
          });
         }, err => {
          if (err) {
            this.spinner.hide();
            let errors = err.error;
            if(Array.isArray(errors)){
              errors.forEach(element => {
                this.toaster.open({
                  text: element.msg,
                  type: 'danger',
                  position: 'top-right',
                  duration: 10000
                });              
              });
            }
          }
        })
      }
    }
  }
  cancel(){
    if(this.templateId){
      localStorage.setItem("fromEditTemplateScreen",this.templateId);
    }
    this.router.navigate(['/alarm-template']);
  }
}

