export class DrinkDto {
    constructor(name: string, cupType: string, code: string, state: DrinkState) {
        this.name = name;
        this.cupType = cupType;
        this.code = code;
        this.state = state;
    }
    
    name: string
    cupType: string
    code: string
    state: DrinkState
}

export const enum DrinkState{
    NoChange = 1,
    Enabled,
    Disabled
}