import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
@Component({
  selector: 'app-price-card-drinks-pricing',
  templateUrl: './price-card-drinks-pricing.component.html',
  styleUrls: ['./price-card-drinks-pricing.component.css']
})
export class PriceCardDrinksPricingComponent implements OnInit {
  @Output() clickButton = new EventEmitter<any>();
  @Input() isPrimaryMilkChanged: boolean = false;
  @Input() isPrimaryCoffeeChanged: boolean = false;

  /*GD2-3065*/
  @Output() isDrinksPricingChanged = new EventEmitter<any>();
  onDrinksPricingChange() {
    this.isDrinksPricingChanged.emit();
  }
  /*GD2-3065*/

  drinks = [];
  cupSizes = [];

  selectedDrinks = [];
  selectedCupSize = [];

  displayedColumns: string[] = ['drinkGroupTemplateDetailName', 'drinkGroupTemplateCupDetailName', 'primaryCoffeeName', 'primaryMilkName', 'price','drinkGroupTemplateDetailId'];
  dataSource: any = [];

  primaryCoffeeDetails: any = {};
  primaryMilkDetails: any = {};
  isTouchedSubmit = false;
  dropdownSettings = {
    singleSelection: false,
    idField: 'drinkGroupTemplateDetailId',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  distinctCupDetailId = [];
  isLoadDrink = false;
  priceFocusIn = {};
  constructor(
    public priceCardService: PriceCardService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getDrinkLookup();
    this.getCupSizeLookup();
    this.getPrimaryDrinkMilk();
    this.getPrimaryDrinkCoffee();
    this.getPriceCardDetails(true);
  }
  clickPrice(index){
    index++;
    if(this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.length != index)
      document.getElementById("TextBox-drink-price-"+index).focus();    
  }
  getPrimaryDrinkCoffee() {
    this.priceCardService.getwrapperdetails(this.priceCardService.priceCardDetails.priceCardVersionId, { type: 'coffee', isPrimary: true }).subscribe(response => {
      this.primaryCoffeeDetails = response['data']['drinkDetails'][0];
    });
  }
  getPrimaryDrinkMilk() {
    this.priceCardService.getwrapperdetails(this.priceCardService.priceCardDetails.priceCardVersionId, { type: 'milk', isPrimary: true }).subscribe(response => {
      this.primaryMilkDetails = response['data']['drinkDetails'][0];
    });
  }
  getCupSizeLookup() {
    this.priceCardService.getwrapperdetails(this.priceCardService.priceCardDetails.priceCardVersionId, { type: 'cupsize' }).subscribe(response => {
      this.cupSizes = response['data']['drinkDetails'];
      if(this.cupSizes.length){
        this.cupSizes = this.sortByKey(this.cupSizes, 'sequenceNumber');//for sorting
      }
    });
  }
  getDrinkLookup() {
    this.priceCardService.getwrapperdetails(this.priceCardService.priceCardDetails.priceCardVersionId, { type: 'drink' }).subscribe(response => {
      this.drinks = response['data']['drinkDetails'];
      if(this.drinks.length){
        this.drinks = this.sortByKey(this.drinks, 'name');//for sorting
      }
    });
  }
  getPriceCardDetails(fromLoad=false) {
    if(fromLoad){
      this.spinner.show();
    }
    let priceCardVersionId = this.priceCardService.priceCardDetails.priceCardVersionId;
    this.priceCardService.getPriceCardDetails(priceCardVersionId, 'drink').subscribe(async response => {
      if (response) {
        this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList = response['data'];
        this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.forEach(data => {
          data.hasMilk = this.hasPrimaryIngredients(data, 'milk');
          data.hasCoffee = this.hasPrimaryIngredients(data, 'coffee');
          if (!data.hasMilk) { data.primaryMilkId = null; data.primaryMilkName = null };
          if (!data.hasCoffee) { data.primaryCoffeeId = null; data.primaryCoffeeName = null };
        });
        this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList = this.priceCardService.sortGridByGroup(this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList);//for sorting
        
        if (response.data.length) {
          this.priceCardService.priceCardDetails.drinkGroups.cupsizes = [];
          //----GD-2850---//
          if(!this.isLoadDrink){
            await this.setCupSizeData(response.data);
          }
          //----GD-2850---//
          let cupsize = [];
          let drinkGroupTemplateCupIds = [];
          response['data'].forEach(element => {
            if (!cupsize.includes(element.drinkGroupTemplateCupDetailId) && this.distinctCupDetailId.includes(element.drinkGroupTemplateCupDetailId)) {
              cupsize.push(element.drinkGroupTemplateCupDetailId);

              this.priceCardService.priceCardDetails.drinkGroups.cupsizes.push({
                drinkGroupTemplateDetailId: element.drinkGroupTemplateCupDetailId,
                name: element.drinkGroupTemplateCupDetailName,
                sequenceNumber:element.sequenceNumber
              })



              //To check if new drink is added then need to add drink in existing coffeepricing
              if (this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.length && this.distinctCupDetailId.includes(element.drinkGroupTemplateCupDetailId)) {
                let isCoffeeFound = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.some(el => el.drinkGroupTemplateCupDetailId == element.drinkGroupTemplateCupDetailId);
                if (!isCoffeeFound) {
                  let coffePrice = [];
                  let coffePriceList = [];
                  let coffeIndex = 0;
                  this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.forEach(coffeeElement => {
                    if (!coffePrice.includes(coffeeElement.drinkGroupTemplateDetailId)) {
                      coffePrice.push(coffeeElement.drinkGroupTemplateDetailId);
                      coffePriceList.push(
                        {
                          priceCardDrinkDetailId: '',
                          drinkGroupTemplateDetailId: coffeeElement.drinkGroupTemplateDetailId,
                          drinkGroupTemplateDetailName: coffeeElement.drinkGroupTemplateDetailName,
                          drinkGroupTemplateCupDetailId: element.drinkGroupTemplateCupDetailId,
                          drinkGroupTemplateCupDetailName: element.drinkGroupTemplateCupDetailName,
                          primaryCoffeeId: coffeeElement.drinkGroupTemplateDetailId,
                          primaryCoffeeName: coffeeElement.name,
                          primaryMilkId: coffeeElement.drinkGroupTemplateDetailId,
                          primaryMilkName: coffeeElement.name,
                          price: '',
                          sequenceNumber:element.sequenceNumber
                        }
                      );
                    }
                    if (coffeIndex === (this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.length - 1)) {
                      setTimeout(() => {
                        this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.concat(coffePriceList);
                        this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = this.priceCardService.sortGridByGroup(this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList);//for sorting
                      }, 500);
                    }
                    coffeIndex++;
                  });
                }
              }

              //To check if new drink is added then need to add drink in existing milkpricing
              if (this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.length && this.distinctCupDetailId.includes(element.drinkGroupTemplateCupDetailId)) {
                let isMilkFound = this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.some(el => el.drinkGroupTemplateCupDetailId === element.drinkGroupTemplateCupDetailId);
                if (!isMilkFound) {
                  let milkPrice = [];
                  let milkPriceList = [];
                  let milkIndex = 0;
                  this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.forEach(milkElement => {
                    if (!milkPrice.includes(milkElement.drinkGroupTemplateDetailId)) {
                      milkPrice.push(milkElement.drinkGroupTemplateDetailId);
                      milkPriceList.push(
                        {
                          priceCardDrinkDetailId: '',
                          drinkGroupTemplateDetailId: milkElement.drinkGroupTemplateDetailId,
                          drinkGroupTemplateDetailName: milkElement.drinkGroupTemplateDetailName,
                          drinkGroupTemplateCupDetailId: element.drinkGroupTemplateCupDetailId,
                          drinkGroupTemplateCupDetailName: element.drinkGroupTemplateCupDetailName,
                          primaryCoffeeId: milkElement.drinkGroupTemplateDetailId,
                          primaryCoffeeName: milkElement.name,
                          primaryMilkId: milkElement.drinkGroupTemplateDetailId,
                          primaryMilkName: milkElement.name,
                          price: '',
                          sequenceNumber:element.sequenceNumber
                        }
                      );
                    }
                    if (milkIndex === (this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.length - 1)) {
                      setTimeout(() => {
                        this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList = this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.concat(milkPriceList);
                        this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList = this.priceCardService.sortGridByGroup(this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList);//for sorting
                      }, 500);
                    }
                    milkIndex++;
                  });
                }
              }

              //To check if new drink is added then need to add drink in existing syruppricing
              let isSyrupFound = this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.some(el => el.drinkGroupTemplateCupDetailId === element.drinkGroupTemplateCupDetailId);
              if (!isSyrupFound && !drinkGroupTemplateCupIds.includes(element.drinkGroupTemplateCupDetailId && this.distinctCupDetailId.includes(element.drinkGroupTemplateCupDetailId))) {
                let syrupPrice = [];
                let syrupPriceList = [];
                drinkGroupTemplateCupIds.push(element.drinkGroupTemplateCupDetailId);
                syrupPriceList.push(
                  {
                    priceCardDrinkDetailId: '',
                    drinkGroupTemplateDetailId: '00000000-0000-0000-0000-000000000000',
                    drinkGroupTemplateDetailName: '',
                    drinkGroupTemplateCupDetailId: element.drinkGroupTemplateCupDetailId,
                    drinkGroupTemplateCupDetailName: element.drinkGroupTemplateCupDetailName,
                    primaryCoffeeId: '',
                    primaryCoffeeName: '',
                    primaryMilkId: '',
                    primaryMilkName: '',
                    price: '',
                    sequenceNumber:element.sequenceNumber
                  }
                );
                setTimeout(() => {
                  this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.concat(syrupPriceList);  
                  
                  this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = this.sortByKey(this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList, 'sequenceNumber');//for sorting                
                  this.priceCardService.setSyrupGroup();
                }, 500);

              }
            }
          });
        }
      }else{
        this.isLoadDrink = true;
      }
      if(fromLoad){
        this.spinner.hide();
      }
    });
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  addDrink() {
    let list = [];
    let drintIndex = 0;
    let isValid = true;
    this.selectedDrinks.forEach(drink => {
      let cupIndex = 0;
      this.selectedCupSize.forEach(cup => {
        let cupDetails = this.cupSizes.filter(c=>c.drinkGroupTemplateDetailId == cup.drinkGroupTemplateDetailId);        
        let obj = {
          priceCardDrinkDetailId: '',
          drinkGroupTemplateDetailId: drink.drinkGroupTemplateDetailId,
          drinkGroupTemplateDetailName: drink.name,
          drinkGroupTemplateCupDetailId: cup.drinkGroupTemplateDetailId,
          drinkGroupTemplateCupDetailName: cup.name,
          primaryCoffeeId: this.primaryCoffeeDetails.drinkGroupTemplateDetailId,
          primaryCoffeeName: this.primaryCoffeeDetails.name,
          primaryMilkId: this.primaryMilkDetails.drinkGroupTemplateDetailId,
          primaryMilkName: this.primaryMilkDetails.name,
          price: '',
          sequenceNumber:cupDetails[0].sequenceNumber
        };

        list.push(obj);
        let found = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.some(el => el.drinkGroupTemplateDetailId === drink.drinkGroupTemplateDetailId && el.drinkGroupTemplateCupDetailId === cup.drinkGroupTemplateDetailId);
        if (found) {
          isValid = false;
        }
        if (drintIndex === (this.selectedDrinks.length - 1)) {
          if (cupIndex === (this.selectedCupSize.length - 1)) {
            if (!isValid) {
              this.toaster.open({
                text: 'Selected combination is already exists',
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
            } else {
              this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.concat(list);
              this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.forEach(data => {
                data.hasMilk = this.hasPrimaryIngredients(data, 'milk');
                data.hasCoffee = this.hasPrimaryIngredients(data, 'coffee');
                if (!data.hasMilk) { data.primaryMilkId = null; data.primaryMilkName = null };
                if (!data.hasCoffee) { data.primaryCoffeeId = null; data.primaryCoffeeName = null };
              });
              this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList = this.priceCardService.sortGridByGroup(this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList);//for sorting
              
              this.selectedDrinks = [];
              this.selectedCupSize = [];
              /*GD2-3065*/
              this.onDrinksPricingChange();
              var allSteps: any = document.getElementsByTagName("mat-step-header");
              if (allSteps.length > 0) {
                for (var i = 2; i < allSteps.length; i++) {
                  allSteps[i].style.pointerEvents = "none";
                }
              }
              /*GD2-3065*/
            }
          }
        }
        cupIndex++;
      });
      drintIndex++;
    });
  }
  submitForm(type) {
    this.isTouchedSubmit = true;
    if (type != 'next') {
      this.clickButton.next({ stepKey: 'drinks-pricing', buttonType: type });
    } else {
      if(!this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.length){
        this.toaster.open({
          text: 'At least a single drink should have added',
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      }else{
       let isFoundInValidPrice = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.filter(el => el.price == '');
       
       if(isFoundInValidPrice.length){
         this.toaster.open({
          text: 'Please complete pricing for highlighted Drink(s)',
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
       }else{

      let body = {
        "priceCardVersionId": this.priceCardService.priceCardDetails.priceCardVersionId,
        "detailType": "drink",
        "priceCardDetails": this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList
      }
      this.spinner.show();
      this.priceCardService.addPriceCardDetails(body).subscribe(async response => {
        //----GD-2800---//
        await this.manageCMS();
        //----GD-2800---//        
        
        let removeCups = [];
        let removeCupIndex = 1;
        this.cupSizes.forEach(cupElement => {
          if (!response.distinctCupDetailId.includes(cupElement.drinkGroupTemplateDetailId)) {
            removeCups.push(cupElement.drinkGroupTemplateDetailId);
          }
          if (removeCupIndex == this.cupSizes.length) {
            let rcIndex = 1;
            if (!removeCups.length) {
              this.spinner.hide();
              this.distinctCupDetailId = response.distinctCupDetailId;
              this.getPriceCardDetails();
              this.clickButton.next({ stepKey: 'drinks-pricing', buttonType: type });
            } else {
              removeCups.forEach(rc => {
                //to remove cus sizes from existing coffe milk and syrups list
                this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.filter(el => el.drinkGroupTemplateCupDetailId != rc);

                this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList = this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.filter(el => el.drinkGroupTemplateCupDetailId != rc);

                this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.filter(el => el.drinkGroupTemplateCupDetailId != rc);
                this.priceCardService.setSyrupGroup();
                if (rcIndex == removeCups.length) {
                  this.spinner.hide();
                  this.distinctCupDetailId = response.distinctCupDetailId;
                  this.getPriceCardDetails();
                  this.clickButton.next({ stepKey: 'drinks-pricing', buttonType: type });
                }
                rcIndex++;
              });
            }
          }
          removeCupIndex++;
        });
      }, err => {
        if (err && err.error) {
          this.spinner.hide();
          let errors = err.error;
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
            if(element.msg == "Oops! Something went wrong."){
              this.router.navigate(['/price-card']);
            }
          });
        }
      });
    }
    }
    }
  
  }
  async manageCMS(){
    await this.cupSizes.forEach(cupElement => {
      let isOldDrinkExists = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.some(el => (el.priceCardDrinkDetailId != null && el.priceCardDrinkDetailId != '') && el.drinkGroupTemplateCupDetailName == cupElement.name);
      
      if(!isOldDrinkExists){
        let isNewDrinkExists = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.some(el => el.drinkGroupTemplateCupDetailName == cupElement.name);
        if(isNewDrinkExists){
          if(this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.length){
            let hasCoffee = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.some(v => v.drinkGroupTemplateCupDetailName === cupElement.name);
            if(hasCoffee){
            
              this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.map(v => v.drinkGroupTemplateCupDetailName === cupElement.name ? { ...v, price: '',priceCardDrinkDetailId: '' } : v);
            
          }
        }

          if(this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.length){
            let hasMilk = this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.some(v => v.drinkGroupTemplateCupDetailName === cupElement.name);
            if(hasMilk){
              this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList = this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.map(v => v.drinkGroupTemplateCupDetailName === cupElement.name ? { ...v, price: '',priceCardDrinkDetailId: '' } : v);            
            }
          }

          if(this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.length){
            let hasSyrup = this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.some(v => v.drinkGroupTemplateCupDetailName === cupElement.name);
            if(hasSyrup){
          
              this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.map(v => v.drinkGroupTemplateCupDetailName === cupElement.name ? { ...v, price: '',priceCardDrinkDetailId: '' } : v); 
              this.priceCardService.setSyrupGroup();           
            }
          }

        }else{

        this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.filter(el => el.drinkGroupTemplateCupDetailName != cupElement.name);

        this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList = this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList.filter(el => el.drinkGroupTemplateCupDetailName != cupElement.name);;

        this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.filter(el => el.drinkGroupTemplateCupDetailName != cupElement.name);
        this.priceCardService.setSyrupGroup();
      }
      }
    });    
  }
  
  async setCupSizeData(drinks){
    let cups = [];
    await drinks.forEach(drink => {

      if(!cups.includes(drink.drinkGroupTemplateCupDetailId)){
        cups.push(drink.drinkGroupTemplateCupDetailId);
        this.priceCardService.priceCardDetails.drinkGroups.cupsizes.push({
          drinkGroupTemplateDetailId: drink.drinkGroupTemplateCupDetailId,
          name: drink.drinkGroupTemplateCupDetailName,
          sequenceNumber:drink.sequenceNumber
        })      
      }
    }); 
    this.isLoadDrink = true;   
  }
  removePrimary(type, i) {
    if (type == 'coffee') {
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryCoffeeId'] = null;
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryCoffeeName'] = '';
    } else {
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryMilkId'] = null;
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryMilkName'] = '';
    }
  }
  addPrimary(type, i) {
    if (type == 'coffee') {
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryCoffeeId'] = this.primaryCoffeeDetails.drinkGroupTemplateDetailId;
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryCoffeeName'] = this.primaryCoffeeDetails.name;
    } else {
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryMilkId'] = this.primaryMilkDetails.drinkGroupTemplateDetailId;
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['primaryMilkName'] = this.primaryMilkDetails.name;
    }
  }
  onPriceFocusin(i) {
    if(this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']){
      
      this.priceFocusIn[i]= this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price'];
      
      //this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']= this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']*100;  
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']=this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price'].replace(/\./g,'')
      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price'] = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price'].replace(/^0+/, '');
    }
  }

  onPriceFocusout(i) {
    this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['touched'] = true;
    if (this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']) {
      if(this.priceFocusIn[i] !=  this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']){
        this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price'] = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']/100;
        
        this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price'] = parseFloat(this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList[i]['price']).toFixed(2);
      }
    }
  }
  removeDrink(i){
   let drinkGroupsList = this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList;
    drinkGroupsList.splice(i, 1);
    this.spinner.show();
    this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList = [];
    setTimeout(() => {
      /*GD2-3065*/
      this.onDrinksPricingChange();
      var allSteps: any = document.getElementsByTagName("mat-step-header");
      if (allSteps.length > 0) {
        for (var i = 2; i < allSteps.length; i++) {
          allSteps[i].style.pointerEvents = "none";
        }
      }
      /*GD2-3065*/

      this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList = drinkGroupsList;
      if(this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList.length == 0 || this.priceCardService.priceCardDetails.drinkGroups.drinkGroupsList == null){
        this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = [];
        this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = [];
        this.priceCardService.priceCardDetails.drinkGroups.milkGroupsList = [];
        this.priceCardService.setSyrupGroup();
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 100);
    }, 10);
  }

  hasPrimaryIngredients(element, type) : boolean {
    var displayPrimaryField = false;
    if (this.drinks.length) {
      var drinkDetails:any = this.drinks.filter(x => x.drinkGroupTemplateDetailId == element.drinkGroupTemplateDetailId);
      if (drinkDetails.length) {
        displayPrimaryField = type == 'coffee' ? drinkDetails[0].hasCoffee == "True" : (type == 'milk' ? drinkDetails[0].hasMilk : false);
      }
    }
    return displayPrimaryField;
  }
}


