import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {combineLatest, merge, Observable, of, pipe, ReplaySubject, Subject, Subscription} from "rxjs";
import {
  catchError,
  delay,
  filter,
  map,
  mapTo,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  take
} from "rxjs/operators";
import {TemplateNameComponent} from "../../components/template-name/template-name.component";
import {
  MarketCountrySelectionComponent
} from "../../components/market-country-selection/market-country-selection.component";
import {
  DrinkPropertiesComponent,
  SelectedDrinkProperties
} from "../../components/drink-properties/drink-properties.component";
import {DrinksSetupComponent} from "../../components/drink-list/drinks-list.component";
import {SelectedMarketCountry} from "../../components/market-country-selection/selected-market-country";
import {DrinkSetup} from "../../components/drink-list/drinks-setup";
import {DrinkSetupService} from "../../services/drink-setup.service";
import {
  DrinkGroupTemplateDetailDrink,
  RequestAddDrinkGroupTemplate
} from "../../services/request-add-drink-group-template";
import { Toaster } from "ngx-toast-notifications";
import { TemplateNameModel } from "../../components/template-name/template-name.model";

@Component({
  selector: 'app-add-drinks-setup-page',
  templateUrl: './add-drinks-setup-page.html',
  styleUrls: ['./add-drinks-setup-common.css']
})

export class AddDrinksSetupComponent implements OnInit, OnDestroy {
  protected readonly parentPagePath = '/drinks-setup';
  private readonly snackBarTimeout: number = 8000;

  @ViewChild('templateName') private templateNameComponent: TemplateNameComponent;
  @ViewChild('marketCountrySelection') private marketCountrySelectionComponent: MarketCountrySelectionComponent;
  @ViewChild('drinkProperties') private drinkPropertiesComponent: DrinkPropertiesComponent;
  @ViewChild('drinksList') private drinksSetupComponent: DrinksSetupComponent;

  private saveSubscription: Subscription;

  // Source Streams
  readonly saveClick$ = new Subject<true>();
  readonly templateName$ = new ReplaySubject<TemplateNameModel>(1);
  readonly marketCountry$ = new ReplaySubject<SelectedMarketCountry>(1);
  readonly drinkProperties$ = new ReplaySubject<SelectedDrinkProperties>(1);
  readonly drinkList$ = new ReplaySubject<DrinkSetup[]>(1);
  readonly snackBarClose$ = new Subject<true>();

  // Presentation Streams
  protected loading$: Observable<boolean>;
  protected showSnackBar$: Observable<boolean>;
  protected snackBarHeader$: Observable<string>;
  protected snackBarMessage$: Observable<string>;

  constructor(
    private drinkSetupService: DrinkSetupService,
    private router: Router,
    private toaster: Toaster) {
  }

  ngOnInit(): void {
    const noError = {header: '', message: ''};

    // Intermediate Streams
    // --------------------
    const validateAll$ = pipe(
      map(() => {
        let isTemplateNameValid = this.templateNameComponent.validate();
        let isMarketCountryValid = this.marketCountrySelectionComponent.validate();
        let isDrinkPropertiesValid = this.drinkPropertiesComponent.validate();
        let isDrinkListValid = this.drinksSetupComponent.validate();

        return isTemplateNameValid && isMarketCountryValid && isDrinkPropertiesValid && isDrinkListValid;
      })
    );

    const allValues$ = combineLatest([
      this.templateName$,
      this.marketCountry$,
      this.drinkProperties$,
      this.drinkList$
    ]).pipe(
      map(([tn, mc, dp, dl]) => ({templateName: tn, marketCountry: mc, drinkProperties: dp, drinkList: dl}))
    );

    const validatedSave$ = this.saveClick$.pipe(validateAll$);

    const validSave$: Observable<true> = validatedSave$.pipe(
      filter(v => v),
      mapTo(true)
    );

    const saveResult$ = validSave$.pipe(
      switchMap(() => allValues$.pipe(take(1))),
      map(v => new RequestAddDrinkGroupTemplate(
        v.templateName.name,
        v.templateName.propositionType,
        v.marketCountry.market.id,
        v.marketCountry.level2?.id,
        v.marketCountry.level3?.id,
        v.drinkList.map(v => new DrinkGroupTemplateDetailDrink(v.id)),
        v.drinkProperties.cupSize.map(v => v.id),
        v.drinkProperties.milk.map(v => v.id),
        v.drinkProperties.coffee.map(v => v.id),
        v.drinkProperties.syrup.map(v => v.id),
        v.drinkProperties.coffee.find(v => v.isPrimary)?.id,
        v.drinkProperties.milk.find(v => v.isPrimary)?.id
      )),
      switchMap(v => this.drinkSetupService.saveDrinkSetup(v).pipe(
        map(v => ({result: v})),
        catchError((e: HttpErrorResponse) => of('title' in e.error && 'errors' in e.error
          ? {error: {title: e.error.title, message: Object.keys(e.error.errors).map(k => e.error.errors[k]).join(', ')}}
          : Array.isArray(e.error) && e.error.length > 0 && 'code' in e.error[0]
            ? {error: {title: e.error[0].code, message: e.error[0].msg}}
            : {error: {title: 'Unexpected error.', message: 'An unexpected error has occurred.'}}))
      )),
      publishReplay(1),
      refCount()
    );

    const snackBarMessage$ = merge(
      validatedSave$.pipe(map(v => v ? noError : {
        header: 'Selection error.',
        message: 'Please complete the mandatory fields.'
      })),
      saveResult$.pipe(map(v => 'error' in v ? {
        header: v.error.title,
        message: v.error.message
      } : noError)),
      this.snackBarClose$.pipe(mapTo(noError))
    ).pipe(
      switchMap(v => v !== noError ? of(noError).pipe(delay(this.snackBarTimeout), startWith(v)) : of(v)),
      startWith(noError)
    );

    // Presentation Streams
    // --------------------
    this.loading$ = merge(
      validSave$.pipe(mapTo(true)),
      saveResult$.pipe(mapTo(false))
    );

    this.showSnackBar$ = snackBarMessage$.pipe(
      map(v => v !== noError)
    );

    this.snackBarHeader$ = snackBarMessage$.pipe(
      map(v => v !== noError ? v.header : '')
    );

    this.snackBarMessage$ = snackBarMessage$.pipe(
      map(v => v !== noError ? v.message : '')
    );

    this.saveSubscription = saveResult$
      .pipe(filter(v => !('error' in v)))
      .subscribe(() => {
          this.router.navigate([this.parentPagePath]);

          this.toaster.open({
            text: "Drinks Setup created successfully",
            type: 'success',
            position: 'top-right',
            duration: 10000
          });
      }
    );
  }

  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }
}
