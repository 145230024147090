import { Component, Input } from '@angular/core';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CcDialogService } from '../../../../../../services/ui/cc-dialog.service';

@Component({
  selector: 'app-new-task-header',
  templateUrl: './new-task-header.component.html',
  styleUrls: ['./new-task-header.component.css', '../new-task-step-common.css'],
})
export class NewTaskHeaderComponent {
  @Input() taskId = '';
  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  constructor(
    private router: Router,
    private dialogService: CcDialogService,
  ) {
  }

  public openConfirmDialog(): void {
    const { key, component } = this.dialogService.openDialog();
    component.instance.title = 'Cancel New Task';
    component.instance.description = 'Are you sure you want to cancel creating this task?';
    component.instance.primary = 'No';
    component.instance.secondary = 'Yes';
    const dialogSubs = new Subscription();
    dialogSubs.add(
      component.instance.primaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
      }),
    );
    dialogSubs.add(
      component.instance.secondaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
        this.router.navigate(['/task-management-overview']);
      }),
    );
  }

  public isSummaryPage(): boolean {
    return this.taskId !== '' && this.taskId !== undefined && this.taskId != null;
  }
}
