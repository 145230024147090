import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-file-repository-page",
  templateUrl: "./file-repository-page-remote-update.html",
  styleUrls: ["./../../remote-update-tasks.component.css"],
})
export class FileRepositoryPageComponent implements OnInit {
  ngOnInit(): void {}

}
