import { SummaryInformationModel } from './summary-info-model';

export class SummaryCardModel {
    title: string;
    itens: SummaryInformationModel[];
    numberChips: number;
    textLenght: number;

    constructor(title: string, itens: SummaryInformationModel[], numberChips: number = 1, textLenght: number = 15) {
      this.title = title;
      this.itens = itens;
      this.numberChips = numberChips;
      this.textLenght = textLenght;
    }
}