import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

@Component({
  selector: 'grid-highlight-icon-button',
  templateUrl: './highlight-icon-button.component.html',
  styleUrls: ['./highlight-icon-button.component.css']
})
export class HighlightIconButtonComponent implements OnInit {
  @Input() id: string = '';
  @Input() isDisabled = false;
  @Input() btnTooltip = '';
  @Input() btnTooltipPosition: TooltipPosition = 'bottom';
  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

}
