import { Component, OnInit } from "@angular/core";
import { RemoteUpdateTaskExportCsvService } from "../../services/event-services/remote-update-task-export-csv.service";
import { RemoteUpdateTaskToggleSearchService } from "../../services/event-services/remote-update-task-toggle-search.service";
import { RemoteUpdateTaskSearchService } from "../../services/event-services/remote-update-task-search.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-header-remote-update",
  templateUrl: "./header-remote-update.component.html",
  styleUrls: ["./../../remote-update-tasks.component.css", './header-remote-update.component.css'],
})
export class HeaderRemoteUpdateComponent implements OnInit {
  searchText: string

  constructor(
    private remoteUpdateTaskExportCsvService: RemoteUpdateTaskExportCsvService,
    private remoteUpdateTaskToggleSearchService: RemoteUpdateTaskToggleSearchService,
    private remoteUpdateTaskSearchService: RemoteUpdateTaskSearchService) {}

  ngOnInit(): void { }

  sendExportToCsvEvent() {
    this.remoteUpdateTaskExportCsvService.sendExportRemoteUpdateTasksToCsvEvent();
  }

  toggleHeaderFilterEvent() {
    this.remoteUpdateTaskToggleSearchService.toggleRemoteUpdateHeaderFilter();
  }

  sendSearchDatatableEvent(){
    this.remoteUpdateTaskSearchService.sendSearchDatatable(this.searchText);
  }
}
