import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetPackageSelectList } from '../../dtos/get-package-select-list.model';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { RemoteUpdateTaskType } from '../../enums/remote-update-task-type.enum';

@Injectable({
  providedIn: 'root'
})
export class GetPackageService {
 
	constructor(
		
		private cookieService: CookieService,
		private httpClient: HttpClient
	) { }

	cookieName: string = environment.cookieName

	getRemoteUpdatePackageList(
    taskType: RemoteUpdateTaskType, 
    proposition: string
  ): Observable<GetPackageSelectList>
  {		
		const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token')
            }
		}

		return this.httpClient
		.get<GetPackageSelectList>(environment.apiUrl.remoteUpdatePackage + "/v1/remoteupdatepackage/" + `tasktype/${taskType}/proposition/${proposition}`, requestOptions)
		.pipe(map(response => {
			return response;
		}));
	}
}
