import { RemoteUpdateTaskLevel } from "./remote-update-task-level";

export interface RemoteUpdateTaskMachine {
    page: number;
    limit: number;
    queryParams: Map<string, string>;
    levels: RemoteUpdateTaskLevel;
    Type: RemoteUpdateTaskMachineType; 
 }

 export enum RemoteUpdateTaskMachineType {
   GetAll,
   GetAllCertificates
  }
