import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header-file-repository",
  templateUrl: "./header-file-repository.html",
  styleUrls: ["./../../../../remote-update-tasks.component.css"],
})
export class HeaderFileRepositoryComponent implements OnInit {
  ngOnInit(): void {}
}
