import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { RoleService } from 'src/app/services/role/role.service';
import { PriceCardPreviewComponent } from '../price-card-preview/price-card-preview.component';
declare var $: any
@Component({
  selector: 'app-price-card-add',
  templateUrl: './price-card-add.component.html',
  styleUrls: ['./price-card-add.component.css']
})
export class PriceCardAddComponent implements OnInit {
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  steps: any = {
    ['information']: { isCompleted: false, index: 0 },
    ['drinks-pricing']: { isCompleted: false, index: 1 },
    ['coffee-pricing']: { isCompleted: false, index: 2 },
    ['milk-pricing']: { isCompleted: false, index: 3 },
    ['syrups-pricing']: { isCompleted: false, index: 4 },
    ['preview']: { isCompleted: false, index: 5 },
  }
  dialogRef: MatDialogRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: ''
  }
  screenType = 'add';
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(PriceCardPreviewComponent) PriceCardPreviewComponent:PriceCardPreviewComponent;
  isEditable = false;
  isPrimaryCoffeeChanged = false;
  isPrimaryMilkChanged = false;
  enableStepperClick = false;
  previewByNextStep = false;
  isDrinkChanged = false;

  constructor(public roleService: RoleService, private activatedRoute: ActivatedRoute,
    private toaster: Toaster, public dialog: MatDialog, private spinner: NgxSpinnerService, private router: Router, public priceCardService: PriceCardService) {
    this.resetPriceCard();
    this.activatedRoute.params.subscribe(params => {
      if (params.priceCardVersionId) {
        this.priceCardService.priceCardDetails.priceCardVersionId = params.priceCardVersionId;
      } else {
        this.priceCardService.priceCardDetails.priceCardVersionId = '';
      }
    });
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.screenType) {
        this.screenType = queryParams.screenType;
      } else if (this.priceCardService.priceCardDetails.priceCardVersionId != '') {
        this.screenType = 'edit';
      }
    });
  }

  ngOnInit(): void {
      if (!this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isMarketAdmin && !this.roleService.objRole.isLevel2Admin && !this.roleService.objRole.isLevel3Admin && this.screenType != 'read') {
      this.router.navigate(['/unauthorized']);
    }
    if (this.screenType == 'edit') {
      this.getPriceCardInformation();
    }
  }
  resetPriceCard() {
    this.priceCardService.priceCardDetails = {
      priceCardId: '',
      priceCardVersionId:'',
      priceCardName: '',
      drinkGroupTemplateId: '',
      drinkGroups: { drinkGroupsList: [], cupsizes: [], coffeeGroupsList: [], milkGroupsList: [], syrupGroupsList: [], entityMappingList: [] },
      priceCardEntities: [],
      priceCardPreviews: []
    }
  }
  getPriceCardInformation() {
    this.priceCardService.getPriceCardInformation(this.priceCardService.priceCardDetails.priceCardVersionId).subscribe(response => {
      if(response && response.data){
      this.priceCardService.priceCardDetails.drinkGroupTemplateId = response.data.drinkGroupTemplateId;
      this.priceCardService.priceCardDetails.priceCardName = response.data.priceCardName;
    }else{
      this.spinner.hide();
      this.toaster.open({
        text: 'Oops! Something went wrong.',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      this.router.navigate(['/price-card']);
    }
    });
  }
  resetAllSteps() {
    this.steps = {
      ['information']: { isCompleted: false, index: 0 },
      ['drinks-pricing']: { isCompleted: false, index: 1 },
      ['coffee-pricing']: { isCompleted: false, index: 2 },
      ['milk-pricing']: { isCompleted: false, index: 3 },
      ['syrups-pricing']: { isCompleted: false, index: 4 },
      ['entity-mapping']: { isCompleted: false, index: 5 },
      ['preview']: { isCompleted: false, index: 6 },
    }
    for (let i = 1; i < 7; i++) {
      $('#cdk-step-label-0-' + i).removeClass('step-done');
    }
    this.priceCardService.priceCardDetails.priceCardEntities = [];
    this.priceCardService.priceCardDetails.priceCardPreviews = [];
    this.priceCardService.priceCardDetails.drinkGroups = {
      drinkGroupsList: [], cupsizes: [], coffeeGroupsList: [],
      milkGroupsList: [], syrupGroupsList: [], entityMappingList: []
    };
    this.priceCardService.priceCardDetails.priceCardName = '';
    let templateId = this.priceCardService.priceCardDetails.drinkGroupTemplateId;
    this.priceCardService.priceCardDetails.drinkGroupTemplateId = '';
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.priceCardService.priceCardDetails.drinkGroupTemplateId = templateId;
    }, 500);

  }
  next(stepper, params) {
    if (params.buttonType == 'next') {
      if (params.needReset) {
        this.resetAllSteps();
      }
      if (this.screenType == 'add') {
        var allSteps: any = document.getElementsByTagName("mat-step-header");
        if (allSteps.length > 0) {
          for (var i = 0; i < allSteps.length; i++) {
            allSteps[i].style.pointerEvents = "none";
          }
        }
      }
      // GD2-2126
      if (params.stepKey == 'information' && this.screenType == 'edit') {
        this.enableStepperClick = true;
        
        if (this.isDrinkChanged)
        {
          this.steps = {
            ['information']: { isCompleted: true, index: 0 },
            ['drinks-pricing']: { isCompleted: true, index: 1 },
            ['coffee-pricing']: { isCompleted: false, index: 2 },
            ['milk-pricing']: { isCompleted: false, index: 3 },
            ['syrups-pricing']: { isCompleted: false, index: 4 },
            ['entity-mapping']: { isCompleted: false, index: 5 },
            ['preview']: { isCompleted: false, index: 6, },
          }
        }
        else {
          this.steps = {
            ['information']: { isCompleted: true, index: 0 },
            ['drinks-pricing']: { isCompleted: true, index: 1 },
            ['coffee-pricing']: { isCompleted: true, index: 2 },
            ['milk-pricing']: { isCompleted: true, index: 3 },
            ['syrups-pricing']: { isCompleted: true, index: 4 },
            ['entity-mapping']: { isCompleted: true, index: 5 },
            ['preview']: { isCompleted: true, index: 6, },
          }
        }
      } else if (params.stepKey == 'drinks-pricing' && this.screenType == 'edit') {
        this.steps['coffee-pricing']['isCompleted'] = false;
        this.steps['milk-pricing']['isCompleted'] = false;
        this.steps['syrups-pricing']['isCompleted'] = false;
        this.steps['preview']['isCompleted'] = false;
      }

      if (params.isPrimaryCoffeeChanged && !this.isPrimaryCoffeeChanged)
        this.isPrimaryCoffeeChanged = params.isPrimaryCoffeeChanged;
      if (params.isPrimaryMilkChanged && !this.isPrimaryMilkChanged)
        this.isPrimaryMilkChanged = params.isPrimaryMilkChanged;
      if (params.stepKey != 'information')
        this.spinner.show();

      this.steps[params.stepKey]['isCompleted'] = true;
      $('#cdk-step-label-0-' + this.steps[params.stepKey]['index']).addClass('step-done');

      var StapperNext: any = document.getElementById('cdk-step-label-0-' + (this.steps[params.stepKey]['index'] + 1));
      if (StapperNext != null && StapperNext != undefined)
        StapperNext.style.pointerEvents = "auto";

      if (params.stepKey == 'syrups-pricing') {
        this.previewByNextStep = true;
        this.PriceCardPreviewComponent.getPriceCardDetails(true);
        setTimeout(() => {
          stepper.next();
        }, 500);
      } else {
        setTimeout(() => {
          if(params.stepKey != 'information')
            this.spinner.hide();
          
          stepper.next();
        }, 500);
      }
    } else if (params.buttonType == 'previous') {
      stepper.previous();
    } else if (params.buttonType == 'cancel') {
      if (this.priceCardService.priceCardDetails.priceCardVersionId == '') {
        this.router.navigate(['/price-card'])
      } else {
        this.openDialog('form_cancel');
      }
    } else if (params.buttonType == 'create' || params.buttonType == 'save') {
      this.router.navigate(['/price-card'])
    }
  }  
  openDialog(type: string = '', element = {}, slot_i = -1): void {
    if (type == 'form_cancel') {
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
        deviceName: '',
        status: '',
        title: 'Confirmation',
        type: type
      }
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }
  onConfirm(type: string = 'form_cancel') {
    this.dialogRef.close();
    if (type == 'form_cancel') {
      this.deletePriceCard();
      this.router.navigate(['/price-card']);
    }
  }
  deletePriceCard() {
    this.priceCardService.deleteDraftPriceCard(this.priceCardService.priceCardDetails.priceCardVersionId).subscribe(response => {
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
  onStepsChange(event, stepper) {
    // pricecard preview screen
    if (event.selectedIndex == 5) {
      this.priceCardService.priceCardPreviewSearch = '';
      if (this.steps['information']['isCompleted'] && this.steps['drinks-pricing']['isCompleted'] && this.steps['coffee-pricing']['isCompleted'] && this.steps['milk-pricing']['isCompleted'] && this.steps['syrups-pricing']['isCompleted']) {
        this.PriceCardPreviewComponent.getPriceCardDetails(true);
      }
    }
  }

  onDrinksPricingChanges() {
    if (this.screenType == 'edit')
    {
      this.steps['coffee-pricing']['isCompleted'] = false;
      this.steps['milk-pricing']['isCompleted'] = false;
      this.steps['syrups-pricing']['isCompleted'] = false;
      this.steps['preview']['isCompleted'] = false;
      this.isDrinkChanged = true;
    }
  }
}
