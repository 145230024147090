import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import {
  CustomNgxDatetimeAdapter,
  NgxMatMomentDateAdapterOptions,
} from '../../../sox-audit-logs/sox-audit-logs-list/customngx.datetime.adapter';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AvailableAppearances } from '@costa-coffee/pattern-lib';
import { MachineService } from '../../../../services/machine/machine.service';
import { of, Subscription } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { Toaster } from 'ngx-toast-notifications';
import { MachineLog } from '../../../../interfaces/machine/machine-log';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm:ss',
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const CustomNgxDatetimeAdapterFactory = () => {
  const options: NgxMatMomentDateAdapterOptions = { strict: false, useUtc: true };
  return new CustomNgxDatetimeAdapter('', options);
};

@Component({
  selector: 'app-entity-request-log',
  templateUrl: './entity-request-log.component.html',
  styleUrls: ['./entity-request-log.component.css'],
  providers: [
    {
      provide: NgxMatDateAdapter,
      useFactory: CustomNgxDatetimeAdapterFactory,
      deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EntityRequestLogComponent implements OnInit, OnDestroy {

  readonly requestSentText = 'A machine log request has been sent to the machine.';
  readonly dateRequiredText = 'Please select a valid date.';

  @Input() machineId!: string;
  maxDate = moment();
  minDate = moment().subtract(180, 'days');
  formGroup: FormGroup;
  infoText = '';
  infoVariant: AvailableAppearances | undefined = undefined;
  loadingRequestLogs = false;
  requestLogsSub: Subscription = new Subscription();
  subs: Subscription = new Subscription();
  logs: MachineLog[] = [];
  delayTime = 15 * 60 * 1000;

  constructor(
    private machineService: MachineService,
    private toaster: Toaster,
  ) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      StartDate: new FormControl({
        value: null,
        disabled: false,
      }, [Validators.required]),
    });

    this.subs.add(
      this.formGroup.get('StartDate').valueChanges.subscribe((value) => {
        this.requestLogsSub.unsubscribe();
        this.loadingRequestLogs = false;
        this.infoText = null;
      }),
    );

    this.machineService.getMachineLogs(this.machineId).subscribe(resp => {
      this.logs = resp;
    }, () => {
      this.toaster.open({
        text: `Loading machine logs failed`,
        type: 'danger',
        position: 'top-right',
        duration: 10000,
      });
    });
  }

  ngOnDestroy(): void {
    this.requestLogsSub.unsubscribe();
    this.subs.unsubscribe();
  }

  submitForm(): void {
    this.formGroup.markAsTouched();
    if (this.formGroup.valid) {
      const data = this.formGroup.getRawValue();
      data.StartDate = data.StartDate.format('YYYY-MM-DD');
      this.formGroup.get('StartDate').disable();
      this.loadingRequestLogs = true;
      this.infoText = this.requestSentText;
      this.infoVariant = undefined;
      this.requestLogsSub.unsubscribe();
      this.machineService.requestMachineLogs(this.machineId, data).subscribe(() => {
        this.infoText = this.requestSentText;
        this.infoVariant = undefined;
        this.requestLogsSub.unsubscribe();
        this.requestLogsSub = of(true).pipe(delay(this.delayTime)).subscribe(
          () => {
            this.loadingRequestLogs = false;
            this.infoText = null;
            this.formGroup.get('StartDate').enable();
          },
        );
        this.toaster.open({
          text: `Machine logs requested successfully`,
          type: 'success',
          position: 'top-right',
          duration: 10000,
        });
      }, (error) => {
        this.loadingRequestLogs = false;
        this.formGroup.get('StartDate').enable();
        this.toaster.open({
          text: `Machine logs request failed`,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      });
    } else {
      this.infoText = this.dateRequiredText;
      this.infoVariant = 'error';
    }
  }
}
