export class CupSizeSetupConfiguration {
    waffleCupsEnabled: boolean = true;    
    plasticCupsEnabled: boolean = true;    
    crockeryEnabled: boolean = true;    
    glassEnabled: boolean = true;    
    parentWaffleCupsEnabled: boolean = true;
    parentPlasticCupsEnabled: boolean = true;
    parentCrockeryEnabled: boolean = true;
    parentGlassEnabled: boolean = true;
}
