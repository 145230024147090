import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { PriceCardTemplateService } from 'src/app/services/pricecard-template/pricecard-template.service';
import { RoleService } from 'src/app/services/role/role.service';
declare var $: any;

@Component({
    selector: 'app-price-card-template-list',
    templateUrl: './pricecard-template-list.component.html',
    styleUrls: ['./pricecard-template-list.component.css']
})

export class PriceCardTemplateListComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['templateName', 'noOfDrinks', 'marketName', 'isActive', 'actions'];
    dataSource: any = [];
    @ViewChild(MatSort) sort: MatSort;
    isShowFilter = false;
    drawerfilter: boolean = false;
    isFilterClear: boolean = false;
    isFilterApply: boolean = false;
    priceCardTemplateList: any = []
    selectedFiled: any;
    markets: any = [];
    drinks: any = [];
    labelTitle: string = "";
    labelAddButton: string = "";
    routeBaseUrl: string = "";
    routeBaseEditUrl: string = "";
    statusList: any = [];
    filter = {
        entityId: [],
        drinkId: [],
        status: '',
        search: ''
    }
    tempfilter = {//[GD2-3706]
        entityId: [],
        drinkId: [],
        status: '',
        search: ''
    }
    filterColumns: string[] = ['entityId', 'drinkId', 'status'];//[GD2-3706]
    dropdownSettings = {
        singleSelection: false,
        defaultOpen: false,
        idField: "masterDataId",
        textField: "text",
        enableCheckAll:false,
        itemsShowLimit: 3,
        allowSearchFilter: true
    };
    recordsTotal = 0;
    pageIndex = 0;
    pageSizeOptions: number[] = [10, 25, 50, 100];
    params = {
        pageNumber: 1,
        pageSize: this.pageSizeOptions[0],
        searchText: '',
        sortBy: 'createdDate desc'
    };
    lookUpObject = {
        "search": "",
        "configurationType": MasterLookupEnum.Drink,
        "pageNo": 1,
        "pageSize": -1,
        "orderBy": "text"
    }
    dialogRef: MatDialogRef<any>;
    isPageDataLoad = false;
    @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
    constructor(private spinner: NgxSpinnerService,public priceCardTypeConstant:PriceCardTypeLookupConstant,
        private priceCardTemplateService: PriceCardTemplateService,
        private router: Router,
        public dialog: MatDialog,
        private toaster: Toaster,
        public roleService:RoleService,
        private azureService: AzureService) {
    }
    async ngOnInit(): Promise<void> {
        await this.SetFeatureFlagsConfigurations();

        if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel1R || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel2R || this.roleService.objRole.isUserAuditorLevel3 || this.roleService.objRole.isUserAuditorLevel3R) {
            this.router.navigate(['/unauthorized']);
        }
        this.getMarketLookup();
        this.getDrinkLookup();
        this.getStatusLookup();
        this.getPriceCardTemplateList();
    }

    applyFilter() {//[GD2-3706]
        this.assignFilterToTempFilter();
        this.getPriceCardTemplateList('apply');
    }

    assignFilterToTempFilter() {//[GD2-3706]
        this.filterColumns.forEach(key => {
            this.tempfilter[key] = this.filter[key];
        });
    }

    changefilter() {
        this.roleService.setPopupScroll('section-pricecardtemplatefilter');
        if (!this.drawerfilter) {
            $(".sidebar-mini").addClass("filter-open");
         }
        else {
            this.filterColumns.forEach(key => {//[GD2-3706]
                this.filter[key] = this.tempfilter[key];
            });
             $(".sidebar-mini").removeClass("filter-open");
             this.roleService.resetPopupScroll();
         }
          setTimeout(() => {
            this.drawerfilter = !this.drawerfilter;
          }, this.roleService.scrollSetTimeoutTime);
    }
    clickFilter(val) {
        this.isShowFilter = val;

    }
    clearFilter() {
        this.filter.entityId = [];
        this.filter.drinkId = [];
        this.filter.status = '';
        this.isFilterClear = true;
        this.isFilterApply = false;
        let fromClear = true;
        this.params.pageNumber = 1
        this.assignFilterToTempFilter();//[GD2-3706]
        this.getPriceCardTemplateList(fromClear);
    }
    clearSearch() {
        this.filter.search = '';
        this.params.pageNumber = 1
        this.getPriceCardTemplateList();
    }
    sortData(e) {
        e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
        this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
        this.params.pageNumber = 1;
        this.getPriceCardTemplateList();
    }
    pageChange(e) {
        this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
        this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
        this.getPriceCardTemplateList();
    }
    getPriceCardTemplateList(fromClear = undefined) {
        this.spinner.show();
        if (!this.isFilterClear) {
            this.isShowFilter = false;
            this.drawerfilter = false;
        }
        this.isFilterClear = false;
        if (fromClear == "apply") {
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll();
        }
        if (fromClear == undefined)
            this.drawerfilter = false;
        if (fromClear == "search" || fromClear == "apply")
            this.params.pageNumber = 1
        let obj = {};
        this.isFilterApply = false;
        if (this.filter.entityId != undefined && this.filter.entityId.length > 0) { obj['entityId'] = this.filter.entityId.map(a=>a.masterDataId); this.isFilterApply = true; };
        if (this.filter.drinkId != undefined && this.filter.drinkId.length > 0) { obj['drinkId'] = this.filter.drinkId.map(a=>a.masterDataId); this.isFilterApply = true; };
        if (this.filter.status) { obj['status'] = this.filter.status == 'Active' ? true : false; this.isFilterApply = true; };
        if (this.filter.search != '') { obj['search'] = this.filter.search; };
        if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
        if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
        if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };

        this.priceCardTemplateService.getPriceCardTemplateList(obj).subscribe(response => {
            this.recordsTotal = response?.params?.count;
            this.spinner.hide();
            this.isPageDataLoad=true;
            if (response && response['data'].length) {
                this.priceCardTemplateList = response['data']
            } else {
                this.priceCardTemplateList = [];
            }
        }, err => {
            let errors = err.errors == undefined ? err.error : err.errors;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }

    getMarketLookup() {
        this.priceCardTemplateService.getMarketLookup().subscribe(response => {
            if (response && response['data'].length) {
                this.markets = response['data'];
            }
        });
    }

    getDrinkLookup() {
        this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Drink).subscribe(response => {
            if (response && response['data'].length) {
                this.drinks = response['data'];
            }
        });
    }
    getStatusLookup() {
        this.priceCardTemplateService.getStatusLookup().subscribe(response => {
            if (response && response['data'].length) {
                this.statusList = response['data'];
            }
        });
    }

    clickToAdd() {
        this.router.navigate(['/pricecard-template/add']);
    }
    onConfirmDeleteCancel() {
        this.dialogRef.close()
    }
    onConfirmDeleteYes() {
        this.spinner.show();
        this.priceCardTemplateService.deletePriceCardTemplate(this.selectedFiled.drinkGroupTemplateId).subscribe(response => {
            this.spinner.hide();
            if (response) {
                let msg = "Price Card Template has been deleted successfully";
                this.toaster.open({
                    text: msg,
                    type: 'success',
                    position: 'top-right',
                    duration: 10000
                });
                this.dialogRef.close()
                this.getPriceCardTemplateList();
            }
        }, err => {
            this.dialogRef.close()
            let errors = err.errors == undefined ? err.error : err.errors;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }
    onConfirmDelete(element) {
        const entityMoveDataModel = new MatDialogConfig();
        entityMoveDataModel.height = 'auto';
        entityMoveDataModel.width = '670px';
        this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
        this.selectedFiled = element;
    }
    get getItems() {
        return this.markets.reduce((acc, curr) => {
          acc[curr.masterDataId] = curr;
          return acc;
        }, {});
    }

    private async SetFeatureFlagsConfigurations() {
         let isDrinkSetup = await this.azureService.isEnableDrinkSetupFT();

         this.labelAddButton = isDrinkSetup ? "New Drinks Setup": "Price Card Template";
         this.labelTitle = isDrinkSetup ? "Drinks Setup": "Price Card Template";
         this.routeBaseUrl = isDrinkSetup ? "/drinks-setup" : "/price-card-template";
         this.routeBaseEditUrl = isDrinkSetup ? "/drinks-setup/edit/" : "/price-card-template/";
      }
}
