export const fieldValidationJson ={
    "marketFranchiseId": {
      "length": { "min": 1, "max": 16 },
      "level": 1
    },
    "marketName": {
      "length": { "min": 0, "max": 20 },
      "level": 1
    },
    "marketDescription": {
      "length": { "min": 0, "max": 254 },
      "level": 1
    },
    "marketBillTo": {
      "length": { "min": 4, "max": 20 },
      "level": 1
    },
    "marketContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 1
    },
    "marketContactPhoneCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 1
    },
    "marketContactPhone": {
      "length": { "min": 7, "max": 15 },
      "level": 1
    },
    "marketAlarmEscalationEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 1
    },
    "marketParentOrganizationName": {
      "length": { "min": 6, "max": 150 },
      "level": 1
    },
    "level2EntityName": {
      "length": { "min": 2, "max": 100 },
      "level": 2
    },
    "level2FranchiseCountryID": {
      "length": { "min": 0, "max": 20 },
      "level": 2
    },
    "level2EntityReference": {
      "length": { "min": 4, "max": 20 },
      "level": 2
    },
    "level2BillTo": {
      "length": { "min": 4, "max": 20 },
      "level": 2
    },
    "level2BrandCode": {
      "length": { "min": 4, "max": 20 },
      "level": 2
    },
    "level2ContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 2
    },
    "level2ContactPhoneCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 2
    },
    "level2ContactPhone": {
      "length": { "min": 7, "max": 15 },
      "level": 2
    },
    "level3EntityName": {
      "length": { "min": 2, "max": 100 },
      "level": 3
    },
    "level3FranchiseCountryID": {
      "length": { "min": 0, "max": 20 },
      "level": 3
    },
    "level3EntityReference": {
      "length": { "min": 4, "max": 20 },
      "level": 3
    },
    "level3BillTo": {
      "length": { "min": 4, "max": 20 },
      "level": 3
    },
    "level3BrandCode": {
      "length": { "min": 4, "max": 20 },
      "level": 3
    },
    "level3ContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 3
    },
    "level3ContactPhoneCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 3
    },
    "level3ContactPhone": {
      "length": { "min": 7, "max": 15 },
      "level": 3
    },
    "level3_5EntityName": {
      "length": { "min": 2, "max": 100 },
      "level": 3
    },
    "level3_5EntityReference": {
      "length": { "min": 4, "max": 20 },
      "level": 3
    },
    "level3_5BillTo": {
      "length": { "min": 4, "max": 20 },
      "level": 3
    },
    "level3_5BrandCode": {
      "length": { "min": 4, "max": 20 },
      "level": 3
    },
    "level3_5ContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 3
    },
    "level3_5ContactPhoneCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 3
    },
    "level3_5ContactPhone": {
      "length": { "min": 7, "max": 15 },
      "level": 3
    },
    "partnerName": {
      "length": { "min": 2, "max": 100 },
      "level": 4
    },
    "partnerReference": {
      "length": { "min": 0, "max": 20 },
      "level": 4
    },
    "partnerBillTo": {
      "length": { "min": 4, "max": 20 },
      "level": 4
    },
    "partnerBrandCode": {
      "length": { "min": 2, "max": 20 },
      "level": 4
    },
    "partnerContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 4
    },
    "partnerContactPhoneCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 4
    },
    "partnerContactPhone": {
      "length": { "min": 7, "max": 15 },
      "level": 4
    },
    "level5EntityName": {
      "length": { "min": 2, "max": 100 },
      "level": 5
    },
    "level5EntityReference": {
      "length": { "min": 4, "max": 20 },
      "level": 5
    },
    "level5BillTo": {
      "length": { "min": 4, "max": 20 },
      "level": 5
    },
    "level5BrandCode": {
      "length": { "min": 2, "max": 20 },
      "level": 5
    },
    "level5ContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 5
    },
    "level5ContactPhoneCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 5
    },
    "level5ContactPhone": {
      "length": { "min": 7, "max": 15 },
      "level": 5
    },
    "level6EntityName": {
      "length": { "min": 2, "max": 100 },
      "level": 6
    },
    "level6EntityReference": {
      "length": { "min": 4, "max": 20 },
      "level": 6
    },
    "level6BillTo": {
      "length": { "min": 4, "max": 20 },
      "level": 6
    },
    "level6BrandCode": {
      "length": { "min": 2, "max": 20 },
      "level": 6
    },
    "level6ContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 6
    },
    "level6ContactPhoneCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 6
    },
    "level6ContactPhone": {
      "length": { "min": 7, "max": 15 },
      "level": 6
    },
    "siteName": {
      "length": { "min": 2, "max": 50 },
      "level": 7
    },
    "siteReference": {
      "length": { "min": 0, "max": 20 },
      "level": 7
    },
    "siteSellToNumber": {
      "length": { "min": 4, "max": 20 },
      "level": 7
    },
    "siteBrandCode": {
      "length": { "min": 2, "max": 20 },
      "level": 7
    },
    "sitePrimaryAlarmFirstContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 7
    },
    "sitePrimaryAlarmFirstContactNumberCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 7
    },
    "sitePrimaryAlarmFirstContactNumber": {
      "length": { "min": 7, "max": 15 },
      "level": 7
    },
    "sitePrimaryAlarmSecondContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 7
    },
    "sitePrimaryAlarmSecondContactNumberCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 7
    },
    "sitePrimaryAlarmSecondContactNumber": {
      "length": { "min": 7, "max": 15 },
      "level": 7
    },
    "siteSecondaryAlarmFirstContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 7
    },
    "siteSecondaryAlarmFirstContactNumberCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 7
    },
    "siteSecondaryAlarmFirstContactNumber": {
      "length": { "min": 7, "max": 15 },
      "level": 7
    },
    "siteSecondaryAlarmSecondContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 7
    },
    "siteSecondaryAlarmSecondContactNumberCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 7
    },
    "siteSecondaryAlarmSecondContactNumber": {
      "length": { "min": 7, "max": 15 },
      "level": 7
    },
    "siteServiceAgentName": {
      "length": { "min": 2, "max": 250 },
      "level": 7
    },
    "siteServiceAgentEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 7
    },
    "siteServiceAgentMobileNumberCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 7
    },
    "siteServiceAgentMobileNumber": {
      "length": { "min": 7, "max": 15 },
      "level": 7
    },
    "siteEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 7
    },
    "sitePhoneNumberCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 7
    },
    "sitePhoneNumber": {
      "length": { "min": 7, "max": 15 },
      "level": 7
    },
    "siteAddress": {
      "length": { "min": 2, "max": 100 },
      "level": 7
    },
    "siteAddress2": {
      "length": { "min": 2, "max": 100 },
      "level": 7
    },
    "siteCity": {
      "length": { "min": 2, "max": 100 },
      "level": 7
    },
    "siteLocality": {
      "length": { "min": 2, "max": 100 },
      "level": 7
    },
    "sitePostalCode": {
      "length": { "min": 2, "max": 20 },
      "level": 7
    },
    "machineReference": {
      "length": { "min": 0, "max": 20 },
      "level": 8
    },
    "machinePrimaryAlarmFirstContactMobileCountry#": {
      "length": { "min": 1, "max": 3 },
      "level": 8
    },
    "machinePrimaryAlarmFirstContactMobile#": {
      "length": { "min": 7, "max": 15 },
      "level": 8
    },
    "machinePrimaryAlarmFirstContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 8
    },
    "machinePrimaryAlarmsecondContactPhoneCountry#": {
      "length": { "min": 1, "max": 3 },
      "level": 8
    },
    "machinePrimaryAlarmsecondContactMobile#": {
      "length": { "min": 7, "max": 15 },
      "level": 8
    },
    "machinePrimaryAlarmsecondContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 8
    },
    "machineSecondaryAlarmFirstContactMobileCountry#": {
      "length": { "min": 1, "max": 3 },
      "level": 8
    },
    "machineSecondaryAlarmFirstContactMobile#": {
      "length": { "min": 7, "max": 15 },
      "level": 8
    },
    "machineSecondaryAlarmFirstContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 8
    },
    "machineSecondaryAlarmsecondContactMobileCountry#": {
      "length": { "min": 1, "max": 3 },
      "level": 8
    },
    "machineSecondaryAlarmsecondContactMobile#": {
      "length": { "min": 7, "max": 15 },
      "level": 8
    },
    "machineSecondaryAlarmsecondContactEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 8
    },
    "machineLocation": {
      "length": { "min": 4, "max": 50 },
      "level": 8
    },
    "machinePaymentSystemType": {
      "length": { "min": 6, "max": 50 },
      "level": 8
    },
    "machinePaymentTerminalID": {
      "length": { "min": 6, "max": 50 },
      "level": 8
    },
    "machineGeoPositionLat": {
      "length": { "min": 6, "max": 50 },
      "level": 8
    },
    "machineGeoPositionLong": {
      "length": { "min": 6, "max": 50 },
      "level": 8
    },
    "machineServiceAgentMobileNumberCountry": {
      "length": { "min": 1, "max": 3 },
      "level": 8
    },
    "machineServiceAgentMobileNumber": {
      "length": { "min": 7, "max": 15 },
      "level": 8
    },
    "machineServiceAgentEmail": {
      "length": { "min": 6, "max": 254 },
      "level": 1
    },
  }