import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { RoleService } from 'src/app/services/role/role.service';
declare var $: any;

@Component({
  selector: 'app-price-card-list',
  templateUrl: './price-card-list.component.html',
  styleUrls: ['./price-card-list.component.css']
})
export class PriceCardListComponent implements OnInit {

  displayedColumns: string[] = ['priceCardName', 'drinkGroupTemplateName', 'noOfActiveEntities', 'noOfPendingEntities', 'isActive', 'actions'];
  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  isShowFilter = false;
  drawerfilter: boolean = false;
  isFilterClear: boolean = false;
  isFilterApply: boolean = false;
  priceCardList: any = []
  priceCardByPropositionFT : boolean = true;
  selectedFiled: any;
  priceCards: any = [];
  priceTemplates: any = [];
  partners: any = [];
  sites: any = [];
  label = "Price Card"
  statusList: any = [];
  dropdownSettingsTemplate: any = {};
  dropdownSettingsPriceCard: any = {};
  dropdownSettingsPartner: any = {};
  dropdownSettingsSite: any = {};
  selectedPricecards: [] = [];
  selectedPricecardTemlates: [] = [];
  selectedPartners: [] = [];
  selectedSites: [] = [];
  filter = {
    priceCard: [],
    priceCardTemplate: [],
    partner: [],
    site: [],
    status: '',
    search: '',
    city: ''
  }
  recordsTotal = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    searchText: '',
    sortBy: 'priceCardName asc'
  };
  lookUpObject = {
    "search": "",
    "configurationType": MasterLookupEnum.Drink,
    "pageNo": 1,
    "pageSize": -1,
    "orderBy": "text"
  }
  dialogRef: MatDialogRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: ''
  };
  selectedIndex = -1;
  isPageDataLoad = false;
  showFilter = false;
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  advanceFilter = {
    entityID: false,
    entityName: false,
    billTo: false,
    sellTo: false,
    reference: false,
    machineSerialNumber: false
  }
  displayAdvancedSearch = false;

  constructor(private spinner: NgxSpinnerService, private entityService: EntityService,public roleService:RoleService,public priceCardTypeConstant:PriceCardTypeLookupConstant,
    private priceCardService: PriceCardService, private router: Router, public dialog: MatDialog, private toaster: Toaster, private azureService: AzureService) { }

  ngOnInit() {
    if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) {
      this.router.navigate(['/unauthorized']);
    }
    if(this.showFilter){
    this.getPriceCardNameLookup();
    this.getPriceCardTemplateLookup();
    if (!this.roleService.objRole.isLevel6R && !this.roleService.objRole.isLevel5R && !this.roleService.objRole.isSiteR){
      this.getPartnerLookup(true);
    }
    this.getSiteLookup(true);
    }
    this.getPriceCardList();
    this.setFeatureFlagsConfigurations();
  }

  private async setFeatureFlagsConfigurations() {
    this.priceCardByPropositionFT = await this.azureService.isPriceCardByPropositionFT();
  }

  changefilter() {
    this.roleService.setPopupScroll('section-pricecardfilter');
    if (!this.drawerfilter) {
      $(".sidebar-mini").addClass("filter-open");
   }
   else {
       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll()
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;
    }, this.roleService.scrollSetTimeoutTime);
  }
  clickFilter(val) {
    this.isShowFilter = val;

  }
  clearFilter() {
    this.selectedPricecards = [];
    this.selectedPricecardTemlates = [];
    this.selectedPartners = [];
    this.selectedSites = [];
    this.filter.status = '';
    this.isFilterClear = true;
    this.isFilterApply = false;
    let fromClear = true;
    this.sites = [];
    this.getSiteLookup(false);
    this.getPriceCardList(fromClear);
  }
  clearSearch() {
    this.filter.search = '';
    this.advanceFilter = {
      entityID: false,
      entityName: false,
      billTo: false,
      sellTo: false,
      reference: false,
      machineSerialNumber: false
    }
    this.getPriceCardList();
  }
  sortData(e) {
    e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
    this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
    this.params.pageNumber = 1;
    this.getPriceCardList();
  }
  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    this.getPriceCardList();
  }
  getPriceCardList(fromClear = undefined) {
    this.displayAdvancedSearch = false;
    this.spinner.show();
    if (!this.isFilterClear) {
      this.isShowFilter = false;
      this.drawerfilter = false;
    }
    this.isFilterClear = false;
    if (fromClear == "apply") {
      $(".sidebar-mini").removeClass("filter-open");
      this.roleService.resetPopupScroll();
    }
    if (fromClear == "search") {
      this.params.pageNumber = 1;
    }
    if (fromClear == undefined)
      this.drawerfilter = false;
    let obj = {};
    this.filter.priceCard = [];
    this.filter.priceCardTemplate = [];
    this.filter.partner = [];
    this.filter.site = [];
    this.isFilterApply = false;
    if (this.selectedPricecards.length > 0) {
      this.selectedPricecards.forEach(element => {
        this.filter.priceCard.push(element['priceCardId'])
      });
      obj['priceCardIds'] = this.filter.priceCard;
      this.isFilterApply = true;
    };
    if (this.selectedPricecardTemlates.length > 0) {
      this.selectedPricecardTemlates.forEach(element => {
        this.filter.priceCardTemplate.push(element['drinkGroupTemplateId'])
      });
      obj['drinkGroupTemplateIds'] = this.filter.priceCardTemplate;
      this.isFilterApply = true;
    };
    if (this.selectedPartners.length > 0) {
      this.selectedPartners.forEach(element => {
        this.filter.partner.push(element['masterDataId'])
      });
      obj['partnerEntityId'] = this.filter.partner;
      this.isFilterApply = true;
    };
    if (this.selectedSites.length > 0) {
      this.selectedSites.forEach(element => {
        this.filter.site.push(element['value'])
      });
      obj['siteEntityId'] = this.filter.site;
      this.isFilterApply = true;
    };
    if (this.filter.status) { obj['status'] = this.filter.status == 'true' ? true : false; this.isFilterApply = true; };
    if (this.filter.search != '') { obj['search'] = this.filter.search; };
    if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };
    if (this.isFilterApply) {
      obj['pageNo'] = 1;
      this.params.pageNumber = 1;
    }
    var advanceSearchColumns = [];
    if (this.advanceFilter.entityID) {advanceSearchColumns.push("EntityID")};
    if (this.advanceFilter.entityName) {advanceSearchColumns.push("EntityName")};
    if (this.advanceFilter.billTo) {advanceSearchColumns.push("BillTo")};
    if (this.advanceFilter.sellTo) {advanceSearchColumns.push("SellTo")};
    if (this.advanceFilter.reference) {advanceSearchColumns.push("Reference")};
    if (this.advanceFilter.machineSerialNumber) {advanceSearchColumns.push("MachineSerialNumber")};
    if (advanceSearchColumns.length > 0) { obj['advanceSearch'] = advanceSearchColumns; }

    this.priceCardService.getPriceCardList(obj).subscribe(response => {
      this.recordsTotal = response ?.params ?.count;
      this.spinner.hide();
      this.isPageDataLoad = true;
      if (response && response['data'].length) {
        this.priceCardList = response['data']
      } else {
        this.priceCardList = [];
      }

    }, err => {
      let errors = err.error;
      if(Array.isArray(errors)){
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }

  getPriceCardNameLookup() {
    this.priceCardService.getPriceCardNameLookup().subscribe(response => {
      if (response && response['data'].length) {
        this.priceCards = response['data'];
      }
      this.dropdownSettingsPriceCard = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "priceCardId",
        "textField": "priceCardName",
        "enableCheckAll": false,
        "itemsShowLimit": 2,
        "allowSearchFilter": true
      };
    });
  }

  getPriceCardTemplateLookup() {
    this.priceCardService.getDrinkGroupTemplates().subscribe(response => {
      if (response && response['data'].length) {
        this.priceTemplates = response['data'];
      }
      this.dropdownSettingsTemplate = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "drinkGroupTemplateId",
        "textField": "templateName",
        "enableCheckAll": false,
        "itemsShowLimit": 2,
        "allowSearchFilter": true
      };
    });
  }

  getPartnerLookup(isPageLoad) {
    this.sites = [];
    this.partners = [];
    this.filter.site = [];
    this.selectedSites = [];
    this.filter.partner = [];
    if (!isPageLoad) {
      this.spinner.show();
    }
    this.entityService.getPartnerLookup("").subscribe(response => {
      if (!isPageLoad) {
        this.spinner.hide();
      }
      if (response && response['data'].length) {
        this.partners = response['data'];
      }
      this.dropdownSettingsPartner = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "masterDataId",
        "textField": "text",
        "enableCheckAll": false,
        "itemsShowLimit": 2,
        "allowSearchFilter": true
      };
    });
  }

  getSiteLookup(isPageLoad) {
    this.sites = [];
    this.selectedSites = [];
      this.filter.partner = [];
    this.selectedPartners.forEach(element => {
      this.filter.partner.push(element['masterDataId'])
    });
    if (!isPageLoad) {
      this.spinner.show();
    }
    this.priceCardService.getSitesByPartners({ "partnerIds": this.filter.partner }).subscribe(response => {
      if (!isPageLoad) {
        this.spinner.hide();
      }
      if (!response) {
        this.sites = [];
      } else {
        //this.sites = response['data'].filter(el => el.isActive === true);
        this.sites = response['data'];
      }
      this.dropdownSettingsSite = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "value",
        "textField": "text",
        "selectAllText": "Select All",
        "unSelectAllText": "UnSelect All",
        "enableCheckAll": false,
        "itemsShowLimit": 2,
        "allowSearchFilter": true
      };
    });
  }

  onConfirmDeleteCancel() {
    this.dialogRef.close()
  }
  onConfirmDeleteYes() {
    this.spinner.show();
    this.priceCardService.deletePriceCard(this.selectedFiled.priceCardVersionId).subscribe(response => {
      this.spinner.hide();
      if (response) {
        let msg = this.label + " has been deleted successfully";
        this.toaster.open({
          text: msg,
          type: 'success',
          position: 'top-right',
          duration: 10000
        });
        this.dialogRef.close()
        this.getPriceCardList();
      }
    }, err => {
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }
  onConfirmDelete(element) {
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
    this.selectedFiled = element;
  }

  onSelectAll(event){
    this.selectedPartners = [];
    if(event != null){
        this.selectedPartners = event;
    }
    this.getSiteLookup(false);
  }

  onDeSelectAll(event){
    this.selectedPartners = [];
    this.sites = [];
    this.selectedSites = [];
  }

  openDialog(type: string = '', element = {}, slot_i = -1): void {
    this.selectedFiled = element;
    this.selectedIndex=slot_i;
    if (type == 'status') {
      let status = 'Inactive';
      if(!this.priceCardList[this.selectedIndex]['isActive']){
        status = 'Active';
      }
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'Are you sure want to '+status,
        deviceName: element['priceCardName']+' ?',
        status: '',
        title: 'Confirmation',
        type: type
      }
    }else if (type == 'delete') {
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'Are you sure want to delete this',
        deviceName: element['priceCardName']+' ?',
        status: '',
        title: 'Confirmation',
        type: type
      }
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }
  onConfirm(type: string = 'form_cancel') {
    this.dialogRef.close();
    if (type == 'status') {
      this.changeStatus();
    }else if (type == 'delete') {
      this.onConfirmDeleteYes();
    }
  }
  onCancel() {
    this.dialogRef.close();
  }
  changeStatus() {
    this.spinner.show();
    this.priceCardService.changeStatusPriceCard(this.selectedFiled.priceCardVersionId,!this.selectedFiled.isActive).subscribe(response => {
      this.spinner.hide();
      this.priceCardList[this.selectedIndex]['isActive']=!this.selectedFiled.isActive;
      let status = 'Activated';
      if(!this.priceCardList[this.selectedIndex]['isActive']){
        status = 'Inactivated';
      }
      let msg = this.selectedFiled.priceCardName + " has been " +status+ " successfully";
        this.toaster.open({
          text: msg,
          type: 'success',
          position: 'top-right',
          duration: 10000
        });
        this.dialogRef.close()
    }, err => {
      console.log('err',err);
      if (err && err.error) {
        this.spinner.hide();
        let errors = err.error;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }

  advanceToggle () {
      this.displayAdvancedSearch = !this.displayAdvancedSearch;
  }

  applyAdvanceSearch() {
    this.displayAdvancedSearch = false;
    if (this.filter.search != '') {
      this.getPriceCardList('search')
    }
  }
}
