import { maxLength, minLength, prop, required } from "@rxweb/reactive-form-validators";

export class PriceCardTemplateDetail{
    @prop()
    detailMasterId:string;
    @prop()
    isPrimary:boolean = false;
}
export class AddPriceCardTemplateModel{
    @required()
    @minLength({value : 6})
    @maxLength({value : 150})
    templateName:string;
    @prop()
    isActive:boolean;
    @required()
    marketList:any[] = null;
    @required()
    cupType:string = '';
    @prop()
    drinkList:any[] = [];
    @prop()
    waffleCupdrinkList:any[] = [];
    @prop()
    plasticCupdrinkList:any[] = [];
    @required()
    cupSizeList:any[] = null;
    @required()
    coffeeTypeList:any[] = null;
    @required()
    milkTypeList:any[] = null;
    @prop()
    syrupList:any[] = null;
    @required()
    primaryCoffeeType:string;
    @required()
    primaryMilkType:string;
}
export class SavePriceCardRequestTemplateModel{
    templateName:string;
    isActive:boolean;
    marketList:string[] = null;
    marketId:string;
    drinkList:string[] = null;
    cupSizeList:string[] = null;
    coffeeTypeList:string[] = null;
    milkTypeList:string[] = null;
    syrupList:string[] = null;
    primaryCoffeeType:string;
    primaryMilkType:string;
    templateType:string;
}

export class LookupModel{
    @prop()
    masterDataId:string;
    @prop()
    text:string;
    @prop()
    value:string;
    @prop()
    isActive:boolean;
}

export interface GridTotalCountDataModel {
    count: number;
}
export class LookupModelList{
    data:LookupModel[] = [];
    params: GridTotalCountDataModel;
}
