import { Injectable } from "@angular/core";
import { SessionStorageDto } from "./dto/session-storage-dto";
import { DrinkDto } from "src/app/components/remote-update-tasks/services/task-properties/dto/drink-dto";

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    setSession(data: any, sessionName: string, expiration: number) {
        var session = new SessionStorageDto();

        var expiresAt = this.dateTimeNow();

        expiresAt.setMinutes(expiresAt.getMinutes() + expiration);

        session.expiration = expiresAt;
        session.data = data;

        sessionStorage.setItem(sessionName, JSON.stringify(session));
    }

    getSession(sessionName: string, expiration: number): Array<DrinkDto> {
        var sessionValue = sessionStorage.getItem(sessionName);

        if (sessionValue === null) {
            return null;
        }

        var session = new SessionStorageDto();
        Object.assign(session, JSON.parse(sessionValue));
        if (this.sessionExpired(session.expiration)) {
            return null;
        }

        return session.data;
    }

    private dateTimeNowUtc() {
        const now = new Date();

        var date = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());

        return date;
    }

    private dateTimeNow(): Date {
        return new Date();
    }

    private sessionExpired(expiration: Date): Boolean {
        var now = this.dateTimeNowUtc();
        var expiresAt = new Date(expiration);
        var expireAtUtc = Date.UTC(expiresAt.getFullYear(), expiresAt.getMonth(), expiresAt.getDate(), expiresAt.getHours(), expiresAt.getMinutes(), expiresAt.getSeconds());

        if (expireAtUtc >= now) 
            return false;

        return true;
    }
}


