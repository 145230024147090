import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GetRemoteUpdateTaskType } from '../../dtos/get-remote-update-task-type.model';
import { map, switchMap } from 'rxjs/operators';
import { AzureService } from 'src/app/services/azure/azure.service';
import { from, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RemoteUpdateTaskTypePropositionGetListService {

	constructor(
		private cookieService: CookieService,
    private httpClient: HttpClient,
    private azureService: AzureService
  ) {

  }

  cookieName: string = environment.cookieName

	getRemoteUpdateTaskTypeList(hasPackage : boolean = null){
    let params = new HttpParams();
    params = hasPackage !== null ? params.append("hasPackage", hasPackage.toString()) : params;

    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token')
      },
      params
    };

    var ret = this.httpClient
      .get<GetRemoteUpdateTaskType[]>(environment.apiUrl.remoteUpdate + `/v1/remoteupdatetasktype`, configHeader)
      .pipe(response => {
        return this.filterTaskType(response)
      });

    return ret;
  }

  private filterTaskType(remoteUpdateTaskTypes: Observable<GetRemoteUpdateTaskType[]>)
  {
    let ret = remoteUpdateTaskTypes
      .switchMap(response =>
        from(this.azureService.isEnableEnableDisableDrinksTask())
        .pipe(
          map(isEnabled => {
            if (isEnabled)
              return response;
            return response.filter(task => task.name !== "Enable/Disable Drinks");
          })
        )
      );
      ret = remoteUpdateTaskTypes
      .switchMap(response =>
        from(this.azureService.isCertificateManagementTask())
        .pipe(
          map(isEnabled => {
            if (isEnabled)
              return response;
            return response.filter(task => task.name !== "Certificate Management");
          })
        )
      );
    return ret;
  }
}

