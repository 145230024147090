import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export const DiscrepancyTypes = [{
  value: 'Configuration',
  text: 'Configuration'
}, {
  value: 'Price Card',
  text: 'Price Card'
}, {
  value: 'Drink Product',
  text: 'Drink Product'
},
{
  value: 'Cup-Size',
  text: 'Cup Size'
}];

@Injectable({
  providedIn: 'root'
})

export class SalesService {
  cookieName = environment.cookieName;
  isShowUpdateVendsPopup = false;
  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) { }

  getSalesDataDiscrepanciesList(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/salesdiscrepancy/search', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getSalesDataDiscrepanciesListBySites(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/salesdiscrepancy/sitesearch', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  updateVendsByType(body,type) {
    let updateVendsRoute = "";
    if (type == 'Configuration') {
      updateVendsRoute = "updateconfigurationdiscrepancy";      
    } else if (type == 'Price Card') {
      updateVendsRoute = "updatepricecarddiscrepancy";
    } else if (type == 'Drink Product') {
      updateVendsRoute = "updatedrinkdiscrepancy";
    } else if (type == 'Cup-Size') {
      updateVendsRoute = "updatecupdiscrepancy";
    }
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.salesProcessor +'/api/v1/'+ updateVendsRoute, body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getLastSalesDate() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/lastsalesDate', configHeader).pipe(map(response => {
      return response;
    }));

  }
  getSalesDiscrepanciById(salesDiscrepencyId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/getsalesdiscrepancibyid',{
      "salesDiscrepancyId": salesDiscrepencyId
    }, configHeader).pipe(map(response => {
      return response;
    }));

  }
  getMachineLastSalesDate(machineId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machinelastsalesDate/'+machineId, configHeader).pipe(map(response => {
      return response;
    }));

  }
  getMachinesBysites(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/master/getmachinesbysites', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  salesdiscrepancycount(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/salesdiscrepancycount', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getsalesdiscrepencymasters(obj={}) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/master/getsalesdiscrepencymasters', {
      "discrepancyType":obj['discrepancyType'],
      'levelNumber':obj['levelNumber'],
      'parentId':obj['parentId'],
      'marketId':obj['marketId']
    }, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getSalesUpdateHistory(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/amendmentjobhistory/search', body, configHeader).pipe(map(response => {
      return response;
    }));
  }

  getSalesDetailList(body){
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machinesalesdata/getmachinesaleslist', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getSiteLogs(body){
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
      return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machine/movementlog', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getSalseDetails(id,machineId){
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machinesalesdata/getmachinesalesdetail/'+id+'/'+machineId, configHeader).pipe(map(response => {
      return response;
    }));
  }
  exportSalesReport(type) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>
    (environment.baseUrl + '/api/v1/exportsalesembbedreport/'+type, configHeader)
    .pipe(map(response => {
      return response;
    }))
  }
  downloadamendmentjobhistory(amendmentId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>
    (environment.baseUrl + '/api/v1/getsastoken/'+amendmentId, configHeader)
    .pipe(map(response => {
      return response;
    }))
    // return this.httpClient.get<any>
    // (environment.baseUrl + '/api/v1/downloadamendmentjobhistory/'+amendmentId, configHeader)
    // .pipe(map(response => {
    //   return response;
    // }))
}
}

