import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { TaskProperty } from 'src/app/components/remote-update-tasks/enums/remote-update-task-properties';
@Component({
  selector: 'app-task-certificate-management',
  templateUrl: './task-certificate-management.component.html',
  styleUrls: ['./../../new-task-step-common.css', './task-certificate-management.component.css'],
})
export class TaskCertificateManagementComponent implements OnInit {
  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();


  sessionName: string = 'CERTIFICATE_MANAGEMENT';
  sessionDurationInMinutes: number = 5;
  taskPropertyEnum = TaskProperty;
  
  constructor() {}

  isRenewCertificate: boolean = true

  ngOnInit(): void {
  }

  backStep(): void {
    this.backStepEvent.emit();
  }

  nextStep() {
    this.newTaskRequest.TaskProperty = this.isRenewCertificate ? this.taskPropertyEnum.Renew : this.taskPropertyEnum.Revoke;
    this.nextStepEvent.emit();
  }
}
