import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteUpdateTaskToggleSearchService {
  private subject: Subject<void> = new Subject<void>();

  constructor() { }

  toggleRemoteUpdateHeaderFilter() {
    this.subject.next();
  }

  getToggleRemoteUpdateHeaderFilter() {
    return this.subject.asObservable();
  }
}
