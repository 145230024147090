import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { RoleEnum, RoleService } from 'src/app/services/role/role.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  public cookieName = environment.cookieName;
  currentUserId : string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['firstName', 'lastName', 'email','role','entity','createdOn','lastSignInDateTime', 'isActive', 'actions'];
  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort;
  isShowFilter = false;
  drawerfilter: boolean = false;
  isFilterClear: boolean = false;
  isFilterApply: boolean = false;
  userList: any = []
  selectedFiled: any;
  entityLevelList: any = [];
  entityList: Observable<string[]>;
  entities: any = [];
  roleTypeList:any[] = [];
  label = "User"
  userRoleList: any = []
  filter = {
   
    search: ''
    }
    tempfilter = {//[GD2-3706]
        entityLevel: [],
        entity: [],
        userRole: [],
        roleType: [],
        status: []
    }
    tempEntityEvent: any;//[GD2-3706]
    isFromCancelChangefilter: boolean = false;//[GD2-3706]
  recordsTotal = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
      pageNumber: 1,
      pageSize: this.pageSizeOptions[0],
      searchText: '',
      sortBy: 'createdOn desc'
  };
  dialogRef: MatDialogRef<any>;
  isPageDataLoad = false;
  isEntityDisabled : boolean = true;
  enableGridImprovementsFT: boolean;
  showEntityOrRecordName: string = 'Entity';
  userForm: FormGroup;
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;

  constructor(private spinner: NgxSpinnerService,public priceCardTypeConstant:PriceCardTypeLookupConstant,private cookieService: CookieService,private msalService: MsalService,
      private userService: UserService, private router: Router, public dialog: MatDialog, private toaster: Toaster,public roleService:RoleService, private azureService: AzureService) {
  }
  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();
    if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel1R || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel2R || this.roleService.objRole.isUserAuditorLevel3 || this.roleService.objRole.isUserAuditorLevel3R) {
      this.router.navigate(['/unauthorized']);
    }
    this.userForm = new FormGroup({});
    this.userForm.addControl('entityLevel', new FormControl('',[]));
    this.userForm.addControl('entity', new FormControl('',[]));
    this.userForm.addControl('userRole', new FormControl('',[]));
    this.userForm.addControl('roleType', new FormControl('',[]));
    this.userForm.addControl('status', new FormControl('',[]));
    this.currentUserId = this.cookieService.get(this.cookieName + 'userId')
      this.getEntityLevelLookup();
      this.getRoleTypeLookup();
      this.getRoleLookup();
      this.getUserList();
    }

    private async SetFeatureFlagsConfigurations() {
      this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
      this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
    }

    applyFilter() {//[GD2-3706]
    this.assignFilterToTempFilter();
    this.getUserList('apply');
    }

    assignFilterToTempFilter() {//[GD2-3706]
        this.tempfilter['entityLevel'] = this.userForm.controls['entityLevel'].value;
        this.tempfilter['entity'] = this.userForm.controls['entity'].value;
        this.tempfilter['userRole'] = this.userForm.controls['userRole'].value;
        this.tempfilter['roleType'] = this.userForm.controls['roleType'].value;
        this.tempfilter['status'] = this.userForm.controls['status'].value;
    }

  changefilter() {
    this.roleService.setPopupScroll('section-userfilter');  
    if (!this.drawerfilter) {
      $(".sidebar-mini").addClass("filter-open");
   }
    else {
        if (this.tempEntityEvent != undefined) {//[GD2-3706]
            this.tempEntityEvent.value = this.tempfilter['entityLevel'];
            this.isFromCancelChangefilter = true;
            this.getEntityLookup(this.tempEntityEvent);
        }
        //[GD2-3706]
        this.userForm.controls['entityLevel'].setValue((this.tempfilter['entityLevel'] != undefined) ? this.tempfilter['entityLevel'] : '');
        this.userForm.controls['userRole'].setValue((this.tempfilter['userRole'] != undefined && this.tempfilter['userRole'].length) ? this.tempfilter['userRole'] : '');
        this.userForm.controls['roleType'].setValue((this.tempfilter['roleType'] != undefined && this.tempfilter['roleType'].length) ? this.tempfilter['roleType'] : '');
        this.userForm.controls['status'].setValue((this.tempfilter['status'] != undefined && this.tempfilter['status'].length) ? this.tempfilter['status'] : '');

       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll();
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;  
    }, this.roleService.scrollSetTimeoutTime); 
  }
  clickFilter(val) {
      this.isShowFilter = val;

  }
  clearFilter() {
      this.userForm.controls['entityLevel'].setValue('');
      this.userForm.controls['entity'].setValue('');
      this.userForm.controls['userRole'].setValue('');
      this.userForm.controls['roleType'].setValue('');
      this.userForm.controls['status'].setValue('');
      this.isFilterClear = true;
      this.isFilterApply = false;
      let fromClear = true;
      this.params.pageNumber = 1;
      this.entities = [];
      this.isEntityDisabled = true;
      this.assignFilterToTempFilter();//[GD2-3706]
      this.getUserList(fromClear);
  }
  clearSearch() {
      this.filter.search = '';
      this.params.pageNumber = 1
      this.getUserList();
  }
  sortData(e) {
      e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
      this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
      this.params.pageNumber = 1;
      this.getUserList();
  }
  pageChange(e) {
      this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
      this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
      this.getUserList();
  }
  getUserList(fromClear = undefined) {
      this.spinner.show();
      if (!this.isFilterClear) {
          this.isShowFilter = false;
          this.drawerfilter = false;
      }
      this.isFilterClear = false;
      if (fromClear == "apply") {
          $(".sidebar-mini").removeClass("filter-open");
          this.roleService.resetPopupScroll();
      }
      if (fromClear == undefined)
          this.drawerfilter = false;
      if (fromClear == "search" || fromClear == "apply")
          this.params.pageNumber = 1
      let obj = {};
      this.isFilterApply = false;
      if (this.userForm.controls['entityLevel'].value != undefined && this.userForm.controls['entityLevel'].value != '') { obj['EntityLevel'] = this.userForm.controls['entityLevel'].value; this.isFilterApply = true; };
      if (this.userForm.controls['entity'].value != undefined && this.userForm.controls['entity'].value != '') { obj['EntityId'] = this.userForm.controls['entity'].value; this.isFilterApply = true; };
      if (this.userForm.controls['roleType'].value != undefined && this.userForm.controls['roleType'].value != '') { obj['roleTypeId'] = this.userForm.controls['roleType'].value; this.isFilterApply = true; };
      if (this.userForm.controls['userRole'].value != undefined && this.userForm.controls['userRole'].value != '') { obj['RoleId'] = this.userForm.controls['userRole'].value; this.isFilterApply = true; };
      if (this.userForm.controls['status'].value) { obj['Status'] = this.userForm.controls['status'].value == 'Active' ? true : false; this.isFilterApply = true; };
      if (this.filter.search != '') { obj['SearchText'] = this.filter.search; };
      if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
      if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
      if (this.params.sortBy != undefined) { obj['OrderByColumn'] = this.params.sortBy };
      var deleteMessage = '';
      var editMessage = '';
      this.userService.getUserList(obj).subscribe(response => {
          this.recordsTotal = response?.params?.count;
          this.spinner.hide();
          this.isPageDataLoad=true;
          if (response && response['data']) {
              this.userList = response['data']
          } else {
              this.userList = [];
          }
          
          this.userList.forEach(element => {
              if(element.userId.toString().toUpperCase() == this.currentUserId.toString().toUpperCase() || element.role == RoleEnum.SystemAdmin){
                  element.isActionEnabled = false;
                  element.editMessage = 'You do not have permissions to Edit User Account';
              }else if(element.role == RoleEnum.SystemAdmin || element.role == RoleEnum.GlobalMarketAdmin){
                  element.isActionEnabled = false;
                  element.editMessage = 'You do not have permissions to Edit User Account';
              } else {
                element.isActionEnabled = true;
              }
              element.isDeleteEnabled = true;
              if(element.userId.toString().toUpperCase() == this.currentUserId.toString().toUpperCase()){
                  element.deleteMessage = 'You can not delete your own account';
                  element.editMessage = 'You can not edit your own account';
                  element.isDeleteEnabled = false;
              } else if(!this.roleService.objRole.isSystemAdmin){
                element.deleteMessage = 'You do not have permissions to Delete User Account';
                element.isDeleteEnabled = false;
              } else if(element.isActive){
                element.deleteMessage = 'Active user can not be deleted';
                element.isDeleteEnabled = false;
              }
          });
      }, err => {
          let errors = err.errors == undefined ? err.error : err.errors;
          if(Array.isArray(errors)){
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
          }
      });
  }

  getEntityLevelLookup() {
      this.userService.getEntityLevelLookup().subscribe(response => {
          if (response && response['data'].length) {
              this.entityLevelList = response['data'];
          }
      });
  }

  getRoleTypeLookup(){
    this.userService.getRoleTypeLookup().subscribe(response => {
        if (response && response['data'].length) {
            this.roleTypeList = response['data'];
        }
    });
  }

  getEntityLookup(event) {
    this.userForm.controls['entity'].setValue('');
      if(event.value != ''){
        this.isEntityDisabled = false;
        this.userService.getEntityLookup(event.value).subscribe(response => {
            if (response && response['data'].length) {
                this.tempEntityEvent = event;//[GD2-3706]
                this.entities = response['data'];
                this.entityList = this.userForm.controls['entity'].valueChanges
                  .pipe(
                  startWith(''),
                  map(value => this._filter(value))
                );
                if (this.isFromCancelChangefilter) { this.userForm.controls['entity'].setValue((this.tempfilter['entity'] != undefined && this.tempfilter['entity'].length) ? this.tempfilter['entity'] : ''); }//[GD2-3706]
               }
            else 
            {
              this.entities = [];
              this.userForm.controls['entity'].setValue('');
            }
        });
      } else {
        this.isEntityDisabled = true;
        this.userForm.controls['entity'].setValue('');
      }
      
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.entities.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  
  getRoleLookup() {
      this.userService.getRoleLookup().subscribe(response => {
          if (response && response['data'].length) {
              this.userRoleList = response['data'];
          }
      });
  }

  clickToAdd() {
      this.router.navigate(['/user/add']);
  }
  onConfirmDeleteCancel() {
      this.dialogRef.close()
  }

  onConfirmDeleteYes() {
    const account = this.msalService.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: [environment.msal.apiscope],        
      account: account
    }
     this.msalService.instance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse=>{   
    if(accessTokenResponse != null && accessTokenResponse.accessToken != '')
    {                 
        this.deleteUser(accessTokenResponse.accessToken);
      } else {
        this.toaster.open({
          text: "Invalid token",
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      }
    }).catch(function (error) {
        this.toaster.open({
          text: error,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
    });
      
  }

  deleteUser(accessToken){
    this.spinner.show();
    this.userService.deleteUser(this.selectedFiled.userId).then(res => res.subscribe(response => {
        this.spinner.hide();
        if (response) {
            let msg = "User has been deleted successfully";
            this.toaster.open({
                text: msg,
                type: 'success',
                position: 'top-right',
                duration: 10000
            });
            this.dialogRef.close()
            this.getUserList();
        }
    }, err => {
        this.dialogRef.close();
        this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        if(Array.isArray(errors)){
        errors.forEach(element => {
            this.toaster.open({
                text: element.msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
          });
        }
    }));
  }

  onConfirmDelete(element) {
      const entityMoveDataModel = new MatDialogConfig();
      entityMoveDataModel.height = 'auto';
      entityMoveDataModel.width = '670px';
      this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
      this.selectedFiled = element;
  }

  displayFn(): (id: number) => string {
    return (id: number) => {
      if (id) {
        if (this.entities.find(obj => obj.entityId == id)) {
          let obj = this.entities.find(obj => obj.entityId == id);
          if (Object.keys(obj).length) {
            return obj.name
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }
}
