
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from "ngx-toast-notifications";
import { TreeNode } from 'primeng/api';
import { EntityService } from 'src/app/services/entity/entity.service';
import { RoleService } from 'src/app/services/role/role.service';
import { AlertConfirmDialogModel } from '../../common/alert-confirm-dialog/alert-confirm.dialog.model';
import { EntityMoveDataModel } from '../entity-move/entity-move.model';
import { EntityMoveModel } from './entity-list.model';
import { AzureService } from 'src/app/services/azure/azure.service';
declare var powerbi: any;

declare var $: any;

@Component({
  selector: 'app-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.css']
})
export class EntityListComponent implements OnInit {
  selectedEntity:any;
  entityList: TreeNode[] = [];
  entityListTemp: TreeNode[] = []
  markets: any = [];
  partners: any = [];
  sites: any = [];
  availableStatus = [
    {
      id: '',
      name: 'All'
    },
    {
      id: 'true',
      name: 'Active'
    },
    {
      id: 'false',
      name: 'Inactive'
    }
  ]
  entityMoveDataModel: EntityMoveDataModel = new EntityMoveDataModel();
  alertConfirmDataModel:AlertConfirmDialogModel = new AlertConfirmDialogModel();
  filter = {
    propositionTypeId: [],
    marketId: '',
    partnerId: '',
    siteId: '',
    status: '',
    search: ''
  }
  tempfilter = { //[GD2-3706]
        propositionTypeId: [],
        marketId: '',
        partnerId: '',
        siteId: '',
        status: '',
        search: ''
  }
  filterColumns: string[] = ['propositionTypeId', 'marketId', 'partnerId', 'siteId', 'status'];//[GD2-3706]
  isShowFilter = false;
  isMoveShow:boolean = false;
  isFilterClear : boolean = false;
  isFilterApply : boolean = false;
  drawerfilter: boolean = false;
  dialogRef: MatDialogRef<any>;
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  //@ViewChild("entityMoveTemplate") entityMoveTemplate: TemplateRef<any>;
  @ViewChild("alertDialogTemplate") alertDialogTemplate: TemplateRef<any>;
  @ViewChild("siteAlertDialogTemplate") siteAlertDialogTemplate : TemplateRef<any>;
  //@ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  countryCodes = {};
  selectedFiled: any;
  selectedFiledIndex = 0;
  marketDetails: any;
  status = 'Active';
  module: string = "Entity";
  cols: any = [];
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: ''
  }
  isPageDataLoad=false;
  hasRootLevel = true;
  isExpandTree = false;
  hasAddedPopupStyle = false;
  displayAdvancedSearch = false;
  advanceFilter = {
    entityID: false,
    entityName: false,
    billTo: false,
    sellTo: false,
    reference: false,
    machineSerialNumber: false
  }

  expandedEntity = [];
  expandSpinnerShow = false;
  expandEntityCount = 0;
  displayEntity = true;
  entityNameColumnWidth = 0;
  current_time = new Date().getTime();
  propositionTypes: any = [];
  enableGridImprovementsFT: boolean = false;
  showEntityOrRecordName: string = 'Entity';
  updatePropositionTypesId (arr) {
    this.filter.propositionTypeId = arr.map(({id}) => id)
    this.getMarketLookup(true)
  }
  updateMarketId(id)  {
    if(this.filter.marketId === id) return
    this.filter.marketId = id
    this.getPartnerLookup(false)
  }
  updatePartnerId(id)  {
    if(this.filter.partnerId === id) return
    this.filter.partnerId = id
    this.getSiteLookup(false)
  }

  constructor(
    private spinner: NgxSpinnerService,
    private entityService: EntityService,
    private router: Router,
    public dialog: MatDialog,
    private toaster: Toaster,
    public roleService:RoleService,
    private azureService: AzureService
  ) { }

  async ngOnInit() {
    await this.SetFeatureFlagsConfigurations();

    localStorage.removeItem('isOpenedConfirmationPopup')
    if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) {
      this.router.navigate(['/unauthorized']);
    }
    if(!this.entityService.powerbiReportPreloaded){
        let PreloadElement =powerbi.preload({
          type: 'report',
          embedUrl: 'https://app.powerbi.com/reportEmbed',
      }); 
      this.entityService.powerbiReportPreloaded = true;
      $(PreloadElement).on('preloaded', function() {
        console.log('preload',new Date());
        console.log("scripts now preloaded into this page");
      });
    }
    localStorage.removeItem("selectedEntityModel");
    localStorage.removeItem("moveEntityModel");   
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isGlobalMarketAdmin ||
      this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isMarketR || this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1R){ 
        this.getMarketLookup();
    }
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isUserAuditorGRIDR) {
      this.getPropositionTypes(); //GD2-4537
    }
    this.getPartnerLookup(true);
    //this.getSiteLookup(true);
    this.cols = [
      { field: 'name', header: `${this.showEntityOrRecordName} Name`, class: "mw500" },
      { field: 'machineCount', header: '# of Machines', class: "w170" },
      
      // { field: 'isActive', header: 'Status', class: "w140" },
      { field: 'statusText', header: 'Status', class: "w140" },
    ];

    //Added code for GD2-837 - System needs to remember the [Filters + Search Criteria + Open Nodes] 
    //to replicate the previously available hierarchical view.
    let previousUrl:any = localStorage.getItem("previousUrl"); 
    let isFromMoveEntity = previousUrl.includes("isEntityMove=true");
    previousUrl = previousUrl.split('/');
    if(!isFromMoveEntity && (previousUrl[0] == "entity" || previousUrl[1] == "entity")){ 
      let filterData:any =  localStorage.getItem("entityListFilter");
      let advanceFilterData:any =  localStorage.getItem("entityListAdvanceFilter");
      if(filterData){
        filterData = JSON.parse(filterData);
        advanceFilterData = JSON.parse(advanceFilterData);
        this.filter = filterData[0];
        if (this.filter.propositionTypeId.length > 0)  //GD2-4537
          this.getMarketLookup();
        this.advanceFilter = advanceFilterData[0];
        localStorage.removeItem('entityListFilter');
        localStorage.removeItem('entityListAdvanceFilter');
        this.getEntityList();
        this.isExpandTree = true;
      }
    }else{
      this.clearNodeStorage();
      localStorage.removeItem('entityListSorting')
      localStorage.removeItem('entityListFilter'); 
      localStorage.removeItem('entityListAdvanceFilter');              
      this.getEntityList();
    }
    //Added code for GD2-837   
    
  } 

  private async SetFeatureFlagsConfigurations() {
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
    this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
  }

  getCustomEntityOrRecordName(isLower?: boolean, isPlural?: boolean): string {
    let name = this.showEntityOrRecordName;
    
    if (isPlural) {
      if (name === 'Entity') {
        name = 'Entities';
      } else {
        name += 's';
      }
    }
    
    if (isLower) {
      name = name.toLowerCase();
    }

    return name;
  }

  getMaxWidthByLevel(levelNumber){
    if(!this.entityNameColumnWidth) {
      this.entityNameColumnWidth = $('#th-sort-name').width() - 100;
    }
    let maxWidth = this.entityNameColumnWidth;
    if(levelNumber){      
      return (maxWidth - (levelNumber * 30))+'px';
      
    }else{
      return maxWidth+'px'
    }
  } 
  openDialog(type: string = 'editable', element): void {
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate,entityMoveDataModel);
    this.selectedFiled = element;
    if (element.isActive) {
      this.status = 'Inactive';
    } else {
      this.status = 'Active';
    }
    //this.selectedFiledIndex = i;
  }
  openWarningDialog(){
    const alertConfirmDataModel = new MatDialogConfig();
      alertConfirmDataModel.height = 'auto';
      alertConfirmDataModel.width = '670px';
      alertConfirmDataModel.disableClose = true;
      this.alertConfirmDataModel = new AlertConfirmDialogModel();
      this.alertConfirmDataModel.action = "Move";
      this.alertConfirmDataModel.isVisibleCancel = false;
      this.alertConfirmDataModel.requestBody = {};
      this.alertConfirmDataModel.dialogId = "entity-move-validation";
      this.alertConfirmDataModel.okButtonName = "Ok";
      this.alertConfirmDataModel.title = 'Alert';
      this.alertConfirmDataModel.message = "Please complete trade hours setup and allocate a price card to add machines under the selected site.";
      this.dialogRef = this.dialog.open(this.alertDialogTemplate,alertConfirmDataModel);
  }
  counter(i: number) {   
    if(this.hasRootLevel){
        i = i-1;
    }
    return new Array(i);
  }
  filterMachines(rowData){    
    localStorage.setItem("filterMachinesBySite",JSON.stringify([rowData.entityId]));
    this.router.navigate([]).then((result) => {
      window.open('/machine', '_blank');
    });
       
  }

  openEntityMoveDialog(element): void {
    
    //$(".sidebar-mini").addClass("filter-open")
    localStorage.removeItem("selectedEntityModel");
    localStorage.removeItem("moveEntityModel");
    let entityMoveModel:EntityMoveModel = new EntityMoveModel();
    entityMoveModel.entityId = element.entityId;
    entityMoveModel.nextLevelNumber = element.nextLevelNumber;
    entityMoveModel.parentEntityId = element.parentEntityId;
    //entityMoveModel.parentLevelNumber =element.parentLevelNumber;
    entityMoveModel.marketId =element.marketId;
    entityMoveModel.levelNumber =element.levelNumber;
    localStorage.setItem("selectedEntityModel",JSON.stringify(entityMoveModel));
    this.selectedEntity = element;
    let movePoupLevel = [2,3,3.5,4,5,6]
    let isNotSiteOrMachine = movePoupLevel.filter(a=>a == element.levelNumber);
    let siteOrMachineLevel = [7]
    let siteOrMachine = siteOrMachineLevel.filter(a=>a == element.levelNumber);
    if (element.isMovable) {
      
      //window.scrollTo(0,0);
      const entityMoveDataModel = new MatDialogConfig();
      entityMoveDataModel.height = 'auto';
      entityMoveDataModel.width = '670px';
      entityMoveDataModel.disableClose = true;
      this.entityMoveDataModel = new EntityMoveDataModel();
      this.entityMoveDataModel.action = "Move";
      this.entityMoveDataModel.cancelButtonName = "Clear";
      this.entityMoveDataModel.requestBody = element;
      this.entityMoveDataModel.requestBody.entityType = this.getEntityLevelNumber(element.levelNumber);
      this.entityMoveDataModel.dialogId = "entity-move";
      this.entityMoveDataModel.okButtonName = "Move";
      this.entityMoveDataModel.title = this.enableGridImprovementsFT ? 'Record' : this.module;
      
      this.roleService.setPopupScroll('main-entity-move'); 
      $(".sidebar-mini").addClass("filter-open")
      //this.roleService.setPopupScroll('main-');      
      setTimeout(() => {
        this.isMoveShow = true;        
      }, this.roleService.scrollSetTimeoutTime);
      //this.dialogRef = this.dialog.open(this.entityMoveTemplate, entityMoveDataModel);
    }
    else if(!element.isMovable && isNotSiteOrMachine != null && isNotSiteOrMachine.length > 0)
    {
      const alertConfirmDataModel = new MatDialogConfig();
      alertConfirmDataModel.height = 'auto';
      alertConfirmDataModel.width = '670px';
      alertConfirmDataModel.disableClose = true;
      this.alertConfirmDataModel = new AlertConfirmDialogModel();
      this.alertConfirmDataModel.action = "Move";
      this.alertConfirmDataModel.isVisibleCancel = false;
      this.alertConfirmDataModel.requestBody = element;
      this.alertConfirmDataModel.dialogId = "entity-move-validation";
      this.alertConfirmDataModel.okButtonName = "Ok";
      this.alertConfirmDataModel.title = element.name;
      this.alertConfirmDataModel.message = `This ${this.getCustomEntityOrRecordName(true)} has active child ${this.getCustomEntityOrRecordName(true, true)}, therefore you are unable to move it. Please set the child ${this.getCustomEntityOrRecordName(true, true)} to inactive or move them to another ${this.getCustomEntityOrRecordName(true)} before proceeding`;
      this.dialogRef = this.dialog.open(this.alertDialogTemplate,alertConfirmDataModel);
    }
    else if(!element.isMovable && siteOrMachine != null && siteOrMachine.length > 0)
    {
      const alertConfirmDataModel = new MatDialogConfig();
      alertConfirmDataModel.height = 'auto';
      alertConfirmDataModel.width = '670px';
      alertConfirmDataModel.disableClose = true;
      this.alertConfirmDataModel = new AlertConfirmDialogModel();
      this.alertConfirmDataModel.action = "Move";
      this.alertConfirmDataModel.isVisibleCancel = true;
      this.alertConfirmDataModel.requestBody = element;
      this.alertConfirmDataModel.dialogId = "entity-move-validation";
      this.alertConfirmDataModel.okButtonName = "Ok";
      this.alertConfirmDataModel.title = element.name;
      this.alertConfirmDataModel.message = `There are active machines linked to this site. Please ensure you have decommissioned and set the Machine ${this.showEntityOrRecordName} Status to Inactive if these machines are no longer at the site`;
      
      this.roleService.setPopupScroll('main-entity-move');
      $(".sidebar-mini").addClass("filter-open");
      this.hasAddedPopupStyle = true;
      this.dialogRef = this.dialog.open(this.siteAlertDialogTemplate,alertConfirmDataModel);
    }
  }
  onConfirmNotMove(){
    this.dialogRef.close();
  }
  onConfirmSiteMove(){
    this.hasAddedPopupStyle = false;
    //window.scrollTo(0,0);
    this.dialogRef.close();
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.entityMoveDataModel = new EntityMoveDataModel();
    this.entityMoveDataModel.action = "Move";
    this.entityMoveDataModel.cancelButtonName = "Clear";
    this.entityMoveDataModel.requestBody = this.selectedEntity;
    this.entityMoveDataModel.requestBody.entityType = this.getEntityLevelNumber(this.selectedEntity.levelNumber);
    this.entityMoveDataModel.dialogId = "entity-move";
    this.entityMoveDataModel.okButtonName = "Move";
    this.entityMoveDataModel.title = this.enableGridImprovementsFT ? 'Record' : this.module;
    //this.dialogRef = this.dialog.open(this.entityMoveTemplate, entityMoveDataModel);
    //this.roleService.setPopupScroll('main-entity-move');
    //$(".sidebar-mini").addClass("filter-open");
    setTimeout(() => {
        this.isMoveShow = true;        
      }, this.roleService.scrollSetTimeoutTime);    
    
  }
  onSiteCancel(){    
    if(this.hasAddedPopupStyle){
      //this.roleService.setPopupScroll('main-entity-move');
     $(".sidebar-mini").removeClass("filter-open");
      this.roleService.resetPopupScroll(false);
      this.hasAddedPopupStyle = false;
      this.dialogRef.close(); 
    }else{
      this.dialogRef.close();
    }
  }
  getEntityLevelNumber(levelNumber: number): string {
    const entityConstant = new Map<number, string>([
      [1, 'Market'],
      [2, 'Level 2 - Optional'],
      [3, 'Level 3 - Optional'],
      [3.5, 'Level 3.5 - Optional'],
      [4, 'Customer'],
      [5, 'Level 5 - Optional'],
      [6, 'Level 6 - Optional'],
      [7, 'Site'],
      [8, 'Machine'],
    ]);
    let levelName = entityConstant.get(levelNumber);
    return levelName;
  }

  onConfirm(type: string = 'device_status') {
    this.dialogRef.close();
    this.spinner.show();
    let msgStatus = "Activated";
    if (this.status == 'Inactive') {
      msgStatus = "Inactivated";
    }
    this.entityService.updateEntityStatus(this.selectedFiled['entityId']).subscribe(response => {
      this.toaster.open({
        text: this.selectedFiled['name'] + " has been " + msgStatus + " successfully",
        type: 'success',
        position: 'top-right',
        duration: 10000
      });
      this.getEntityList();

    }, err => {
      console.log('err', err);
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });

  }

  onCancel() {
    this.dialogRef.close();
  }
  clickFilter(val) {
    this.isShowFilter = val;

  }
  clearFilter() {
    this.sites = [];
    this.partners = [];
    this.filter.propositionTypeId = [];
    this.filter.marketId = '';
    this.filter.siteId = '';
    this.filter.partnerId = '';
    this.filter.status = '';
    this.isFilterClear = true;
    this.isFilterApply = false;
    this.assignFilterToTempFilter();//[GD2-3706]
    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR || this.roleService.objRole.isUserAuditorGRIDR) {
      this.getMarketLookup();
    }
    this.getPartnerLookup(true);
    this.getEntityList();
  }
  clearSearch(){
    this.filter.search = '';
    this.advanceFilter = {
      entityID: false,
      entityName: false,
      billTo: false,
      sellTo: false,
      reference: false,
      machineSerialNumber: false
    }
    this.getEntityList();
  }

  getEntityList(type="") {
    this.displayAdvancedSearch = false;
    this.expandedEntity = []; //to reset exapanded children

    this.spinner.show();
    if(!this.isFilterClear){
    this.isShowFilter = false;
    //this.drawerfilter = false;
    }
    this.isFilterClear = false;
    this.isFilterApply = false;
    let obj = {};
    //this.filter.marketId = '21002271-b5da-4f61-9f98-efd8b70da62a';
    if (this.filter.marketId) { obj['marketId'] = this.filter.marketId;this.isFilterApply = true; };
    if (this.filter.partnerId) { obj['partnerId'] = this.filter.partnerId;this.isFilterApply = true; };
    if (this.filter.siteId) { obj['siteId'] = this.filter.siteId;this.isFilterApply = true; };
    if (this.filter.status != '') { obj['status'] = this.filter.status;this.isFilterApply = true; };
    if (this.filter.search != '') { obj['search'] = this.filter.search };
    if (this.filter.propositionTypeId.length > 0) { obj['propositionTypeIds'] = this.filter.propositionTypeId.join(","); this.isFilterApply = true; } //GD2-5435
    
    var advanceSearchColumns = [];
    if (this.advanceFilter.entityID) {advanceSearchColumns.push("EntityID")};
    if (this.advanceFilter.entityName) {advanceSearchColumns.push("EntityName")};
    if (this.advanceFilter.billTo) {advanceSearchColumns.push("BillTo")};
    if (this.advanceFilter.sellTo) {advanceSearchColumns.push("SellTo")};
    if (this.advanceFilter.reference) {advanceSearchColumns.push("Reference")};
    if (this.advanceFilter.machineSerialNumber) {advanceSearchColumns.push("MachineSerialNumber")};    
    if (advanceSearchColumns.length > 0) { obj['advanceSearch'] = advanceSearchColumns; }
    
    if(type == "apply"){
      //$(".sidebar-mini").removeClass("filter-open");
      this.changefilter();
    }
    
    localStorage.setItem('entityListFilter',JSON.stringify([this.filter])); //gd2-837 to restore filter when come back from edit/view screen
    localStorage.setItem('entityListAdvanceFilter',JSON.stringify([this.advanceFilter])); //GD2-3336 Org Structure Advanced Search : Check box is not selected after saving the entity
    this.entityService.getEntityListV2(obj).subscribe(response => {
      this.spinner.hide();
      // this.isPageDataLoad = true;
      if (response && response['data'].length) {
        if(response['data'][0]['data']['parentEntityId'] === null){
          this.hasRootLevel = true;
        }else{
          this.hasRootLevel = false;
        }

        this.entityService.getEntityMachineCount(obj).subscribe(res => {
          response['data'].forEach(element => {
            let filteredEntity = res['data'].filter(x => x.entityId == element['data']['entityId']);
            if(filteredEntity.length){
              element['data']['expandCollapseTooltip'] = 'Expand';
              element['data']['machineCount'] = filteredEntity[0].machineCount;
              element['data']['machineCountUpdated'] = true;
              element['data']['isMovable'] = filteredEntity[0].isMovable;
              element['data']['isMovableUpdated'] = true;
            }
          });
        });

        let treeViewNodes:any =  localStorage.getItem("treeViewNodes");
        let latesttreeViewNodes:any = [];
        if(treeViewNodes){
          this.entityListTemp = (response['data'][0]['data']['parentEntityId'] === null) ? response['data'][0]['children'] : response['data'];
          treeViewNodes = JSON.parse(treeViewNodes);
          let hasCheckedMarket = [];
          if(!treeViewNodes.length){
            this.exapandORcollapse(this.entityListTemp);
          }else{
            let i = 1;
            treeViewNodes.forEach(element => {
                if(!hasCheckedMarket.includes(element.marketId)){                  
                let isFoundEntity = this.entityListTemp.filter(el => el['data']['marketId'] == element.marketId);
                if(!isFoundEntity.length){
                  hasCheckedMarket.push(element.marketId);
                }else{
                  if(isFoundEntity[0]['data']['marketId'] == element.marketId && element.levelNumber >= isFoundEntity[0]['data']['levelNumber']){
                    latesttreeViewNodes.push(element);
                  }
                }            
              }
              if(i == treeViewNodes.length){              
                localStorage.setItem('treeViewNodes',JSON.stringify(latesttreeViewNodes));
                this.exapandORcollapse(this.entityListTemp);
              }
            i++;
            });
          }
        }else{
              this.entityList = (response['data'][0]['data']['parentEntityId'] === null) ? response['data'][0]['children'] : response['data'];        
              this.isPageDataLoad = true;
        }
        if(localStorage.getItem('entityListSorting')){
          let sortingColumn:any = localStorage.getItem('entityListSorting').split(' ');          
          setTimeout(() => {
            if(sortingColumn[1] == 'desc'){
              $('#'+sortingColumn[0]).click();
              $('#'+sortingColumn[0]).click();
            }
          }, 100);
        }
      } else {
        this.entityList = [];
      }
    }, err => {
        this.spinner.hide();
    });
  }

  onNodeExpand(e){ 
    //get children on entity expland 
    e.node.data['expandCollapseTooltip']='Collapse';
    if(!this.expandedEntity.includes(e.node.data.entityId)){ 
      this.spinner.show();
      e.node.expanded = false;
      this.expandedEntity.push(e.node.data.entityId);
      
      let payload = {
        LevelNumber:e.node.data.nextLevelNumber,
        ParentEntityId:e.node.data.entityId,
        NextUINextLevelNumber:(e.node.data.uiNextLevelNumber+1),
        Status: this.filter.status
      }
      setTimeout(() => {
        this.entityService.getEntityListV2(payload).subscribe(response => {
          if (response && response?.data) {
            e['node']['children'] = response.data;        
            e.node.expanded = true;

    
            this.entityService.getEntityMachineCount(payload).subscribe(res => {
              e['node']['children'].forEach(element => {
                let filteredEntity = res['data'].filter(x => x.entityId == element['data']['entityId']);
                if(filteredEntity.length){
                  element['data']['expandCollapseTooltip'] = 'Expand';
                  element['data']['machineCount'] = filteredEntity[0].machineCount;
                  element['data']['machineCountUpdated'] = true;
                  element['data']['isMovable'] = filteredEntity[0].isMovable;
                  element['data']['isMovableUpdated'] = true;
                }
              });
            });
            this.entityList = [...this.entityList];  
          }
          this.spinner.hide();  
          });
      }, 30);
      
    }
    //get children on entity expland 
    
    let treeViewNodes:any =  localStorage.getItem("treeViewNodes");
    if(treeViewNodes){
      treeViewNodes = JSON.parse(treeViewNodes);
      let isFoundEntity = treeViewNodes.filter(el => el.entityId == e.node.data.entityId);
      if(!isFoundEntity.length){
      //if(!treeViewNodes.includes(e.node.data.entityId)){
        treeViewNodes.push({entityId:e.node.data.entityId,levelNumber:e.node.data.levelNumber,marketId:e.node.data.marketId});
        localStorage.setItem('treeViewNodes',JSON.stringify(treeViewNodes));
      }
    }else{    
      let data = [{entityId:e.node.data.entityId,levelNumber:e.node.data.levelNumber,marketId:e.node.data.marketId}];
      localStorage.setItem('treeViewNodes',JSON.stringify(data));      
    }
  }

  sortingClick(id){
    let orderBy = 'asc';
    if(localStorage.getItem('entityListSorting')){
      let sortingColumn = localStorage.getItem('entityListSorting').split(' ');
      if(id == sortingColumn[0] && orderBy == sortingColumn[1]){
        orderBy = 'desc';    
      }
    }
    localStorage.setItem('entityListSorting',id+' '+orderBy);
  }
  getMarketLookup(fromPropositionTypeFilter = false) {
    //GD2-4537 start
    let propositionTypeIds = '';
    if (this.filter.propositionTypeId.length > 0)
      propositionTypeIds = this.filter.propositionTypeId.join(",");
    //GD2-4537 end
    this.markets = [];
    this.entityService.getMarketLookup('', propositionTypeIds).subscribe(response => {
      if(response != null){
        if (response['data'].length) {
          this.markets = this.formatSelectOption(response.data)
        }    
      }
      if (this.filter.marketId != "" && fromPropositionTypeFilter) {
        this.filter.marketId = '';
        this.filter.partnerId = '';
        this.filter.siteId = '';
        this.getPartnerLookup(false);
      } 
      else if (fromPropositionTypeFilter && this.markets.length == 0){
        this.partners = [];
        this.sites = [];
      }
      else if (fromPropositionTypeFilter && this.markets.length > 0 && this.partners.length == 0 && this.sites.length == 0) {
        this.getPartnerLookup(false);
      }
    });
  }
  getPartnerLookup(isPageLoad) {
    this.sites = [];
    this.partners = [];
    this.filter.siteId = '';
    this.filter.partnerId = '';
    if(!isPageLoad){
    this.spinner.show();
    }
  if (!(this.roleService.objRole.isLevel5R || this.roleService.objRole.isLevel6R || this.roleService.objRole.isSiteR)) {
    this.entityService.getPartnerLookup(this.filter.marketId).subscribe(response => {
      if(!isPageLoad){
      this.spinner.hide();
      }
      if (response && response['data'].length) {
        this.partners = this.formatSelectOption(response.data)
      }
    });
   }
    if(this.filter.marketId == ""){
      this.getSiteLookup(isPageLoad);
    }
  }
  getSiteLookup(isPageLoad) {
    this.sites = [];
    this.filter.siteId = '';
    if(!isPageLoad){
    this.spinner.show();
      }
      if (this.filter.marketId != "" && this.filter.partnerId.length == 0) { this.sites = []; this.spinner.hide(); }//[GD2-3796]
      else {
          this.entityService.getSiteLookup(this.filter.partnerId).subscribe(response => {
              if (!isPageLoad) {
                  this.spinner.hide();
              }
              if (response && response['data'].length) {
                  this.sites = this.formatSelectOption(response.data)
              }
          });
      }
  }
  addMarket() {
    this.router.navigate(['/entity/add'])
  }
  applyFilter() {//[GD2-3706]
      this.assignFilterToTempFilter();
      this.getEntityList('apply');
  }

    assignFilterToTempFilter() {//[GD2-3706]
        this.filterColumns.forEach(key => {
            this.tempfilter[key] = this.filter[key];
        });
    }
  changefilter() {
    this.roleService.setPopupScroll('section-entity-filter');  
    if (!this.drawerfilter) {
      this.displayAdvancedSearch = false;
      $(".sidebar-mini").addClass("filter-open");
   }
    else {
        this.filterColumns.forEach(key => {//[GD2-3706]
            let valueChanged = false;
            if (this.filter[key] != this.tempfilter[key]) { valueChanged = true; }
            this.filter[key] = this.tempfilter[key];
            if (key == 'propositionTypeId' && this.filter['propositionTypeId'].length >= 0 && valueChanged) {
              this.getMarketLookup();
            }
            if (key == 'marketId' && this.filter['marketId'].length >= 0 && valueChanged) {
                this.getPartnerLookup(false);
            }
            if (key == 'partnerId' && this.filter['partnerId'].length >= 0 && valueChanged) {
                this.getSiteLookup(false);
            }
        });
       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll()
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;  
      
    }, this.roleService.scrollSetTimeoutTime); 
  }
 
  onMoveConfirm(data) {
    let entityId = data.entityId;
    let entityMoveModel:EntityMoveModel = new EntityMoveModel();
    entityMoveModel.entityId = data.entityId;
    entityMoveModel.nextLevelNumber = data.nextLevelNumber;
    entityMoveModel.parentEntityId = data.parentEntityId;
    entityMoveModel.parentLevelNumber =data.parentLevelNumber;
    entityMoveModel.marketId =data.marketId;
    entityMoveModel.levelNumber =data.levelNumber;
    localStorage.setItem("moveEntityModel",JSON.stringify(entityMoveModel));

    this.roleService.setPopupScroll('main-entity-move');
    this.roleService.resetPopupScroll();   
    setTimeout(() => {
      this.isMoveShow = false;
    }, this.roleService.scrollSetTimeoutTime);
    
    this.router.navigate(['/entity/',entityId],{ queryParams:{ nextLevelNumber : data.levelNumber,marketId : data.marketId,parentEntityId : data.parentEntityId,screenType:'move',parentlevelNumber:data.parentlevelNumber,isEntityMove:true}})
    //this.dialogRef.close();
  }
  onMoveCancel() {
    this.roleService.setPopupScroll('main-entity-move'); 
    this.roleService.setPopupScroll('main-');   
    this.roleService.resetPopupScroll();   
    setTimeout(() => {
      this.isMoveShow = false;
    }, this.roleService.scrollSetTimeoutTime);
    //this.isMoveShow = false;
    //this.dialogRef.close();
  }

  addEntity(){
    var url = '/entity/add?nextLevelNumber='+this.roleService.nextLevelNumber+'&parentEntityId='+this.roleService.entityId+'&marketId='+this.roleService.marketId+'&parentlevelNumber='+this.roleService.levelNumber;
    this.router.navigateByUrl(url);
  }
 
  getLevelName(levelNUmber,type){
    if(levelNUmber == 1){
      if(type == 'name'){
        return 'M';
      }else{
        return 'Market ' + this.showEntityOrRecordName;
      }
    }else if(levelNUmber == 4){
      if(type == 'name'){
        return 'C';
      }else{
        return 'Customer ' + this.showEntityOrRecordName;
      }
    }else if(levelNUmber == 7){
      if(type == 'name'){
        return 'S';
      }else{
        return 'Site ' + this.showEntityOrRecordName;
      }
    }else if(levelNUmber == 0){
      return '';
    }else{
      if(type == 'name'){
        return 'L' + levelNUmber;
      }else{
        return 'Level ' + levelNUmber + ' ' + this.showEntityOrRecordName;
      }
    }

  }
   
  onNodeExpandOld(e){    
    let treeViewNodes:any =  localStorage.getItem("treeViewNodes");
    if(treeViewNodes){
      treeViewNodes = JSON.parse(treeViewNodes);
      if(!treeViewNodes.includes(e.node.data.entityId)){
        treeViewNodes.push(e.node.data.entityId);
        localStorage.setItem('treeViewNodes',JSON.stringify(treeViewNodes));
      }
    }else{    
      localStorage.setItem('treeViewNodes',JSON.stringify([e.node.data.entityId]));      
    }
  }  
  onNodeCollapse(e){   
    e.node.data['expandCollapseTooltip']='Expand';
    let treeViewNodes:any =  localStorage.getItem("treeViewNodes");
    if(treeViewNodes){
      treeViewNodes = JSON.parse(treeViewNodes);
      let isFoundEntity = treeViewNodes.filter(el => el.entityId == e.node.data.entityId);
      if(isFoundEntity.length){
      //if(treeViewNodes.includes(e.node.data.entityId)){
        const index = treeViewNodes.findIndex(el => el.entityId == e.node.data.entityId);
        if (index > -1) {
          treeViewNodes.splice(index, 1);
          localStorage.setItem('treeViewNodes',JSON.stringify(treeViewNodes));
        }      
      }
    }    
  } 

  formatSelectOption(response: {text: string, masterDataId: string}[]) {
    return [{name: 'All', id: ''}, ...response.map((elem) => ({name: elem.text, id: elem.masterDataId}))]
  }

  async exapandORcollapse(nodes){   
    let treeViewNodes:any =  localStorage.getItem("treeViewNodes");
    if(treeViewNodes){
      let isNotMatchSignleEntity = true;
      this.expandSpinnerShow = true;
      treeViewNodes = JSON.parse(treeViewNodes);
      let i = 1;
      for(let node of nodes){ 
        if (node.children) {
          let isFoundEntity = treeViewNodes.filter(el => el.entityId == node.data.entityId);
          if(isFoundEntity.length){
         // if(treeViewNodes.includes(node.data.entityId)){
            isNotMatchSignleEntity = false;
            node.expanded = true;
            
            //get children on entity expland 
            if(!this.expandedEntity.includes(node.data.entityId)){
              //debugger 
             // this.expandEntityCount++;
              node.expanded = false;
              this.expandedEntity.push(node.data.entityId);
              //this.spinner.show();
              let payload = {
                LevelNumber:node.data.nextLevelNumber,
                ParentEntityId:node.data.entityId,
                NextUINextLevelNumber:(node.data.uiNextLevelNumber+1),
                Status: this.filter.status
              }
                            
              let res = await this.getChidren(payload);
              node['children'] = res['data'];        
              node.expanded = true;

              this.entityService.getEntityMachineCount(payload).subscribe(res => {
                node['children'].forEach(element => {
                  let filteredEntity = res['data'].filter(x => x.entityId == element['data']['entityId']);
                  if(filteredEntity.length){
                    element['data']['machineCount'] = filteredEntity[0].machineCount;
                    element['data']['machineCountUpdated'] = true;
                    element['data']['isMovable'] = filteredEntity[0].isMovable;
                    element['data']['isMovableUpdated'] = true;
                  }
                });
              });

              this.exapandORcollapseForMarket(node['children']);
              //this.entityList = [...this.entityList];
            }
            //get children on entity expland
          }
          // for (let cn of node.children) {
          //   this.exapandORcollapse(node.children);
          // }
        }
        if(i == nodes.length && isNotMatchSignleEntity){
          this.entityList = this.entityListTemp;
        }
        i++;
      }
    }
  }

  async exapandORcollapseForMarket(nodesOfMarket){
    let treeViewNodes:any =  localStorage.getItem("treeViewNodes");
    treeViewNodes = JSON.parse(treeViewNodes);
      for(let node of nodesOfMarket){ 
        if (node.children) {
          let isFoundEntity = treeViewNodes.filter(el => el.entityId == node.data.entityId);
          if(isFoundEntity.length){
          //if(treeViewNodes.includes(node.data.entityId)){
            node.expanded = true;
            
            //get children on entity expland 
            if(!this.expandedEntity.includes(node.data.entityId)){
              //debugger 
              //this.expandEntityCount++;
              node.expanded = false;
              this.expandedEntity.push(node.data.entityId);
              //this.spinner.show();
              let payload = {
                LevelNumber:node.data.nextLevelNumber,
                ParentEntityId:node.data.entityId,
                NextUINextLevelNumber:(node.data.uiNextLevelNumber+1),
                Status: this.filter.status
              }
              
              let res = await this.getChidren(payload);
              node['children'] = res['data'];  

              this.entityService.getEntityMachineCount(payload).subscribe(res => {
                node['children'].forEach(element => {
                  let filteredEntity = res['data'].filter(x => x.entityId == element['data']['entityId']);
                  if(filteredEntity.length){
                    element['data']['machineCount'] = filteredEntity[0].machineCount;
                    element['data']['machineCountUpdated'] = true;
                    element['data']['isMovable'] = filteredEntity[0].isMovable;
                    element['data']['isMovableUpdated'] = true;
                  }
                });
              });
              node.expanded = true;
              //this.entityList = [...this.entityList];
            }
            //get children on entity expland
          }
          for (let cn of node.children) {
            this.exapandORcollapseForMarket(node.children);
          }
        }
      }
    
  }
  
        
  async getChidren(payload){
    this.current_time = new Date().getTime();    
    return new Promise<any>((resolve, reject) => {
      this.spinner.show();
      let treeViewNodes:any =  localStorage.getItem("treeViewNodes");
      treeViewNodes = JSON.parse(treeViewNodes); 
      this.entityService.getEntityListV2(payload).subscribe(response => {
        this.expandEntityCount++;        
        if(this.expandEntityCount == treeViewNodes.length){
          this.spinner.hide();
          this.expandEntityCount  = 0;
          this.entityList = this.entityListTemp;
          this.displayEntity = true;
          this.isPageDataLoad = true;
          this.current_time = new Date().getTime(); 
        }else{
          //REf : GD2-2500 fixed loader use when expanded multiple entities & apply filter
          setTimeout(() => {
            let diff =(new Date().getTime()) - this.current_time;
            if(diff >= 5000){
              this.spinner.hide();
              this.expandEntityCount  = 0;
              this.entityList = this.entityListTemp;
              this.displayEntity = true;
              this.isPageDataLoad = true;
              this.current_time = new Date().getTime();
            }
          }, 5000);
        }
        resolve(response);
      });
    });
  }

  clearNodeStorage(){
    localStorage.removeItem("treeViewNodes");
    this.isExpandTree = false;    
  }

  getAddTooltip(nextLevelNumber, disabled: boolean = false){
      if([2,3].includes(nextLevelNumber) && disabled) {
        return "You are not able to create a Level "+nextLevelNumber+" " + this.showEntityOrRecordName;
      }
      else if([2,3,3.5,5,6].includes(nextLevelNumber)){
          return "Add Level "+nextLevelNumber+" " + this.showEntityOrRecordName;
      }
      else if(nextLevelNumber == 4){
        return "Add Customer " + this.showEntityOrRecordName;
      }
      else if(nextLevelNumber == 7){
        return "Add Site " + this.showEntityOrRecordName;
      }
      else if(nextLevelNumber == 8){
        return "Add Machine " + this.showEntityOrRecordName;
      }
  }

  getMoveTooltip(ParentLevelNumber, levelNumber){
    if(ParentLevelNumber == 1){
      return "Move to Market";
    }
    else if(ParentLevelNumber == 4 && levelNumber != 7){
      return "Move to Customer";
    }
    else if([2,3,3.5,5,6].includes(ParentLevelNumber) && levelNumber != 7){
      return "Move to Level "+ParentLevelNumber;
    }
    else if(levelNumber == 7) { //GD2-4540
      return "Move Site";
    }
  }

  advanceToggle () {
    this.displayAdvancedSearch = !this.displayAdvancedSearch;  
  }

  applyAdvanceSearch() {
    this.displayAdvancedSearch = false;
    if (this.filter.search != '') {
      this.getEntityList();
    }
  }

  getPropositionTypes() { //GD2-4537
    this.entityService.getEntityPropositions(this.roleService.entityId).subscribe(response => {
      if (response && response['data']) {
        this.propositionTypes = response.data.map(({name, propositionTypeId})=> ({name, id: propositionTypeId}));
      }
    });
  }
}