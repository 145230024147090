declare type UUID = string;

export class RequestAddDrinkGroupTemplate {
  constructor(
    public templateName: string,
    public drinkGroupTemplatePropositionType : string,
    public marketId: UUID,
    public level2Id: UUID | undefined,
    public level3Id: UUID | undefined,
    public drinkList: DrinkGroupTemplateDetailDrink[],
    public cupSizeList: UUID[],
    public milkTypeList: UUID[],
    public coffeeTypeList: UUID[],
    public syrupList: UUID[] | undefined,
    public primaryCoffeeType: UUID,
    public primaryMilkType: UUID,
    public isActive: boolean = true) {
  }
}

export class RequestGetDrinkGroupTemplate {
  constructor(
    public drinkGroupTemplateId: UUID,
    public templateName: string,
    public drinkGroupTemplatePropositionType : string,
    public marketId: UUID,
    public level2Id: UUID | undefined,
    public level3Id: UUID | undefined,
    public drinkList: DrinkGroupTemplateDetailDrink[],
    public cupSizeList: UUID[],
    public milkTypeList: UUID[],
    public coffeeTypeList: UUID[],
    public syrupList: UUID[] | undefined,
    public primaryCoffeeType: UUID,
    public primaryMilkType: UUID,
    public isActive: boolean = true) {
  }
}

export class RequestEditDrinkGroupTemplate {
  constructor(
    public drinkGroupTemplateId: UUID,
    public templateName: string,
    public drinkGroupTemplatePropositionType : string,
    public drinkList: DrinkGroupTemplateDetailDrink[],
    public cupSizeList: UUID[],
    public milkTypeList: UUID[],
    public coffeeTypeList: UUID[],
    public syrupList: UUID[] | undefined,
    public primaryCoffeeType: UUID,
    public primaryMilkType: UUID,
    public isActive: boolean) {
  }
}

export class DrinkGroupTemplateDetailDrink {
  constructor(
    public drinkName: UUID,
    public cupType?: string | undefined) {
  }
}

export class ResponseGetDrinkGroupTemplate {
  constructor (public data: RequestGetDrinkGroupTemplate) {
  }
}
