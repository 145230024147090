import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { CapsuleIngredientService } from 'src/app/services/capsule-ingredient/capsule-ingredient.service';
import { RoleService } from 'src/app/services/role/role.service';
declare var $: any;
@Component({
    selector: 'tab-cup-volumes',
    templateUrl: './cup-volumes.component.html',
    styleUrls: ['./cup-volumes.component.css']
})
export class CupVolumesComponent implements OnInit { //[GD2-3702]

    recordsTotal = 0;
    isPageDataLoad = false;
    cupVolumes: any = [];
    displayedColumns: string[] = ['cupVolume', 'isEatIn', 'isTakeAway', 'isActive', 'action'];
    pageSizeOptions: number[] = [10, 25, 50, 100];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    drawerfilter: boolean = false;
    isEdit: boolean = false;
    addUpdateCupVolumeForm: FormGroup;
    isFilterApply: boolean = false;
    selectedIndex: number = 0;
    radioObj: any = {
        isActive: true,
    };
    cupVolumeMatrixId = '';
    params = {
        cupVolumeMatrixId: '',
        search: '',
        pageNumber: 1,
        pageSize: this.pageSizeOptions[0],
        sortBy: '',
        sortOrder: ''
    }
    tempfilter = {
        capsuleName: '',
        barCode: '',
        buttonPress: '',
        drinkName: ''
    }
    dialogTexts = {
        successTitle: '',
        cancelTitle: '',
        message: '',
        deviceName: '',
        status: '',
        title: '',
        type: '',
        tabindex: 0
    }
    dialogRef: MatDialogRef<any>;
    @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
    showForm: boolean = true;

    constructor(
        private ingredientService: CapsuleIngredientService,
        private spinner: NgxSpinnerService,
        private router: Router,
        public roleService: RoleService,
        private toaster: Toaster,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        if (!this.roleService.objRole.isSystemAdmin) {
            this.router.navigate(['/unauthorized']);
        }
        let isActive = { value: this.radioObj['isActive'], disabled: false }
        this.addUpdateCupVolumeForm = new FormGroup({
            'cupVolume': new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1), Validators.max(99)]),  //numeric value from 1-99
            'isEatIn': new FormControl('', [Validators.required]), //[GD2 - 3909]
            'isTakeAway': new FormControl('', [Validators.required]),//[GD2-3909]
            'isActive': new FormControl(isActive, [Validators.required])
        });
        this.getCupVolumeList();
    }

    getCupVolumeList(type = undefined) {
        this.spinner.show();
        let obj = {};
        this.isFilterApply = false;
        if (type == 'apply') {
            this.drawerfilter = false;
            $(".sidebar-mini").removeClass("filter-open");
            this.roleService.resetPopupScroll();
        }
        if (this.params.search != '') { obj['Search'] = this.params.search; };
        if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
        if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
        if (this.params.sortBy != undefined) { obj['SortBy'] = this.params.sortBy };
        if (this.params.sortOrder != undefined) { obj['SortOrder'] = this.params.sortOrder };

        this.ingredientService.GetCupVolumes(obj).subscribe(response => {
            this.recordsTotal = response?.params?.count;
            this.spinner.hide();
            this.isPageDataLoad = true;

            if (response && response['data']) {
                this.cupVolumes = response['data']
            } else {
                this.cupVolumes = [];
            }
        }, err => {
            this.spinner.hide();
            let errors = err.errors == undefined ? err.error : err.errors;
            if (Array.isArray(errors)) {
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            }
        });
    }

    onSearch() {
        this.params.pageNumber = 1;
        this.getCupVolumeList();
    }

    clearSearch() {
        this.params.search = '';
        this.params.pageNumber = 1;
        this.getCupVolumeList();
    }

    sortData(event) {
        event.direction = (event.active == 'isActive' && event.direction != '' ? (event.direction == 'asc' ? 'desc' : 'asc') : event.direction);
        this.params.pageNumber = 1;
        this.params.sortBy = (event.active != '' && event.direction != '' ? event.active : (event.active != '' && event.direction == '') ? event.active : 'cells_name');
        this.params.sortOrder = (event.direction != '' ? event.direction : (event.direction == '') ? 'asc' : 'asc');
        this.getCupVolumeList();
    }

    pageChange(event) {
        this.params.pageNumber = (event.pageIndex > 0 ? (event.pageIndex + 1) : 1);
        this.params.pageSize = (event.pageSize > 0 ? event.pageSize : this.pageSizeOptions[0]);
        this.getCupVolumeList();
    }

    addUpdateCupVolumes() {
        this.spinner.show();
        if (this.addUpdateCupVolumeForm.status === 'INVALID') {
            this.addUpdateCupVolumeForm.markAllAsTouched();
            this.spinner.hide();
            this.toaster.open({
                text: 'Please provide valid input for all the highlighted fields',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else if ((this.addUpdateCupVolumeForm.controls['isEatIn'].value == "" && this.addUpdateCupVolumeForm.controls['isTakeAway'].value == "")
            || (this.addUpdateCupVolumeForm.controls['isEatIn'].value == "0" && this.addUpdateCupVolumeForm.controls['isTakeAway'].value == "0")) {
            this.spinner.hide();
            this.toaster.open({
                text: 'Please provide at least one applicable service delivery option',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else {
            let body: any = {
                "cupVolume": this.addUpdateCupVolumeForm.controls['cupVolume'].value,
                "isEatIn": (this.addUpdateCupVolumeForm.controls['isEatIn'].value == "") ? null : (this.addUpdateCupVolumeForm.controls['isEatIn'].value == "1" ? true : false),
                "isTakeAway": (this.addUpdateCupVolumeForm.controls['isTakeAway'].value == "") ? null : (this.addUpdateCupVolumeForm.controls['isTakeAway'].value == "1" ? true : false),
                "isActive": this.addUpdateCupVolumeForm.controls['isActive'].value,
                "cupVolumeMatrixId": this.cupVolumeMatrixId,
            };

            this.ingredientService.AddUpdateCupVolume(body).subscribe(response => {
                this.spinner.hide();
                if (response) {
                    this.showForm = false;
                    setTimeout(() => {
                        this.showForm = true;
                    }, 2);
                    this.toaster.open({
                        text: (this.cupVolumeMatrixId == '') ? 'Cup Volume added successfully' : 'Cup Volume updated successfully',
                        type: 'success',
                        position: 'top-right',
                        duration: 10000
                    });

                    //reset form
                    Object.keys(this.addUpdateCupVolumeForm.controls).forEach((key) => {
                        const control = this.addUpdateCupVolumeForm.controls[key];
                        control.patchValue((key == 'isActive') ? true : '');
                        control.markAsPristine();
                        control.markAsUntouched();
                    });

                    this.params.pageNumber = 1;
                    this.cupVolumeMatrixId = '';
                    this.isEdit = false;
                    this.getCupVolumeList();
                }
            }, err => {
                this.spinner.hide();
                let errors = err.errors == undefined ? err.error : err.errors;
                if (Array.isArray(errors)) {
                    errors.forEach(element => {
                        this.toaster.open({
                            text: element.msg,
                            type: 'danger',
                            position: 'top-right',
                            duration: 10000
                        });
                    });
                }
            });
        }

    }
    click(type, element = null) {
        if (type == 'edit') {
            this.isEdit = true;
            this.spinner.show();
            this.cupVolumeMatrixId = element.cupVolumeMatrixId;
            this.addUpdateCupVolumeForm.controls['cupVolume'].patchValue(element.cupVolume);
            this.addUpdateCupVolumeForm.controls['isEatIn'].patchValue((element.isEatIn == null) ? "" : (element.isEatIn ? "1" : "0"));
            this.addUpdateCupVolumeForm.controls['isTakeAway'].patchValue((element.isTakeAway == null) ? "" : (element.isTakeAway ? "1" : "0"));
            this.addUpdateCupVolumeForm.controls['isActive'].patchValue(element.isActive);
            this.spinner.hide();
        }
        else if (type == 'cancel') {
            this.getConfirmationBox('', 3);
        }
    }
    onConfirm() {
        this.dialogRef.close();
        this.params.pageNumber = 1;
        this.cupVolumeMatrixId = '';
        this.isEdit = false;
        this.showForm = false;
        setTimeout(() => {//[GD2-3909]
            this.showForm = true;
            Object.keys(this.addUpdateCupVolumeForm.controls).forEach((key) => {
                const control = this.addUpdateCupVolumeForm.controls[key];
                control.patchValue((key == 'isActive') ? true : '');
                control.markAsPristine();
                control.markAsUntouched();
            });
            this.radioObj['isActive'] = true;
        }, 2);
    }
    onCancel() {
        this.dialogRef.close();
    }
    getConfirmationBox(type: string, tabindex: number) {
        let msg = 'All unsaved changes will be lost. Are you sure you want to cancel?';
        this.dialogTexts = {
            successTitle: 'Yes',
            cancelTitle: 'No',
            message: msg,
            deviceName: '',
            status: '',
            title: 'Confirmation',
            type: type,
            tabindex: tabindex
        }
        const eventPopupModel = new MatDialogConfig();
        eventPopupModel.height = 'auto';
        eventPopupModel.width = '670px';
        eventPopupModel.disableClose = true;
        this.dialogRef = this.dialog.open(this.confirmDialogTemplate, eventPopupModel);
    }

    tabChange(event) {
        this.selectedIndex = event.index;
    }
}
