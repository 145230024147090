//GD2-3703
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BarCodeIngredientLookup } from 'src/app/enums/capsuleIngredientLookup.enum';
import { CapsuleIngredientService } from 'src/app/services/capsule-ingredient/capsule-ingredient.service';
import { RoleService } from 'src/app/services/role/role.service';
declare var $: any;
@Component({
  selector: 'app-barcode-combination',
  templateUrl: './barcode-combination.component.html',
  styleUrls: ['./barcode-combination.component.css']
})
export class BarcodeCombinationComponent implements OnInit {

  recordsTotal = 0;
  isPageDataLoad = false;
  barcodeCombinationList: any = [];
  barCodeList: any = [];
  displayedColumns: string[] = ['barcode', 'capsuleName', 'grammage', 'barcodeString', 'status', 'actions'];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  drawerfilter: boolean = false;
  addBarcodeCombinationForm: FormGroup;
  isFilterApply: boolean = false;
  capsuleNamelookup: any = [];
  capsuleNameList: Observable<string[]>;
  barCodelookup: any = [];
  buttonPresslookup: any = [];
  drinkNamelookup: any = [];
  machineTypes: any = [];
  drinkList: any = [];
  selectedIndex: number = 0;

  params = {
    search: '',
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    sortBy: '',
    sortOrder: ''
  }
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: '',
    tabindex: 0
  }
  associateDialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: '',
    tabindex: 0
  }
  showForm: boolean = true;
  dialogRef: MatDialogRef<any>;
  associateDialogRef: MatDialogRef<any>;
  public barcodeId: string = '';
  public barcodeIsActive: boolean = true;
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  @ViewChild("associateConfirmDialogTemplate") associateConfirmDialogTemplate: TemplateRef<any>;
  constructor(
    private ingredientService: CapsuleIngredientService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public roleService: RoleService,
    private toaster: Toaster,
    private dialog: MatDialog,
    private associateDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (!this.roleService.objRole.isSystemAdmin) {
      this.router.navigate(['/unauthorized']);
    }
    this.barcodeId = '';
    this.barcodeIsActive = true;
    this.addBarcodeCombinationForm = new FormGroup({
      'barcode': new FormControl('', [Validators.required, Validators.pattern("^[0-9a-zA-Z]*$"), Validators.maxLength(20)]), //alphanumeric
      'capsuleName': new FormControl('', [Validators.required, Validators.pattern("^[0-9a-zA-Z ]*$"), Validators.maxLength(50)]), //alphanumeric
      'grammage': new FormControl('', [Validators.required, Validators.min(0), Validators.max(99.9)]), //decimal value from 0.0-99.9
      'status': new FormControl(true, [Validators.required])
    });
    this.refreshData();
  }

  refreshData() {
    this.getBarcodeCombinationLookup();
    this.getBarcodeCombinationList();
  }

  getBarcodeCombinationList(type = undefined) {
    this.spinner.show();
    let obj = {};
    this.isFilterApply = false;
    if (this.params.search != '') { obj['Search'] = this.params.search; };
    if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) { obj['SortBy'] = this.params.sortBy };
    if (this.params.sortOrder != undefined) { obj['SortOrder'] = this.params.sortOrder };

    this.ingredientService.GetBarcodeCombinationList(obj).subscribe(response => {
      this.recordsTotal = response?.params?.count;
      this.spinner.hide();
      this.isPageDataLoad = true;

      if (response && response['data']) {
        this.barcodeCombinationList = response['data']
      } else {
        this.barcodeCombinationList = [];
      }
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (Array.isArray(errors)) {
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }

  onSearch() {
    this.params.pageNumber = 1;
    this.getBarcodeCombinationList();
  }

  clearSearch() {
    this.params.search = '';
    this.params.pageNumber = 1;
    this.getBarcodeCombinationList();
  }

  sortData(event) {
    event.direction = (event.active == 'isActive' && event.direction != '' ? (event.direction == 'asc' ? 'desc' : 'asc') : event.direction);
    this.params.pageNumber = 1;
    this.params.sortBy = (event.active != '' && event.direction != '' ? event.active : (event.active != '' && event.direction == '') ? event.active : 'cells_name');
    this.params.sortOrder = (event.direction != '' ? event.direction : (event.direction == '') ? 'asc' : 'asc');
    this.getBarcodeCombinationList();
  }

  pageChange(event) {
    this.params.pageNumber = (event.pageIndex > 0 ? (event.pageIndex + 1) : 1);
    this.params.pageSize = (event.pageSize > 0 ? event.pageSize : this.pageSizeOptions[0]);
    this.getBarcodeCombinationList();
  }


  displayFn(): (id: string) => string {
    return (id: string) => {
      if (id) {
        if (this.capsuleNamelookup.find(obj => obj.value == id)) {
          let obj = this.capsuleNamelookup.find(obj => obj.value == id);
          if (Object.keys(obj).length) {
            return obj.value
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }

  saveBarcodeCombination(isConfirm) {
    this.spinner.show();
    if (this.addBarcodeCombinationForm.status === 'INVALID') {
      this.addBarcodeCombinationForm.markAllAsTouched();
      this.spinner.hide();
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else {
      let capsuleName = (this.capsuleNamelookup.find(x => x.id == this.addBarcodeCombinationForm.controls['capsuleName'].value) != null || this.capsuleNamelookup.find(x => x.id == this.addBarcodeCombinationForm.controls['capsuleName'].value) != undefined)
        ? this.capsuleNamelookup.find(x => x.id == this.addBarcodeCombinationForm.controls['capsuleName'].value).value : this.addBarcodeCombinationForm.controls['capsuleName'].value;
      let body: any = {
        "capsuleName": capsuleName,
        "barcode": this.addBarcodeCombinationForm.controls['barcode'].value,
        "grammage": this.addBarcodeCombinationForm.controls['grammage'].value,
        "isActive": this.addBarcodeCombinationForm.controls['status'].value,
        "isConfirm": this.barcodeId == '' ? true : isConfirm,
        "barcodeId": this.barcodeId == '' ? null : this.barcodeId
      };

      this.ingredientService.SaveBarcodeCombination(body).subscribe(response => {
        this.spinner.hide();
        if (response) {
          if (response.data.result) {
            this.showForm = false;
            setTimeout(() => { this.showForm = true; }, 2);
            if (this.barcodeId == '') {
              this.toaster.open({
                text: 'Barcode Combination added successfully',
                type: 'success',
                position: 'top-right',
                duration: 10000
              });
            } else {
              this.toaster.open({
                text: 'Barcode Combination updated successfully',
                type: 'success',
                position: 'top-right',
                duration: 10000
              });
            }

            //reset form
            Object.keys(this.addBarcodeCombinationForm.controls).forEach((key) => {
              const control = this.addBarcodeCombinationForm.controls[key];
              control.patchValue('');
              control.markAsPristine();
              control.markAsUntouched();
            });

            this.params.pageNumber = 1;
            this.resetBarcodeCombinationDetail();
            this.refreshData();
          }
          else {

            if (response.data.isAssociated && !isConfirm) {
              this.getAssociateConfirmationBox(response.data.errorMessage);
            }
            else {
              this.toaster.open({
                text: response.data.errorMessage,
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
            }
          }
        }
      }, err => {
        this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        if (Array.isArray(errors)) {
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        }
      });
    }
  }

  tabChange(event) {
    this.selectedIndex = event.index;
  }

  getBarcodeCombinationLookup() {
    this.ingredientService.BarcodeCombinationLookup(BarCodeIngredientLookup.CapsuleName).subscribe(response => {
      if (response && response['data']) {
        this.capsuleNamelookup = response['data'];
        this.capsuleNameList = this.addBarcodeCombinationForm.controls['capsuleName'].valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
      else {
        this.capsuleNamelookup = [];
        this.capsuleNameList = this.addBarcodeCombinationForm.controls['capsuleName'].valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    });
  }

  resetBarcodeCombinationDetail() {
    this.barcodeId = '';
    this.barcodeIsActive = true;
    this.addBarcodeCombinationForm.controls['barcode'].patchValue('');
    this.addBarcodeCombinationForm.controls['capsuleName'].patchValue('');
    this.addBarcodeCombinationForm.controls['grammage'].patchValue('');
    this.addBarcodeCombinationForm.controls['status'].patchValue(true);
    this.showForm = false;
    setTimeout(() => {
      this.showForm = true;
      //reset form
      Object.keys(this.addBarcodeCombinationForm.controls).forEach((key) => {
        const control = this.addBarcodeCombinationForm.controls[key];
        if (key == 'status') {
          control.patchValue(true);
        }
        else {
          control.patchValue('');
        }

        control.markAsPristine();
        control.markAsUntouched();
      });
    }, 2);
  }

  editBarcodeCombination(element) {
    this.addBarcodeCombinationForm.controls['barcode'].patchValue(element.barcode);
    this.addBarcodeCombinationForm.controls['capsuleName'].patchValue(element.capsuleName);
    this.addBarcodeCombinationForm.controls['grammage'].patchValue(element.grammage);
    this.addBarcodeCombinationForm.controls['status'].patchValue(element.isActive);
    this.barcodeId = element.barcodeId;
    this.barcodeIsActive = element.isActive;
  }

  private _filter(value: string): string[] {
    const filterValue = value == null ? null : value.toLowerCase();
    return this.capsuleNamelookup.filter(option => option.value.toLowerCase().includes(filterValue));
  }

  cancelAddUpdate() {
    this.getConfirmationBox('', 3);
  }
  onConfirm() {
    this.resetBarcodeCombinationDetail();
    this.dialogRef.close();
    this.params.pageNumber = 1;
    this.getBarcodeCombinationList();
  }
  onCancel() {
    this.dialogRef.close();
  }
  getConfirmationBox(type: string, tabindex: number) {
    let msg = 'All unsaved changes will be lost. Are you sure you want to cancel?';
    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: msg,
      deviceName: '',
      status: '',
      title: 'Confirmation',
      type: type,
      tabindex: tabindex
    }
    const eventPopupModel = new MatDialogConfig();
    eventPopupModel.height = 'auto';
    eventPopupModel.width = '670px';
    eventPopupModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, eventPopupModel);
  }

  onAssociateConfirm() {
    this.saveBarcodeCombination(true);
    this.associateDialogRef.close();
  }
  onAssociateCancel() {
    this.associateDialogRef.close();
  }
  getAssociateConfirmationBox(message: string) {
    this.associateDialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: message,
      deviceName: '',
      status: '',
      title: 'Confirmation',
      type: '',
      tabindex: 3
    }
    const associateEventPopupModel = new MatDialogConfig();
    associateEventPopupModel.height = 'auto';
    associateEventPopupModel.width = '670px';
    associateEventPopupModel.disableClose = true;
    this.associateDialogRef = this.associateDialog.open(this.associateConfirmDialogTemplate, associateEventPopupModel);
  }

  onGrammageFocusOut() {
    if ($('#TextBox-Grammage').val() != '') {
      $('#TextBox-Grammage').val(parseFloat($('#TextBox-Grammage').val()).toFixed(1));

      let grammageValue = $('#TextBox-Grammage').val();
      if (grammageValue.includes(".0")) {
        $('#TextBox-Grammage').val(grammageValue.replace(".0", ""));
      }
    }
  }
}
