import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { DrinkSetupStrings } from 'src/app/enums/drinkSetupStrings.enum';
import { PriceCardStateEnum } from 'src/app/enums/priceCardState.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { RoleService } from 'src/app/services/role/role.service';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface UserData {
  entityName: string;
  levelType: string;
  startDateDisplay: Date | null;
  action: string;
}
@Component({
  selector: 'app-price-card-entity-mapping',
  templateUrl: './price-card-entity-mapping.component.html',
  styleUrls: ['./price-card-entity-mapping.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class PriceCardEntityMappingComponent implements OnInit {
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  @Output() clickButton = new EventEmitter<any>();
  displayedColumns: string[] = ['entityName', 'levelType', 'startDateDisplay', 'action'];
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  dialogRef: MatDialogRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: ''
  }
  markets = [];
  partners = [];
  sites = [];
  entityMappingList: MatTableDataSource<UserData>;
  entityMappingListData:any=[];
  selectedPartners = [];
  selectedSites = [];
  drinkSetupName: string = "";

  start_time: any = new Date();
  start_date: any = moment();
  minDate: any = new Date();
  dropdownSettings = {
    site: {
      singleSelection: false,
      idField: 'value',
      textField: 'partnerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: true,
    },
    partner: {
      singleSelection: false,
      idField: 'masterDataId',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: true,
    },
    market: {
      singleSelection: true,
      idField: 'entityId',
      textField: 'entityName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  };
  isdisplay: boolean = false;
  originalEntityMappingList :any;

  priceCardDetails :any={
    priceCardId:'',
    priceCardVersionId:'',
    priceCardNumber:0,
    priceCardName:'',
    priceCardTemplateName:'',
    versionStartDateTime:'',
    hasTargetVersion:false,
    targetStartDate:'',
    priceCardState:PriceCardStateEnum['Target'],
    marketId:'',
    isEditable:false
  }
  params = {
    sortBy: 'entityName asc'
  };
  isPageDataLoad = false;
    
  priceCardStateEnum = PriceCardStateEnum;
  filter = { search : '' };
  advanceFilter = {
    entityID: false,
    entityName: false,
    billTo: false,
    sellTo: false,
    reference: false,
    machineSerialNumber: false
  }
  displayAdvancedSearch = false;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public priceCardService: PriceCardService,
    private entityService: EntityService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private router: Router,
    public roleService: RoleService,
    private azureService: AzureService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.priceCardId) {
        this.priceCardDetails.priceCardId = params.priceCardId;
      }
    });
  }

  ngOnInit(): void {
    this.SetFeatureFlagsConfigurations();

    if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin) {
      this.isdisplay = true;
    } else {
      this.isdisplay = false;
    }

    this.start_time.setHours(this.start_time.getHours() + 1);
    this.getPriceCardDetails(true);
  }

  resetDefault() {
    this.sites = [];
    this.selectedSites = [];
    this.selectedPartners = [];
    this.start_time = new Date();
    this.start_date = moment();
    this.start_time.setHours(this.start_time.getHours() + 1);
  }
  sortData(e) {    
  }
  
  getSiteLookup(type = '') {
    this.selectedSites = [];
    this.spinner.show();
    if (type == 'selectAll') {
      this.selectedPartners = this.partners;
    } else if (type == 'deSelectAll') {
      this.selectedPartners = [];
    }
    if (!this.selectedPartners.length) {
      this.sites = [];
      this.spinner.hide();
    } else {
      let partners = [];
      this.selectedPartners.forEach(element => {
        partners.push(element.masterDataId);
      });

      this.priceCardService.getSitesByPartners({
        "partnerIds": partners
      }).subscribe(response => {
        if (!response) {
          this.sites = [];
        } else {
          this.sites = response['data'].filter(el => el.isActive === true);
        }
        this.spinner.hide();
      });
    }
  }
  getPartnerLookup() {
    this.partners = [];
    this.sites = [];
    this.selectedSites = [];
    this.selectedPartners = [];
    this.spinner.show();    
      this.entityService.getPartnerLookup(this.priceCardDetails.marketId).subscribe(response => {
        if (response) {
          this.partners = response['data'].filter(el => el.isActive === true);
        }
        this.spinner.hide();
      });
    
  }
  getPriceCardDetails(isLoad=false) {
    this.displayAdvancedSearch = false;
    this.spinner.show();
    var obj = {};
    if (this.priceCardDetails.priceCardId != '') { obj['priceCardId'] = this.priceCardDetails.priceCardId; };
    if (this.filter.search != '') { obj['search'] = this.filter.search; };
    var advanceSearchColumns = [];
    if (this.advanceFilter.entityID) {advanceSearchColumns.push("EntityID")};
    if (this.advanceFilter.entityName) {advanceSearchColumns.push("EntityName")};
    if (this.advanceFilter.billTo) {advanceSearchColumns.push("BillTo")};
    if (this.advanceFilter.sellTo) {advanceSearchColumns.push("SellTo")};
    if (this.advanceFilter.reference) {advanceSearchColumns.push("Reference")};
    if (this.advanceFilter.machineSerialNumber) {advanceSearchColumns.push("MachineSerialNumber")};    
    if (advanceSearchColumns.length > 0) { obj['advanceSearch'] = advanceSearchColumns; }
    
    this.priceCardService.getPricecardEntity(obj).subscribe(response => {
      if (response) {
        if(Array.isArray(response['data']['entityList'])){
          this.entityMappingListData = response['data']['entityList'];
          let count=1;
          this.entityMappingListData.forEach(element => {
            element['startDateDisplay'] = moment(element['startDate']).format('YYYY-MM-DD-MMTHH:mm:ss.s');
            if(count ==  this.entityMappingListData.length){
              this.setdataWithSort();
              this.originalEntityMappingList = JSON.stringify(this.entityMappingListData);
            }            
            count++;
          });           
        }
        else{
          this.originalEntityMappingList = JSON.stringify([]);
          this.entityMappingListData=[];
          this.setdataWithSort();
        }        
        this.priceCardDetails.priceCardNumber = response.data.priceCardNumber;
        this.priceCardDetails.priceCardName = response.data.priceCardName;
        this.priceCardDetails.priceCardTemplateName = response.data.drinkGroupTemplateName;
        this.priceCardDetails.hasTargetVersion = response.data.hasTargetVersion;
        this.priceCardDetails.priceCardState = response.data.priceCardState;
        this.priceCardDetails.targetStartDate = response.data.targetStartDate;
        this.priceCardDetails.versionStartDateTime = (response.data.priceCardState == this.priceCardStateEnum['Target'])?response.data.targetStartDate:response.data.startDate;   
        if (this.priceCardDetails.versionStartDateTime.charAt(this.priceCardDetails.versionStartDateTime.length - 1).toLowerCase() != 'z') {
          this.priceCardDetails.versionStartDateTime = this.priceCardDetails.versionStartDateTime + 'Z';
        }
        if (this.priceCardDetails.targetStartDate && this.priceCardDetails.targetStartDate.charAt(this.priceCardDetails.targetStartDate.length - 1).toLowerCase() != 'z') {
          this.priceCardDetails.targetStartDate = this.priceCardDetails.targetStartDate + 'Z';
        }  
        this.priceCardDetails['versionStartDateTime'] = moment(moment(this.priceCardDetails['versionStartDateTime']).format('YYYY-MM-DD HH:mm:ss')); 

        this.priceCardDetails.marketId=response.data.marketId;
        this.priceCardDetails.isEditable=response.data.isEditable;
        this.priceCardDetails.priceCardVersionId = response.data.priceCardVersionId
        
        if ((this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin) && !response.data.isEditable) {
          this.isdisplay = false;
        }
        if(this.isdisplay && isLoad){
          this.getPartnerLookup();
        }else{
          this.spinner.hide();
        }
        
        
      }else{
        this.originalEntityMappingList = JSON.stringify([]);
      }
      
      this.isPageDataLoad = true;
    });
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  getDisplayDateFormat(date, type, isTemp, i) {
    if (!isTemp && (date.charAt(date.length - 1)).toLowerCase() != 'z') {
      date = date + 'Z';
    }
    if (type == 'date') {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment(date).format('HH:mm')
    }
  }

  addEntity() {
    let current_time=  moment();
    let datetime = moment();
    datetime = moment(datetime).set({'year': moment(this.start_date).year(),'month': moment(this.start_date).month(),'date': moment(this.start_date).date()});
    datetime = moment(datetime).set({'hours': moment(this.start_time).hours(),'minutes': moment(this.start_time).minutes(),'seconds':0});
    let diffInSeconds = datetime.diff(this.priceCardDetails['versionStartDateTime'], 'seconds');
    if (current_time > datetime || diffInSeconds < 0) {
      this.toaster.open({
        text: "Entity Activation Start Date & Time must be in the future and greater than Start Date & Time of Price card.",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } else {
      if (!this.selectedSites.length) {
        this.addEntityInGrid();
      } else {
        let sIndex = 1;
        this.selectedSites.forEach(selectedSite => {
          let siteDetails = this.sites.filter(el => el.value === selectedSite.value);
          selectedSite.masterDataId = siteDetails[0]['masterDataId'];
          selectedSite.text = siteDetails[0]['text'];
          if (sIndex == this.selectedSites.length) {

            this.addEntityInGrid();
          }
          sIndex++;
        });
      }
    }
  }
  addEntityInGrid() {
    let datetime = moment();
    datetime = moment(datetime).set('year', moment(this.start_date).year());
    datetime = moment(datetime).set('month', moment(this.start_date).month());
    datetime = moment(datetime).set('date', moment(this.start_date).date());
    datetime = moment(datetime).set('hours', moment(this.start_time).hours());
    datetime = moment(datetime).set('minutes', moment(this.start_time).minutes());
    let tempList = [];
    let isValid = true;
    let partnerIndex = 0;
    this.selectedPartners.forEach(partner => {
      let siteIndex = 0;
      let hasSite = this.selectedSites.some(el => el.masterDataId === partner.masterDataId);
      if (hasSite) {
        let hasSites = this.selectedSites.filter(el => el.masterDataId === partner.masterDataId);
        hasSites.forEach(hSite => {
          let siteDetails = this.sites.filter(el => el.value === hSite.value);
          let obj = {
            "priceCardEntityId": "",
            "entityId": hSite.value,
            "entityName": hSite.text,
            "partnerEntityId": hSite.masterDataId,
            "levelNumber": 7,
            "levelType": "Site",
            "marketEntityId": this.priceCardDetails.marketId,
            "startDate": datetime,
            "startDateDisplay": moment(datetime).utc().format('YYYY-MM-DD-MMTHH:mm:ss.s'),
            "isTemp": true,
            "isEntityActive":siteDetails[0]['isActive']            
          };
          let hasRecord = this.entityMappingListData.some(el => el.entityId === hSite.value && el.partnerEntityId === hSite.masterDataId);
          if (!hasRecord) {
            tempList.push(obj);
          } else {
            isValid = false;
          }
          if (partnerIndex === (this.selectedPartners.length - 1)) {
            if (siteIndex === (hasSites.length - 1)) {
              if (!isValid) {
                this.toaster.open({
                  text: 'Selected combination is already exists',
                  type: 'danger',
                  position: 'top-right',
                  duration: 10000
                });
              } else {
                this.entityMappingListData = this.entityMappingListData.concat(tempList);
                this.setdataWithSort();
                this.resetDefault();

              }
            }
          }
          siteIndex++;
        });
      } else {
        let partnerDetails = this.partners.filter(el => el.masterDataId === partner.masterDataId);
        let obj = {
          "priceCardEntityId": "",
          "entityId": partner.masterDataId,
          "entityName": partner['text'],
          "partnerEntityId": partner.masterDataId,
          "levelNumber": 4,
          "levelType": "Customer",
          "marketEntityId": this.priceCardDetails.marketId,
          "startDate": datetime,
          "startDateDisplay": moment(datetime).utc().format('YYYY-MM-DD-MMTHH:mm:ss.s'),
          "isTemp": true,
          "isEntityActive":partnerDetails[0]['isActive'] 
        };
        let hasRecord = this.entityMappingListData.some(el => el.entityId === partner.masterDataId && el.partnerEntityId === partner.masterDataId);
        if (!hasRecord) {
          tempList.push(obj);
        } else {
          isValid = false;
        }
        if (partnerIndex === (this.selectedPartners.length - 1)) {
          if (!isValid) {
            this.toaster.open({
              text: 'Selected combination is already exists',
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          } else {
            this.entityMappingListData = this.entityMappingListData.concat(tempList);
            this.setdataWithSort();
            this.resetDefault();
          }
        }
      }
      partnerIndex++;
    })
  }
  removeEntity(e) {
    let list = this.entityMappingListData.filter(el => el.entityId != e.entityId);
    this.spinner.show();
    this.entityMappingListData = [];
    setTimeout(() => {
      this.entityMappingListData = list;
      this.setdataWithSort();
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    }, 50);
  }
  setdataWithSort(){
    this.entityMappingList = new MatTableDataSource(this.entityMappingListData);
    this.entityMappingList.sort = this.sort;
  }
  submitForm(type) {
    if (type == 'cancel') {
      this.router.navigate(['/price-card']);
    } else {      
      if(this.originalEntityMappingList == JSON.stringify(this.entityMappingListData)){
        this.router.navigate(['/price-card']);    
      }else{
      this.spinner.show();
      let body = {
        "priceCardId": this.priceCardDetails.priceCardId,
        "priceCardVersionId": this.priceCardDetails.priceCardVersionId,
        "requestPriceCardEntities":this.entityMappingListData
      }
      this.priceCardService.addPriceCardentity(body).subscribe(response => {
        this.spinner.hide();
        this.toaster.open({
          text: "Entity Allocation updated successfully",
          type: 'success',
          position: 'top-right',
          duration: 10000
        }); 
        this.router.navigate(['/price-card']);
      }, err => {
          if (err && err.error) {
            this.spinner.hide();
            let errors = err.error; 
            if(Array.isArray(errors)){              
              errors.forEach(element => {
                this.roleService.showToasterMsg(element.msg,'danger');                
              });        
            }else{
              this.roleService.showToasterMsg('Oops! Something went wrong.','danger');
              this.router.navigate(['/price-card']);
            }
          }
      });    
    }
    }
  }
  openDialog(type: string = '', element = {}, slot_i = -1): void {
    if (!this.isdisplay) {
      this.router.navigate(['/price-card']);
    } else {
      if (type == 'form_cancel') {
        this.dialogTexts = {
          successTitle: 'Yes',
          cancelTitle: 'No',
          message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
          deviceName: '',
          status: '',
          title: 'Confirmation',
          type: type
        }
      }
      const entityMoveDataModel = new MatDialogConfig();
      entityMoveDataModel.height = 'auto';
      entityMoveDataModel.width = '670px';
      entityMoveDataModel.disableClose = true;
      this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
    }
  }
  onConfirm(type: string = 'form_cancel') {
    this.dialogRef.close();
    if (type == 'form_cancel') {
      this.router.navigate(['/price-card']);
    }
  }
  onCancel() {
    this.dialogRef.close();
  }

  clearSearch() {
    this.filter.search = '';
    this.advanceFilter = {
      entityID: false,
      entityName: false,
      billTo: false,
      sellTo: false,
      reference: false,
      machineSerialNumber: false
    }
    this.getPriceCardDetails();
  }

  advanceToggle () {
    this.displayAdvancedSearch = !this.displayAdvancedSearch;  
  }

  applyAdvanceSearch() {
    this.displayAdvancedSearch = false;
    if (this.filter.search != '') {
      this.getPriceCardDetails()
    }
  }

  private SetFeatureFlagsConfigurations() {
    this.azureService.isEnableDrinkSetupFT()
     .then(isDrinkSetup => 
        this.drinkSetupName = isDrinkSetup ? DrinkSetupStrings.DrinkSetup : DrinkSetupStrings.PriceCardTemplate
      ).catch(() =>
        this.drinkSetupName = DrinkSetupStrings.PriceCardTemplate
    );
 }
}










