export enum AlarmListFilterTypesEnum {
    AlarmName = 1,
    MachineSerialNumber = 2,
    MachineType = 3,
    MachineModelName = 4,
    SiteName = 5,
    ServiceAgent = 6 ,
    CustomerName = 7,
    CountryCode = 8  
}
