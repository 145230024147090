export enum RemoteUpdateEntityDeployLevel {
    Market = "Market",
    Level2 = "Level 2",
    Level3 = "Level 3",
    Level35 = "Level 3.5",
    Customer = "Customer",
    Level5 = "Level 5",
    Level6 = "Level 6",
    Site = "Site",
    Machine = "Machine"
  }
