import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AzureService } from '../azure/azure.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from '../role/role.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements CanActivate {
  public cookieName = environment.cookieName;
  public userInfo: any = {
    is_admin: false,
  };
  public currentRoute: string = '';
  public loginError: any = '';
  public pageTitle: string = '';
  public hasCalledSignoutLog = false;
  public tabInfo = 0;
  public currentUserToken = '';
  load(): Promise<any> {
    this.userInfo.is_admin = false;
    if (this.isCheckLogin()) {
      var configHeader = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
        },
      };
      return this.httpClient
        .get(environment.baseUrl + 'user/user-info', configHeader)
        .toPromise()
        .then((response: any) => {
          if (response.status == 200) {
            this.userInfo = response.data;
            if (
              response.data.CompanyUser &&
              response.data.CompanyUser.Role &&
              response.data?.CompanyUser?.Role?.role_name !== undefined &&
              response.data?.CompanyUser?.Role?.role_name !== null &&
              (response.data?.CompanyUser?.Role?.role_name).toLowerCase() == 'admin'
            ) {
              this.userInfo.is_admin = true;
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private roleService: RoleService,
    private msalService: MsalService,
    private azureService: AzureService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    this.currentRoute = next.routeConfig.path;
    this.getHeaderTitle();
    let adminRoutes = ['devices/add', 'devices/edit/:device_id', 'users/add', 'users/edit/:user_id'];
    if (next.routeConfig.path != 'login' && next.routeConfig.path != 'forgot-password' && next.routeConfig.path != 'reset-password/:code') {
      if (this.isCheckLogin()) {
        if (adminRoutes.indexOf(next.routeConfig.path) >= 0) {
          if (this.userInfo.is_admin) {
            return true;
          } else {
            this.router.navigate(['/dashboard']);
            return false;
          }
        } else if (next.routeConfig.path == '**') {
          return true;
        } else {
          if (
            next.routeConfig.path != 'dashboard' &&
            next.routeConfig.path != 'entity' &&
            next.routeConfig.path != 'machine' &&
            next.routeConfig.path != 'price-card' &&
            next.routeConfig.path != 'task-management-overview'
          ) {
            var moduleId = next.data['moduleIds'];
            var screenId = next.data['screenIds'];
            var permissionId = next.data['permissionIds'];
            return new Promise((resolve, reject) => {
              this.roleService.checkUserPermissions(moduleId, screenId, permissionId).then((response) => {
                if (response) {
                  resolve(true);
                } else {
                  resolve(false);
                  this.router.navigate(['/unauthorized']);
                }
              });
            });
          } else {
            if (
              this.cookieService.get(this.cookieName + 'userId') != '' &&
              this.cookieService.get(this.cookieName + 'userId') != undefined &&
              this.cookieService.get(this.cookieName + 'userId') != null
            ) {
              if (this.roleService.roleKey == '') {
                return new Promise((resolve, reject) => {
                  this.roleService.getUserAccess().then((response) => {
                    if (response) {
                      resolve(true);
                    } else {
                      resolve(false);
                      this.router.navigate(['/unauthorized']);
                    }
                  });
                });
              } else {
                return Promise.resolve(true);
              }
            } else {
              this.removeAllCookies();
              this.router.navigate(['/']);
              return false;
            }
          }
        }
      } else {
        this.removeAllCookies();
        this.router.navigate(['/']);
        return false;
      }
    } else if (next.routeConfig.path == 'reset-password/:code') {
      return true;
    } else {
      if (!this.isCheckLogin()) {
        return true;
      } else {
        this.router.navigate(['/dashboard']);
        return false;
      }
    }
  }

  isCheckLogin() {
    if (this.cookieService.get(this.cookieName + 'access_token') !== '') {
      return true;
    } else {
      return false;
    }
  }

  removeAllCookies() {
    this.cookieService.delete(this.cookieName + 'userId', '/', environment.domainName);
    this.cookieService.delete(this.cookieName + 'access_token', '/', environment.domainName);
    this.cookieService.delete(this.cookieName + 'refresh_token', '/', environment.domainName);
    this.cookieService.delete(this.cookieName + 'b2baccess_token', '/', environment.domainName);
    this.cookieService.delete(this.cookieName + 'root_entity', '/', environment.domainName);
    localStorage.clear();
  }

  getHeaderTitle() {
    if (this.currentRoute == 'configuration/machinetypes' || this.currentRoute.indexOf('configuration/machinetype') != -1) {
      this.pageTitle = 'MACHINE TYPE MASTER';
    } else if (this.currentRoute == 'configuration/tickerTapMessages' || this.currentRoute.indexOf('configuration/tickerTapMessage') != -1) {
      this.pageTitle = 'MESSAGE SCHEDULER MASTER';
    } else if (this.currentRoute == 'machine' || this.currentRoute.indexOf('machine') != -1) {
      this.pageTitle = 'MACHINE MANAGEMENT';
    } else if (this.currentRoute == 'configuration') {
      this.pageTitle = 'CONFIGURATIONS';
    } else if (this.currentRoute == 'configuration/ingredients') {
      this.pageTitle = 'INGREDIENTS MASTER';
    } else if (this.currentRoute == 'price-card-template' || this.currentRoute.indexOf('price-card-template') != -1) {
      this.pageTitle = 'PRICE CARD TEMPLATE MANAGEMENT';
    } else if (this.currentRoute.indexOf('drinks-setup') == 0) {
      this.pageTitle = 'DRINKS SETUP MANAGEMENT';
    } else if (this.currentRoute == 'price-card' || this.currentRoute.indexOf('price-card') != -1) {
      this.pageTitle = 'PRICE CARD MANAGEMENT';
    } else if (this.currentRoute == 'sales' || this.currentRoute.indexOf('sales') != -1) {
      this.pageTitle = 'SALES INFORMATION';
    } else if (this.currentRoute == 'dashboard' || this.currentRoute == 'entity' || this.currentRoute.indexOf('entity') != -1) {
      this.pageTitle = 'ORGANISATIONAL STRUCTURE';
    } else if (this.currentRoute == 'user-audit' || this.currentRoute.indexOf('user-audit') != -1) {
      this.pageTitle = 'USER AUDIT';
    } else if (this.currentRoute == 'user-verification-review') {
      this.pageTitle = 'User Verification Review';
    } else if (this.currentRoute == 'user' || this.currentRoute.indexOf('user') != -1) {
      this.pageTitle = 'USER MANAGEMENT';
    } else if (this.currentRoute == 'notifications') {
      this.pageTitle = 'NOTIFICATIONS';
    } else if (this.currentRoute == 'audit-logs') {
      this.pageTitle = 'AUDIT LOGS';
    } else if (this.currentRoute == 'alarms' || this.currentRoute.indexOf('alarms') != -1) {
      this.pageTitle = 'Alarms';
    } else if (this.currentRoute == 'alarm-template' || this.currentRoute.indexOf('alarm-template') != -1) {
      this.pageTitle = 'Alarm Template MANAGEMENT';
    } else if (this.currentRoute == 'configuration/cupTypeDefinition' || this.currentRoute.indexOf('configuration/cupTypeDefinition') != -1) {
      this.pageTitle = 'CUP TYPE DEFINITION';
    } else if (this.currentRoute == 'unauthorized') {
      this.pageTitle = 'UNAUTHORIZED';
    }
  }

  logOut(callLogApi = true) {
    if (callLogApi) {
      this.signout().subscribe(
        (response) => {
          this.msalService.logoutRedirect();
          this.removeAllCookies();
          localStorage.clear();
        },
        (err) => {
          this.msalService.logoutRedirect();
          this.removeAllCookies();
          localStorage.clear();
        },
      );
    } else {
      this.msalService.logoutRedirect();
      this.removeAllCookies();
      localStorage.clear();
    }
  }

  async b2blogin(loginData) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let baseUrl = '/api/v1/account/b2blogin';

    let shouldUseUserAuthV2 = await this.azureService.isUseUserAuthV2FT();

    if (shouldUseUserAuthV2)
      baseUrl = '/api/v2/account/b2blogin';

    return this.httpClient.post<any>(environment.baseUrl + baseUrl, loginData, configHeader).pipe(
      map((response) => {
        this.cookieService.set(this.cookieName + 'b2baccess_token', response.data.b2baccess_token, 1, '/', environment.domainName, false, 'Lax');
        this.cookieService.set(this.cookieName + 'access_token', response.data.access_token, 1, '/', environment.domainName, false, 'Lax');
        this.cookieService.set(this.cookieName + 'refresh_token', response.data.refresh_token, 1, '/', environment.domainName, false, 'Lax');
        this.cookieService.set(this.cookieName + 'root_entity', response.data.userDetail.entityGuid, 1, '/', environment.domainName, false, 'Lax');
        this.cookieService.set(this.cookieName + 'userId', response.data.userDetail.id, 1, '/', environment.domainName, false, 'Lax');
        this.cookieService.set(this.cookieName + 'fromLandingPage', '1', 1, '/', environment.domainName, false, 'Lax');
        return response;
      }),
    );
  }

  async refreshToken(b2bid_token) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };

    let body = {
      access_token: this.cookieService.get(this.cookieName + 'access_token'),
      id_token: b2bid_token,
    };

    let baseUrl = '/api/v1/account/refreshtoken';

    let shouldUseUserAuthV2 = await this.azureService.isUseUserAuthV2FT();

    if (shouldUseUserAuthV2)
      baseUrl = '/api/v2/account/refreshtoken';

    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/account/refreshtoken', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  signout() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.currentUserToken,
      },
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/account/signout/', configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }
}
