import { prop, required } from "@rxweb/reactive-form-validators";

export class entityConfigurationModel{
    @prop()
    waffleCupsShort:string = "";
    @prop()
    waffleCupsSmall:string = "";
    @prop()
    waffleCupsMedium:string = "";
    @prop()
    waffleCupsLarge:string = "";
    @prop()
    plasticCupsMedium : string = "";
    @prop()
    plasticCupsLarge: string = "";
    @prop()
    crockeryCupsShort: string = "";
    @prop()
    crockeryCupsSmall: string = "";
    @prop()
    crockeryCupsMedium: string = "";
    @prop()
    crockeryCupsLarge: string = "";
    @prop()
    glassCupsShort: string = "";
    @prop()
    glassCupsSmall: string = "";
    @prop()
    glassCupsMedium: string = "";
    @prop()
    glassCupsLarge: string = "";
}

export class entityIngredientsModel{
    @prop()
    @required()
    coffeeType:any[] = [];
    @prop()
    milkType:any[] = [];
    @prop()
    syrup:any[] = [];
}

export class entityLowAdminModel{
    @prop()
    @required()
    machineModelType:any[] = [];
    @prop()
    @required()
    lowAdminEnabled:boolean = false;
}