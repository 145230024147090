import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { GetPackageList } from '../../dtos/get-package-list.model';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class RemoteUpdatePackageGetListService {

	constructor(
		private cookieService: CookieService,
		private httpClient: HttpClient
	) { }

	cookieName: string = environment.cookieName
	
	getRemoteUpdatePackageList(page: number, limit: number, queryParams: Map<string, string>){
		let params = new HttpParams();
		params = page !== null ? params.append("page", page.toString()) : params;
		params = limit !== null ? params.append("limit", limit.toString()) : params;

		if (queryParams != null) {
			for(let query of queryParams) {
				params = query[1] !== null ? params.set(query[0], query[1]) : params;
			}
		}

		const requestOptions = {
			params,
			headers: {
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
			}
		};

		return this.httpClient
			.get<GetPackageList>(environment.apiUrl.remoteUpdatePackage + "/v1/remoteupdatepackage/getAll", requestOptions)
			.pipe(map(response => {
				return response;
			}));
	}
}
