import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { GetRemoteUpdateTaskResponse } from '../../dtos/get-remote-update-tasks.response.model';

@Injectable({
  providedIn: 'root'
})
export class GetTaskService {

  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) { }

	cookieName: string = environment.cookieName

  getRemoteUpdateTaskList(taskId: number): Observable<GetRemoteUpdateTaskResponse>
  {
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
				'auth_custom': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
    
    return this.httpClient.get<GetRemoteUpdateTaskResponse>(environment.apiUrl.remoteUpdate + "/v1/remoteupdatetask/" + taskId, configHeader);
	}
}
