import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfigurationConstant } from 'src/app/constant/configuration.constant';
import { CapsuleIngredientLookup } from 'src/app/enums/capsuleIngredientLookup.enum';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { CapsuleIngredientService } from 'src/app/services/capsule-ingredient/capsule-ingredient.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { RoleService } from 'src/app/services/role/role.service';
declare var $: any;
@Component({
  selector: 'app-capsules-ingredients',
  templateUrl: './capsules-ingredients.component.html',
  styleUrls: ['./capsules-ingredients.component.css']
})
export class CapsulesIngredientsComponent implements OnInit {

  recordsTotal = 0;
  isPageDataLoad = false;
  capsuleIngredients: any = [];
  barCodeList: any = [];
  displayedColumns: string[] = ['barcodeString', 'machineType', 'buttonPress', 'serviceDelivery', 'productName', 'drinkProduct', 'blendType', 'cupVolume', 'actions']; //GD2-3643
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  drawerfilter: boolean = false;
  capsulesForm: FormGroup;
  addCapsuleDetailForm: FormGroup;
  isFilterApply: boolean = false;
  buttonPressFilterlookup: any = [];
  drinkNameFilterLookup: any = [];
  machineTypes: any = [];
  selectedIndex: number = 0;  
  params = {
    search: '',
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    sortBy: '',
    sortOrder: ''
  }
  tempfilter = {//[GD2-3706]
    barcodeCombination: '',
    machineType: '',
    buttonPress: '',
    serviceDelivery: '',
    drinkProduct: ''
  }
  showForm: boolean = true;
  serviceDeliveryLookup:any = [];
  barcodeCombinationFilterList: any = [];
  machineTypeFilterList: any = [];
  barCodeCombinations: any = [];
  barCodeCombinationList: Observable<string[]>;
  productNames: any = [];
  productNameList: Observable<string[]>;
  drinkProducts: any = [];
  drinkProductList: Observable<string[]>;
  blendTypes: any = [];
  blendTypeList: Observable<string[]>;
  cupVolumeMaster: any = []; // initially load all cup volumes and filter based on service delivery
  cupVolumeList: any = [];
  
  capsuleIngredientLookup = CapsuleIngredientLookup;
  capsulesMasterIngredientsId = '';
  deleteCapsuleId = '';
  dialogRef: MatDialogRef<any>;
  @ViewChild("confirmDeleteDialogTemplate") confirmDeleteDialogTemplate: TemplateRef<any>;
  @ViewChild("confirmCancelDialogTemplate") confirmCancelDialogTemplate: TemplateRef<any>;

  constructor(
    private ingredientService: CapsuleIngredientService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public roleService: RoleService,
    private toaster: Toaster,
    private entityService: EntityService,
    private activatedRoute: ActivatedRoute,
    private configurationConstant: ConfigurationConstant,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (!this.roleService.objRole.isSystemAdmin) {
      this.router.navigate(['/unauthorized']);
    }

    //GD2-3704
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.tabIndex) {
        this.selectedIndex = queryParams.tabIndex;
      }
    });

    this.createCapsuleForm();
    if (this.selectedIndex == 0) {
      this.getHomeScreenDetails();
    }
  }

  getCapsulesIngredientList(type = undefined) {
    this.spinner.show();
    let obj = {};
    this.isFilterApply = false;
    if (type == 'apply') {
      this.drawerfilter = false;
      $(".sidebar-mini").removeClass("filter-open");
      this.roleService.resetPopupScroll();
    }
    if (this.params.search != '') { obj['Search'] = this.params.search; };
    if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) { obj['SortBy'] = this.params.sortBy };
    if (this.params.sortOrder != undefined) { obj['SortOrder'] = this.params.sortOrder };
    if (this.capsulesForm.controls['barcodeCombination'].value != undefined && this.capsulesForm.controls['barcodeCombination'].value != '') { obj['barCodeId'] = this.capsulesForm.controls['barcodeCombination'].value; this.isFilterApply = true };
    if (this.capsulesForm.controls['machineType'].value != undefined && this.capsulesForm.controls['machineType'].value != '') { obj['machineTypeId'] = this.capsulesForm.controls['machineType'].value; this.isFilterApply = true };
    if (this.capsulesForm.controls['buttonPress'].value != undefined && this.capsulesForm.controls['buttonPress'].value != '') { obj['buttonPress'] = this.capsulesForm.controls['buttonPress'].value; this.isFilterApply = true };
    if (this.capsulesForm.controls['serviceDelivery'].value != undefined && this.capsulesForm.controls['serviceDelivery'].value != '') { obj['serviceDelivery'] = this.capsulesForm.controls['serviceDelivery'].value; this.isFilterApply = true };
    if (this.capsulesForm.controls['drinkProduct'].value != undefined && this.capsulesForm.controls['drinkProduct'].value != '') { obj['drinkMasterId'] = this.capsulesForm.controls['drinkProduct'].value; this.isFilterApply = true };

    this.ingredientService.GetCapsuleIngredients(obj).subscribe(response => {
      this.recordsTotal = response?.params?.count;
      this.spinner.hide();
      this.isPageDataLoad = true;
      this.capsuleIngredients = (response && response['data']) ? response['data'] : [];
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (Array.isArray(errors)) {
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }

  getLookUpAPIs() {
    this.serviceDeliveryLookup = this.configurationConstant.serviceDeliveryOption;
    this.getBarcodeCombinationList();
    this.getMachineTypeList();
    this.getProductNameList();
    this.getDrinksList();
    this.getBlendTypeList();
    this.getCupVolumeList();
  }

  getFilterLookupApis() {
    this.getBarcodeCombinationFilterLookup();
    this.getMachineTypeFilterLookup();
    this.getButtonPressFilterLookup();
    this.getDrinkProductFilterLookup();
  }

  getBarcodeCombinationFilterLookup(){
    this.ingredientService.GetCapsuleIngredientLookups(CapsuleIngredientLookup.BarCodeCombinationFilter).subscribe(response => {
      this.barcodeCombinationFilterList = (response && response['data']) ? response['data'] : [];
    });
  }

  getMachineTypeFilterLookup() {
    this.ingredientService.GetCapsuleIngredientLookups(CapsuleIngredientLookup.MachineTypeFilter).subscribe(response => {
      this.machineTypeFilterList = (response && response['data']) ? response['data'] : [];
    });
  }
  
  getButtonPressFilterLookup() {
    this.ingredientService.GetCapsuleIngredientLookups(CapsuleIngredientLookup.ButtonPressFilter).subscribe(response => {
      this.buttonPressFilterlookup = (response && response['data']) ? response['data'] : [];
    });
  }

  getDrinkProductFilterLookup() {
    this.ingredientService.GetCapsuleIngredientLookups(CapsuleIngredientLookup.DrinkProductFilter).subscribe(response => {
      this.drinkNameFilterLookup = (response && response['data']) ? response['data'] : [];
    });
  }

  getBarcodeCombinationList() {
    this.ingredientService.GetCapsuleIngredientLookups(CapsuleIngredientLookup.BarCodeCombinationList).subscribe(response => {
      this.barCodeCombinations = (response && response['data']) ? response['data'] : [];
      this.barCodeCombinationList = this.addCapsuleDetailForm.controls['barCodeCombination'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, CapsuleIngredientLookup.BarCodeCombinationList))
      );
    });
  }

  getMachineTypeList() {
    let code = this.selectedIndex == 0 ? 'PD' : '';
    this.ingredientService.GetMachineModelByPropositionTypeCode(code).subscribe(response => {
      this.machineTypes = (response && response['data']) ? response['data'] : [];
    });
  }

  getProductNameList() {
    this.ingredientService.GetCapsuleIngredientLookups(CapsuleIngredientLookup.ProductNameList).subscribe(response => {
      this.productNames = (response && response['data']) ? response['data'] : [];
      this.productNameList = this.addCapsuleDetailForm.controls['productName'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, CapsuleIngredientLookup.ProductNameList))
      );
    });
  }

  getDrinksList() {
    var body = {
      "orderBy": "text",
      "configurationType": MasterLookupEnum.Drink
    }
    this.entityService.getmasterConfiguration(body).subscribe(response => {
      this.drinkProducts = (response && response['data']) ? response['data'] : [];
      this.drinkProductList = this.addCapsuleDetailForm.controls['drinkProduct'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, CapsuleIngredientLookup.DrinkProductList))
      );
    });
  }

  getBlendTypeList() {
    var body = {
      "orderBy": "text",
      "configurationType": MasterLookupEnum.Coffee
    }
    this.entityService.getmasterConfiguration(body).subscribe(response => {
      this.blendTypes = (response && response['data']) ? response['data'] : [];
      this.blendTypeList = this.addCapsuleDetailForm.controls['blendType'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, CapsuleIngredientLookup.BlendTypeList))
      );
    });
  }

  getCupVolumeList() {
    this.ingredientService.GetCapsuleIngredientLookups(CapsuleIngredientLookup.CupVolumeList).subscribe(response => {
      this.cupVolumeMaster = (response && response['data']) ? response['data'] : [];
      this.cupVolumeList = [];
    });
  }

  onSearch() {
    this.params.pageNumber = 1;
    this.getCapsulesIngredientList();
  }

  clearSearch() {
    this.params.search = '';
    this.params.pageNumber = 1;
    this.getCapsulesIngredientList();
  }

  sortData(event) {
    event.direction = (event.active == 'isActive' && event.direction != '' ? (event.direction == 'asc' ? 'desc' : 'asc') : event.direction);
    this.params.pageNumber = 1;
    this.params.sortBy = (event.active != '' && event.direction != '' ? event.active : (event.active != '' && event.direction == '') ? event.active : 'cells_name');
    this.params.sortOrder = (event.direction != '' ? event.direction : (event.direction == '') ? 'asc' : 'asc');
    this.getCapsulesIngredientList();
  }

  pageChange(event) {
    this.params.pageNumber = (event.pageIndex > 0 ? (event.pageIndex + 1) : 1);
    this.params.pageSize = (event.pageSize > 0 ? event.pageSize : this.pageSizeOptions[0]);
    this.getCapsulesIngredientList();
  }

  clearFilter() {
    this.capsulesForm.controls['barcodeCombination'].setValue('');
    this.capsulesForm.controls['machineType'].setValue('');
    this.capsulesForm.controls['buttonPress'].setValue('');
    this.capsulesForm.controls['serviceDelivery'].setValue('');
    this.capsulesForm.controls['drinkProduct'].setValue('');
    this.assignFilterToTempFilter();//[GD2-3706]
    this.isFilterApply = false;
    this.params.pageNumber = 1;
    this.getCapsulesIngredientList();
  }

  applyFilter() {//[GD2-3706]
      this.assignFilterToTempFilter();
      this.params.pageNumber = 1; //GD2-4209
      this.getCapsulesIngredientList('apply');
  }

  assignFilterToTempFilter() {//[GD2-3706]
      this.tempfilter['barcodeCombination'] = this.capsulesForm.controls['barcodeCombination'].value;
      this.tempfilter['machineType'] = this.capsulesForm.controls['machineType'].value;
      this.tempfilter['buttonPress'] = this.capsulesForm.controls['buttonPress'].value;
      this.tempfilter['serviceDelivery'] = this.capsulesForm.controls['serviceDelivery'].value;
      this.tempfilter['drinkProduct'] = this.capsulesForm.controls['drinkProduct'].value;
  }

  changefilter() {
    this.roleService.setPopupScroll('section-capsulesIngredientFilter');
    if (!this.drawerfilter) {
      $(".sidebar-mini").addClass("filter-open");
    }
    else {
      //[GD2-3706]
      this.capsulesForm.controls['barcodeCombination'].setValue(this.tempfilter['barcodeCombination'] != undefined ? this.tempfilter['barcodeCombination'] : '');
      this.capsulesForm.controls['machineType'].setValue(this.tempfilter['machineType'] != undefined ? this.tempfilter['machineType'] : '');
      this.capsulesForm.controls['buttonPress'].setValue(this.tempfilter['buttonPress'] != undefined ? this.tempfilter['buttonPress'] : '');
      this.capsulesForm.controls['serviceDelivery'].setValue(this.tempfilter['serviceDelivery'] != undefined ? this.tempfilter['serviceDelivery'] : '');
      this.capsulesForm.controls['drinkProduct'].setValue(this.tempfilter['drinkProduct'] != undefined ? this.tempfilter['drinkProduct'] : '');
      $(".sidebar-mini").removeClass("filter-open");
      this.roleService.resetPopupScroll();
    }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;
    }, this.roleService.scrollSetTimeoutTime);
  }

  displayFn(type): (id: number) => string {
    return (id: number) => {
      if (id) {
        //GD2-3643
        let masterList = type == CapsuleIngredientLookup.BarCodeCombinationList ? this.barCodeCombinations
                         : type == CapsuleIngredientLookup.ProductNameList ? this.productNames
                         : type == CapsuleIngredientLookup.DrinkProductList ? this.drinkProducts
                         : type == CapsuleIngredientLookup.BlendTypeList ? this.blendTypes
                         : [];

        if (masterList.find(obj => obj.id == id || obj.masterDataId == id)) {
          let obj = masterList.find(obj => obj.id == id || obj.masterDataId == id);
          if (Object.keys(obj).length) {
            return (type == CapsuleIngredientLookup.DrinkProductList || type == CapsuleIngredientLookup.BlendTypeList) ? obj.text : obj.value
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }

  saveCapsulesIngredients() {
    this.spinner.show();
    if (this.addCapsuleDetailForm.status === 'INVALID') {
      this.addCapsuleDetailForm.markAllAsTouched();
      this.spinner.hide();
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } 
    else {
      let body: any = {
        "capsulesMasterIngredientsId": this.capsulesMasterIngredientsId,
        "barCodeId": this.addCapsuleDetailForm.controls['barCodeCombination'].value,
        "productNameId": this.addCapsuleDetailForm.controls['productName'].value,
        "cupVolume": this.addCapsuleDetailForm.controls['cupVolume'].value,
        "machineTypeId": this.addCapsuleDetailForm.controls['machineType'].value,
        "buttonPress": this.addCapsuleDetailForm.controls['buttonPress'].value,
        "drinkMasterId": this.addCapsuleDetailForm.controls['drinkProduct'].value,
        "coffeeMasterId": this.addCapsuleDetailForm.controls['blendType'].value,
        "serviceDelivery": this.addCapsuleDetailForm.controls['serviceDelivery'].value
      };

      this.ingredientService.SaveCapsuleIngredient(body).subscribe(response => {
        this.spinner.hide();
        if (response) {
          this.showForm = false;
          setTimeout(() => { this.showForm = true; }, 2);

          let message = (this.capsulesMasterIngredientsId == '') ? 'Capsule added successfully' : 'Capsule updated successfully';
          this.capsulesMasterIngredientsId = '';
          this.toaster.open({
            text: message,
            type: 'success',
            position: 'top-right',
            duration: 10000
          });

          this.resetSaveCapsuleForm();
          this.params.pageNumber = 1;
          this.getFilterLookupApis();
          this.getCapsulesIngredientList();
        }
      }, err => {
        this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        if (Array.isArray(errors)) {
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        }
      });
    }
  }

  tabChange(event) {
    this.selectedIndex = event.index;

    //GD2-3704
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams['tabIndex'] && queryParams['tabIndex'] != this.selectedIndex){
          let queryObj = {};
          queryObj['tabIndex'] = this.selectedIndex;          
          this.router.navigate(
          [], 
          {
              relativeTo: this.activatedRoute,
              queryParams: queryObj, 
              queryParamsHandling: 'merge'
          });
      }      
    });
    if (this.selectedIndex == 0) {
      this.capsulesMasterIngredientsId = '';
      this.deleteCapsuleId = '';
      this.createCapsuleForm();
      this.getHomeScreenDetails();
    }
  }

  private _filter(value: string, type: any): string[] {
    //GD2-3643
    const filterValue = value == null ? null: value.toLowerCase();
    if (type == CapsuleIngredientLookup.BarCodeCombinationList)
      return this.barCodeCombinations.filter(option => option.value.toLowerCase().includes(filterValue));
    else if (type == CapsuleIngredientLookup.ProductNameList)
      return this.productNames.filter(option => option.value.toLowerCase().includes(filterValue));
    else if (type == CapsuleIngredientLookup.DrinkProductList)
      return this.drinkProducts.filter(option => option.text.toLowerCase().includes(filterValue));
    else if (type == CapsuleIngredientLookup.BlendTypeList)
      return this.blendTypes.filter(option => option.text.toLowerCase().includes(filterValue));
    else 
      return;
  }

  createCapsuleForm() {
    this.capsulesForm = new FormGroup({
      'barcodeCombination': new FormControl(''),
      'machineType': new FormControl(''),
      'buttonPress': new FormControl(''),
      'serviceDelivery': new FormControl(''),
      'drinkProduct': new FormControl('')
    });

    this.addCapsuleDetailForm = new FormGroup({
      'barCodeCombination': new FormControl('', [Validators.required, this.requireMatch('barCodeCombination', this)]),
      'machineType': new FormControl('', Validators.required),
      'buttonPress': new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(0), Validators.max(99)]), //numeric value from 0-99
      'serviceDelivery' : new FormControl('', Validators.required),
      'productName' : new FormControl('', [Validators.required, this.requireMatch('productName', this)]),
      'drinkProduct': new FormControl('', [Validators.required, this.requireMatch('drinkProduct', this)]),
      'blendType': new FormControl('', [Validators.required, this.requireMatch('blendType', this)]),
      'cupVolume': new FormControl('', [Validators.required])
    });
  }

  getHomeScreenDetails() {
    this.params = {
      search: '',
      pageNumber: 1,
      pageSize: this.pageSizeOptions[0],
      sortBy: '',
      sortOrder: ''
    }
    this.tempfilter = {
      barcodeCombination: '',
      machineType: '',
      buttonPress: '',
      serviceDelivery: '',
      drinkProduct: ''
    }
    
    this.getFilterLookupApis(); //GD2-3643
    this.getLookUpAPIs(); //GD2-3643
    this.getCapsulesIngredientList();
  }

  // START GD2-3643
  resetSaveCapsuleForm() {
    this.cupVolumeList = [];
    Object.keys(this.addCapsuleDetailForm.controls).forEach((key) => {
      const control = this.addCapsuleDetailForm.controls[key];
      control.patchValue('');
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  edit(element) {
    this.spinner.show();
    this.capsulesMasterIngredientsId = element.capsulesMasterIngredientsId;
    this.onServiceDeliveryChange(element.serviceDelivery);

    this.addCapsuleDetailForm.patchValue({
      'barCodeCombination': this.barCodeCombinations.filter(x => x.id == element.barcodeId)?.length ? element.barcodeId : '',
      'machineType': element.machineTypeId,
      'buttonPress': element.buttonPress,
      'serviceDelivery' : element.serviceDelivery.toString(),
      'productName' : this.productNames.filter(x => x.id == element.productNameId)?.length ? element.productNameId : '',
      'drinkProduct': element.drinkMasterId,
      'blendType': element.blendTypeId,
      'cupVolume': this.cupVolumeList.filter(x => x.value == element.cupVolume.toString()).length ? element.cupVolume.toString() : ''
    });
    this.spinner.hide();
  }

  onServiceDeliveryChange(value){
    this.cupVolumeList = [];
    this.addCapsuleDetailForm.controls['cupVolume'].patchValue('');
    if (value != '') {
      if (value == 1) // TakeAway
        this.cupVolumeList = this.cupVolumeMaster.filter(x => x.isTakeAway);
      else if (value == 2) // EatIn
        this.cupVolumeList = this.cupVolumeMaster.filter(x => x.isEatIn);
    }
  }

  onDelete(capsuleId) {
    const deleteCapsuleModel = new MatDialogConfig();
    deleteCapsuleModel.height = 'auto';
    deleteCapsuleModel.width = '670px';
    this.dialogRef = this.dialog.open(this.confirmDeleteDialogTemplate, deleteCapsuleModel);
    this.deleteCapsuleId = capsuleId;
  }

  onConfirmDeleteYes() {
    this.spinner.show();
    this.dialogRef.close();
    this.ingredientService.deleteCapsuleIngredient(this.deleteCapsuleId).subscribe(response => {
      this.deleteCapsuleId = '';
      if (response) {
        let msg = "Capsule has been deleted successfully";
        this.toaster.open({
            text: msg,
            type: 'success',
            position: 'top-right',
            duration: 10000
        });
        this.getFilterLookupApis();
        this.getCapsulesIngredientList();
      }
      this.spinner.hide();
    }, err => {
      this.deleteCapsuleId = '';
      this.dialogRef.close();
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if(Array.isArray(errors)){
      errors.forEach(element => {
          this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
          });
        });
      }
    });
  }

  onConfirmCancel() {
    this.dialogRef.close();
  }  

  onCancelCapsule() {
    const cancelCapsuleModel = new MatDialogConfig();
    cancelCapsuleModel.height = 'auto';
    cancelCapsuleModel.width = '670px';
    this.dialogRef = this.dialog.open(this.confirmCancelDialogTemplate, cancelCapsuleModel);
  }

  onConfirmCancelYes() {
    this.dialogRef.close();
    this.capsulesMasterIngredientsId = '';
    this.showForm = false;
    setTimeout(() => {
      this.showForm = true;
      this.resetSaveCapsuleForm();
    }, 2); 
  }

  requireMatch = (controlName, options) => {
    return (control: FormControl) => {
      const selection: any = control.value;
      if (selection != '' && selection != null) {
        var lst = [];
        let masterDataList = controlName == 'barCodeCombination' ? 'barCodeCombinationList'
                         : controlName == 'productName' ? 'productNameList'
                         : controlName == 'drinkProduct' ? 'drinkProductList'
                         : controlName == 'blendType' ? 'blendTypeList'
                         : [];
        if (options[masterDataList.toString()] != undefined && options[masterDataList.toString()] != null) {
          options[masterDataList.toString()].subscribe(x => {
            lst = x;
          });
          var obj = lst.filter(x => x.id == selection || x.masterDataId == selection);
          if (obj == null || obj.length == 0) {
            return { requireMatch: true };
          }
          else {
            return null;
          }
        } else {
          return null;
        }
      } else { return null; }
    }
  }
  // END GD2-3643
}
