import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CreateRemoteUpdateTaskRequest } from "../../dtos/create-remote-update-task.model";
import { configHeaderRemoteUpdate } from "../config/config-header-remote-update";
import { CreatedRemoteUpdateTaskResponse } from "../../dtos/created-remote-update-task-response";
import { Observable } from "rxjs";
import { DrinkState } from "../task-properties/dto/drink-dto";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class CreateRemoteUpdateTaskService {
  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  private filterAndPrepareDrinks(taskRequest: CreateRemoteUpdateTaskRequest) {
    var { DrinkList, ...taskRequestFiltered } = taskRequest
    if (taskRequest.DrinkList !== undefined) {
      taskRequestFiltered["DrinkList"] = taskRequest.DrinkList.filter(drink =>
        drink.state !== DrinkState.NoChange)
        .map(drink => ({
          ...drink,
          enabled: drink.state === DrinkState.Enabled,
        }));
    }
    return taskRequestFiltered;
  }

  createNewTask(body: CreateRemoteUpdateTaskRequest): Observable<CreatedRemoteUpdateTaskResponse> {
    var bodyToSend = this.filterAndPrepareDrinks(body);

    var configHeader = {
			headers: {
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
			}
		};

    return this.httpClient.post<CreatedRemoteUpdateTaskResponse>(environment.apiUrl.remoteUpdate + "/v1/remoteupdatetask/", bodyToSend, configHeader).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
