import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MultiSelectComponent } from "@costa-coffee/pattern-lib";
import { DrinkSetupService } from "../../services/drink-setup.service";
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { DrinkSetup } from 'src/app/components/drinks-setup/components/drink-list/drinks-setup';
import { RequestGetDrinkGroupTemplate } from '../../services/request-add-drink-group-template';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-drinks-list',
  templateUrl: './drinks-list.component.html',
  styleUrls: ['./drinks-list.component.css']
})
export class DrinksSetupComponent implements OnInit {
  @ViewChild('drinkList') private drinkList: MultiSelectComponent;

  @Output('selection-changed') selectionChanged: EventEmitter<DrinkSetup[]> = new EventEmitter<DrinkSetup[]>();
  @Input() drinkGroupTemplateForEdit: RequestGetDrinkGroupTemplate = null;

  selectedDrinksList: DrinkSetup[] = [];
  entities$: Observable<DrinkSetup[]>;
  showEmptyListMessage: boolean = false;
  emptyListMessage: string = 'This field is required.';
  validVariant: "default" | "error" = 'default';

  public get selectIds() {
    if (!this.drinkGroupTemplateForEdit || !this.drinkGroupTemplateForEdit.drinkList?.length)
      return [];

    return this.drinkGroupTemplateForEdit.drinkList.map(m => m.drinkName);
  }

  constructor(
    private DrinkSetupService: DrinkSetupService) {
  }

  ngOnInit() {
    this.getDrinkList();
  }

  protected getDrinkList() {
    this.entities$ = this.DrinkSetupService.getMasterLookup(MasterLookupEnum.Drink)
      .mergeMap(x => x.data)
      .map(item => ({
        id: item.masterDataId,
        name: item.text
      }))
      .toArray();
  }

  protected drinkListonChanges(selectedItems: any[]) {

    this.selectedDrinksList = selectedItems;

    this.validate();

    this.selectionChanged.emit(this.selectedDrinksList);
  }

  protected removeDrinks(id: string): void {
    this.drinkList.removeItem(id);
  }

  validate(): boolean {
    if (this.selectedDrinksList.length == 0) {
      this.showEmptyListMessage = true;
      this.validVariant = 'error';
      return false;
    }
    else {
      this.showEmptyListMessage = false;
      this.validVariant = 'default';
      return true;
    }
  }
}
