import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportToCsv } from 'export-to-csv';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { RoleService } from 'src/app/services/role/role.service';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-price-card-preview',
  templateUrl: './price-card-preview.component.html',
  styleUrls: ['./price-card-preview.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PriceCardPreviewComponent implements OnInit {
  @Output() clickButton = new EventEmitter<any>();
  displayedColumns: string[] = ['entityName', 'levelType', 'marketName', 'startDate', 'levelNumber'];
  displayedPreviewColumns: string[] = ['drinkName', 'cupsizeName', 'coffeeName', 'milkName', 'syrupName', 'price'];
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'Pricing'
  };
  priceCardType = 'Normal';
  pipe = new DatePipe('en-US');
  screenType = 'add';
  priceCardVersionId = '';
  priceCardNumber = '';
  priceCardName = '';
  isEditable: boolean = true;

  start_time: any;
  start_date: any;
  minDate: any = new Date();

  hasTouchedInputs = {
    start_time:false,
    start_date:false
  }
  versionDateTime:any = '';
  isIngredientChanged: boolean = true;
  priceCardId = '';
  versionList = [];
  isLatestVersionSelected = false;
  allPriceCardPreviews:any = [];
  previewListSorting:any = [];
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private activatedRoute: ActivatedRoute,
    public priceCardService: PriceCardService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private router: Router,
    public roleService: RoleService,
    public priceCardTypeConstant:PriceCardTypeLookupConstant
  ) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.screenType) {
        this.screenType = queryParams.screenType;
      }
      
      this.activatedRoute.params.subscribe(params => {
        if (params.priceCardVersionId) {
          if(this.screenType == 'read'){
            this.priceCardId = params.priceCardVersionId;
          }
          else{
            this.priceCardVersionId = params.priceCardVersionId;
            this.screenType = 'edit';
          }
                  
        }
      });
    });
  }

  ngOnInit(): void {
    this.priceCardService.priceCardPreviewSearch = null;
    if (!this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isMarketAdmin && !this.roleService.objRole.isLevel2Admin && !this.roleService.objRole.isLevel3Admin && this.screenType != 'read') {
      this.router.navigate(['/unauthorized']);
    }
    if (this.screenType == 'read') {
      this.getPriceCardVersionLookup();      
    }
  }
  onVersionChange(){
    this.getPriceCardDetails(true); 
    let selectedVersion = this.versionList.find(el => el.priceCardVersionId === this.priceCardService.priceCardDetails.priceCardVersionId);
    if (selectedVersion) {
      this.isEditable = selectedVersion.isEditable; 
      this.versionDateTime = selectedVersion.startDate;
      this.isLatestVersionSelected  =  selectedVersion.isLatestVersion;
      if (this.versionDateTime.charAt(this.versionDateTime.length - 1).toLowerCase() != 'z') {
        this.versionDateTime = this.versionDateTime + 'Z';
      }  
    }
  }
  getPriceCardVersionLookup(){
    this.spinner.show();
    this.priceCardService.getPriceCardVersionLookup(this.priceCardId).subscribe(response => {
      this.versionList = response.data;
      let latestVersion = response.data.find(el => el.isLatestVersion === true);
      if (latestVersion) {
        this.isLatestVersionSelected  =  latestVersion.isLatestVersion;
        this.priceCardService.priceCardDetails.priceCardVersionId = latestVersion.priceCardVersionId;
        this.onVersionChange();
        this.getPriceCardInformation();
      }             
    });
  }
  getPriceCardInformation() {
    this.priceCardService.getPriceCardInformation(this.priceCardService.priceCardDetails.priceCardVersionId).subscribe(response => {
      if (response.data.priceCardType) {
        this.priceCardType = response.data.priceCardType;
      }
      this.isEditable = response.data.isEditable;
      this.priceCardName = response.data.priceCardName;
        
    });
  }
  getPriceCardDetails(showSpinner=false) {
    if (this.screenType == 'read' || showSpinner) {
      this.spinner.show();
    }
    let priceCardVersionId = this.priceCardService.priceCardDetails.priceCardVersionId;
    this.priceCardService.getPriceCardPreview(priceCardVersionId).subscribe(response => {
      if (response) {
        this.priceCardNumber = response.versionNo;
        this.isIngredientChanged = response.isIngredientChanged;
        if(response.startDate){
          let startDate = response.startDate;
          if (startDate.charAt(startDate.length - 1).toLowerCase() != 'z') {
            startDate = startDate + 'Z';
          }
          this.start_date = moment(startDate);
          this.start_time= new Date();
          this.start_time.setHours(moment(startDate).get('hours'));
          this.start_time.setMinutes(moment(startDate).get('minutes'));
        }
        if (response['priceCardPreviews']) {
          this.priceCardService.priceCardDetails.priceCardPreviews = response['priceCardPreviews'];
          this.allPriceCardPreviews = response['priceCardPreviews'];
        } else {
          this.priceCardService.priceCardDetails.priceCardPreviews = [];
          this.allPriceCardPreviews = [];
        }
        this.sortData();
      }
      if (this.screenType == 'read' || showSpinner) {
        this.spinner.hide();
      }
    });
  }  
  sortData(){
    this.previewListSorting = new MatTableDataSource(this.priceCardService.priceCardDetails.priceCardPreviews);
    this.previewListSorting.sort = this.sort;
  }
  getDisplayDateFormat(date, type) {
    if (type == 'date') {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment(date).format('HH:mm')
    }
  }
  submitForm(type) {
    if (this.screenType == 'read') {
      if (type == 'edit') {
        this.spinner.show();
        this.router.navigate(['/price-card/' + this.priceCardService.priceCardDetails.priceCardVersionId]);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      } else if (type == 'cancel') {
        this.router.navigate(['/price-card']);
      }
    } else {      
      if (type == 'previous' || type == 'cancel') {
        this.clickButton.next({ stepKey: 'preview', buttonType: type });
      } else {
        this.hasTouchedInputs = {
          start_time:true,
          start_date:true
        }
        if(!this.start_date || !this.start_time){
          this.toaster.open({
            text: 'Please provide valid input for all the highlighted fields',
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        }else{     
          let current_time = moment();
          let versionStartDate:any = moment();
          versionStartDate = moment(versionStartDate).set({
            'year':moment(this.start_date).year(),
            'month':moment(this.start_date).month(),
            'date':moment(this.start_date).date(),
            'hours':moment(this.start_time).hours(),
            'minutes':moment(this.start_time).minutes(),
            'seconds':0
          });
          if (versionStartDate < current_time) {
          this.toaster.open({
            text: 'Version start date and time must be in the future',
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
            } else {
            this.spinner.show();  
            this.priceCardService.createPriceCard({ priceCardVersionId: this.priceCardService.priceCardDetails.priceCardVersionId,versionStartDate:versionStartDate }).subscribe(response => {
              this.spinner.hide();
              let msg = "Price Card created successfully"
              if (type == 'save') {
                msg = "Price Card updated successfully"
              }
              this.toaster.open({
                text: msg,
                type: 'success',
                position: 'top-right',
                duration: 10000
              });
              this.clickButton.next({ stepKey: 'preview', buttonType: type });

            }, err => {
              if (err && err.error) {
                this.spinner.hide();
                let errors = err.error;
                errors.forEach(element => {
                  this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                  });
                  if(element.msg == "Oops! Something went wrong."){
                    this.router.navigate(['/price-card']);
                  }
                });
              }
            });
          }
        }
      }
    
    }
  }
  exportData() {
    this.spinner.show();
    this.options.filename = this.priceCardService.priceCardDetails.priceCardName;
    this.options.title = 'Pricing for ' + this.priceCardService.priceCardDetails.priceCardName;
    if (this.screenType == 'read') {
      this.options.filename = this.priceCardName;
      this.options.title = this.priceCardName
    }
    let csvExporter = new ExportToCsv(this.options);

    let exportData = [];
    let sortedData: any = [];
    if ('filteredData' in this.previewListSorting)
      sortedData = this.previewListSorting?.filteredData;
    else 
      sortedData = this.previewListSorting;

    for (let i = 0; i < sortedData.length; i++) {
      let obj = {
        ['Drink Name']: sortedData[i]['drinkName'],
        ['Cup Size']: sortedData[i]['cupsizeName'],
        ['Coffee Type']: (sortedData[i]['coffeeName']) ? sortedData[i]['coffeeName'] : '',
        ['Milk Type']: (sortedData[i]['milkName']) ? sortedData[i]['milkName'] : '',
        ['Syrup']: (sortedData[i]['syrupName']) ? sortedData[i]['syrupName'] : '',
        ['Price']: sortedData[i]['price'],
      };
      exportData.push(obj);
    }
    if (exportData.length == 0) {
      let obj = {
        ['Drink Name']: '',
        ['Cup Size']: '',
        ['Coffee Type']: '',
        ['Milk Type']: '',
        ['Syrup']: '',
        ['Price']: '',
      };
      exportData.push(obj);
    }

    csvExporter.generateCsv(exportData);
    this.spinner.hide();
  }

  onFilter(event: Event) { 
    // Default mat filter will not work as will display 'Syrup' instread of syrup name
    if (this.priceCardService.priceCardPreviewSearch != null && this.priceCardService.priceCardPreviewSearch != '' && this.priceCardService.priceCardPreviewSearch != undefined){
      this.previewListSorting = [];
      this.priceCardService.priceCardDetails.priceCardPreviews.forEach(element => {
        if (element.drinkName?.toLowerCase()?.includes(this.priceCardService.priceCardPreviewSearch.toLowerCase()) 
        || element.cupsizeName?.toLowerCase()?.includes(this.priceCardService.priceCardPreviewSearch.toLowerCase())
        || element.coffeeName?.toLowerCase()?.includes(this.priceCardService.priceCardPreviewSearch.toLowerCase())
        || element.milkName?.toLowerCase()?.includes(this.priceCardService.priceCardPreviewSearch.toLowerCase())
        || (element.syrupName ? 'Syrup' : '')?.toLowerCase()?.includes(this.priceCardService.priceCardPreviewSearch.toLowerCase())
        || element.price?.toString().includes(this.priceCardService.priceCardPreviewSearch.toLowerCase())) {
          this.previewListSorting.push(element);
        }
      });
    } else {
      this.previewListSorting = this.priceCardService.priceCardDetails.priceCardPreviews;
    }    
  }

  clearSearch() {
      this.priceCardService.priceCardPreviewSearch = '';
      this.previewListSorting = this.priceCardService.priceCardDetails.priceCardPreviews;
  }
}











