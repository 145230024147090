import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class AppConstant {
  gridShowFirstLastButtons: boolean = true;
  grid_setting = {
    pageSize: 10,
    pageIndex: 0,
    gridTotalRecord: 0,
    pageSizeOptions: [10, 25, 50, 100]
  };
  grid_settingNames = {
    pageNo: 'pageNo',
    pageSize: 'pageSize',
    orderby: 'orderByColumn',
    search: 'search',
    emptyMessage: 'No results found.'
  };
  sqlMinDate = moment("1753-01-01");
  sqlMaxDate = moment("9999-12-31");
}