import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { CapsuleIngredientService } from 'src/app/services/capsule-ingredient/capsule-ingredient.service';
import { RoleService } from 'src/app/services/role/role.service';

//GD2-3704
@Component({
  selector: 'app-capsules-productname',
  templateUrl: './capsules-productname.component.html',
  styleUrls: ['./capsules-productname.component.css']
})
export class CapsulesProductnameComponent implements OnInit {

  productNameForm: FormGroup;
  productNameId = '';
  recordsTotal = 0;
  productNameList:any = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  displayedColumns: string[] = ['productName', 'isActive','actions'];
  params = {
    search: '',
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    sortBy: '',
    sortOrder: ''
  }
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }

  dataAssociateTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  @ViewChild("associateDialogTemplate") associateDialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  isPageDataLoad = false;
  showForm: boolean = true;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    public roleService: RoleService,
    private toaster: Toaster,
    private ingredientService: CapsuleIngredientService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    if (!this.roleService.objRole.isSystemAdmin) {
      this.router.navigate(['/unauthorized']);
    }

    this.productNameForm = new FormGroup({
      'productName': new FormControl('', [Validators.required, Validators.pattern("^[0-9a-zA-Z ]*$"), Validators.maxLength(50)]), //alphanumeric with space
      'isActive': new FormControl(true, [Validators.required])
    });

    this.getProductNameList();
  }

  getProductNameList() {
    this.spinner.show();
    let obj = {};

    if (this.params.search != '') { obj['Search'] = this.params.search; };
    if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) { obj['SortBy'] = this.params.sortBy };
    if (this.params.sortOrder != undefined) { obj['SortOrder'] = this.params.sortOrder };

    this.ingredientService.GetProductName(obj).subscribe(response => {
      this.recordsTotal = response?.params?.count;
      this.spinner.hide();
      this.isPageDataLoad = true;

      if (response && response['data']) {
        this.productNameList = response['data']
      } else {
        this.productNameList = [];
      }
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (Array.isArray(errors)) {
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }

  saveProductName(confirmAssociateRecord = false) {
    if (confirmAssociateRecord) {
      this.dialogRef.close();
    }
    this.spinner.show();

    if (this.productNameForm.status === 'INVALID') {
      this.productNameForm.markAllAsTouched();
      this.spinner.hide();
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } 
    else {
      let body: any = {
        'productNameId': this.productNameId,
        'productName': this.productNameForm.controls['productName'].value,
        'isActive': this.productNameForm.controls['isActive'].value,
        'confirmAssociateRecord' : confirmAssociateRecord
      }

      this.ingredientService.AddUpdateProductName(body).subscribe(response => {
        this.spinner.hide();

        if (response.data.result && !response.data.isAssociated){
          this.showForm = false;
          setTimeout(() => { this.showForm = true; }, 2);

          let message = this.productNameId ? 'Product Name updated successfully' : 'Product Name added successfully';
          this.productNameId = '';
          this.toaster.open({
            text: message,
            type: 'success',
            position: 'top-right',
            duration: 10000
          });

          this.clearForm();
          this.params.pageNumber = 1;
          this.getProductNameList();
        } 
        else if (response.data.isAssociated) {
          this.onProductNameAssociate(response.data.errorMessage);
        }
      }, err => {
        this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        if (Array.isArray(errors)) {
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        }
      });
    }
  }

  edit(element: any) {
    this.spinner.show();
    this.productNameId = element.productNameId;
    this.productNameForm.controls['productName'].patchValue(element.productName);
    this.productNameForm.controls['isActive'].patchValue(element.isActive);
    this.spinner.hide();
  }

  clearSearch() {
    this.params.search = '';
    this.params.pageNumber = 1;
    this.getProductNameList();
  }

  sortData(event) {
    event.direction = (event.active == 'isActive' && event.direction != '' ? (event.direction == 'asc' ? 'desc' : 'asc') : event.direction);
    this.params.pageNumber = 1;
    this.params.sortBy = (event.active != '' && event.direction != '' ? event.active : (event.active != '' && event.direction == '') ? event.active : 'cells_name');
    this.params.sortOrder = (event.direction != '' ? event.direction : (event.direction == '') ? 'asc' : 'asc');
    this.getProductNameList();
  }

  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    this.getProductNameList();
  }

  clearForm() {
    Object.keys(this.productNameForm.controls).forEach((key) => {
      const control = this.productNameForm.controls[key];
      control.patchValue(key=='isActive' ? true : '');
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  onSearch() {
    this.params.pageNumber = 1;
    this.getProductNameList();
  }

  cancelProductName(){
    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
      deviceName: '',
      status: '',
      title: 'Confirmation',
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }

  onConfirm() {
    this.dialogRef.close();
    this.productNameId = '';
    this.showForm = false;
    setTimeout(() => {
      this.showForm = true;
      this.clearForm();  
    }, 2);    
  }

  onCancel() {
    this.dialogRef.close();
  }

  onProductNameAssociate(msg) {
    this.dataAssociateTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: msg,
      deviceName: '',
      status: '',
      title: 'Confirmation',
    }
    const dataModel = new MatDialogConfig();
    dataModel.height = 'auto';
    dataModel.width = '670px';
    dataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.associateDialogTemplate, dataModel);
  }
}
