export enum SuspendOrResumeMachineTypes {
  NOT_APPLICABLE = 'N/A',
  SUSPENDED = 'Suspended',
  TRADING = 'Trading',
}

export enum SuspendOrResumeLabelTypes {
  SUSPEND='Suspend Machine',
  RESUME='Resume Machine'
}

export type SuspendOrResumeActionTypes = 'resume' | 'suspend'
