export enum RemoteUpdateTaskStatus {
  Pending = "Pending",
  Immediate = "Immediate",
  Scheduled = "Scheduled",
  InProgress = "In Progress",
  Completed = "Completed",
  CompletedWithErrors = "Completed With Errors",
  Failed = "Failed",
  Cancelled = "Cancelled",
  PartiallyCancelled = "Partially Cancelled",
  CancellationPending = "Cancellation Pending"
}
