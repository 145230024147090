import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PriceCardService {
  cookieName = environment.cookieName;
  drinkGroupTemplatePropositionType = '';
  syrupGroup = [];
  priceCardDetails = {
    priceCardId: '',
    priceCardVersionId:'',
    priceCardName: '',
    drinkGroupTemplateId: '',    
    drinkGroups: { drinkGroupsList: [], cupsizes: [], coffeeGroupsList: [], milkGroupsList: [], syrupGroupsList: [], entityMappingList: [] },
    priceCardEntities: [],
    priceCardPreviews: []
  }
  priceCardPreviewSearch = '';
  
  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) { }

  getDrinkGroupTemplates(type = '',isActive='',isOutDated='') {​​​​​​​
    var configHeader = {​​​​​​​​​​​​​​
      headers: {​​​​​​​​​​​​​​
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }​​​​​​​​​​​​​​
    }​​​​​​​​​​​​​​;
    const parameter = {​​​​​​​​​​​​​​
      params: {​​​​​​​​​​​​​​
        'isSystemGeneratedTemplate': type,
        'isActive':isActive,
        'isOutDated':isOutDated
      }​​​​​​​​​​​​​​,
      timestamp: Date.now()
    }​​​​​​​​​​​​​​;
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/drinkgrouptemplates', reqParameter).pipe(map(response => {​​​​​​​​​​​​​​
      return response;
    }​​​​​​​​​​​​​​));
  }
  
  getDrinkGroupTemplateById(drinkGroupTemplateId: string) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };

    return this.httpClient.get<any>(`${environment.baseUrl}/api/v2/drinkgrouptemplate/${drinkGroupTemplateId}`, {
      headers: configHeader.headers
    }).pipe(map(response => {
      return response;
    }));
  }​​​​​​​​​​​​​​
  
  getDrinkGroupTemplatesByType(templateId, queryParams: any) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    const parameter = {
      params: {
        'drinkType': queryParams.type,
        'isPrimary': (queryParams.isPrimary) ? queryParams.isPrimary : false
      },
      timestamp: Date.now()
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/drinkgrouptemplate/' + templateId, reqParameter).pipe(map(response => {
      return response;
    }));
  }

  getwrapperdetails(priceCardVersionId, queryParams: any){
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    let body = {
        'drinkType': queryParams.type,
        'isPrimary': (queryParams.isPrimary) ? queryParams.isPrimary : false,
        'priceCardVersionId':priceCardVersionId
    };
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/pricecard/getwrapperdetails', body,configHeader).pipe(map(response => {
      return response;
    }));
  }
  getSitesByPartners(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/master/getsitesbypartners', body, configHeader).pipe(map(response => {
      return response;
    }));
  }  
  getSitesByPartnersAndPropositions(partnerIds: string[], propositionIds: string[]) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };

    let body = {
      'partnerIds': partnerIds,
      'propositionTypeIds': propositionIds
    }
    
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v2/master/getsitesbypartners', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getPriceCardInformation(priceCardVersionId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/pricecardinformation/' + priceCardVersionId, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getPriceCardVersionLookup(priceCardId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/pricecardversion/' + priceCardId, configHeader).pipe(map(response => {
      return response;
    }));
  }

  addPriceCardInformation(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/pricecardinformation', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  addPriceCardDetails(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    body['route'] = 'price-card';
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/pricecarddetails', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  addPriceCardentity(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/pricecardentity', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  addPriceCardentityV2(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v2/pricecardentity', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  createPriceCard(body = {}) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    body['route'] = 'price-card'
    return this.httpClient.put<any>(environment.apiUrl.entity + '/api/v1/finalizepricecard', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  deletePriceCard(priceCardVersionId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.put<any>(environment.apiUrl.entity + '/api/v1/deletepricecard/' + priceCardVersionId, {}, configHeader).pipe(map(response => {
      return response;
    }));
  }
  deleteDraftPriceCard(priceCardVersionId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.delete<any>(environment.apiUrl.entity + '/api/v1/cancelpricecard/' + priceCardVersionId, configHeader).pipe(map(response => {
      return response;
    }));
  }
  changeStatusPriceCard(priceCardVersionId, status) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    let body = {
      "priceCardVersionId": priceCardVersionId,
      "isActive": status
    };
    body['route'] = 'price-card';
    return this.httpClient.put<any>(environment.apiUrl.entity + '/api/v1/pricecardstatus/',body , configHeader).pipe(map(response => {
      return response;
    }));
  }
  getPriceCardDetails(priceCardVersionId, type) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/pricecarddetails/' + priceCardVersionId + '/' + type, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getPricecardEntity(body) {    
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/pricecardentites',body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getPricecardEntityV2(body) {    
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v2/pricecardentites',body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getPriceCardPreview(priceCardVersionId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/pricecardpreview/' + priceCardVersionId, configHeader).pipe(map(response => {
      return response;
    }));
  }

  getPriceCardList(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/pricecard/search', body, configHeader).pipe(map(response => {
      return response;
    }));

  }

  getPriceCardNameLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/pricecards/', configHeader).pipe(map(response => {
      return response;
    }));
  }
  getPriceCardUnrecognizedVends(body={"isActive":true,siteIds:null}) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.baseUrl + '/api/v1/pricecards/unrecognizedvends', body,configHeader).pipe(map(response => {
      return response;
    }));
  }
  
  getPriceCardTemplateLookup() {
    return null;
  }

  getPartnerLookup() {
    return null;
  }

  getSiteLookup() {
    return null;
  }

  getStatusLookup() {
    return null;
  }
  sortGridByGroup(array) {        
    return array.sort(function (a, b) {
      return a['drinkGroupTemplateDetailName'].localeCompare(b['drinkGroupTemplateDetailName']) || a['sequenceNumber'] - b['sequenceNumber'];
    });  
  
  }

  setSyrupGroup() {
    this.syrupGroup = this.priceCardDetails.drinkGroups.syrupGroupsList;
    if (this.drinkGroupTemplatePropositionType === 'ConcessionSpecific') {
      const syrupGroup = this.priceCardDetails.drinkGroups.syrupGroupsList.reduce((acc, curr) => {
        acc.drinkGroupTemplateCupDetailName += acc.drinkGroupTemplateCupDetailName ? ` | ${curr.drinkGroupTemplateCupDetailName}` : curr.drinkGroupTemplateCupDetailName;
        acc.price = curr.price;
        return acc;
      }, { drinkGroupTemplateCupDetailName: "", price: "" });
      this.syrupGroup = [syrupGroup];
    }
  }
}
