export enum RemoteUpdateSortableField {
  TaskId = 1,
  TaskType,
  PropositionType,
  PackageName,
  Package,
  ScheduledOn,
  TaskStatus,
  EntityDeployLevel,
  CreatedBy,
  UpdatedBy
}
