export class SummaryInformationModel {
    title: string;
    itens: string[];
    isChip: boolean;

    constructor(title: string, itens: string[], isChip: boolean = false) {
      this.title = title;
      this.itens = itens;
      this.isChip = isChip;
    }
}