import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs-compat';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleEnum, RoleService } from 'src/app/services/role/role.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from './../../../environments/environment';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	moduleId: 'login'
})
export class LoginComponent implements OnInit {
	public cookieName = environment.cookieName;
	loggedIn: Boolean;
	hide = true;
	loginForm: FormGroup;
	checkSubmitStatus = false;
	loginObject = {
		username: '',
		password: '',
		isrememberme: false
	};
	isIframe = false;
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private msalService: MsalService,
		private router: Router,
		private roleService: RoleService,
		private cookieService: CookieService,
		private spinner: NgxSpinnerService,
		private authService:AuthService,
		private userService:UserService
	) {


	}

	ngOnInit() {
		this.setLoginDisplay();	

		this.spinner.show();
		this.msalService.instance.handleRedirectPromise().then(authResult => {
			// Check if user signed in 

			if (authResult != null && authResult.accessToken != '' && authResult.idToken != '') {
				let body = {
					"access_token": authResult.accessToken,
					"id_token": authResult.idToken,
					"session_state": authResult.state
				};

				this.authService.b2blogin(body).then(res => res.subscribe(response => {
					if (response != null) {						
						this.userService.getUIConfigurations().subscribe(async (response) => { //to get default UI configurations			
						});
						this.authService.hasCalledSignoutLog = false;
						this.roleService.levelNumber = response.data.levelNumber;
						this.roleService.nextLevelNumber = response.data.nextLevelNumber;
						this.roleService.entityId = response.data.entityId;
						this.roleService.marketId = response.data.marketId;
						this.roleService.userPermissions = response.data.userPermissions;
						this.roleService.currentRole = response.data.userDetail.roleName;
						this.roleService.userName = response.data.userDetail.fullName;
						this.roleService.roleKey = this.roleService.getEnumKeyByEnumValue(RoleEnum, this.roleService.currentRole);
						for (let key in this.roleService.objRole) {
							this.roleService.objRole[key] = false;
						}
						if (this.roleService.roleKey != '') {
							this.roleService.objRole['is' + this.roleService.roleKey] = true;
						}
						this.cookieService.set(this.cookieName + 'userId', response.data.userDetail.id);
						response.data.userDetail['marketId'] = response.data.marketId;
						response.data.userDetail['parentId'] = response.data.parentId;
						this.userService.userDetails = response.data.userDetail;
						}
						this.spinner.hide();
						if (this.loginObject.isrememberme) {
							var date = new Date();
							date.setDate(date.getDate() + (30));
							this.cookieService.set(this.cookieName + 'username', this.loginObject.username, date, '/');
							this.cookieService.set(this.cookieName + 'password', btoa(this.loginObject.password), date, '/');
							this.cookieService.set(this.cookieName + 'isrememberme', 'y', date, '/');
						}
						else {
							this.cookieService.delete(this.cookieName + 'username', '/', environment.domainName);
							this.cookieService.delete(this.cookieName + 'password', '/', environment.domainName);
							this.cookieService.delete(this.cookieName + 'isrememberme', '/', environment.domainName);
						}

						if (this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3)
							this.router.navigate(['/user-audit']);
						else
							this.router.navigate(['/machine']);
				}, err => {
					this.spinner.hide();
					let errors = err.error;
					this.authService.loginError = errors;
					this.router.navigate(['/error']);
				 }));
			} else {
				const account = this.msalService.instance.getActiveAccount();
				if (!account) {
					// redirect anonymous user to login page 
					this.msalService.instance.loginRedirect();
				}
			}
		}).catch(err => {				
			// TODO: Handle errors
			this.authService.loginError = [{msg:'Something went wrong.'}];
			this.router.navigate(['/error']);
			console.log(err);
		});

	}

	setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
		if (!this.loginDisplay)
			this.msalService.loginRedirect();
	}


	loginRedirect() {
		if (this.msalGuardConfig.authRequest) {
			this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
		} else {
			this.msalService.loginRedirect();
		}
	}
}


