import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { GetPackageNotes } from '../../dtos/get-package-notes.model';

@Injectable({
	providedIn: 'root'
})
export class RemoteUpdatePackagesService {

	constructor(
		private cookieService: CookieService,
		private httpClient: HttpClient
	) { }

	cookieName: string = environment.cookieName

	configHeader = {
		headers: {
			'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
		}
	};

	getRemoteUpdatePackageNotes(id: number){
		return this.httpClient
			.get<GetPackageNotes>(environment.apiUrl.remoteUpdatePackage + "/v1/remoteupdatepackage/notes/" + id, this.configHeader)
			.pipe(map(response => {
				return response;
			}));
	}

	changeRemoteUpdatePackageStatus(id: number, enabled: boolean){
		const userId = this.cookieService.get(this.cookieName + 'userId');
		const body = {
			isEnabled: enabled,
			userId: userId
		};

		return this.httpClient
      .patch<any>(environment.apiUrl.remoteUpdatePackage + "/v1/remoteupdatepackage/" + id, body, this.configHeader)
      .pipe(map(response => response));
	}
}
