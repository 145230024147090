import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from "ngx-toast-notifications";
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MasterLookupConstant, MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PriceCardTemplateService } from 'src/app/services/pricecard-template/pricecard-template.service';
import { RoleService } from 'src/app/services/role/role.service';
import { AddPriceCardTemplateModel, LookupModelList, SavePriceCardRequestTemplateModel } from './pricecard-template-add.model';
declare var $: any
@Component({
  selector: 'app-price-card-template-add',
  templateUrl: './pricecard-template-add.component.html',
  styleUrls: ['./pricecard-template-add.component.css']
})
export class PriceCardTemplateAddComponent implements OnInit {
  today = new Date();
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }
  dropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "masterDataId",
    textField: "text",
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 0
  };
  marketDropdownSettings = {
    singleSelection: true,
    defaultOpen: false,
    idField: "masterDataId",
    textField: "text",
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1
  };
  addPriceCardTemplateModel = new AddPriceCardTemplateModel();
  screenType = 'add';
  isFormLoad = false;
  drinkGroupTemplateId: any;
  dialogRef: MatDialogRef<any>;
  label = 'Price Card Template';
  marketList: any = [];
  plasticCupDrpDrinkList: any = [];
  waffletCupDrpDrinkList: any = [];
  OriginaldrinkList: any = [];
  waffleCupDrinkList : any = [];
  plasticCupDrinkList : any = [];
  syrupList: any = [];
  cupSizeList: any = [];
  coffeeTypeList: any = [];
  milkTypeList: any = [];
  cupSizeDropdownSettings: any = {};
  milkTypeDropdownSettings: any = {};
  coffeeTypeDropdownSettings: any = {};
  drinkDropdownSettings: any = {
    singleSelection: false,
    defaultOpen: false,
    idField: "masterDataId",
    textField: "text",
    enableCheckAll: true,
    allowSearchFilter: true,
    itemsShowLimit: 0
  };;
  syrupDropdownSettings: any = {};
  MasterLookupEnum: typeof MasterLookupEnum;
  addPriceCardTemplateFormGroup: RxFormGroup;
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  constructor(
    public priceCardTypeLookupConstant: PriceCardTypeLookupConstant,
    public masterLookupConstant: MasterLookupConstant,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toaster: Toaster,
    private dialog: MatDialog,
    public authService: AuthService,
    private priceCardTemplateService: PriceCardTemplateService,
    private formBuilder: RxFormBuilder,
    public roleService:RoleService) {
    this.activatedRoute.params.subscribe(params => {
      if (params.drinkGroupTemplateId) {
        this.drinkGroupTemplateId = params.drinkGroupTemplateId;
        this.screenType = 'edit';
      }
    });
  }
  getLookupRequests() {
    const lookUpRequests = [];
    lookUpRequests.push(this.priceCardTemplateService.getMarketLookup());
    lookUpRequests.push(this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Drink));
    lookUpRequests.push(this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.CupSize,"createdDate"));
    lookUpRequests.push(this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Coffee));
    lookUpRequests.push(this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Milk));
    lookUpRequests.push(this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Syrup));
    if (this.drinkGroupTemplateId != undefined && this.drinkGroupTemplateId != "") {
      lookUpRequests.push(this.priceCardTemplateService.getDrinkGroupTemplatesById(this.drinkGroupTemplateId));
    }
    return lookUpRequests;
  }


  ngOnInit(): void {
    if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel1R || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel2R || this.roleService.objRole.isUserAuditorLevel3 || this.roleService.objRole.isUserAuditorLevel3R) {
      this.router.navigate(['/unauthorized']);
    }
    this.MasterLookupEnum = MasterLookupEnum
    this.spinner.show();
    forkJoin(this.getLookupRequests()).pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe(([marketList, drinkList, cupSizeList, coffeeTypeList, milkTypeList, syrupList, drinkGroupResponse]:
      [LookupModelList, LookupModelList, LookupModelList, LookupModelList, LookupModelList, LookupModelList, any]) => {
      if (marketList && marketList.data.length) {
        this.marketList = marketList.data.map(a => {
          const container = {};
          container['masterDataId'] = a.masterDataId,
            container['text'] = a.text,
            container['isDisabled'] = !a.isActive ? true : false
          return container;
        });      
      }
      if (drinkList && drinkList.data.length) {
        this.OriginaldrinkList = drinkList.data;
      }
      if (cupSizeList && cupSizeList.data.length) {
        this.cupSizeList = cupSizeList.data;
      }
      if (coffeeTypeList && coffeeTypeList.data.length) {
        this.coffeeTypeList = coffeeTypeList.data;
      }
      if (milkTypeList && milkTypeList.data.length) {
        this.milkTypeList = milkTypeList.data;
      }
      if (syrupList && syrupList.data.length) {
        this.syrupList = syrupList.data;
      }
      if (drinkGroupResponse && drinkGroupResponse['data']) {
        let responseData = drinkGroupResponse['data'];
        this.addPriceCardTemplateModel.templateName = responseData['templateName']
        this.addPriceCardTemplateModel.isActive = responseData['isActive'];
        
        this.addPriceCardTemplateModel.marketList = [{masterDataId:responseData['market']['entityId'],text:responseData['market']['entityName']}];
        let drinkList = responseData['drinkDetails'].filter(a => a.detailType == this.masterLookupConstant.Drink.toLowerCase())
        this.addPriceCardTemplateModel.plasticCupdrinkList = drinkList.filter(x => x.cupType == 'Plastic').map(a => {
          const container = {};
          container['masterDataId'] = a.detailMasterDataId,
            container['text'] = a.name
          return container;
        });
        if(this.addPriceCardTemplateModel.plasticCupdrinkList.length > 0){
          this.addPriceCardTemplateModel.cupType = 'Plastic';
        } else {
          this.addPriceCardTemplateModel.cupType = 'Waffle';
        }
        
        this.addPriceCardTemplateModel.waffleCupdrinkList = drinkList.filter(x => x.cupType == 'Waffle').map(a => {
          const container = {};
          container['masterDataId'] = a.detailMasterDataId,
            container['text'] = a.name
          return container;
        });
        this.plasticCupDrinkList = this.addPriceCardTemplateModel.plasticCupdrinkList ;
        this.waffleCupDrinkList = this.addPriceCardTemplateModel.waffleCupdrinkList; 

        let coffeeList = responseData['drinkDetails'].filter(a => a.detailType == this.masterLookupConstant.Coffee.toLowerCase())
        this.addPriceCardTemplateModel.coffeeTypeList = coffeeList.map(a => {
          const container = {};
          container['masterDataId'] = a.detailMasterDataId,
            container['text'] = a.name
          this.onMasterSelect(container, MasterLookupEnum.Coffee);
          return container;
        });
        this.addPriceCardTemplateModel.primaryCoffeeType = coffeeList.filter(a => a.isPrimary).map(a => a.detailMasterDataId)[0];
        let cupSizeList = responseData['drinkDetails'].filter(a => a.detailType == this.masterLookupConstant.CupSize.toLowerCase())
        this.addPriceCardTemplateModel.cupSizeList = cupSizeList.map(a => {
          const container = {};
          container['masterDataId'] = a.detailMasterDataId,
            container['text'] = a.name
          return container;
        });
        let syrupList = responseData['drinkDetails'].filter(a => a.detailType == this.masterLookupConstant.Syrup.toLowerCase())
        this.addPriceCardTemplateModel.syrupList = syrupList.map(a => {
          const container = {};
          container['masterDataId'] = a.detailMasterDataId,
            container['text'] = a.name
          return container;
        });
        let milkList = responseData['drinkDetails'].filter(a => a.detailType == this.masterLookupConstant.Milk.toLowerCase())
        this.addPriceCardTemplateModel.milkTypeList = milkList.map(a => {
          const container = {};
          container['masterDataId'] = a.detailMasterDataId,
            container['text'] = a.name
          this.onMasterSelect(container, MasterLookupEnum.Milk);
          return container;
        });
        this.addPriceCardTemplateModel.primaryMilkType = milkList.filter(a => a.isPrimary).map(a => a.detailMasterDataId)[0];
        this.addPriceCardTemplateFormGroup.controls["primaryMilkType"].markAsTouched();
        this.addPriceCardTemplateFormGroup.controls["primaryCoffeeType"].markAsTouched();
        this.addPriceCardTemplateFormGroup.controls["templateName"].disable();
        this.addPriceCardTemplateFormGroup.patchValue(
          this.addPriceCardTemplateModel
        );
        this.getDrinkList();
      }
    }, (err) => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    },
      () => {
      });
    this.addPriceCardTemplateFormGroup = this.formBuilder.formGroup(this.addPriceCardTemplateModel) as RxFormGroup;
    this.isFormLoad = true;
  }

  onMasterAllChange(objectValue: any, type: number) {
    objectValue.forEach(element => {
      this.onMasterSelect(element, type);
    });
  }
  onMasterDeselect(objectValue: any, type: number) {
    if (objectValue != undefined) {
      switch (type) {
        case MasterLookupEnum.Coffee:
          {
            if (objectValue.masterDataId == this.addPriceCardTemplateFormGroup.controls['primaryCoffeeType'].value)
              this.addPriceCardTemplateFormGroup.controls['primaryCoffeeType'].reset();
          }
          break;
        case MasterLookupEnum.Milk:
          {
            if (objectValue.masterDataId == this.addPriceCardTemplateFormGroup.controls['primaryMilkType'].value)
              this.addPriceCardTemplateFormGroup.controls['primaryMilkType'].reset();
          }
          break;
      }
    }
  }
  removeMaster(objectValue: any, type: number,cupType : string = '') {
    let currentObject;
    let formControlValue;
    if (objectValue != undefined) {
      switch (type) {
        case MasterLookupEnum.Market:
          formControlValue = this.addPriceCardTemplateFormGroup.controls['marketList'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            formControlValue.splice(currentObject, 1);
            this.addPriceCardTemplateFormGroup.patchModelValue({ 'marketList': formControlValue })
          }
          break;
        case MasterLookupEnum.Drink:
          if(cupType == 'Plastic'){
            formControlValue = this.addPriceCardTemplateFormGroup.controls['plasticCupdrinkList'].value;
            currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
            if (currentObject >= 0) {
              formControlValue.splice(currentObject, 1);
              this.addPriceCardTemplateFormGroup.patchModelValue({ 'plasticCupdrinkList': formControlValue })
            }
          } else {
            formControlValue = this.addPriceCardTemplateFormGroup.controls['waffleCupdrinkList'].value;
            currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
            if (currentObject >= 0) {
              formControlValue.splice(currentObject, 1);
              this.addPriceCardTemplateFormGroup.patchModelValue({ 'waffleCupdrinkList': formControlValue })
            }
        }
        this.getDrinkList();
        this.addDrink();
          break;
        case MasterLookupEnum.CupSize:
          formControlValue = this.addPriceCardTemplateFormGroup.controls['cupSizeList'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            formControlValue.splice(currentObject, 1);
            this.addPriceCardTemplateFormGroup.patchModelValue({ 'cupSizeList': formControlValue })
          }
          break;
        case MasterLookupEnum.Coffee:
          formControlValue = this.addPriceCardTemplateFormGroup.controls['coffeeTypeList'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            formControlValue.splice(currentObject, 1);
            this.addPriceCardTemplateFormGroup.patchModelValue({ 'coffeeTypeList': formControlValue })
            this.onMasterDeselect(objectValue, MasterLookupEnum.Coffee);
          }
          break;
        case MasterLookupEnum.Milk:
          formControlValue = this.addPriceCardTemplateFormGroup.controls['milkTypeList'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            formControlValue.splice(currentObject, 1);
            this.addPriceCardTemplateFormGroup.patchModelValue({ 'milkTypeList': formControlValue })
            this.onMasterDeselect(objectValue, MasterLookupEnum.Milk);
          }
          break;
        case MasterLookupEnum.Syrup:
          formControlValue = this.addPriceCardTemplateFormGroup.controls['syrupList'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            formControlValue.splice(currentObject, 1);
            this.addPriceCardTemplateFormGroup.patchModelValue({ 'syrupList': formControlValue })
          }
          break;
      }
    }
  }
  onMasterSelect(objectValue: any, type: number) {
    if (objectValue != undefined) {
      switch (type) {
        case MasterLookupEnum.Coffee:
          this.addPriceCardTemplateFormGroup.controls['primaryCoffeeType'].markAsTouched();
          break;
        case MasterLookupEnum.Milk:
          this.addPriceCardTemplateFormGroup.controls['primaryMilkType'].markAsTouched();
          break;
      }
    }
  }
  savePriceCardTemplate() {
    if(this.plasticCupDrinkList.length > 0 || this.waffleCupDrinkList.length > 0){
      this.addPriceCardTemplateFormGroup.controls["cupType"].clearValidators();
      this.addPriceCardTemplateFormGroup.controls["cupType"].updateValueAndValidity();
    } else {
      let validations = [];
      validations.push(Validators.required);
      this.addPriceCardTemplateFormGroup.controls["plasticCupdrinkList"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["waffleCupdrinkList"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["drinkList"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["cupType"].setValidators(validations);
      this.addPriceCardTemplateFormGroup.controls["cupType"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["cupType"].updateValueAndValidity();
    }
    this.addPriceCardTemplateFormGroup.markAllAsTouched();
    if(this.addPriceCardTemplateFormGroup.status === 'INVALID' || this.addPriceCardTemplateFormGroup.value['marketList']?.length == 0 || this.addPriceCardTemplateFormGroup.value['marketList']?.length == 0 || (this.addPriceCardTemplateFormGroup.value['plasticCupdrinkList']?.length == 0 && this.addPriceCardTemplateFormGroup.value['waffleCupdrinkList']?.length == 0) || this.addPriceCardTemplateFormGroup.value['cupSizeList']?.length == 0 || this.addPriceCardTemplateFormGroup.value['milkTypeList']?.length == 0 || this.addPriceCardTemplateFormGroup.value['coffeeTypeList']?.length == 0){
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      
    }else{
    this.spinner.show();
    let savePriceCardRequestTemplateModel: SavePriceCardRequestTemplateModel = new SavePriceCardRequestTemplateModel();
    let addPriceCardValue = this.addPriceCardTemplateFormGroup.value;
    savePriceCardRequestTemplateModel.templateName = addPriceCardValue.templateName;
    if (addPriceCardValue.marketList && addPriceCardValue.marketList.length > 0)
      savePriceCardRequestTemplateModel.marketId = addPriceCardValue.marketList[0]['masterDataId'];
      var lst = [];
      savePriceCardRequestTemplateModel.drinkList = [];
    if (addPriceCardValue.plasticCupdrinkList && addPriceCardValue.plasticCupdrinkList.length > 0){
      for(var i=0 ; i < addPriceCardValue.plasticCupdrinkList.length ; i++){
        var item = {"drinkName": addPriceCardValue.plasticCupdrinkList[i].masterDataId,"cupType": "Plastic"};
        lst.push(item);
      }
    }
    if (addPriceCardValue.waffleCupdrinkList && addPriceCardValue.waffleCupdrinkList.length > 0){
      for(var i=0 ; i < addPriceCardValue.waffleCupdrinkList.length ; i++){
        var item = {"drinkName": addPriceCardValue.waffleCupdrinkList[i].masterDataId,"cupType": "Waffle"};
        lst.push(item);
      }
    }
    savePriceCardRequestTemplateModel.drinkList = lst;
    if (addPriceCardValue.cupSizeList && addPriceCardValue.cupSizeList.length > 0)
      savePriceCardRequestTemplateModel.cupSizeList = addPriceCardValue.cupSizeList.map(a => a.masterDataId);
    if (addPriceCardValue.milkTypeList && addPriceCardValue.milkTypeList.length > 0)
      savePriceCardRequestTemplateModel.milkTypeList = addPriceCardValue.milkTypeList.map(a => a.masterDataId);
    if (addPriceCardValue.coffeeTypeList && addPriceCardValue.coffeeTypeList.length > 0)
      savePriceCardRequestTemplateModel.coffeeTypeList = addPriceCardValue.coffeeTypeList.map(a => a.masterDataId);
    if (addPriceCardValue.syrupList && addPriceCardValue.syrupList.length > 0)
      savePriceCardRequestTemplateModel.syrupList = addPriceCardValue.syrupList.map(a => a.masterDataId);
    savePriceCardRequestTemplateModel.primaryMilkType = addPriceCardValue.primaryMilkType;
    savePriceCardRequestTemplateModel.primaryCoffeeType = addPriceCardValue.primaryCoffeeType;
    savePriceCardRequestTemplateModel.templateType = this.priceCardTypeLookupConstant.Normal;
    if (this.drinkGroupTemplateId == undefined || this.drinkGroupTemplateId == "") {
      savePriceCardRequestTemplateModel.isActive = true;
      this.priceCardTemplateService.savePriceCardTemplate(savePriceCardRequestTemplateModel).subscribe(response => {
        this.toaster.open({
          text: "Price Card Template created successfully",
          type: 'success',
          position: 'top-right',
          duration: 10000
        });
        this.router.navigate(['/price-card-template']);
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      });
    } else {
      savePriceCardRequestTemplateModel.templateName = this.addPriceCardTemplateModel.templateName;
      savePriceCardRequestTemplateModel.isActive = addPriceCardValue.isActive;
      this.priceCardTemplateService.updatePriceCardTemplate(this.drinkGroupTemplateId, savePriceCardRequestTemplateModel).subscribe(response => {
        this.toaster.open({
          text: "Price Card Template updated successfully.",
          type: 'success',
          position: 'top-right',
          duration: 10000
        });
        this.router.navigate(['/price-card-template']);
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      });
    }
  }
  }
  cancelPriceCardTemplate() {
    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
      deviceName: '',
      status: '',
      title: 'Confirmation',
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }
  onConfirm() {
    this.dialogRef.close();
    this.router.navigate(['/price-card-template']);
  }
  onCancel() {
    this.dialogRef.close();
  }

  getDrinkList(){
    let addPriceCardValue = this.addPriceCardTemplateFormGroup.value;
    if(addPriceCardValue.cupType  != ''){
      this.waffletCupDrpDrinkList = [];
      this.plasticCupDrpDrinkList = [];
      if(addPriceCardValue.cupType  != 'Plastic'){
        for(var i=0 ; i<this.OriginaldrinkList.length ; i++){
          if(addPriceCardValue.plasticCupdrinkList != null && addPriceCardValue.plasticCupdrinkList.length > 0){
            var index = addPriceCardValue.plasticCupdrinkList.findIndex(x=>x.masterDataId == this.OriginaldrinkList[i].masterDataId);
            if(index == -1){
              this.waffletCupDrpDrinkList.push(this.OriginaldrinkList[i]);
            }
          } else {
            this.waffletCupDrpDrinkList = this.OriginaldrinkList;
          }
        }
      } else {
        for(var i=0 ; i<this.OriginaldrinkList.length ; i++){
          if(addPriceCardValue.waffleCupdrinkList != null && addPriceCardValue.waffleCupdrinkList.length > 0){
            var index = addPriceCardValue.waffleCupdrinkList.findIndex(x=>x.masterDataId == this.OriginaldrinkList[i].masterDataId);
            if(index == -1){
              this.plasticCupDrpDrinkList.push(this.OriginaldrinkList[i]);
            }
          } else{
            this.plasticCupDrpDrinkList = this.OriginaldrinkList;
          }
        }
      }
    }
  }

  onDrinkChange(event,isSelect){
    let addPriceCardValue = this.addPriceCardTemplateFormGroup.value;
    if(addPriceCardValue.cupType  != ''){
      if(isSelect){
        if(addPriceCardValue.cupType  == 'Plastic'){
          if(addPriceCardValue.plasticCupdrinkList.length != 0){
            if(addPriceCardValue.plasticCupdrinkList.filter(x=>x.masterDataId == event.masterDataId).length == 0){
              addPriceCardValue.plasticCupdrinkList.push(event);
            }
          } else {
            addPriceCardValue.plasticCupdrinkList.push(event);
          }
        } else {
          if(addPriceCardValue.waffleCupdrinkList.length != 0){
            if(addPriceCardValue.waffleCupdrinkList.filter(x=>x.masterDataId == event.masterDataId).length == 0){
              addPriceCardValue.waffleCupdrinkList.push(event);
            }
          } else {
            addPriceCardValue.waffleCupdrinkList.push(event);
          }
        }
      } else {
        if(addPriceCardValue.cupType  == 'Plastic'){
            for (var i =0;i< addPriceCardValue.plasticCupdrinkList.length; i++) {
              if (addPriceCardValue.plasticCupdrinkList[i].masterDataId === event.masterDataId){
                addPriceCardValue.plasticCupdrinkList.splice(i,1);
                break;
              }
            }
        } else {
          for (var i =0;i< addPriceCardValue.waffleCupdrinkList.length; i++) {
            if (addPriceCardValue.waffleCupdrinkList[i].masterDataId === event.masterDataId){
              addPriceCardValue.waffleCupdrinkList.splice(i,1);
              break;
            }
          }
        }
      }
    }
  }

  addDrink(fromUI=false){
    if(fromUI && !this.addPriceCardTemplateFormGroup.controls['cupType'].value){
      let validations = [];
      validations.push(Validators.required);
      this.addPriceCardTemplateFormGroup.controls["cupType"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["cupType"].setValidators(validations);
      this.addPriceCardTemplateFormGroup.controls["cupType"].updateValueAndValidity();
    }else{

      let addPriceCardValue = this.addPriceCardTemplateFormGroup.value;
      this.plasticCupDrinkList = [];
      this.waffleCupDrinkList = [];
      if(addPriceCardValue.plasticCupdrinkList.length != 0){
        for (var i =0;i< addPriceCardValue.plasticCupdrinkList.length; i++) {
          this.plasticCupDrinkList.push(addPriceCardValue.plasticCupdrinkList[i]);
        }
        
        if(this.addPriceCardTemplateFormGroup.controls['cupType'].value == 'Plastic'){
          this.addPriceCardTemplateFormGroup.controls['cupType'].patchValue('');
        }
      }
      if(addPriceCardValue.waffleCupdrinkList.length != 0){
        for (var i =0;i< addPriceCardValue.waffleCupdrinkList.length; i++) {
          this.waffleCupDrinkList.push(addPriceCardValue.waffleCupdrinkList[i]);
        }
        if(this.addPriceCardTemplateFormGroup.controls['cupType'].value == 'Waffle'){
          this.addPriceCardTemplateFormGroup.controls['cupType'].patchValue('');
        }
      }   
    
    if(this.plasticCupDrinkList.length > 0 || this.waffleCupDrinkList.length > 0){
      this.addPriceCardTemplateFormGroup.controls["cupType"].clearValidators();
      this.addPriceCardTemplateFormGroup.controls["cupType"].updateValueAndValidity();
    } else {
      let validations = [];
      validations.push(Validators.required);
      this.addPriceCardTemplateFormGroup.controls["plasticCupdrinkList"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["waffleCupdrinkList"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["drinkList"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["cupType"].setValidators(validations);
      this.addPriceCardTemplateFormGroup.controls["cupType"].markAsTouched();
      this.addPriceCardTemplateFormGroup.controls["cupType"].updateValueAndValidity();
    }
    }
  }

}

