import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-body-remote-update",
  templateUrl: "./body-remote-update.component.html",
  styleUrls: ["./../../remote-update-tasks.component.css"],
})
export class BodyRemoteUpdateComponent implements OnInit {
  ngOnInit(): void {}

  openFilter() {}

}
