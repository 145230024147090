import { Component, OnInit, Output, ViewChild, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { GetPackageSelectList, PackageSelectItem } from 'src/app/components/remote-update-tasks/dtos/get-package-select-list.model';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { GetPackageService } from 'src/app/components/remote-update-tasks/services/package/get-package.service';
import { Subscription } from 'rxjs';
import { CcDialogService } from '../../../../../../../services/ui/cc-dialog.service';

@Component({
  selector: 'app-task-package',
  templateUrl: './task-package.component.html',
  styleUrls: ['./../../new-task-step-common.css', './task-package.component.css'],
})
export class TaskPackageComponent implements OnInit, OnDestroy {
  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPackageEvent: EventEmitter<PackageSelectItem> = new EventEmitter<PackageSelectItem>();

  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  getPackageSelectList: GetPackageSelectList = { packages: [] };
  filteredPackages: PackageSelectItem[] = [];
  formSubs: Subscription = new Subscription();

  packageFormGroup: FormGroup = new FormGroup({
    packageSearch: new FormControl(
      { value: '', disabled: false },
      [
        Validators.required,
      ]),
    package: new FormControl(
      { value: null, disabled: false },
      [
        Validators.required,
      ]),
  });

  selectedTaskType: RemoteUpdateTaskType;
  selectedPropositionId = '';

  showSnackBar = false;
  snackBarHeader = '';
  snackBarMessage = '';

  get packageSearchField(): FormControl {
    return this.packageFormGroup.get('packageSearch') as FormControl;
  }

  constructor(
    private packageService: GetPackageService,
    private spinner: NgxSpinnerService,
    private dialogService: CcDialogService,
  ) {
  }

  ngOnInit(): void {
    this.loadPackage();
    this.formSubs.add(
      this.packageSearchField.valueChanges.subscribe(
        (val: string | PackageSelectItem) => {
          if (!val) {
            this.filteredPackages = this.getPackageSelectList.packages;
          } else {
            const valLower = (typeof val === 'string') ? val.toLowerCase() : (`${val.packageName} - ${val.packageVersion}`).toLowerCase();
            this.filteredPackages = this.getPackageSelectList.packages.filter(packageRow => {
              return `${packageRow.packageName} - ${packageRow.packageVersion}`.toLowerCase().includes(valLower);
            });
          }
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.formSubs.unsubscribe();
  }

  nextStep(): void {
    this.packageFormGroup.markAllAsTouched();
    if (this.packageFormGroup.valid) {
      const packageItem: PackageSelectItem = this.packageFormGroup.get('package').value;
      this.newTaskRequest.Package = `${packageItem.packageName} - ${packageItem.packageVersion}`;
      this.newTaskRequest.PackageId = packageItem.packageId;
      this.nextStepEvent.emit();
    } else {
      this.packageFormGroup.markAllAsTouched();
      const { key, component } = this.dialogService.openDialog();
      component.instance.title = 'Selection error';
      component.instance.description = 'Please complete all the required fields';
      component.instance.primary = 'Ok';
      const dialogSubs = new Subscription();
      dialogSubs.add(
        component.instance.primaryClick.subscribe(() => {
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }),
      );
    }
  }

  backStep(): void {
    this.backStepEvent.emit();
  }

  loadPackage(): void {
    this.showSnackBar = false;
    if (this.isTaskRequestLoaded() && this.taskRequestHasChanges()) {
      this.selectedPropositionId = this.newTaskRequest.PropositionTypeId;
      this.selectedTaskType = this.newTaskRequest.TaskType;

      this.spinner.show();

      this.packageService.getRemoteUpdatePackageList(
        this.selectedTaskType,
        this.selectedPropositionId,
      ).subscribe(
        data => {
          this.spinner.hide();
          this.getPackageSelectList = data;
          this.filteredPackages = this.getPackageSelectList.packages;
          this.showSnackBar = false;
          this.showNoPackagesMessage();
        },
        (err) => {
          this.showSnackBar = true;
          this.snackBarMessage = 'Something went wrong with your request, try again in a few minutes';
          this.spinner.hide();
        },
      );
    } else {
      this.showNoPackagesMessage();
    }
  }

  closeSnackBar(): void {
    this.showSnackBar = false;
  }

  private showNoPackagesMessage(): void {
    if (this.getPackageSelectList.packages.length === 0) {
      this.showSnackBar = true;
      this.snackBarMessage = 'There are no packages available for selection.';
    }
  }

  private isTaskRequestLoaded(): boolean {
    return !!this.newTaskRequest?.PropositionTypeId || !!this.newTaskRequest?.TaskType;
  }

  private taskRequestHasChanges(): boolean {
    return this.selectedPropositionId !== this.newTaskRequest.PropositionTypeId || this.selectedTaskType !== this.newTaskRequest.TaskType;
  }

  displayPackageFn(packageItem: PackageSelectItem): string {
    if (!packageItem) {
      return '';
    }
    return `${packageItem.packageName} - ${packageItem.packageVersion}`;
  }

  autocompleteClose(): void {
    const search = this.packageSearchField.value;
    if (this.isPackage(search)) {
      this.packageFormGroup.get('package').setValue(search);
    } else {
      const packageFound = this.filteredPackages.find(obj => {
        return `${obj.packageName} - ${obj.packageVersion}`.toLowerCase() === search.toLowerCase();
      });
      if (!!packageFound) {
        this.packageSearchField.setValue(packageFound);
        this.packageFormGroup.get('package').setValue(packageFound);
      } else {
        this.packageSearchField.setValue('');
        this.packageFormGroup.get('package').setValue(null);
      }
    }
  }

  private isPackage(object: any): object is PackageSelectItem {
    if (typeof object !== 'object' || object === null) {
      return false;
    }
    return 'packageId' in object
      && 'packageName' in object
      && 'packageVersion' in object
      && 'minimalVersion' in object;
  }
}
