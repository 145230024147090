import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {
  LookupModelList
} from 'src/app/components/pricecard-template/pricecard-template-add/pricecard-template-add.model';
import {EntityResponseList} from '../models/drinks-setup-entity-level.model';
import {DrinksSetupEntityLevel} from '../enum/drinks-setup-entity-level.enum';
import {MasterLookupEnum} from "../../../enums/masterlookup.enum";
import {RequestAddDrinkGroupTemplate, RequestEditDrinkGroupTemplate, ResponseGetDrinkGroupTemplate} from "./request-add-drink-group-template";

declare type UUID = string;
declare type MasterDataField =
  "masterDataId" |
  "marketId" |
  "text" |
  "value" |
  "isActive" |
  "createdBy" |
  "createdDate" |
  "drinkType" |
  "hasMilk" |
  "isShort" |
  "hasSyrup" |
  "temperature" |
  "defaultEatInCupType";

@Injectable({
  providedIn: 'root'
})
export class DrinkSetupService {
  cookieName = environment.cookieName;

  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {
  }

  getMasterLookup(configurationType: MasterLookupEnum, orderBy: MasterDataField = "text"): Observable<LookupModelList> {
    let configHeader: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      })
    };
    let lookUpObject: any = {
      "search": "",
      "configurationType": configurationType,
      "pageNo": 1,
      "pageSize": -1,
      "orderBy": orderBy
    }
    return this.httpClient.post<LookupModelList>(environment.baseUrl + '/api/v1/master/masterConfiguration', lookUpObject, configHeader)
  }

  saveDrinkSetup(body: RequestAddDrinkGroupTemplate): Observable<UUID> {
    let configHeader: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      })
    };
    return this.httpClient.post<UUID>(environment.baseUrl + '/api/v2/drinkgrouptemplate', body, configHeader);
  }

  getDrinkSetup(drinkTemplateId: UUID): Observable<ResponseGetDrinkGroupTemplate> {
     let configHeader: { headers: HttpHeaders } = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
       })
     };
     return this.httpClient.get<ResponseGetDrinkGroupTemplate>(`${environment.baseUrl}/api/v2/drinkgrouptemplate/${drinkTemplateId}`, configHeader);
  }

  editDrinkSetup(body: RequestEditDrinkGroupTemplate): Observable<boolean> {
    let configHeader: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      })
    };
    return this.httpClient.put<any>(`${environment.baseUrl}/api/v2/drinkgrouptemplate`, body, configHeader);
  }

  getLevels(entityLevel: DrinksSetupEntityLevel, parentId?: string): Observable<EntityResponseList> {
    let httpOptions: { headers: HttpHeaders; params: {} } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }),
      params: {}
    };

    if (parentId != '' && parentId != null) {
      httpOptions.params['parent'] = parentId;
    }

    return this.httpClient.get<EntityResponseList>(environment.baseUrl + '/api/v2/entity/level/'+ entityLevel.toString(), httpOptions);
  }
}
