import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';

@Component({
  selector: 'app-price-card-syrups-pricing',
  templateUrl: './price-card-syrups-pricing.component.html',
  styleUrls: ['./price-card-syrups-pricing.component.css']
})
export class PriceCardSyrupsPricingComponent implements OnInit {
  @Output() clickButton = new EventEmitter<any>();
  drinks = [];
  cupSizes = [];

  selectedDrinks = [];

  displayedColumns: string[] = ['drinkGroupTemplateCupDetailName', 'price'];
  dataSource: any = [];
  syrups = [];
  primaryCoffeeDetails: any = {};
  primaryMilkDetails: any = {};
  isTouchedSubmit = false;
  priceFocusIn = {};
  constructor(
    public priceCardService: PriceCardService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getSyrupLookup();
    this.getPriceCardDetails();
  }
  getSyrupLookup() {
    let templateId = this.priceCardService.priceCardDetails.drinkGroupTemplateId;
    this.priceCardService.getwrapperdetails(this.priceCardService.priceCardDetails.priceCardVersionId, { type: 'syrup' }).subscribe(response => {
      this.syrups = response['data']['drinkDetails'];
    });
  }

  getPriceCardDetails() {
    let priceCardVersionId = this.priceCardService.priceCardDetails.priceCardVersionId;
    this.priceCardService.getPriceCardDetails(priceCardVersionId, 'syrup').subscribe(response => {
      if (response) {
        this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = response['data'];
        
        this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList = this.sortByKey(this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList, 'sequenceNumber');//for sorting                
        this.priceCardService.setSyrupGroup();
      }
    });
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }  

  SyrupGroupsListValid(): Boolean {
    let isFoundInValidPrice = this.priceCardService.syrupGroup.filter(el => el.price == '');
    return isFoundInValidPrice.length >= 0;
  }
  submitForm(type) {
    this.isTouchedSubmit = true;
    if (!this.syrups.length || !this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.length || type != 'next') {
      this.clickButton.next({ stepKey: 'syrups-pricing', buttonType: type });
    } else {
      if(!this.SyrupGroupsListValid()){
        this.toaster.open({
         text: 'Please complete pricing for highlighted Syrup Cup Size(s)',
         type: 'danger',
         position: 'top-right',
         duration: 10000
       });
      }else{
        if (this.priceCardService.drinkGroupTemplatePropositionType === 'ConcessionSpecific') {
          this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList.forEach(element => {
            element.price = this.priceCardService.syrupGroup[0].price;
          });
        }
      let body = {
        "priceCardVersionId": this.priceCardService.priceCardDetails.priceCardVersionId,
        "detailType": "syrup",
        "priceCardDetails": this.priceCardService.priceCardDetails.drinkGroups.syrupGroupsList
      }
      this.spinner.show();
      this.priceCardService.addPriceCardDetails(body).subscribe(response => {
        this.spinner.hide();
        this.getPriceCardDetails();
        this.clickButton.next({ stepKey: 'syrups-pricing', buttonType: type });
      }, err => {
        if (err && err.error) {
          this.spinner.hide();
          let errors = err.error;
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
            if(element.msg == "Oops! Something went wrong."){
              this.router.navigate(['/price-card']);
            }
          });
        }
      });
    }
    }
  }
  onPriceFocusin(i) {
    if(this.priceCardService.syrupGroup[i]['price']){
      
      this.priceFocusIn[i]= this.priceCardService.syrupGroup[i]['price'];
      
      this.priceCardService.syrupGroup[i]['price']=this.priceCardService.syrupGroup[i]['price'].replace(/\./g,'')
      this.priceCardService.syrupGroup[i]['price'] = this.priceCardService.syrupGroup[i]['price'].replace(/^0+/, '');
    }
  }
  onPriceFocusout(i) {    
    this.priceCardService.syrupGroup[i]['touched'] = true;
    if (this.priceCardService.syrupGroup[i]['price']) {
      this.priceCardService.syrupGroup[i]['price'] = this.priceCardService.syrupGroup[i]['price']/100;
        
      this.priceCardService.syrupGroup[i]['price'] = parseFloat(this.priceCardService.syrupGroup[i]['price']).toFixed(2);
    }
  }
  clickPrice(index){
    index++;
    if(this.priceCardService.syrupGroup.length != index)
      document.getElementById("TextBox-syrup-price-"+index).focus();    
  }
  removeSyrup(i){
    let syrupGroupsList = this.priceCardService.syrupGroup;
    syrupGroupsList.splice(i, 1);
     this.spinner.show();
     this.priceCardService.syrupGroup = [];
     setTimeout(() => {
       this.priceCardService.syrupGroup = syrupGroupsList;
       setTimeout(() => {
         this.spinner.hide();
       }, 100);
     }, 10);
  }
}













