import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

@Injectable()
export default class UnsavedChangesCanDeactivateGuard implements CanDeactivate<any> {
  canDeactivate(component: {
    unsavedCheck: (proceed: Function) => Promise<boolean>;
  }, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    return component.unsavedCheck ? !component.unsavedCheck(function (self: any) { self.router.navigate([nextState.url]) }) : true;
  }
}
