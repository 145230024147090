import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, Subject} from "rxjs";
import {delay, distinctUntilChanged, filter, map, skipWhile, startWith, switchMap, takeUntil, tap} from "rxjs/operators";
import {DrinksSetupEntityLevel} from "../../enum/drinks-setup-entity-level.enum";
import {DrinkSetupService} from "../../services/drink-setup.service";
import {SelectedMarketCountry} from "./selected-market-country";
import {SelectOption} from "./select-option";
import {SelectedItem} from "./selected-item";
import { SelectComponent } from '@costa-coffee/pattern-lib';
import { RequestGetDrinkGroupTemplate } from '../../services/request-add-drink-group-template';

@Component({
  selector: 'app-market-country-selection',
  templateUrl: './market-country-selection.component.html',
  styleUrls: []
})
export class MarketCountrySelectionComponent implements OnInit, OnDestroy, OnChanges {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  protected readonly marketErrorMessage: string = 'This field is required.';
  protected readonly FormControl = FormControl;

  // Source Streams
  readonly selectedMarket$: ReplaySubject<SelectedItem> = new ReplaySubject<SelectedItem>(1);
  readonly selectedLevel2$: ReplaySubject<SelectedItem> = new ReplaySubject<SelectedItem>(1);
  readonly selectedLevel3$: ReplaySubject<SelectedItem> = new ReplaySubject<SelectedItem>(1);
  readonly marketDropdownVisibility$: ReplaySubject<VisibilityState> = new ReplaySubject<VisibilityState>(1);
  readonly whenValidationRequested$: Subject<boolean> = new Subject<boolean>();

  // Presentation Streams
  protected marketOptions$: Observable<SelectOption[]>;
  protected level2Options$: Observable<SelectOption[]>;
  protected level3Options$: Observable<SelectOption[]>;
  protected showMarketError$: Observable<boolean>;

  // Elements
  @ViewChild("martket") selMarket: SelectComponent;
  @ViewChild("level2") sellevel2: SelectComponent;
  @ViewChild("level3") sellevel3: SelectComponent;

  // Output Streams
  @Output('selection-changed') selectionChanged: EventEmitter<SelectedMarketCountry> = new EventEmitter<SelectedMarketCountry>();
  protected readonly isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() drinkGroupTemplateForEdit: RequestGetDrinkGroupTemplate = null;
  protected isEdit = false;

  constructor(private drinkSetupService: DrinkSetupService) {
  }

  ngOnInit(): void {
    // Operators
    const getEntities = (entityLevel: DrinksSetupEntityLevel, parentId?: string) =>
      this.drinkSetupService.getLevels(entityLevel, parentId).pipe(
        map(value => value.data.map(x => new SelectOption(x.name, x.entityId, !x.isActive)))
      );

    const isMarketCountriesEqual = (x: SelectedMarketCountry, y: SelectedMarketCountry): boolean =>
      x.market?.id == y.market?.id && x.level2?.id == y.level2?.id && x.level3?.id == y.level3?.id;

    // Intermediate Streams
    // --------------------
    const marketIsValid$ = this.selectedMarket$.pipe(
      map(v => v.id != ''),
      startWith(false)
    );

    const marketDropdownClose$ = this.marketDropdownVisibility$.pipe(
      skipWhile(v => v != "visible"),
      filter(v => v == "hidden"),
      map(() => true)
    );

    const marketIsValidAfterCloseOrRequest$ = combineLatest([
      marketIsValid$,
      merge(marketDropdownClose$, this.whenValidationRequested$)
    ]).pipe(
      map(([v1,]) => v1),
      distinctUntilChanged()
    );

    // Presentation Streams
    // --------------------
    this.marketOptions$ = getEntities(DrinksSetupEntityLevel.Market);     

    this.level2Options$ = this.selectedMarket$.pipe(
      switchMap(value => value.id != ''
        ? getEntities(DrinksSetupEntityLevel.Level2, value.id)
        : [])
    );

    this.level3Options$ = this.selectedLevel2$.pipe(
      switchMap(value => value.id != ''
        ? getEntities(DrinksSetupEntityLevel.Level3, value.id)
        : of([]))
    );    

    this.showMarketError$ = marketIsValidAfterCloseOrRequest$.pipe(
      map(v => !v),
      startWith(false)
      );

      // Output Streams
      // --------------
    combineLatest([
      this.selectedMarket$.pipe(map(x => x.id != '' ? x : null)),
      this.selectedLevel2$.pipe(map(x => x.id != '' ? x : null)),
      this.selectedLevel3$.pipe(map(x => x.id != '' ? x : null))
    ])
      .pipe(
        map(([v1, v2, v3]) => new SelectedMarketCountry(v1, v2, v3)),
        distinctUntilChanged(isMarketCountriesEqual),
        takeUntil(this.destroy$))
      .subscribe(this.selectionChanged);
      
    marketIsValid$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$))
        .subscribe(this.isValid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.drinkGroupTemplateForEdit && changes.drinkGroupTemplateForEdit.currentValue) {
      this.isEdit = true;
    }
  }
  
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  
  validate(): boolean {
    this.whenValidationRequested$.next(true);
    return this.isValid.value;
  }
}
