import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { GetRemoteUpdateMachineList } from '../../dtos/get-remote-update-machine-list.model';
import { GetRemoteUpdateMachineIdList } from '../../dtos/get-remote-update-machine-id-list.model';
import { RemoteUpdateTaskLevel } from '../../dtos/remote-update-task-level';
import { RemoteUpdateTaskMachine } from '../../dtos/remote-update-task-machine';
import { RemoteUpdateTaskMachineType } from '../../dtos/remote-update-task-machine';
import { Observable } from 'rxjs/internal/Observable';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
	providedIn: 'root'
})
export class RemoteUpdateMachineGetListService {

	constructor(
		private httpClient: HttpClient,
		private cookieService: CookieService,
	) { }

	cookieName: string = environment.cookieName

	getRemoteMachineUpdate<T extends GetRemoteUpdateMachineList | GetRemoteUpdateMachineIdList>(paramsObject: RemoteUpdateTaskMachine): Observable<T> {
		let params = new HttpParams();
		params = paramsObject.page !== null ? params.append("page", paramsObject.page .toString()) : params;
		params = paramsObject.limit !== null ? params.append("limit", paramsObject.limit.toString()) : params;
		params = this.adjustQueryParams(params, paramsObject.queryParams);

		const requestOptions = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token')
			  },
			params: params
		};

		var levelFilter = JSON.stringify(paramsObject.levels);
		var url ="";

		if(paramsObject.Type == RemoteUpdateTaskMachineType.GetAllCertificates) {
			url	="/v1/machines/getAllCertificates";
		}
		else{
			url = "/v1/machines/getall";
		}

		return this.httpClient
				   .post<T>(environment.apiUrl.remoteUpdate + url, levelFilter, requestOptions)
				   .pipe(map(response => {
						return response;
			     	}));
	}

	postRemoteMachineUpdate<T extends GetRemoteUpdateMachineList | GetRemoteUpdateMachineIdList>(paramsObject: RemoteUpdateTaskMachine): Observable<T> {
		let params = new HttpParams();
		params = paramsObject.page !== null ? params.append("page", paramsObject.page .toString()) : params;
		params = paramsObject.limit !== null ? params.append("limit", paramsObject.limit.toString()) : params;
		params = this.adjustQueryParams(params, paramsObject.queryParams);

		const requestOptions = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token')
			  },
			params: params
		};

		var levelFilter = JSON.stringify(paramsObject.levels);
		var url ="/v1/machines/getAllById";

		return this.httpClient
				   .post<T>(environment.apiUrl.remoteUpdate + url, levelFilter, requestOptions)
				   .pipe(map(response => {
						return response;
			     	}));
	}

	private adjustQueryParams(params: HttpParams, queryParams: Map<string, string>) {
		if (queryParams != null) {
			for (let query of queryParams) {
				params = query[1] !== null ? params.set(query[0], query[1]) : params;
			}
		}

		return params;
	}
}
