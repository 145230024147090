import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GetRemoteUpdateTaskType, Proposition } from 'src/app/components/remote-update-tasks/dtos/get-remote-update-task-type.model';
import { RemoteUpdateTaskTypePropositionGetListService } from 'src/app/components/remote-update-tasks/services/get-list/remote-update-taskType-proposition-get-list.service';
import { Router } from '@angular/router';
import { UploadComponent } from '../../../../../upload/upload.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreatePackageMetadataService } from 'src/app/components/remote-update-tasks/services/package/create-package-metadata.service';
import { UploadPackageFileService } from 'src/app/components/remote-update-tasks/services/package/upload-package-file.service';
import { CreatePackageMetadataRequest } from 'src/app/components/remote-update-tasks/dtos/create-package-metadata-request';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UploadPackageFileRequest } from 'src/app/components/remote-update-tasks/dtos/upload-package-file-request';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { CcDialogService } from '../../../../../../services/ui/cc-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-file-packages',
  templateUrl: './upload-file-packages.component.html',
  styleUrls: ['./upload-file-packages.component.css'],
})
export class UploadFilePackagesComponent implements OnInit {

  packageValidations: ValidatorFn[] = [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(50),
    Validators.pattern('^([0]|[1-9][0-9]{0,3})\\.([0]|[1-9][0-9]{0,3})\\.([0]|[1-9][0-9]{0,3})$'),
  ];

  public uploadFileFormGroup: FormGroup = new FormGroup({
    taskType: new FormControl({ value: '', disabled: false }, Validators.required),
    propositions: new FormControl({ value: '', disabled: false }, Validators.required),
    packageName: new FormControl({ value: '', disabled: false }, Validators.required),
    validFromDate: new FormControl({ value: '', disabled: false }, Validators.required),
    validFromTime: new FormControl({ value: '', disabled: false }, Validators.required),
    minimalVersion: new FormControl({ value: '', disabled: false }, this.packageValidations),
    packageVersion: new FormControl({ value: '', disabled: false }, this.packageValidations),
    file: new FormControl({ value: '', disabled: false }, Validators.required),
    notes: new FormControl({ value: '', disabled: false }, Validators.maxLength(1000)),
  });

  requiredFieldMessage: string = 'This field is required.';
  genericServiceErrorMessage: string = 'Something went wrong. Please retry shortly.';
  cookieName: string = environment.cookieName;

  tasks: { name: string; id: GetRemoteUpdateTaskType; isDisabled: boolean }[] = [];
  showTaskTypeError: boolean = false;

  propositions: Proposition[] = [];
  propositionsId: string[] = [];
  propositionsName: string[] = [];
  activeChips: string[] = null;

  packageNameValidVariant: string = 'default';

  propositionsShowMessage: boolean = false;
  propositionsValidVariant: string = 'default';
  propositionsIsDisabled: boolean = true;

  file: File;
  fileAllowedTypes: Array<string> = [];
  fileInputValidVariant: string = '';
  fileType: string = '';
  fileSize: number = 150;
  fileIsDisabled: boolean = true;
  fileHasError: boolean = false;
  fileErrorMessage: string = '';

  minimalVersionValidVariant = 'default';
  minimalVersionValidCustomMessages = 'Between 5 - 50 characters.';

  packageVersionValidVariant = 'default';
  packageVersionShowMessage = false;
  packageVersionValidCustomMessages = 'Between 5 - 50 characters.';

  textAreaValidVariant: string = 'default';

  showCcSnackbar: boolean = false;
  snackBarHeader: string = 'Selection error.';
  snackBarMessage: string = 'Please complete all the required fields.';

  @ViewChild(UploadComponent) uploadComponent: UploadComponent;

  constructor(
    public router: Router,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private remoteUpdateTaskTypePropositionService: RemoteUpdateTaskTypePropositionGetListService,
    private createPackageMetadataService: CreatePackageMetadataService,
    private uploadPackageFileService: UploadPackageFileService,
    private dialogService: CcDialogService,
  ) {
    const now = new Date();

    const currentDate = new Date().toISOString().split('T')[0];
    this.uploadFileFormGroup.get('validFromDate').setValue(currentDate);

    const currentTime = now.toTimeString().substring(0, 5);
    this.uploadFileFormGroup.get('validFromTime').setValue(currentTime);
  }

  ngOnInit(): void {
    this.getTaskType();
  }

  getTaskType() {
    this.spinner.show();
    const selectDefault = { id: undefined, name: 'Select...', isDisabled: false };
    this.tasks.push(selectDefault);

    this.remoteUpdateTaskTypePropositionService.getRemoteUpdateTaskTypeList(true).subscribe({
      next: (next) => (this.tasks = [selectDefault, ...next.map((x) => ({ id: x, name: x.name, isDisabled: false }))]),
      error: (error) => {
        console.log(error.message);
      },
      complete: () => {
        this.spinner.hide();
      },
    });
  }

  taskTypeValueChanged(value: { name: string; id: GetRemoteUpdateTaskType; index: number }): void {
    this.propositions = [];
    this.activeChips = null;
    this.uploadFileFormGroup.get('taskType').setValue('');
    this.uploadFileFormGroup.get('propositions').setValue('');
    this.uploadFileFormGroup.get('file').setValue('');
    this.uploadComponent.fileName = '';

    if (typeof value.id === 'undefined') {
      this.propositionsIsDisabled = true;
      this.fileIsDisabled = true;
      this.fileHasError = false;
      this.fileErrorMessage = '';
      return;
    }

    let taskType = value.id;
    if (!taskType.propositions) return;

    this.propositions = taskType.propositions;
    this.propositionsName = this.propositions.map((p) => p.name);
    this.propositionsId = this.propositions.map((p) => p.id);

    this.propositionsIsDisabled = false;
    this.activeChips = [];
    this.activeChips.push(this.propositionsName[0]);
    this.uploadFileFormGroup.get('taskType').setValue(taskType.name);
    this.uploadFileFormGroup.get('propositions').setValue(this.propositionsId);
    this.setAllowedFileType(taskType.name);
    this.validateTaskType();
  }

  private setAllowedFileType(taskType: string): void {
    if (taskType === 'Software Update') {
      this.fileAllowedTypes = ['.cup'];
      this.fileType = '.cup';
    } else if (taskType === 'Firmware Update') {
      this.fileAllowedTypes = ['.bin'];
      this.fileType = '.bin';
    }
    this.fileIsDisabled = false;
  }

  showYesNoDialog(): void {
    const {key, component} = this.dialogService.openDialog();
    component.instance.title = 'Cancel Upload';
    component.instance.description = 'Are you sure you want to cancel the upload?';
    component.instance.secondary = 'Yes';
    component.instance.primary = 'Ok';
    const dialogSubs = new Subscription();
    dialogSubs.add(
      component.instance.primaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
      })
    );
    dialogSubs.add(
      component.instance.secondaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
        this.router.navigate(['/task-management-overview/file-repository']);
      })
    );
  }

  closeSnackBar(): void {
    this.showCcSnackbar = false;
  }

  uploadPackage(): void {
    this.uploadFileFormGroup.markAllAsTouched();

    const validations = [
      this.validateTaskType.bind(this),
      this.validatePropositions.bind(this),
      this.validateFile.bind(this),
      this.validateNotes.bind(this),
      this.validatePackageName.bind(this),
      () => this.validatePackage('minimalVersion'),
      () => this.validatePackage('packageVersion')
    ];

    const hasValidationErrors = validations
      .map(isValid => !isValid())
      .includes(true);

    if (hasValidationErrors) {
      this.showCcSnackbar = true;
      return;
    }

    this.createPackage();
  }

  validateTaskType(): boolean {
    const input = this.uploadFileFormGroup.get('taskType');
    if (input.invalid && input.errors['required']) {
      this.showTaskTypeError = true;
      return false;
    }

    this.showTaskTypeError = false;
    return true;
  }

  validatePropositions(): boolean {
    this.uploadFileFormGroup.get('propositions').setValue(this.propositionsId);

    const input = this.uploadFileFormGroup.get('propositions');

    this.propositionsValidVariant = 'default';
    this.propositionsShowMessage = false;

    if (input.untouched || input.valid || input.errors == null || input.errors['required'] == null) return true;

    this.propositionsValidVariant = 'error';
    this.propositionsShowMessage = true;
    return false;
  }

  validatePackageName(): boolean {
    const input = this.uploadFileFormGroup.get('packageName');
    if (input.invalid && input.errors['required']) {
      this.packageNameValidVariant = 'error';
      this.packageVersionShowMessage = true;
      return false;
    }

    this.packageNameValidVariant = 'default';
    this.packageVersionShowMessage = false;
    return true;
  }

  validatePackage(inputName: string): boolean {
    let input = this.uploadFileFormGroup.get(inputName);
    let validVariant = 'default';
    let message = 'Between 5 - 50 characters.';

    if (input.invalid && input.errors != null) {
      validVariant = 'error';
      if (input.errors['required']) message = this.requiredFieldMessage;
      else if (input.errors['minlength']) message = 'Minimum 5 characters.';
      else if (input.errors['maxlength']) message = 'Maximum 50 characters.';
      else if (input.errors['pattern']) message = 'Please follow the specified format: 0.0.0';
    }

    if (inputName === 'minimalVersion') {
      this.minimalVersionValidCustomMessages = message;
      this.minimalVersionValidVariant = validVariant;
    } else if (inputName === 'packageVersion') {
      this.packageVersionValidCustomMessages = message;
      this.packageVersionValidVariant = validVariant;
    }

    if (validVariant === 'error') return false;

    return true;
  }

  validateFile(): boolean {
    const input = this.uploadFileFormGroup.get('file');
    if (input.invalid && input.errors['required']) {
      this.fileInputValidVariant = 'error';
      this.fileHasError = true;
      this.fileErrorMessage = this.requiredFieldMessage;
      return false;
    }

    this.fileInputValidVariant = 'default';
    this.fileHasError = false;
    this.fileErrorMessage = '';
    return true;
  }

  validateNotes(): boolean {
    const input = this.uploadFileFormGroup.get('notes');
    if (input.invalid && input.errors['maxlength']) {
      this.textAreaValidVariant = 'error';
      return false;
    }

    this.textAreaValidVariant = 'default';
    return true;
  }

  removePropositions(usedItems: string[]): void {
    this.propositions = this.propositions.filter((p) => usedItems.indexOf(p.name) > -1);
    this.propositionsName = this.propositions.map((p) => p.name);
    this.propositionsId = this.propositions.map((p) => p.id);

    this.activeChips = usedItems;
    this.uploadFileFormGroup.get('propositions').setValue(this.propositionsId);
    this.uploadFileFormGroup.get('propositions').markAsTouched();
    this.validatePropositions();
  }

  selectedFile(e: File): void {
    this.file = e;
  }

  createPackageMetadataRequest(userId: string): CreatePackageMetadataRequest {
    const validFrom = this.uploadFileFormGroup.get('validFromDate').value + 'T' + this.uploadFileFormGroup.get('validFromTime').value;
    const fileName = this.uploadComponent.fileName;

    const taskType = this.uploadFileFormGroup.get('taskType').value as string;
    const taskTypeEnum = taskType.replace(' ', '') as RemoteUpdateTaskType;

    return {
      UserId: userId,
      TaskType: taskTypeEnum,
      MinimalVersion: this.uploadFileFormGroup.get('minimalVersion').value,
      PackageVersion: this.uploadFileFormGroup.get('packageVersion').value,
      PackageName: this.uploadFileFormGroup.get('packageName').value,
      PropositionsId: this.uploadFileFormGroup.get('propositions').value,
      Notes: this.uploadFileFormGroup.get('notes').value,
      ValidFrom: validFrom,
      FileName: fileName,
    };
  }

  createUploadPackageFileRequest(userId: string, packageId: number): UploadPackageFileRequest {
    return {
      userId: userId,
      packageId: packageId,
      fileData: this.file,
    };
  }

  createPackage(): void {
    if (this.uploadFileFormGroup.valid) {
      this.uploadFileFormGroup.markAsUntouched();
      this.spinner.show();
      const userId = this.cookieService.get(this.cookieName + 'userId');

      var packageMetadataRequest = this.createPackageMetadataRequest(userId);
      this.snackBarHeader = '';

      this.createPackageMetadataService.Execute(packageMetadataRequest).subscribe({
        next: (next) => {
          if (!next.success) {
            this.spinner.hide();
            this.snackBarMessage = next.message;
            this.showCcSnackbar = true;
            return;
          }

          var uploadPackageFileRequest = this.createUploadPackageFileRequest(userId, next.id);
          this.uploadPackageFileService.Execute(uploadPackageFileRequest).subscribe({
            next: (next) => {
              if (!next.success) {
                this.spinner.hide();
                this.snackBarMessage = next.message;
                this.showCcSnackbar = true;
                return;
              }

              this.spinner.hide();
              this.router.navigate(['/task-management-overview/file-repository']);
              return;
            },
            error: (error) => {
              this.spinner.hide();
              if (error.error.message) this.snackBarMessage = error.error.message;
              else this.snackBarMessage = this.genericServiceErrorMessage;

              this.showCcSnackbar = true;
            },
          });
        },
        error: (error) => {
          this.spinner.hide();
          if (error.error.message) this.snackBarMessage = error.error.message;
          else this.snackBarMessage = this.genericServiceErrorMessage;

          this.showCcSnackbar = true;
        },
      });
      return;
    }
  }
}
