import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteUpdateTaskSearchService {
  private subject = new Subject<string>();


  sendSearchDatatable(text: string) {
    this.subject.next(text);
  }

  getSearchDatatableData() {
    return this.subject.asObservable();
  }
  constructor() { }
}
