import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from "@angular-material-components/datetime-picker";
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular-material-components/moment-adapter";
import { LabelType, Options } from "@angular-slider/ngx-slider";
import { ChangeDetectorRef, Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MAT_DATE_LOCALE, ThemePalette } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { AppConstant } from "src/app/app.constant";
import { AlarmEventCauseLostTradingHoursEnum } from 'src/app/enums/alarmEventCauseLostTradingHours.enum';
import { AlarmEventTypesEnum } from "src/app/enums/alarmEventTypes.enum";
import { AlarmListFilterEscalationLevelsEnum } from "src/app/enums/alarmListFilterEscalationLevels.enum";
import { AlarmListFilterStatusEnum } from "src/app/enums/alarmListFilterStatus.enum";
import { AlarmListFilterTypesEnum } from "src/app/enums/alarmListFilterTypes.enum";
import { EventFilterTypesEnum } from 'src/app/enums/eventFilterTypes.enum';
import { AlarmTemplateService } from 'src/app/services/alarm-template/alarm-template.service';
import { EntityService } from "src/app/services/entity/entity.service";
import { RoleService } from 'src/app/services/role/role.service';
import { UserService } from "src/app/services/user/user.service";
import { CustomNgxDatetimeAdapter, NgxMatMomentDateAdapterOptions } from "../../sox-audit-logs/sox-audit-logs-list/customngx.datetime.adapter";
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { I } from "@angular/cdk/keycodes";
declare var $: any;
const moment = _moment;
export const MY_FORMATS = {
  parse: {
  dateInput: 'DD/MM/YYYY HH:mm:ss',
  },
  display: {
  dateInput: 'DD/MM/YYYY HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
  },
  };
  const CustomNgxDatetimeAdapterFactory = () => {
  let _options: NgxMatMomentDateAdapterOptions = { strict: false, useUtc: true }
  return new CustomNgxDatetimeAdapter("", _options);
  };
@Component({
  selector: 'app-alarm-list',
  templateUrl: './alarm-list.component.html',
  styleUrls: ['./alarm-list.component.css'],
  providers: [
    {
    provide: NgxMatDateAdapter,
    //useClass: CustomNgxDatetimeAdapter,
    useFactory: CustomNgxDatetimeAdapterFactory,
    deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class AlarmListComponent implements OnInit {
  escalationDurationFrom: number = parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMin);
  escalationDurationTo: number = parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMax);
  options: Options = {
    floor: parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMin),
    ceil: parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMax),
    translate: (value: number, label: LabelType): string => {
      let totalMinutes = value*60;
      let days = Math.floor(totalMinutes / (3600*24));
      let hours = Math.floor(totalMinutes % (3600*24) / 3600);
      let minutes = Math.floor(totalMinutes % 3600 / 60);
      if(value == parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMin) || value == parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMax)){
        return days+'d';
      }else{
        return days+'d, '+hours+'h, '+minutes+'m';
      }
    }
  };  
  tabIndex = 0;
  isPageDataLoad = false;
  isShowFilter = false;
  drawerfilter: boolean = true;
  isFilterClear: boolean = false;
  isFilterApply: boolean = false;
  alarmList: any = [];
  recordsTotal = 0;
  pageIndex = 0;
  escalationLevels = [1,2,3,4]
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    searchText: '',
    sortBy: ''
  };
  filter:any = {
    search:'',
    occurrenceFromDate:'',
    occurrenceToDate:'',
    currentEscalationLevel:[],
    eventCategoryTypes:[],
    alarmStatus:[],
    alarmName:[],
    machineSerialNumber:[],
    causesLostTradingHours:[],
    machineModelName:[],
    siteName:[],
    serviceAgent:[],
    countryCode:[],
    customerName: [],
    machineType: [],
    propositionType: []
  };
  tempAlarmFilter: any = {
    search: '',
    occurrenceFromDate: '',
    occurrenceToDate: '',
    currentEscalationLevel: [],
    eventCategoryTypes: [],
    alarmStatus: [],
    alarmName: [],
    machineSerialNumber: [],
    causesLostTradingHours: [],
    machineModelName: [],
    siteName: [],
    serviceAgent: [],
    countryCode: [],
    customerName: [],
    machineType: [],
    propositionType: [],
    escalationDurationFrom: parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMin),
    escalationDurationTo: parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMax)
    };
    filterAlarmColumns = ['occurrenceFromDate', 'occurrenceToDate', 'escalationDurationFrom', 'escalationDurationTo', 'currentEscalationLevel', 'eventCategoryTypes', 'alarmStatus', 'propositionType', 'alarmName', 'machineSerialNumber', 'causesLostTradingHours', 'machineModelName', 'customerName', 'siteName', 'serviceAgent', 'countryCode', 'customerName', 'machineType'];//[GD2-3706]
  escalationFilter:any = {
    searchText: '',
    filterFromDate: '',
    filterToDate: '',
    fventAlarmId: ''
  };
  @ViewChild(MatTabGroup) tabs: MatTabGroup;

  alarmEventCauseLostTradingHoursList:any = [];
  filterAlarmEventCauseLostTradingHoursList:any = [];
  alarmEventEscalationsList:any = [];
  escalationLevelNotificationMethodsList :any = [];
  alarmEventTypesList:any = [];
  escalationLevelList:any = [];
  alarmStatusList:any = [];
  displayedColumns: string[] = ['propositionType', 'alarmName', 'description', 'uniqueMachineNumber', 'machineType',  'machineModelName','currentEscalationLevel','occurredDateTime','eventDuration','causesLostTradingHours','eventCategoryType','siteName','serviceAgent','customerName','countryCode','alarmStatus','resolvedDateTime','closeEventOccurredDateTime','resolutionType', 'eventAlarmId']; 
  
  lookupDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "value",
    textField: "text",
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1
  };

  lookupPropositionTypeDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "propositionTypeId",
    textField: "name",
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1
  };

  singleSelectLookupDropdownSettings = {
    singleSelection: true,
    defaultOpen: false,
    idField: "value",
    textField: "text",
    enableCheckAll: false,
    allowSearchFilter: false,
    itemsShowLimit: 1
  };
  dataSource: any = [];
  alarmEventCauseLostTradingHoursEnum = AlarmEventCauseLostTradingHoursEnum;
  isFilter = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild("closeAlarmTemplate") closeAlarmTemplate: TemplateRef<any>;
  filterlookups:any = {};
  exportFilter = {};
  blob: any;
  minDate: any = moment().subtract(this.userService.defaultUIConfiuraitions.alarmFilterOccurrenceDateMinInDays, 'days');
  maxDate: any = moment();
  alarmId: any = '';
  fromMachineDetailTab = false;  
  alarmEscalations : any[] = [];
  alarmEscalationsColumns: any[] = ['alarmName', 'levelOfEscalation', 'dateTimeofNotification', 'contactDetails', 'messageType', 'machineUniqueNumber', 'site'];
  exportAlarmEscalations = {};
  touchUi = false;
  enableMeridian = false;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  showSeconds = 1;
  color: ThemePalette = 'primary';
  escalationMinDate = '';
  escalationMaxDate = '';
  escalationMinToDate = '';
  escalationMaxToDate = '';
  showSpinners = true;
  hasCloseAlarmAccess = false;
  dialogRef: MatDialogRef<any>;
  dialogTexts = {
      successTitle: '',
      cancelTitle: '',
      message: '',
      deviceName: '',
      status: '',
      title: '',
  }
  closeAlarmId = '';
  advanceFilter = {
    entityID: false,
    entityName: false,
    billTo: false,
    sellTo: false,
    reference: false,
    machineSerialNumber: false
  }
  displayAdvancedSearch = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private alarmTemplateService:AlarmTemplateService,
    private spinner:NgxSpinnerService,
    public roleService:RoleService,
    private router: Router,
    private toaster: Toaster,
    private userService:UserService,
      private activatedRoute: ActivatedRoute,
      private dialog: MatDialog,
    private appConstant: AppConstant,
    private entityService: EntityService) {
      let that = this;
      $.each( EventFilterTypesEnum, function( key, value ) {
        if(Number.isInteger(value)){
          that.filterlookups[key]=[]; 
        }
      }); 
      activatedRoute.params.subscribe(params => {
        if (params.alarmId)
          this.alarmId = params.alarmId;
      });
      this.createLookupFromEnum();
  }
  isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
    return (buttonToggleGroup.value || []).indexOf(id) !== -1;
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  ngOnInit(): void {  
    if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) {
      this.router.navigate(['/unauthorized']);
    }
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tabIndex)
        this.tabIndex = params.tabIndex;
      else 
        this.tabIndex = 0;
      
      if (this.tabIndex == 0) {
        this.alarmTemplateService.resetMachineAlarmFilters();
        this.params.sortBy = 'occurredDateTime desc';
        this.getAlarmList();
        this.getFilterLookup();
        if (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin || this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3){
          this.hasCloseAlarmAccess = true;
        }
      } else {
        this.drawerfilter = false;
        if (localStorage.getItem('fromMachineDetailTab'))
          this.fromMachineDetailTab = true;
        else {
          this.alarmTemplateService.resetMachineAlarmFilters();
        }
        this.getAlarmEscalations();
      }
    }); 
  }
  createLookupFromEnum(){
    let that = this;
    $.each( AlarmEventTypesEnum, function( key, value ) {
      if(Number.isInteger(value)){
        that.alarmEventTypesList.push({value:value,text:key});
      }
    });
    $.each( AlarmEventCauseLostTradingHoursEnum, function( key, value ) {
      if(Number.isInteger(value)){
        that.alarmEventCauseLostTradingHoursList.push({value:value,text:key});
      }
    });
    $.each( AlarmListFilterEscalationLevelsEnum, function( key, value ) {
      if(Number.isInteger(value)){
        that.escalationLevelList.push({value:value,text:key});
      }
    });
    $.each( AlarmListFilterStatusEnum, function( key, value ) {
      if(Number.isInteger(value)){
        if(key == 'Active'){
          key = 'Active & Resolved';
        }
        that.alarmStatusList.push({value:value,text:key});
      }
    });
  }
  getFilterLookup(){
    let that = this;
    this.getPropositionTypeFilter(); //GD2-4153

    $.each( AlarmListFilterTypesEnum, function( key, value ) {
      if(Number.isInteger(value)){
       that.filterlookups[key] = [];
        that.alarmTemplateService.getAlarmFilterLookupByType(value).subscribe(response => {
          if(response){
            that.filterlookups[key]  = response['data'];          
          }
        }, err => {
          let errors = err.error;
          if(Array.isArray(errors)){
            errors.forEach(element => {
              that.roleService.showToasterMsg(element.msg,'danger');             
            });
          }
        });     
      }
    });
  }

  getPropositionTypeFilter() {
    this.entityService.getLookup('/api/v1/entitypropositions/' + this.roleService.entityId).subscribe(response => {
      if (response) {   
        this.filterlookups['PropositionType'] = [];     
        this.filterlookups['PropositionType'] = response['data'];          
      }
    });
  }

  searchText(){
    if (this.tabIndex == 0)
      this.getAlarmList('search')
    else 
      this.getAlarmEscalations('search');
  }
  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    if (this.tabIndex == 0)
      this.getAlarmList();
    else
      this.getAlarmEscalations();
    }

    applyAlarmFilter() {//[GD2-3706]
        this.assignFilterToTempFilter();
        this.getAlarmList('apply');
    }

    assignFilterToTempFilter() {
        this.filterAlarmColumns.forEach(key => {//[GD2-3706]
            this.tempAlarmFilter[key] = this.filter[key];
            if (key == 'escalationDurationFrom' && this.escalationDurationFrom != this.filter[key]) {
                this.tempAlarmFilter['escalationDurationFrom'] = this.escalationDurationFrom;
            }
            if (key == 'escalationDurationTo' && this.escalationDurationTo != this.filter[key]) {
                this.tempAlarmFilter['escalationDurationTo'] = this.escalationDurationTo;
            }
        });
    }
  changefilter() {
    this.roleService.setPopupScroll('section-userfilter');  
    if (!this.drawerfilter) {
      this.displayAdvancedSearch = false;
      $(".sidebar-mini").addClass("filter-open");
   }
    else {
        this.filterAlarmColumns.forEach(key => {//[GD2-3706]
            this.filter[key] = this.tempAlarmFilter[key];
            if (key == 'escalationDurationFrom' && this.escalationDurationFrom != this.tempAlarmFilter[key]) {
                this.escalationDurationFrom = parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMin);
                this.filter['escalationDurationFrom'] = this.escalationDurationFrom;
            }
            if (key == 'escalationDurationTo' && this.escalationDurationTo != this.tempAlarmFilter[key]) {
                this.escalationDurationTo = parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMax);
                this.filter['escalationDurationTo'] = this.escalationDurationTo;
            }
        });
       $(".sidebar-mini").removeClass("filter-open");
       this.roleService.resetPopupScroll();
   }
    setTimeout(() => {
      this.drawerfilter = !this.drawerfilter;  
    }, this.roleService.scrollSetTimeoutTime); 
  }
  export(filename: string, base64: string,contentType:string) {
    var filetype = contentType; 
    let byteCharacters = atob(base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    this.blob = new Blob([byteArray], { type: filetype });
    FileSaver.saveAs(this.blob, filename);
    $('.ajax-investmentUpload-request').hide();
  } 
  clickToExport(){
    this.spinner.show();      
    this.alarmTemplateService.exportAlrarmData(this.exportFilter).subscribe(response => {
      if(response != null){
        this.export(response.fileDownloadName,response.fileContents,response.contentType);
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });  
  }
  click(type,element=null){
      if (type == 'apply') {
          this.getAlarmList();
      } else if (type == 'clear') {
          let that = this;
          $.each(this.filter, function (key, value) {
              if (key != 'search') {
                  that.filter[key] = [];
              }
          });
          that.getAlarmList();
      } else if (type == 'close') {
          // GD2-2434
          this.dialogTexts = {
              successTitle: 'Yes',
              cancelTitle: 'No',
              message: 'Are you sure you wish to close this alarm?',
              deviceName: '',
              status: '',
              title: 'Confirmation'
          }
          this.closeAlarmId = element['eventAlarmId'];
          const closeAlarmDataModel = new MatDialogConfig();
          closeAlarmDataModel.height = 'auto';
          closeAlarmDataModel.width = '670px';
          closeAlarmDataModel.disableClose = true;
          this.dialogRef = this.dialog.open(this.closeAlarmTemplate, closeAlarmDataModel);
      }
  }
    onCloseAlarmConfirm() {
        this.dialogRef.close();
        this.spinner.show();
        let obj = {};
        obj['eventAlarmId'] = this.closeAlarmId;
        this.alarmTemplateService.closeAlarm(obj).subscribe(response => {
            if (response != null) {
                this.getAlarmList();
            }
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
            let errors = err.error;
            errors.forEach(element => {
                this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                });
            });
        });
    }

    onCloseAlarmCancel() {
        this.dialogRef.close();
    }
  sortData(e) {
    e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
    this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
    this.params.pageNumber = 1;
    if (this.tabIndex == 0)
      this.getAlarmList();
    else
      this.getAlarmEscalations();
  }
  clearSearch() {
    this.params.pageNumber = 1;
    if (this.tabIndex == 0) {
      this.filter.search = '';    
      this.advanceFilter = {
        entityID: false,
        entityName: false,
        billTo: false,
        sellTo: false,
        reference: false,
        machineSerialNumber: false
      }
      this.getAlarmList();
    } else {
      this.escalationFilter.search = '';
      this.getAlarmEscalations();
    }
  }
  getAlarmList(fromClear = undefined) {
    this.displayAdvancedSearch = false;    
    if (this.filter['occurrenceFromDate'] && !this.filter['occurrenceToDate']) {
      this.filter['occurrenceToDate'] = moment().format('YYYY-MM-DD');
    }
    if (!this.filter['occurrenceFromDate'] && this.filter['occurrenceToDate']) {
      this.toaster.open({
        text: "Occurred From Date cannot be empty",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });      
    } else if (this.filter['occurrenceFromDate'] > this.filter['occurrenceToDate']) {
      this.toaster.open({
        text: "Occurred To Date must be greater than From Date",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });      
    }else if (this.filter['occurrenceFromDate'] && moment(this.filter['occurrenceFromDate']).format('YYYY-MM-DD') < moment(this.minDate).format('YYYY-MM-DD')) {
      this.toaster.open({
        text: "Occurred From Date must be up to past 3 Months",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });      
    }else{ 
      this.spinner.show();
      if (!this.isFilterClear) {
        this.isShowFilter = false;
        this.drawerfilter = false;
      }
      this.isFilterClear = false;    
    
      if (fromClear == undefined)
        this.drawerfilter = false;
      
      let obj = {};    
      this.isFilterApply = false; 

      if (fromClear == "search" || fromClear == "apply" || fromClear == "refresh")
        this.params.pageNumber = 1
      
      obj['searchDescription'] = this.filter.search;
      if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };
      if (this.params.pageNumber != undefined) { obj['PageNo'] = this.params.pageNumber };
      if (this.params.pageSize != undefined) { obj['PageSize'] = this.params.pageSize };
      if (this.filter['occurrenceFromDate']) { 
        obj['occurrenceFromDate'] = moment(this.filter['occurrenceFromDate']).format('YYYY-MM-DD'); obj['occurrenceToDate'] = moment(this.filter['occurrenceToDate']).format('YYYY-MM-DD'); 
        this.isFilterApply = true;
      }else{
        obj['occurrenceFromDate'] = moment().subtract(this.userService.defaultUIConfiuraitions.alarmFilterOccurrenceDateMinInDays, 'days').format('YYYY-MM-DD');
        obj['occurrenceToDate'] = moment().format('YYYY-MM-DD');
      }
      
      obj['escalationDurationFrom'] = this.escalationDurationFrom;
      obj['escalationDurationTo'] = this.escalationDurationTo;
      obj['isGlobalRequest'] = true;
      var advanceSearchColumns = [];
      if (this.advanceFilter.entityID) {advanceSearchColumns.push("EntityID")};
      if (this.advanceFilter.entityName) {advanceSearchColumns.push("EntityName")};
      if (this.advanceFilter.billTo) {advanceSearchColumns.push("BillTo")};
      if (this.advanceFilter.sellTo) {advanceSearchColumns.push("SellTo")};
      if (this.advanceFilter.reference) {advanceSearchColumns.push("Reference")};
      if (this.advanceFilter.machineSerialNumber) {advanceSearchColumns.push("MachineSerialNumber")};    
      if (advanceSearchColumns.length > 0) { obj['advanceSearch'] = advanceSearchColumns; }
      
      if(this.escalationDurationFrom != parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMin) || this.escalationDurationTo != parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMax)){
        this.isFilterApply = true;
      }
      let filters = ['currentEscalationLevel','eventCategoryTypes','alarmStatus','propositionType','alarmName','machineSerialNumber','causesLostTradingHours','machineModelName','siteName','serviceAgent','countryCode','customerName','machineType'];     
      let that = this;  
      filters.forEach(key => {     
      if(fromClear == true){
        that.filter[key] = [];               
      }
      if(that.filter[key] && that.filter[key].length){              
            let ids = [];
            that.filter[key].forEach(e=>{
              if(key == 'propositionType')
                ids.push(e.propositionTypeId);
              else if(e.value)
                ids.push(e.value);
            })
            if(ids.length){
              if(key == 'alarmStatus' && ids[0] == AlarmListFilterStatusEnum.Active){
                obj[key] =[AlarmListFilterStatusEnum.Active,AlarmListFilterStatusEnum.Resolved]
              }else{
                obj[key] =ids;
              }       
              that.isFilterApply = true;
            }        
        }else if(key == 'alarmStatus'){
          obj[key] = [AlarmListFilterStatusEnum.Active]
        }
      });
      this.alarmTemplateService.getAlarmList(obj).subscribe(response => { 
        this.exportFilter = obj;   
        this.isPageDataLoad = true;      
        this.recordsTotal = response ?.params ?.count;
        this.spinner.hide();
        if (response && response['data'].length) {
            this.alarmList = response['data']            
        } else {
          this.alarmList = [];
        } 
        if (fromClear == "apply") {
          this.drawerfilter = false;
          $(".sidebar-mini").removeClass("filter-open");
          this.roleService.resetPopupScroll();
        }     
      }, err => {
        this.isPageDataLoad = true;
        this.spinner.hide(); 
        this.alarmList = [];    
        let errors = err.error;
        if(Array.isArray(errors)){
          errors.forEach(element => {
            this.roleService.showToasterMsg(element.msg,'danger');  
          });
        }
      });
    }
  }
  clearFilter() {    
    this.isFilterClear = true;
    this.isFilterApply = false;
    this.advanceFilter = {
          entityID: false,
          entityName: false,
          billTo: false,
          sellTo: false,
          reference: false,
          machineSerialNumber: false
      }
    this.params.pageNumber = 1;
    this.escalationDurationFrom = parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMin);
    this.escalationDurationTo = parseInt(this.userService.defaultUIConfiuraitions.alarmFilterEscalationRangeMax);
    this.filter['occurrenceFromDate'] = '';
    this.filter['occurrenceToDate'] = '';

    this.tempAlarmFilter['search'] = '';
    this.tempAlarmFilter['occurrenceFromDate'] = '';
    this.tempAlarmFilter['occurrenceToDate'] = '';
    this.tempAlarmFilter['currentEscalationLevel'] = [];
    this.tempAlarmFilter['eventCategoryTypes'] = [];
    this.tempAlarmFilter['alarmStatus'] = [];
    this.tempAlarmFilter['alarmName'] = [];
    this.tempAlarmFilter['machineSerialNumber'] = [];
    this.tempAlarmFilter['causesLostTradingHours'] = [];
    this.tempAlarmFilter['machineModelName'] = [];
    this.tempAlarmFilter['propositionType'] = [];
    this.tempAlarmFilter['siteName'] = [];
    this.tempAlarmFilter['serviceAgent'] = [];
    this.tempAlarmFilter['countryCode'] = [];
    this.tempAlarmFilter['customerName'] = [];
    this.tempAlarmFilter['machineType'] = [];
    this.tempAlarmFilter['escalationDurationFrom'] = this.escalationDurationFrom;
    this.tempAlarmFilter['escalationDurationTo'] = this.escalationDurationTo;

    this.getAlarmList(true);
   }

   onTabChanged(event) {
    this.alarmTemplateService.resetMachineAlarmFilters();
    this.tabIndex = event.index;
    this.recordsTotal = 0;
    this.params.pageNumber = 1;
    this.params.pageSize = this.pageSizeOptions[0];
    this.isFilterClear = true;
    this.isFilterApply = false;
    this.params.pageNumber = 1;
    this.filter.search = '';
    this.filter['occurrenceFromDate'] = '';
    this.filter['occurrenceToDate'] = '';
    this.advanceFilter = {
          entityID: false,
          entityName: false,
          billTo: false,
          sellTo: false,
          reference: false,
          machineSerialNumber: false
      }
    if (this.alarmId != '' && this.tabIndex == 0) {
      this.alarmId = '';
      this.router.navigate(['/alarms']);
    }
   }

   getAlarmEscalations(action = undefined) {
    if (this.escalationFilter['filterFromDate'] != null && this.escalationFilter['filterFromDate'] != '' && this.escalationFilter['filterToDate'] != null && this.escalationFilter['filterToDate'] != '' && this.escalationFilter['filterFromDate'] > this.escalationFilter['filterToDate']) {
      this.toaster.open({
        text: "Escalation To Date must be greater than From Date",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });      
    }  else if (this.escalationFilter['filterFromDate'] != null && this.escalationFilter['filterFromDate'] != '' && (this.escalationFilter['filterFromDate'] < this.appConstant.sqlMinDate || this.escalationFilter['filterFromDate'] > this.appConstant.sqlMaxDate)) {
      this.toaster.open({
        text: "Escalation From Date is not valid",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } else if (this.escalationFilter['filterToDate'] != null && this.escalationFilter['filterToDate'] != '' && (this.escalationFilter['filterToDate'] < this.appConstant.sqlMinDate || this.escalationFilter['filterToDate'] > this.appConstant.sqlMaxDate)) {
      this.toaster.open({
        text: "Escalation To Date is not valid",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } else {
      if (action == 'search')
        this.params.pageNumber = 1;
      this.spinner.show();
      let obj = {};
      obj['searchText'] = this.escalationFilter.search;
      if (this.escalationFilter.filterFromDate != null && this.escalationFilter.filterFromDate != '') { obj['filterFromDate'] = moment(this.escalationFilter.filterFromDate).format('YYYY-MM-DD HH:mm:ss'); }
      if (this.escalationFilter.filterToDate != null && this.escalationFilter.filterToDate != '') { obj['filterToDate'] = moment(this.escalationFilter.filterToDate).format('YYYY-MM-DD HH:mm:ss') };
      if(this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber; }
      if(this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize; }
      if(this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy; }
      obj['eventAlarmId'] = this.alarmId;

      this.alarmTemplateService.getEscalationNotificationDetails(obj).subscribe(response => {
        this.exportAlarmEscalations = obj;
        this.isPageDataLoad = true;
        this.recordsTotal = response?.params?.count;
        if (response?.params?.fromDateTime != '' && response?.params?.fromDateTime != null && (this.escalationFilter.filterFromDate == '' || this.escalationFilter.filterFromDate == null)) {
          this.escalationFilter.filterFromDate = moment(response.params.fromDateTime).format('YYYY-MM-DD HH:mm:ss');
          this.escalationMinDate = moment(response.params.fromDateTime).format('YYYY-MM-DD HH:mm:ss');
          this.escalationMinToDate = moment(response.params.fromDateTime).format('YYYY-MM-DD HH:mm:ss');
        }
        if (response?.params?.toDateTime != '' && response?.params?.toDateTime != null && (this.escalationFilter.filterToDate == '' || this.escalationFilter.filterToDate == null)) {
          this.escalationFilter.filterToDate = moment(response.params.toDateTime).format('YYYY-MM-DD HH:mm:ss');
          this.escalationMaxDate = moment(response.params.toDateTime).format('YYYY-MM-DD HH:mm:ss');
          this.escalationMaxToDate = moment(response.params.toDateTime).format('YYYY-MM-DD HH:mm:ss');
        }
        this.spinner.hide();
        if (response && response['data'].length) {
          this.alarmEscalations = response['data']
        } else {
          this.alarmEscalations = [];
        }
      }, err => {
        this.isPageDataLoad = true;
        this.spinner.hide();
        let errors = err.error;
        this.alarmEscalations = [];
        if(Array.isArray(errors)){
          errors.forEach(element => {
            this.roleService.showToasterMsg(element.msg,'danger');  
          });
        }
      });    
    }
   }

   clickToExportAlarmEscalations() {
    this.spinner.show();      
    this.alarmTemplateService.exportAlarmEscalationData(this.exportAlarmEscalations).subscribe(response => {
      if(response != null){
        this.export(response.fileDownloadName,response.fileContents,response.contentType);
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });  
   }

   backToMachineDetails() {
     if (localStorage.getItem('machineAlarmFilter')) {
       var filters = JSON.parse(localStorage.getItem('machineAlarmFilter'));
       if (Object.keys(filters).length > 0 && filters['machineId'] != null && filters['machineId'] != '') {
        this.router.navigate(['/machine/', filters['machineId']], {queryParams : { nextLevelNumber : filters['nextLevelNumber'], screenType : filters['screenType'], parentlevelNumber : filters['parentlevelNumber'], tabIndex : 6}})
       }
     }
   }
   
  advanceToggle () {
    this.displayAdvancedSearch = !this.displayAdvancedSearch;  
  }

  applyAdvanceSearch() {
    this.displayAdvancedSearch = false;
    if (this.filter.search != '') {
      this.getAlarmList('search');
    }
  }
}

