import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { EntityService } from 'src/app/services/entity/entity.service';
import { MachineTypeService } from 'src/app/services/machine-type/machine-type.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { RoleService } from 'src/app/services/role/role.service';

@Component({
  selector: 'app-add-edit-machine-type',
  templateUrl: './add-edit-machine-type.component.html',
  styleUrls: ['./add-edit-machine-type.component.css']
})
export class AddEditMachineTypeComponent implements OnInit {

  title = "Add Machine Type";
  screenType = "add";
  machineTypeId: string = '';
  addMachineTypeFormGroup: FormGroup;
  allMachineTypeGroups: any = [];
  machineTypeGroup: any = [];
  propositionTypes: any = [];
  machineModelType: any = [{ value: "Proprietary" }, { value: "Legacy" }];
  dialogRef: MatDialogRef<any>;
  selectedProposition: any = {};
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    public roleService: RoleService,
    private router: Router,
    private machineTypeService: MachineTypeService,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private dialog: MatDialog,
    private entityService: EntityService,
    private machineService: MachineService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.machinemodelId) {
        this.machineTypeId = params.machinemodelId;
        this.screenType = "edit";
      }
    });
  }

  ngOnInit(): void {
    this.title = this.screenType == "add" ? "Add Machine Type" : "Edit Machine Type";
    if (!this.roleService.objRole.isSystemAdmin) {
      this.router.navigate(['/unauthorized']);
    }
    this.addMachineTypeFormGroup = new FormGroup({
      propositionType: new FormControl('', [Validators.required]), //GD2-3464
      machineModelName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      machineModelType: new FormControl('', [Validators.required]),
      machineModelCode: new FormControl('', [Validators.maxLength(100)]),
      machineGroup: new FormControl('', [Validators.required]),
      schaererMachineCode: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.pattern("^[0-9a-zA-Z_ ]*$")]),
      schaererMachineType: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.pattern("^[0-9a-zA-Z]*$")]) //alphanumeric
    });
    this.getMachineGroups();
    this.getPropositionTypes(); // GD2-3464

    if (this.machineTypeId != null && this.machineTypeId != '') {
      this.getMachineModelById();
    }
  }

  getMachineGroups() {
    this.machineService.getMachineGroupByPropositionType().subscribe(response => {
      if (response && response['data'].length) {
        this.allMachineTypeGroups = response['data'];
      }
    });
  }

  getMachineModelById() {
    this.spinner.show();
    let obj = {};
    obj['pageNo'] = 1;
    obj['pageSize'] = 1;
    obj['machineModelId'] = this.machineTypeId;
    this.machineTypeService.GetmachineModels(obj).subscribe(response => {
      if (response && response['data'].length > 0) {
        setTimeout(() => {
          this.machineTypeGroup = this.allMachineTypeGroups.filter(x => x.propositionTypeId == response['data'][0].propositionTypeId);
          this.addMachineTypeFormGroup.patchValue({
            "machineModelName": response['data'][0].machineModelName,
            "machineModelType": response['data'][0].machineModelType?.trim(),
            "machineModelCode": response['data'][0].machineModelCode,
            "machineGroup": response['data'][0].machineGroupId,
            "schaererMachineCode": response['data'][0].schaererMachineCode,
            "schaererMachineType": response['data'][0].schaererMachineType,
            "propositionType": response['data'][0].propositionTypeId
          });
        }, 100);        
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  saveMachineType() {
    this.addMachineTypeFormGroup.markAllAsTouched();
    if (this.addMachineTypeFormGroup.status === 'INVALID') {
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } 
    else {
      this.spinner.show();
      var body = {
        "machineModelName": this.addMachineTypeFormGroup.controls["machineModelName"].value,
        "machineModelType": this.addMachineTypeFormGroup.controls["machineModelType"].value?.trim(),
        "machineModelCode": this.addMachineTypeFormGroup.controls["machineModelCode"].value,
        "machineGroupId": this.addMachineTypeFormGroup.controls["machineGroup"].value,
        "schaererMachineCode": this.addMachineTypeFormGroup.controls["schaererMachineCode"].value,
        "schaererMachineType": this.addMachineTypeFormGroup.controls["schaererMachineType"].value,
        "machineModelId": this.machineTypeId
      };

      this.machineTypeService.AddUpdateMachineType(body).subscribe(response => {
        if (response) {
          var message = (this.machineTypeId != null && this.machineTypeId != '') ? "Machine Type updated successfully" : "Machine Type added successfully";
          this.toaster.open({
            text: message,
            type: 'success',
            position: 'top-right',
            duration: 10000
          });
        }
        this.spinner.hide();
        this.router.navigateByUrl('/configuration/machinetypes');
      }, err => {
        this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      });
    }
  }

  cancelMachineType() {
    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
      deviceName: '',
      status: '',
      title: 'Confirmation',
    }
    
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }

  onConfirm() {
    this.dialogRef.close();
    this.router.navigate(['/configuration/machinetypes']);
  }

  onCancel() {
    this.dialogRef.close();
  }

  machineModelCodeRequired(){
    if(this.selectedProposition.name == 'Concession')
      return true;

    return false;
  }

  changeMachineGroupList(propositionId) {
    //GD2-3464
    this.machineTypeGroup = [];
    let foundProposition = this.propositionTypes.filter(x => x.id == propositionId);
    this.selectedProposition = foundProposition ? foundProposition[0] : {};
    this.addMachineTypeFormGroup.controls['machineGroup'].patchValue('');
    if (propositionId != null && propositionId != undefined && propositionId != '') {
      this.machineTypeGroup = this.allMachineTypeGroups.filter(x => x.propositionTypeId == propositionId);
    }

    if(this.selectedProposition.name == "Concession")
      this.addMachineTypeFormGroup.get('machineModelCode').setValidators([Validators.required, Validators.maxLength(100)]);
    else
      this.addMachineTypeFormGroup.get('machineModelCode').setValidators([Validators.maxLength(100)]);

    this.addMachineTypeFormGroup.get('machineModelCode').updateValueAndValidity();
  }

  getPropositionTypes() {
    this.entityService.getLookup("/api/v1/master/machine/getpropositiontype").subscribe(response => {
      if (response && response['data'].length) {
       this.propositionTypes = response['data'].map(data => ({id : data.masterDataId, name : data.text}));
      }
    });
  }
}
