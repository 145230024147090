import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder, RxFormControl, RxFormGroup } from '@rxweb/reactive-form-validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { cupSizeEnum } from 'src/app/enums/cupSize.enum';
import { cupTypeEnum } from 'src/app/enums/cupType.enum';
import { IngrreientsEnum } from 'src/app/enums/ingredients.enum';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { PropositionMasterEnum } from 'src/app/enums/propositionMaster.enum';
import { CupSizeSetupConfiguration } from 'src/app/models/cup-size-setup-configuration.model';
import { MarketPropositionSetup } from 'src/app/models/market-proposition-setup.model';
import { EntityService } from 'src/app/services/entity/entity.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { PriceCardTemplateService } from 'src/app/services/pricecard-template/pricecard-template.service';
import { RoleService } from 'src/app/services/role/role.service';
import { entityConfigurationModel, entityIngredientsModel, entityLowAdminModel } from '../entityConfigurationModel';
import { AzureService } from 'src/app/services/azure/azure.service';
import { CcDialogService } from '../../../services/ui/cc-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-entity-configuration',
  templateUrl: './entity-configuration.component.html',
  styleUrls: ['./entity-configuration.component.css']
})
export class EntityConfigurationComponent implements OnInit {
  @Input() levelNumber: any = '';
  @Input() marketId: any = '';
  @Input() entityId: any = null;
  @Input() machineId: any = null;
  @Input() parentLevelNumber: any = null;
  @Input() screenType: any = '';
  @Output() editScreen = new EventEmitter<string>();
  @Input() machineSrNo: any = '';
  @Input() sitePropositionTypeId: any = '';
  @Input()
  set isWareHouseMultiProposition(value: any) {
    this._isWareHouseMultiProposition = value == 'true';
  }
  _isWareHouseMultiProposition : boolean = false;
  @Input() isGridOneIngested: boolean;
  @Input() isConcessionMigrated: boolean;
  constructor(
    private formBuilder: RxFormBuilder,
    private priceCardTemplateService: PriceCardTemplateService,
    private entityService: EntityService,
    private machineService: MachineService,
    public roleService: RoleService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: Toaster,
    private activatedRoute: ActivatedRoute,
    private azureService: AzureService,
    private dialogService: CcDialogService,
  ) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.parentEntityId)
        this.parentEntityId = queryParams.parentEntityId;
    });
  }

  entityConfigurationFormGroup: RxFormGroup;
  entityManageIngredientsFormGroup: RxFormGroup;
  entityIngredientsSetupFormGroup: FormGroup;
  initialFormValuesEntityIngredientsSetup: Record<string, any> = {};
  initialFormValuesEntityLowAdmin: Record<string, any> = {};
  initialFormValuesEntityManageIngredients: Record<string, any> = {};
  initialFormValuesCapsuleEntityConfiguration: Record<string, any> = {};
  initialFormValuesAutonomousEntityConfiguration: Record<string, any> = {};
  initialFormValuesConcessionEntityConfiguration: Record<string, any> = {};
  initialFormValuesEntityConfiguration: Record<string, any> = {};
  entityConfigurationModel = new entityConfigurationModel();
  entityIngredientsModel = new entityIngredientsModel();
  ingredientsSetupFormGroupFieldValidators: any = [];
  syrupList: any = [];
  coffeeTypeList: any = [];
  milkTypeList: any = [];
  waffleCups: any = {
    Short: [] = [],
    Small: [] = [],
    Medium: [] = [],
    Large: [] = []
  }
  plasticCups: any = {
    Medium: [] = [],
    Large: [] = []
  }
  glassCups: any = {
    Short: [] = [],
    Small: [] = [],
    Medium: [] = [],
    Large: [] = []
  }
  crockeryCups: any = {
    Short: [] = [],
    Small: [] = [],
    Medium: [] = [],
    Large: [] = []
  }
  allCupSizeValues = [];//[GD2-3875]
  MasterLookupEnum: typeof MasterLookupEnum;
  dropdownSettings = {};
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }
  ingredientColumns: any[] = [];
  filteredIngredientColumns: any[] = [];
  machineTypeList: any[] = [];
  ingredientsType: any[] = [
    {
      "IngredientsName": "Coffee Types",
      "IngredientsType": "coffeeType"
    },
    {
      "IngredientsName": "Milk Types",
      "IngredientsType": "milkType"
    },
    {
      "IngredientsName": "Syrups",
      "IngredientsType": "syrup"
    }];
  cupSizeKeys: string[] = ['waffleCupsShort', 'waffleCupsSmall', 'waffleCupsMedium', 'waffleCupsLarge', 'plasticCupsMedium', 'plasticCupsLarge', 'crockeryCupsShort', 'crockeryCupsSmall', 'crockeryCupsMedium', 'crockeryCupsLarge', 'glassCupsShort', 'glassCupsSmall', 'glassCupsMedium', 'glassCupsLarge'];//[GD2-3875]
  hasAuth = true;
  configTab: string = 'tab-2';
  machineTypeName: string = '';
  parentEntityId = '';
  cupSizeSetupConfiguration: CupSizeSetupConfiguration = new CupSizeSetupConfiguration();
  propositionSetup: MarketPropositionSetup[] = [];
  isMarketAddScreen: boolean = false;

  //GD2-4421
  propositionMasterEnum = PropositionMasterEnum;
  selectedIndex = 0;
  prevTabIndex = 0;
  nextTabIndex = 0;
  nextPropositionType: string = '';
  counterTopEnabled: boolean = false;
  capsulesEnabled: boolean = false;
  autonomousEnabled: boolean = false;
  concessionEnabled: boolean = false;

  lowAdminEnabled: boolean = false;
  machineModelTypes = [];
  selectedMachineModelTypes = [];
  machineModelDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "machineModelId",
    textField: "machineModelName",
    enableCheckAll: false,
    allowSearchFilter: false,
    itemsShowLimit: 1
  };
  entityLowAdminFormGroup: FormGroup;

  entityLowAdminModel = new entityLowAdminModel();
  lowAdminFT: boolean = false;
  enableGridImprovementsFT: boolean = false;
  disableConcessionLockdownPropertiesFT: boolean = false;
  shouldBlockFieldsForConcessions = false;
  activePropositionTypeId: string = '';

  capsuleEntityConfigurationFormGroup: RxFormGroup;
  capsuleCupSizeSetupConfiguration: CupSizeSetupConfiguration = new CupSizeSetupConfiguration();

  autonomousEntityConfigurationFormGroup: RxFormGroup;
  autonomousCupSizeSetupConfiguration: CupSizeSetupConfiguration = new CupSizeSetupConfiguration();

  concessionEntityConfigurationFormGroup: RxFormGroup;
  concessionCupSizeSetupConfiguration: CupSizeSetupConfiguration = new CupSizeSetupConfiguration();

  activePropositionType = PropositionMasterEnum.CoffeeCounter.toString();
  isLoaded = false;
  // tempTabChangeEvent: any;
  @ViewChild('tooltipMessageDiv') rebootTooltipMessageDiv: ElementRef;
  private rebootMachineMessage: string = '';
  validationDialogTexts: any = {
    title: '',
    successTitle: '',
    cancelTitle: '',
    validationMessage: '',
    validationResult: false,
    status: '',
    showCancelButton: true
  }
  reviewSites: any[] = [];
  reviewSitesColumns: string[] = [];
  isOpenReviewSiteSidePane = false;
  confirmSiteInactiveMessage = '';
  @ViewChild('propositionTabs') propositionTabs: MatTabGroup;

  ngOnInit(): void {
    this.SetFeatureFlagsConfigurations();

    // GD2-4627
    if (this.levelNumber == 1)
      this.reviewSitesColumns = ['siteAutoGeneratedId', 'siteName', 'customerAutoGeneratedId', 'customerName'];
    else if (this.levelNumber == 4)
      this.reviewSitesColumns = ['siteAutoGeneratedId', 'siteName'];

    if (this.levelNumber == 4) {
      this.configTab = 'tab-1';
    } else {
      this.configTab = 'tab-2';
    }
    if (!this.roleService.objRole.isSystemAdmin) {
      if (this.levelNumber != undefined && this.levelNumber != null && this.levelNumber != 0) {
        if (this.roleService.levelNumber != 1) {
          if (this.levelNumber < this.roleService.levelNumber) {
            this.router.navigate(['/unauthorized']);
            this.hasAuth = false;
          }
        } else {
          if (this.levelNumber == 1 && this.roleService.levelNumber == 1 && this.screenType == 'add' && !this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin) {
            this.router.navigate(['/unauthorized']);
            this.hasAuth = false;
          }
        }
      }
      if ((this.screenType != 'read' && !this.roleService.objRole.isMarketAdmin && !this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isLevel2Admin && !this.roleService.objRole.isLevel3Admin)) {
        if (!((this.levelNumber == 7 || this.levelNumber == 8) && (this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3))) {
          this.router.navigate(['/unauthorized']);
          this.hasAuth = false;
        } else {
          this.screenType = 'read';
        }
      }
    }

    if (this.hasAuth) {
      this.MasterLookupEnum = MasterLookupEnum;
      this.entityConfigurationFormGroup = this.formBuilder.formGroup(this.entityConfigurationModel) as RxFormGroup;
      this.capsuleEntityConfigurationFormGroup = this.formBuilder.formGroup(this.entityConfigurationModel) as RxFormGroup; //GD2-4421
      this.autonomousEntityConfigurationFormGroup = this.formBuilder.formGroup(this.entityConfigurationModel) as RxFormGroup; //GD2-4421
      this.concessionEntityConfigurationFormGroup = this.formBuilder.formGroup(this.entityConfigurationModel) as RxFormGroup; //GD2-4421
      this.entityManageIngredientsFormGroup = this.formBuilder.formGroup(this.entityIngredientsModel) as RxFormGroup;
      this.entityIngredientsSetupFormGroup = new FormGroup({});
      let validations = [];
      if (this.levelNumber == 7 || this.levelNumber == 8) {
        validations.push(Validators.required);
      }
      this.entityIngredientsSetupFormGroup.addControl('machineType', new FormControl('', validations));

      this.entityLowAdminFormGroup = new FormGroup({});
      this.entityLowAdminFormGroup.addControl('machineModelType', new FormControl(''))
      this.updateMachineModelTypeValidator();

      /// Nested API calls to handle spinner
      /// getEntityPropositions calls getIngredientcupsize API
      /// getIngredientcupsize calls getIngredientcolumns API
      this.getEntityPropositions(); // GD2-3636

      if (this.levelNumber == 7) {
        this.getMarketMachineTypeLookup(this.marketId);
      }
      else {
        this.getMachineTypeLookup();
      }

      // start GD2-3636
      this.isMarketAddScreen = (this.levelNumber == 1 && this.screenType == 'add');
      // }
      // end GD2-3636
      if (this.levelNumber == 1 && (this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin || this.roleService.objRole.isGridR)) {
        this.getCoffeeTypeList();
        this.getMilkTypeList();
        this.getSyrupList();
      }
      this.getIngredientMasters();
      this.dropdownSettings = {
        "singleSelection": false,
        "defaultOpen": false,
        "idField": "masterDataId",
        "textField": "text",
        "enableCheckAll": false,
        "itemsShowLimit": 0,
        "allowSearchFilter": true
      };
    }
    this.initialFormValuesEntityLowAdmin = this.entityLowAdminFormGroup.getRawValue()
  }

  ngAfterViewInit(): void {
    if (this.propositionTabs) {
      this.propositionTabs._handleClick = (tab, header, index) => {
        if (this.selectedIndex != index) {
          this.nextTabIndex = index;
          this.nextPropositionType = tab.textLabel;
          let existingData = this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()];
          let latestData = this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()];

          if ((JSON.stringify(existingData['cupsize']) != JSON.stringify(latestData['cupsize'])) ||
            (JSON.stringify(existingData['manageIngrdient']) != JSON.stringify(latestData['manageIngrdient']) && this.activePropositionType == PropositionMasterEnum.CoffeeCounter) ||
            (JSON.stringify(existingData['ingredientSetup'])?.replace('"machineType":"",', '') != JSON.stringify(latestData['ingredientSetup'])?.replace('"machineType":"",', '') && this.activePropositionType == PropositionMasterEnum.CoffeeCounter) ||
            (this.activePropositionType == PropositionMasterEnum.CoffeeCounter && this.propositionSetup.filter(x => x.name == PropositionMasterEnum.CoffeeCounter)[0].isEnabled != this.counterTopEnabled) ||
            (this.activePropositionType == PropositionMasterEnum.Capsules && this.propositionSetup.filter(x => x.name == PropositionMasterEnum.Capsules)[0].isEnabled != this.capsulesEnabled) ||
            (this.activePropositionType == PropositionMasterEnum.Autonomous && this.propositionSetup.filter(x => x.name == PropositionMasterEnum.Autonomous)[0].isEnabled != this.autonomousEnabled) ||
            (this.activePropositionType == PropositionMasterEnum.Concession && this.propositionSetup.filter(x => x.name == PropositionMasterEnum.Concession)[0].isEnabled != this.concessionEnabled)
          ) {
            const {key, component} = this.dialogService.openDialog();
            component.instance.title = 'Unsaved Changes';
            component.instance.description = 'You are attempting to navigate away from this screen with unsaved changes - do you wish to proceed?';
            component.instance.primary = 'No';
            component.instance.secondary = 'Yes';
            const dialogSubs = new Subscription();
            dialogSubs.add(
              component.instance.primaryClick.subscribe(() => {
                this.dialogService.closeDialog(key);
                dialogSubs.unsubscribe();
              })
            );
            dialogSubs.add(
              component.instance.secondaryClick.subscribe(() => {
                component.instance.disableControls = true;
                this.onConfirmRebootMachine(key);
                dialogSubs.unsubscribe();
              })
            );
          }
          else {
            this.resetUnSavedPropositionTypeToggle();
            this.selectedIndex = index;
            this.innerTabChange();
          }
        }
      };
    }
  }

  getIngredientMasters() {
    this.entityIngredientsModel = new entityIngredientsModel();
    this.entityManageIngredientsFormGroup = this.formBuilder.formGroup(this.entityIngredientsModel) as RxFormGroup
    this.entityService.getIngredientMasters(this.marketId).subscribe(response => {
      if (response && response['data'].length) {
        let coffeeList = response['data'].filter(x => x.ingredientType == IngrreientsEnum.coffeeType);
        this.entityIngredientsModel.coffeeType = coffeeList;
        let milkList = response['data'].filter(x => x.ingredientType == IngrreientsEnum.milkType);
        this.entityIngredientsModel.milkType = milkList;
        let syrupList = response['data'].filter(x => x.ingredientType == IngrreientsEnum.syrup);
        this.entityIngredientsModel.syrup = syrupList;
        this.entityManageIngredientsFormGroup.patchValue(
          this.entityIngredientsModel
        );
        this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = {};
        //GD2-1348
        if (this.entityManageIngredientsFormGroup.value != null) {
          Object.entries(this.entityManageIngredientsFormGroup.value).forEach(([key, value]) => {
            let val: any = value;
            this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'][key] = [];
            val.forEach(element => {
              this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'][key].push({
                ingredientMasterId: element.ingredientMasterId,
                marketId: element.marketId,
                ingredientType: element.ingredientType,
                masterDataId: element.masterDataId,
                text: element.text
              });
            });
          });
        }
        this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = this.entityManageIngredientsFormGroup.value;
        this.entityManageIngredientsFormGroup.valueChanges.subscribe(x => {
          this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = this.entityManageIngredientsFormGroup.value;
        })
      } else {
        this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = {};
        this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient']['coffeeType'] = [];
        this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient']['milkType'] = [];
        this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient']['syrup'] = [];
        this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = {};
        this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient']['coffeeType'] = [];
        this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient']['milkType'] = [];
        this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient']['syrup'] = [];
      }
      this.initialFormValuesEntityManageIngredients = this.entityManageIngredientsFormGroup.getRawValue()
    });
  }

  getIngredientcupsize(propositionTypeId, showSpinner = false) {
    let propositionType = this.propositionSetup.filter(x => x.propositionTypeId == propositionTypeId)[0].name; //GD2-4421

    let isAddRequest = false;
    if (this.screenType == 'add') {
      isAddRequest = true;
    }
    if (showSpinner)
    this.spinner.show();
this.entityService.getIngredientcupsize(this.marketId, this.entityId, this.machineId, this.parentLevelNumber, this.levelNumber, isAddRequest, this.parentEntityId, propositionTypeId).subscribe(response => {
      this.resetCupsLookups(); //GD2-4421
      if (showSpinner || this.activePropositionType != PropositionMasterEnum.CoffeeCounter)
        this.spinner.hide();
      if (response && response['data'] && response['data'].cupValueDetails.length) {
        this.allCupSizeValues = response['data'];//[GD2-3875]
        this.setEnableDisableCupSizeToggle(propositionType, response); //GD2-4421

        Object.keys(cupTypeEnum).forEach(cupType => {
          Object.keys(cupSizeEnum).forEach(cupSize => {
            response['data'].cupValueDetails.filter(x => x.cupType == cupType && x.cupSize == cupSize).forEach(element => {
              element.cupValue = element.cupValue == 0 ? 'Not in Use' : (element.cupValue) + 'oz';
              var type = "";
              if (cupType == cupTypeEnum.Waffle) {
                type = 'waffleCups';
                this.waffleCups[element.cupSize].push(element);
              } else if (cupType == cupTypeEnum.Plastic) {
                type = 'plasticCups';
                this.plasticCups[element.cupSize].push(element);
              }
              else if (cupType == cupTypeEnum.Crockery) {
                type = 'crockeryCups';
                this.crockeryCups[element.cupSize].push(element);
              }
              else if (cupType == cupTypeEnum.Glass) {
                type = 'glassCups';
                this.glassCups[element.cupSize].push(element);
              }
              if (element.selectedCupValue == "00000000-0000-0000-0000-000000000000") {
                if (element.cupValue == 'Not in Use') {
                  this.entityConfigurationModel[type + element.cupSize] = element.cupValueDetailId;
                }
              } else {
                this.entityConfigurationModel[type + element.cupSize] = element.selectedCupValue;
              }
            });
          });
        });

        if (propositionType == PropositionMasterEnum.CoffeeCounter)
          this.entityConfigurationFormGroup.patchValue(this.entityConfigurationModel);
        else if (propositionType == PropositionMasterEnum.Capsules)
          this.capsuleEntityConfigurationFormGroup.patchValue(this.entityConfigurationModel);  //GD2-4421
        else if (propositionType == PropositionMasterEnum.Autonomous)
          this.autonomousEntityConfigurationFormGroup.patchValue(this.entityConfigurationModel);  //GD2-4421
        else if (propositionType == PropositionMasterEnum.Concession)
          this.concessionEntityConfigurationFormGroup.patchValue(this.entityConfigurationModel);  //GD2-4421

        this.setCupSizeValidators();//[GD2-3875]
        //GD2-1348
        if (propositionType == PropositionMasterEnum.CoffeeCounter) {
          this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.entityConfigurationFormGroup.value;
          this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.entityConfigurationFormGroup.value;
          this.entityConfigurationFormGroup.valueChanges.subscribe(x => {
            this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.entityConfigurationFormGroup.value;
          });
        }
        else if (propositionType == PropositionMasterEnum.Capsules) {
          this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.capsuleEntityConfigurationFormGroup.value;
          this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.capsuleEntityConfigurationFormGroup.value;
          this.capsuleEntityConfigurationFormGroup.valueChanges.subscribe(x => {
            this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.capsuleEntityConfigurationFormGroup.value;
          });
        }
        else if (propositionType == PropositionMasterEnum.Autonomous) {
          this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.autonomousEntityConfigurationFormGroup.value;
          this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.autonomousEntityConfigurationFormGroup.value;
          this.autonomousEntityConfigurationFormGroup.valueChanges.subscribe(x => {
            this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.autonomousEntityConfigurationFormGroup.value;
          });
        }
        else if (propositionType == PropositionMasterEnum.Concession) {
          this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.concessionEntityConfigurationFormGroup.value;
          this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.concessionEntityConfigurationFormGroup.value;
          this.concessionEntityConfigurationFormGroup.valueChanges.subscribe(x => {
            this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = this.concessionEntityConfigurationFormGroup.value;
          });
        }
      }
      if (propositionType == PropositionMasterEnum.CoffeeCounter) {
        this.getIngredientcolumns("", true);
      }
      this.initialFormValuesCapsuleEntityConfiguration = this.capsuleEntityConfigurationFormGroup.getRawValue()
      this.initialFormValuesAutonomousEntityConfiguration = this.autonomousEntityConfigurationFormGroup.getRawValue()
      this.initialFormValuesEntityConfiguration = this.entityConfigurationFormGroup.getRawValue()
      this.initialFormValuesConcessionEntityConfiguration = this.concessionEntityConfigurationFormGroup.getRawValue()
    }, err => {
      this.spinner.hide();
    });
  }
  setEnableDisableCupSizeToggle(propositionType, response) { //GD2-4421
    if (propositionType == PropositionMasterEnum.CoffeeCounter) {
      this.cupSizeSetupConfiguration = response['data'].cupSizeSetupConfigurationModel;
      if (this.cupSizeSetupConfiguration.plasticCupsEnabled && !this.cupSizeSetupConfiguration.parentPlasticCupsEnabled)
        this.cupSizeSetupConfiguration.plasticCupsEnabled = false;
      if (this.cupSizeSetupConfiguration.crockeryEnabled && !this.cupSizeSetupConfiguration.parentCrockeryEnabled)
        this.cupSizeSetupConfiguration.crockeryEnabled = false;
      if (this.cupSizeSetupConfiguration.glassEnabled && !this.cupSizeSetupConfiguration.parentGlassEnabled)
        this.cupSizeSetupConfiguration.glassEnabled = false;
    }
    else if (propositionType == PropositionMasterEnum.Capsules) {
      this.capsuleCupSizeSetupConfiguration = response['data'].cupSizeSetupConfigurationModel;
      if (this.capsuleCupSizeSetupConfiguration.plasticCupsEnabled && !this.capsuleCupSizeSetupConfiguration.parentPlasticCupsEnabled)
        this.capsuleCupSizeSetupConfiguration.plasticCupsEnabled = false;
      if (this.capsuleCupSizeSetupConfiguration.crockeryEnabled && !this.capsuleCupSizeSetupConfiguration.parentCrockeryEnabled)
        this.capsuleCupSizeSetupConfiguration.crockeryEnabled = false;
      if (this.capsuleCupSizeSetupConfiguration.glassEnabled && !this.capsuleCupSizeSetupConfiguration.parentGlassEnabled)
        this.capsuleCupSizeSetupConfiguration.glassEnabled = false;
    }
    else if (propositionType == PropositionMasterEnum.Autonomous) {
      this.autonomousCupSizeSetupConfiguration = response['data'].cupSizeSetupConfigurationModel;
      if (this.autonomousCupSizeSetupConfiguration.plasticCupsEnabled && !this.autonomousCupSizeSetupConfiguration.parentPlasticCupsEnabled)
        this.autonomousCupSizeSetupConfiguration.plasticCupsEnabled = false;
      if (this.autonomousCupSizeSetupConfiguration.crockeryEnabled && !this.autonomousCupSizeSetupConfiguration.parentCrockeryEnabled)
        this.autonomousCupSizeSetupConfiguration.crockeryEnabled = false;
      if (this.autonomousCupSizeSetupConfiguration.glassEnabled && !this.autonomousCupSizeSetupConfiguration.parentGlassEnabled)
        this.autonomousCupSizeSetupConfiguration.glassEnabled = false;
    }
    if (propositionType == PropositionMasterEnum.Concession) {
      this.concessionCupSizeSetupConfiguration = response['data'].cupSizeSetupConfigurationModel;
      if (this.concessionCupSizeSetupConfiguration.plasticCupsEnabled && !this.concessionCupSizeSetupConfiguration.parentPlasticCupsEnabled)
        this.concessionCupSizeSetupConfiguration.plasticCupsEnabled = false;
      if (this.concessionCupSizeSetupConfiguration.crockeryEnabled && !this.concessionCupSizeSetupConfiguration.parentCrockeryEnabled)
        this.concessionCupSizeSetupConfiguration.crockeryEnabled = false;
      if (this.concessionCupSizeSetupConfiguration.glassEnabled && !this.concessionCupSizeSetupConfiguration.parentGlassEnabled)
        this.concessionCupSizeSetupConfiguration.glassEnabled = false;
    }
  }

  getMachineTypeLookup() {
    this.entityService.getMachineType().subscribe(response => {
      if (response && response['data'].length) {
        response['data'].forEach(x => x.IsEnabled = true);
        this.machineTypeList = response['data'];
        this.entityService.getMarketMachineType(this.marketId).subscribe(marketResponse => {
          if (marketResponse && marketResponse['data'].length) {
            this.machineTypeList.forEach(element => {
              if ((marketResponse['data']).filter(x => x.masterDataId == element.masterDataId).length > 0) {
                element.IsEnabled = true;
              }
              else {
                element.IsEnabled = false;
                this.updateValidatorsByMachineOnOff(element.masterDataId, false);
              }
            });
          }
        });
      }
    });
  }

  getMarketMachineTypeLookup(marketId) {
    this.entityService.getMarketMachineType(marketId).subscribe(response => {
      if (response && response['data'].length) {
        this.machineTypeList = response['data'];
      }
    });
  }

  cupSizeSetupEnabledChange(requestCupType, event, propositionType) {
    //GD2-3907 start
    let dependantCupType = '';
    if ((propositionType == PropositionMasterEnum.CoffeeCounter || propositionType == PropositionMasterEnum.Capsules)
      && (requestCupType == 'Crockery' || requestCupType == 'Glass')
    ) {
      dependantCupType = requestCupType == 'Crockery' ? 'Glass' : 'Crockery';
    }
    this.updateCupSizeSetupToggle(requestCupType, propositionType, event.checked);
    if (dependantCupType != '')
      this.updateCupSizeSetupToggle(dependantCupType, propositionType, event.checked);
    //GD2-3907 end

    if (this.screenType != 'Add' && this.entityId) {
      if (!event.checked) {
        this.spinner.show();
        let requestModel = {
          "entityId": this.entityId,
          "levelNumber": this.levelNumber,
          "cupType": requestCupType,
          "propositionTypeId": this.propositionSetup.filter(x => x.name == propositionType)[0].propositionTypeId
        };

        this.entityService.cupTypeDisableValidation(requestModel).subscribe(response => {
          this.spinner.hide();
          if (response && response['data'] && !response['data'].result) {

            this.updateCupSizeSetupToggle(requestCupType, propositionType, true);
            if (dependantCupType != '') //GD2-3907
              this.updateCupSizeSetupToggle(dependantCupType, propositionType, true);

            this.toaster.open({
              text: response['data'].validationMessage,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          }
          else if (response && response['data'] && response['data'].result) {
            this.setToNotInUseCupSizes(requestCupType, propositionType);
            if (dependantCupType != '') //GD2-3907
              this.setToNotInUseCupSizes(dependantCupType, propositionType);
          }
        });
      }
    }
    else {
      this.setToNotInUseCupSizes(requestCupType, propositionType);
      if (dependantCupType != '') //GD2-3907
        this.setToNotInUseCupSizes(dependantCupType, propositionType);
    }
  }

  updateCupSizeSetupToggle(requestCupType, propositionType, value) { //GD2-4421
    if (requestCupType == cupTypeEnum.Waffle) {
      if (propositionType == PropositionMasterEnum.CoffeeCounter)
        this.cupSizeSetupConfiguration.waffleCupsEnabled = value;
      else if (propositionType == PropositionMasterEnum.Capsules)
        this.capsuleCupSizeSetupConfiguration.waffleCupsEnabled = value;
      else if (propositionType == PropositionMasterEnum.Autonomous)
        this.autonomousCupSizeSetupConfiguration.waffleCupsEnabled = value;
      else if (propositionType == PropositionMasterEnum.Concession)
        this.concessionCupSizeSetupConfiguration.waffleCupsEnabled = value;
    }
    else if (requestCupType == cupTypeEnum.Plastic) {
      if (propositionType == PropositionMasterEnum.CoffeeCounter)
        this.cupSizeSetupConfiguration.plasticCupsEnabled = value;
      else if (propositionType == PropositionMasterEnum.Capsules)
        this.capsuleCupSizeSetupConfiguration.plasticCupsEnabled = value;
      else if (propositionType == PropositionMasterEnum.Autonomous)
        this.autonomousCupSizeSetupConfiguration.plasticCupsEnabled = value;
      else if (propositionType == PropositionMasterEnum.Concession)
        this.concessionCupSizeSetupConfiguration.plasticCupsEnabled = value;
    }
    else if (requestCupType == cupTypeEnum.Crockery) {
      if (propositionType == PropositionMasterEnum.CoffeeCounter)
        this.cupSizeSetupConfiguration.crockeryEnabled = value;
      else if (propositionType == PropositionMasterEnum.Capsules)
        this.capsuleCupSizeSetupConfiguration.crockeryEnabled = value;
      else if (propositionType == PropositionMasterEnum.Autonomous)
        this.autonomousCupSizeSetupConfiguration.crockeryEnabled = value;
      else if (propositionType == PropositionMasterEnum.Concession)
        this.concessionCupSizeSetupConfiguration.crockeryEnabled = value;
    }
    else if (requestCupType == cupTypeEnum.Glass) {
      if (propositionType == PropositionMasterEnum.CoffeeCounter)
        this.cupSizeSetupConfiguration.glassEnabled = value;
      else if (propositionType == PropositionMasterEnum.Capsules)
        this.capsuleCupSizeSetupConfiguration.glassEnabled = value;
      else if (propositionType == PropositionMasterEnum.Autonomous)
        this.autonomousCupSizeSetupConfiguration.glassEnabled = value;
      else if (propositionType == PropositionMasterEnum.Concession)
        this.concessionCupSizeSetupConfiguration.glassEnabled = value;
    }
  }

  setToNotInUseCupSizes(requestCupType, propositionType) {
    if (requestCupType == cupTypeEnum.Waffle) {
      this.setToNotInUseCupSize(cupSizeEnum.Short, 'waffleCups', this.waffleCups.Short, 'waffleCupsShort', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Small, 'waffleCups', this.waffleCups.Small, 'waffleCupsSmall', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Medium, 'waffleCups', this.waffleCups.Medium, 'waffleCupsMedium', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Large, 'waffleCups', this.waffleCups.Large, 'waffleCupsLarge', propositionType);
    }
    else if (requestCupType == cupTypeEnum.Plastic) {
      this.setToNotInUseCupSize(cupSizeEnum.Medium, 'plasticCups', this.plasticCups.Medium, 'plasticCupsMedium', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Large, 'plasticCups', this.plasticCups.Large, 'plasticCupsLarge', propositionType);
    }
    else if (requestCupType == cupTypeEnum.Crockery) {
      this.setToNotInUseCupSize(cupSizeEnum.Short, 'crockeryCups', this.crockeryCups.Short, 'crockeryCupsShort', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Small, 'crockeryCups', this.crockeryCups.Small, 'crockeryCupsSmall', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Medium, 'crockeryCups', this.crockeryCups.Medium, 'crockeryCupsMedium', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Large, 'crockeryCups', this.crockeryCups.Large, 'crockeryCupsLarge', propositionType);
    }
    else if (requestCupType == cupTypeEnum.Glass) {
      this.setToNotInUseCupSize(cupSizeEnum.Short, 'glassCups', this.glassCups.Short, 'glassCupsShort', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Small, 'glassCups', this.glassCups.Small, 'glassCupsSmall', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Medium, 'glassCups', this.glassCups.Medium, 'glassCupsMedium', propositionType);
      this.setToNotInUseCupSize(cupSizeEnum.Large, 'glassCups', this.glassCups.Large, 'glassCupsLarge', propositionType);
    }
  }

  setToNotInUseCupSize(requestCupSize, mappingType, cupArray, controlKey, propositionType) {
    cupArray.find(x => x.cupSize == requestCupSize && x.cupValue == 'Not in Use');
    if (propositionType == this.propositionMasterEnum.CoffeeCounter)
      this.entityConfigurationFormGroup.controls[controlKey].setValue(cupArray.find(x => x.cupSize == requestCupSize && x.cupValue == 'Not in Use').cupValueDetailId);
    else if (propositionType == this.propositionMasterEnum.Capsules)
      this.capsuleEntityConfigurationFormGroup.controls[controlKey].setValue(cupArray.find(x => x.cupSize == requestCupSize && x.cupValue == 'Not in Use').cupValueDetailId);
    else if (propositionType == this.propositionMasterEnum.Autonomous)
      this.autonomousEntityConfigurationFormGroup.controls[controlKey].setValue(cupArray.find(x => x.cupSize == requestCupSize && x.cupValue == 'Not in Use').cupValueDetailId);
    else if (propositionType == this.propositionMasterEnum.Concession)
      this.concessionEntityConfigurationFormGroup.controls[controlKey].setValue(cupArray.find(x => x.cupSize == requestCupSize && x.cupValue == 'Not in Use').cupValueDetailId);
  }

  machineTypeEnabledChange(machineType, event) {
    machineType.IsEnabled = event.checked;
    if (!event.checked) {
      this.spinner.show();
      this.entityService.machineGroupDisableValidation(this.marketId, machineType.masterDataId).subscribe(response => {
        this.spinner.hide();
        if (response && response['data'] && !response['data'].result) {
          machineType.IsEnabled = true;
          this.toaster.open({
            text: response['data'].validationMessage,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        }
        else if (response && response['data'] && response['data'].result) {
          this.updateValidatorsByMachineOnOff(machineType.masterDataId, false);
          this.setvalueForMachineOnOff(machineType.masterDataId);
        }
      });
    } else {
      this.updateValidatorsByMachineOnOff(machineType.masterDataId, true);
    }
  }

  setvalueForMachineOnOff(machineTypeId) {
    this.ingredientColumns.filter(x => x.machineTypeId == machineTypeId).forEach(element => {
      this.entityIngredientsSetupFormGroup.controls[element.ingredientColumnMasterId].setValue("");
    });
  }

  updateValidatorsByMachineOnOff(machineTypeId, isEnabled) {
    if (!isEnabled) {
      this.ingredientsSetupFormGroupFieldValidators.filter(x => x.machineTypeId == machineTypeId).forEach(element => {
        this.entityIngredientsSetupFormGroup.get(element.ingredientColumnMasterId).clearValidators();
        this.entityIngredientsSetupFormGroup.get(element.ingredientColumnMasterId).updateValueAndValidity();
      });
    }
    else {
      this.ingredientsSetupFormGroupFieldValidators.filter(x => x.machineTypeId == machineTypeId).forEach(element => {
        this.entityIngredientsSetupFormGroup.get(element.ingredientColumnMasterId).setValidators(element.fieldValidators);
        this.entityIngredientsSetupFormGroup.get(element.ingredientColumnMasterId).updateValueAndValidity();
      });
    }
  }

  async getIngredientcolumns(machineTypeId, isPageLoad) {
    this.spinner.show();
    let isAddRequest = false;
    let modelDetails = this.entityService.addEntityOnHoldData['level-8']['tab-0'];
    let machineModelId = '';
    let propositionTypeId = this.propositionSetup.filter(x => x.name == PropositionMasterEnum.CoffeeCounter)[0].propositionTypeId;
    if (this.screenType == 'add') {
      isAddRequest = true;
      if (modelDetails != null && modelDetails != '')
        machineModelId = modelDetails['machineModelId'];
    }
    await this.entityService.getIngredientcolumns(machineTypeId, this.marketId, this.entityId, this.machineId, this.parentLevelNumber, this.levelNumber, isAddRequest, this.parentEntityId, machineModelId, propositionTypeId).subscribe(response => {
      if (response && response['data'].length) {
        this.ingredientColumns = response['data'];
        if (isPageLoad) {
          if (this.levelNumber == 8) {
            this.machineTypeName = this.ingredientColumns[0].machineTypeName;
          }
          else {
            this.machineTypeName = '';
          }
          if (this.ingredientColumns.length < 10) {
            this.entityIngredientsSetupFormGroup.controls['machineType'].patchValue(this.ingredientColumns[0].machineTypeId);
            this.machineTypeChange(this.ingredientColumns[0].machineTypeId);
          }
        }
        Object.keys(this.entityIngredientsSetupFormGroup.controls).forEach(key => {
          if (key != 'machineType') {
            this.entityIngredientsSetupFormGroup.removeControl(key);
          }
        });
        if (this.levelNumber == 1 || this.levelNumber == 4 || ((this.levelNumber == 7 || this.levelNumber == 8) && this.ingredientColumns[0].machineTypeId != '')) {
          this.ingredientColumns.forEach(element => {
            element.id = 'Drp_' + element.columnName.replace(' ', '_');
            element.isDisabled = (((this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin) && element.isEditableBySystemAdmin) || ((1 || this.roleService.objRole.isMarketAdmin) && element.isEditableByMarketAdmin)) ? false : true;
            let validations = [];
            if (element.isMandatory) {
              validations.push(Validators.required);
              this.ingredientsSetupFormGroupFieldValidators.push({
                machineTypeId: element.machineTypeId,
                ingredientColumnMasterId: element.ingredientColumnMasterId,
                columnName: element.columnName,
                fieldValidators: validations
              });
              if (this.machineTypeList.filter(x => x.masterDataId == element.machineTypeId && !x.IsEnabled).length > 0) {
                validations = [];
              }
            }
            let val = { value: element.selectedColumnValue == null ? "" : element.selectedColumnValue, disabled: element.isDisabled };
            this.entityIngredientsSetupFormGroup.addControl(element.ingredientColumnMasterId, new FormControl(val, validations));
          });
          if (machineTypeId != '') {
            this.filteredIngredientColumns = this.ingredientColumns;
          }
        }
        //GD2-1348
        if (isPageLoad) {
          this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['ingredientSetup'] = this.entityIngredientsSetupFormGroup.value;
        }
        this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['ingredientSetup'] = this.entityIngredientsSetupFormGroup.value;

        this.entityIngredientsSetupFormGroup.valueChanges.subscribe(x => {
          this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['ingredientSetup'] = this.entityIngredientsSetupFormGroup.value;
        })
        this.initialFormValuesEntityIngredientsSetup = this.entityIngredientsSetupFormGroup.getRawValue();
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  filterIngredientcolumns(machineTypeId, ingredientsType) {
    this.filteredIngredientColumns = this.ingredientColumns.filter(x => x.machineTypeId == machineTypeId && x.ingredientType == ingredientsType);
    return this.ingredientColumns.filter(x => x.machineTypeId == machineTypeId && x.ingredientType == ingredientsType);
  }

  async machineTypeChange(value) {
    if (value != '') {
      this.getIngredientcolumns(value, false);
    }
    else {
      this.spinner.show();
      let isAddRequest = false;
      if (this.screenType == 'add') {
        isAddRequest = true;
      }
      this.ingredientColumns = [];
      this.filteredIngredientColumns = [];
      await this.entityService.getIngredientcolumns("", this.marketId, this.entityId, this.machineId, this.parentLevelNumber, this.levelNumber, isAddRequest, this.parentEntityId).subscribe(response => {
        if (response && response['data'].length) {
          this.ingredientColumns = response['data'];
          Object.keys(this.entityIngredientsSetupFormGroup.controls).forEach(key => {
            if (key != 'machineType') {
              this.entityIngredientsSetupFormGroup.removeControl(key);
            }
          });
          if ((this.levelNumber == 7 || this.levelNumber == 8) && this.ingredientColumns[0].machineTypeId != '') {
            this.ingredientColumns.forEach(element => {
              element.id = 'Drp_' + element.columnName.replace(' ', '_');
              element.isDisabled = (((this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isGlobalMarketAdmin) && element.isEditableBySystemAdmin) || (this.roleService.objRole.isMarketAdmin && element.isEditableByMarketAdmin)) ? false : true;
              let validations = [];
              if (element.isMandatory) {
                validations.push(Validators.required);
              }
              let val = { value: element.selectedColumnValue == null ? "" : element.selectedColumnValue, disabled: element.isDisabled };
              this.entityIngredientsSetupFormGroup.addControl(element.ingredientColumnMasterId, new FormControl(val, validations));
            });
          }
          //GD2-1348
          this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['ingredientSetup'] = this.entityIngredientsSetupFormGroup.value;
          this.entityIngredientsSetupFormGroup.valueChanges.subscribe(x => {
            this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['ingredientSetup'] = this.entityIngredientsSetupFormGroup.value;
          })
        }
        this.spinner.hide();
      });

    }
    // this.filteredIngredientColumns = this.ingredientColumns.filter(x => x.machineTypeId == value);
  }

  getIngredientListByType(ingredientsType) {
    return this.filteredIngredientColumns.filter(x => x.ingredientType == ingredientsType);
  }

  getCoffeeTypeList() {
    this.coffeeTypeList = [];
    this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Coffee).subscribe(response => {
      if (response && response['data'].length) {
        this.coffeeTypeList = response['data'];
      }
    });
  }

  getMilkTypeList() {
    this.milkTypeList = [];
    this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Milk).subscribe(response => {
      if (response && response['data'].length) {
        this.milkTypeList = response['data'];
      }
    });
  }

  getSyrupList() {
    this.syrupList = [];
    this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Syrup).subscribe(response => {
      if (response && response['data'].length) {
        this.syrupList = response['data'];
      }
    });
  }

  removeMaster(objectValue: any, type: number) {
    let currentObject;
    let formControlValue;
    if (objectValue != undefined) {
      switch (type) {

        case MasterLookupEnum.Coffee:
          this.entityManageIngredientsFormGroup.controls['coffeeType'].markAsTouched();
          formControlValue = this.entityManageIngredientsFormGroup.controls['coffeeType'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            Object.keys(this.entityIngredientsSetupFormGroup.controls).forEach(key => {
              if (this.entityIngredientsSetupFormGroup.controls[key].value == formControlValue[currentObject].masterDataId) {
                this.entityIngredientsSetupFormGroup.controls[key].setValue("");
                this.entityIngredientsSetupFormGroup.controls[key].markAsTouched();
              }
            });
            formControlValue.splice(currentObject, 1);
            this.entityManageIngredientsFormGroup.patchModelValue({ 'coffeeType': formControlValue })
          }

          this.entityManageIngredientsFormGroup.valueChanges.subscribe(x => {
            this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = this.entityManageIngredientsFormGroup.value;
          })

          break;
        case MasterLookupEnum.Milk:
          formControlValue = this.entityManageIngredientsFormGroup.controls['milkType'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            Object.keys(this.entityIngredientsSetupFormGroup.controls).forEach(key => {
              if (this.entityIngredientsSetupFormGroup.controls[key].value == formControlValue[currentObject].masterDataId) {
                this.entityIngredientsSetupFormGroup.controls[key].setValue("");
                this.entityIngredientsSetupFormGroup.controls[key].markAsTouched();
              }
            });
            formControlValue.splice(currentObject, 1);
            this.entityManageIngredientsFormGroup.patchModelValue({ 'milkType': formControlValue })
          }
          break;
        case MasterLookupEnum.Syrup:
          formControlValue = this.entityManageIngredientsFormGroup.controls['syrup'].value;
          currentObject = formControlValue.findIndex(a => a['masterDataId'] == objectValue.masterDataId);
          if (currentObject >= 0) {
            Object.keys(this.entityIngredientsSetupFormGroup.controls).forEach(key => {
              if (this.entityIngredientsSetupFormGroup.controls[key].value == formControlValue[currentObject].masterDataId) {
                this.entityIngredientsSetupFormGroup.controls[key].setValue("");
                this.entityIngredientsSetupFormGroup.controls[key].markAsTouched();
              }
            });
            formControlValue.splice(currentObject, 1);
            this.entityManageIngredientsFormGroup.patchModelValue({ 'syrup': formControlValue })
          }
          break;
      }
    }
  }
  async saveEntity() {
    return new Promise<any>((resolve, reject) => {
      let payload = this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-0'];
      this.entityService.addEntity(payload).
        then((res) => res.subscribe(response => {
          resolve(response);
        }, err => {
          reject(0);
          this.spinner.hide();
          let errors = err.errors == undefined ? err.error : err.errors;
          if (!errors.length && (err.status == 0 || err.status == 500 || err.status == 502)) {
            this.toaster.open({
              text: 'Something went wrong. Please try again after some time.',
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
            if (this.levelNumber == 7)
              this.router.navigate(['/entity']);
            else if (this.levelNumber == 8)
              this.router.navigate(['/machine']);
          }
          else {
            errors.forEach(element => {
              this.toaster.open({
                text: element.msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
            });
          }
        }));
    });
  }
  async saveTradehours() {
    return new Promise<any>((resolve, reject) => {
      let payload = this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-1'];
      payload['entityId'] = this.entityId;
      this.entityService.addSiteTradeHours(payload).subscribe(response => {
        resolve(response);
      }, err => {
        reject(0);
        this.spinner.hide();
        let errors = err.error;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      });
    });
  }

  async saveMachineModelDetails() {
    return new Promise<any>((resolve, reject) => {
      let payload = this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-1'];
      payload['machineId'] = this.machineId;
      this.machineService.addMachineModelDetails(payload).subscribe(response => {
        resolve(response);
      }, err => {
        reject(0);
        this.spinner.hide();
        let errors = err.error;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      });
    });
  }

  async saveConfiguration(propositionType) {
    let cupSizeSetupToggleConfiguration: CupSizeSetupConfiguration = new CupSizeSetupConfiguration();
    cupSizeSetupToggleConfiguration = propositionType == PropositionMasterEnum.CoffeeCounter ? this.cupSizeSetupConfiguration
      : propositionType == PropositionMasterEnum.Capsules ? this.capsuleCupSizeSetupConfiguration
        : propositionType == PropositionMasterEnum.Autonomous ? this.autonomousCupSizeSetupConfiguration
          : propositionType == this.propositionMasterEnum.Concession ? this.concessionCupSizeSetupConfiguration
            : this.cupSizeSetupConfiguration;

    if (propositionType == PropositionMasterEnum.CoffeeCounter && this.counterTopEnabled) {
      this.entityConfigurationFormGroup.markAllAsTouched();
      this.entityManageIngredientsFormGroup.markAllAsTouched();
      this.entityIngredientsSetupFormGroup.markAllAsTouched();
    }
    if (propositionType == PropositionMasterEnum.Capsules && this.capsulesEnabled) {
      this.capsuleEntityConfigurationFormGroup.markAllAsTouched(); //GD2-4421
      if(this.lowAdminEnabled && this.isSysAdminMarketLevel())
        this.entityLowAdminFormGroup.markAllAsTouched();
    }
    if (propositionType == PropositionMasterEnum.Autonomous && this.autonomousEnabled)
      this.autonomousEntityConfigurationFormGroup.markAllAsTouched(); //GD2-4421
    if (propositionType == PropositionMasterEnum.Concession && this.concessionEnabled)
      this.concessionEntityConfigurationFormGroup.markAllAsTouched(); //GD2-4421

    if (this.levelNumber == 1 && this.machineTypeList.filter(x => x.IsEnabled).length < 1) {
      this.toaster.open({
        text: 'Please enable at least one machine type group.',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else if ((this.levelNumber == 1 || this.levelNumber == 4) && !this.counterTopEnabled && !this.capsulesEnabled && !this.autonomousEnabled && !this.concessionEnabled) { //GD2-3636
      this.toaster.open({
        text: 'Please enable at least one proposition type.',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else if (this.counterTopEnabled && (this.entityConfigurationFormGroup.status === 'INVALID' || this.entityManageIngredientsFormGroup.status === 'INVALID' || this.entityIngredientsSetupFormGroup.status === 'INVALID')) {
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else if ((this.capsulesEnabled && this.capsuleEntityConfigurationFormGroup.status === 'INVALID')
      || (this.autonomousEnabled && this.autonomousEntityConfigurationFormGroup.status === 'INVALID')
      || (this.concessionEnabled && this.concessionEntityConfigurationFormGroup.status === 'INVALID')) {
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else if ((propositionType == PropositionMasterEnum.CoffeeCounter || propositionType == PropositionMasterEnum.Capsules)
      && cupSizeSetupToggleConfiguration.crockeryEnabled != cupSizeSetupToggleConfiguration.glassEnabled
    ) {
      // GD2-3907
      this.toaster.open({
        text: 'For this proposition Crockery and Glass must either be both on or both off',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else if (this.isSysAdminMarketLevel() && this.lowAdminEnabled && this.capsulesEnabled && this.entityLowAdminFormGroup.status === 'INVALID'){
      this.toaster.open({
        text: 'No Machine type selected. Please select at least one machine type before saving',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else {
      this.spinner.show();
      let resSaveEntity = true;
      let resSaveTradehours = true;
      let resSaveMachineModel = true;

      if (this.screenType == 'add') {
        resSaveEntity = await this.saveEntity();
        if (this.levelNumber == 7) {
          this.entityId = resSaveEntity;
          resSaveTradehours = await this.saveTradehours();
        } else if (this.levelNumber == 8) {
          this.machineId = resSaveEntity;
          if (this.entityService.saveMachineModelDetails)
            resSaveMachineModel = await this.saveMachineModelDetails();
        }
      }
      if ((![7, 8].includes(this.levelNumber)) || (this.levelNumber == 7 && resSaveEntity && resSaveTradehours) || (this.levelNumber == 8 && resSaveEntity && (resSaveMachineModel || !this.entityService.saveMachineModelDetails))) {
        var availableData = [];
        Object.keys(this.entityManageIngredientsFormGroup.controls).forEach(key => {
          if (this.entityManageIngredientsFormGroup.controls[key].value != null) {
            for (var i = 0; i < this.entityManageIngredientsFormGroup.controls[key].value.length; i++) {
              var data = {
                "ingredientType": key,
                "masterDataId": this.entityManageIngredientsFormGroup.controls[key].value[i].masterDataId
              }
              availableData.push(data);
            }
          }
        });
        var masters = {
          "marketId": this.marketId,
          "availableData": availableData
        }

        var ingredientSetupData = [];

        let ingredientsFormGroup: RxFormGroup; //GD2-4421
        ingredientsFormGroup = propositionType == PropositionMasterEnum.CoffeeCounter ? this.entityConfigurationFormGroup
          : propositionType == PropositionMasterEnum.Capsules ? this.capsuleEntityConfigurationFormGroup
            : propositionType == PropositionMasterEnum.Autonomous ? this.autonomousEntityConfigurationFormGroup
              : propositionType == this.propositionMasterEnum.Concession ? this.concessionEntityConfigurationFormGroup
                : this.entityConfigurationFormGroup;
        Object.keys(ingredientsFormGroup.controls).forEach(key => {
          var data = {
            "machineTemplateId": null,
            "ingredientColumnMasterId": null,
            "masterDateId": null,
            "cupValueDetailId": ingredientsFormGroup.controls[key].value,
            "cupValueData": this.allCupSizeValues['cupValueDetails'].find(x => x.cupValueDetailId == ingredientsFormGroup.controls[key].value)?.cupValue,//[GD2-3875]
            "cupValueGroup": this.getCupGroup(key),//[GD2-3875]
            "cupSizeKey": key//[GD2-3875]
          }
          ingredientSetupData.push(data);
        });
        if (this.levelNumber == 1 || this.levelNumber == 4) {
          this.ingredientColumns.forEach(element => {
            if (this.machineTypeList.filter(x => x.IsEnabled && x.masterDataId == element.machineTypeId).length > 0) {
              var data = {
                "machineTemplateId": element.machineTemplateId,
                "machineTypeId": element.machineTypeId,
                "ingredientColumnMasterId": element.ingredientColumnMasterId,
                "masterDateId": this.entityIngredientsSetupFormGroup.controls[element.ingredientColumnMasterId].value,
                "cupValueDetailId": null,
                "cupValueData": "",//[GD2-3875]
                "cupValueGroup": "",//[GD2-3875]
                "cupSizeKey": ""//[GD2-3875]
              }
              ingredientSetupData.push(data);
            }
          });
        } else {
          this.ingredientColumns.filter(x => x.machineTypeId == this.entityIngredientsSetupFormGroup.controls['machineType'].value).forEach(element => {
            var data = {
              "machineTemplateId": element.machineTemplateId,
              "machineTypeId": element.machineTypeId,
              "ingredientColumnMasterId": element.ingredientColumnMasterId,
              "masterDateId": this.entityIngredientsSetupFormGroup.controls[element.ingredientColumnMasterId].value,
              "cupValueDetailId": null,
              "cupValueData": "",//[GD2-3875]
              "cupValueGroup": "",//[GD2-3875]
              "cupSizeKey": ""//[GD2-3875]
            }
            ingredientSetupData.push(data);
          });
        }
        var body = {
          "marketId": this.marketId,
          "entityId": this.entityId,
          "machineId": this.machineId,
          "levelNumber": this.levelNumber,
          "ingredientSetupData": ingredientSetupData,
          "enabledMachineGroups": (this.levelNumber == 1) ? this.machineTypeList.filter(x => x.IsEnabled == true).map(a => a.masterDataId) : [],
          "disabledMachineGroups": (this.levelNumber == 1) ? this.machineTypeList.filter(x => x.IsEnabled == false).map(a => a.masterDataId) : [],
          "propositionType": propositionType.toLowerCase(),//[GD2-3875]
          "propositionTypeId": this.propositionSetup.filter(x => x.name == propositionType)[0].propositionTypeId, //GD2-4421
          "entityPropositions": null,
          "cupSizeSetupConfiguration": {
            "waffleCupsEnabled": cupSizeSetupToggleConfiguration.waffleCupsEnabled,
            "plasticCupsEnabled": cupSizeSetupToggleConfiguration.plasticCupsEnabled,
            "crockeryEnabled": cupSizeSetupToggleConfiguration.crockeryEnabled,
            "glassEnabled": cupSizeSetupToggleConfiguration.glassEnabled,
          }
        }

        if (this.levelNumber == 1 || this.levelNumber == 4) { // GD2-4421, GD2-4422
          body['entityPropositions'] = {
            "entityId": this.entityId,
            "levelNumber": this.levelNumber,
            "propositionTypeIds": this.getSelectedPropositionTypeIds(),
            "currentPropositionTypeId": this.propositionSetup.filter(x => x.name == propositionType)[0].propositionTypeId
          }
          if (propositionType == PropositionMasterEnum.Capsules && this.capsulesEnabled && this.isSysAdminMarketLevel()) {
            if (body.entityPropositions.propositionTypeIds.includes(body.entityPropositions.currentPropositionTypeId)) {
              body.entityPropositions.isLowAdmin = this.lowAdminEnabled;
              body.entityPropositions.machineModelIds = this.entityLowAdminFormGroup.controls['machineModelType'].value.map(x => x.machineModelId);
            }
          } else {
            this.lowAdminEnabled = false;
            this.updateMachineModelTypeValidator();
            this.entityLowAdminFormGroup.controls['machineModelType'].setValue([]);
          }
        }
        if (propositionType == PropositionMasterEnum.CoffeeCounter) {
          this.entityService.saveIngredientMaster(masters).subscribe(response => {
            this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = this.entityManageIngredientsFormGroup.value;
            this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['ingredientSetup'] = this.entityIngredientsSetupFormGroup.value;
            this.saveIngredientSetup(body, propositionType);
          });
        } else {
          // GD2-3636 remove configuration not required for capsules/autonomous and concession machines
          body['enabledMachineGroups'] = [];
          body['ingredientSetupData'] = ingredientSetupData.filter(x => x.cupValueDetailId != null);
          this.saveIngredientSetup(body, propositionType);
        }
      }
    }
  }

  getCupGroup(key) {//[GD2-3875]
    var cupGroup = '';
    if (key.includes("waffle")) { cupGroup = "WaffleCups"; }
    if (key.includes("plastic")) { cupGroup = "PlasticCups"; }
    if (key.includes("crockery")) { cupGroup = "CrockeryCups"; }
    if (key.includes("glass")) { cupGroup = "GlassCups"; }
    return cupGroup;
  }
  duplicateValidate = () => {//[GD2-3875]
    return (control: RxFormControl) => {
      if (this.capsulesEnabled && this.activePropositionType == PropositionMasterEnum.Capsules) {
        const selection: any = control.value;
        if (selection != '' && selection != null) {
          control.markAsTouched();
          var isDuplicate = false;
          var cupValueData = [];
          Object.keys(this.capsuleEntityConfigurationFormGroup.controls).forEach(key => {
            var cupGroup = this.getCupGroup(key);
            var cupVals = {
              'cupKey': key,
              'cupGroup': cupGroup,
              'cupValues': this.allCupSizeValues['cupValueDetails'].find(x => x.cupValueDetailId == this.capsuleEntityConfigurationFormGroup.controls[key].value)?.cupValue
            };
            if (control['keyName'].toLowerCase().includes(cupGroup.toLowerCase())) {
              cupValueData.push(cupVals);
              if (this.capsuleEntityConfigurationFormGroup.controls[key].hasError('duplicateValidate')) {
                this.capsuleEntityConfigurationFormGroup.controls[key].setErrors(null);
              }
            }
          });
          var duplicateDataKeys = [];
          cupValueData.map((el, i) => {
            cupValueData.find((element, index) => {
              if (i !== index && element.cupGroup === el.cupGroup && element.cupValues?.toLowerCase() === el.cupValues?.toLowerCase() && element?.cupValues != "Not in Use") {
                if (element.cupKey == control['keyName']) { isDuplicate = true; }
                duplicateDataKeys.push(element.cupKey);
              }
            });
          });
          if (duplicateDataKeys.length) {
            duplicateDataKeys.forEach(key => {
              this.capsuleEntityConfigurationFormGroup.controls[key].setErrors({ 'duplicateValidate': true });
              this.capsuleEntityConfigurationFormGroup.controls[key].markAsTouched();
            });
          }
          if (isDuplicate) {
            return { duplicateValidate: true };
          } else {
            return null;
          }
        } else
          return null;
      }
      else return null;
    }
  }
  setCupSizeValidators() {//[GD2-3875]
    if (this.capsulesEnabled) {
      this.cupSizeKeys.forEach(key => {
        this.capsuleEntityConfigurationFormGroup.controls[key].setValidators([this.duplicateValidate()]);
        this.capsuleEntityConfigurationFormGroup.controls[key].updateValueAndValidity();
      });
    }
  }
  cancel() {
    if (this.screenType === 'read' || (this.screenType === 'edit' && !this.hasUnsavedChanges())) {
      if (this.levelNumber == 8) {
        this.router.navigate(['/machine']);
      } else {
        this.router.navigate(['/entity']);
      }
    } else {
      const {key, component} = this.dialogService.openDialog();

      component.instance.title = 'Unsaved Changes';
      component.instance.description = 'All unsaved changes will be lost. Are you sure you want to cancel?';
      component.instance.primary = 'No';
      component.instance.secondary = 'Yes';
      const dialogSubs = new Subscription();
      dialogSubs.add(
        component.instance.primaryClick.subscribe(() => {
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        })
      );
      dialogSubs.add(
        component.instance.secondaryClick.subscribe(() => {
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
          this.onConfirm();
        })
      );
    }
  }

  onConfirm() {
    this.resetChanges()
    if (this.levelNumber == 8) {
      this.router.navigate(['/machine']);
    } else {
      this.router.navigate(['/entity']);
    }
  }

  edit() {
    this.editScreen.emit();
  }

  undoValues() {
    this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()] = {};
    this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()] = {};
  }

  onItemSelect(event) {
    this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = this.entityManageIngredientsFormGroup.value;
  }

  onItemSelectAll(event) {
    this.entityService.entityLatestData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['manageIngrdient'] = this.entityManageIngredientsFormGroup.value;
  }

  saveIngredientSetup(body: any, propositionType) {
    this.entityService.saveIngredientSetup(body).subscribe(response => {
      if (response) {
        let msg = (propositionType == PropositionMasterEnum.CoffeeCounter) ? "Ingredients configurations updated successfully" : "Configurations updated successfully";
        if (this.screenType == 'add' && this.levelNumber == 7) {
          msg = "Site has been added successfully"
        } else if (this.screenType == 'add' && this.levelNumber == 8) {
          var machineData = this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-0'];
          let currentMachineSrNo = machineData.formResponse.find(x => x.fieldUniqueName == 'machineSerialNumber')?.value;
          if (this.machineSrNo != '' && this.machineSrNo != null && currentMachineSrNo != null && currentMachineSrNo != '' && this.machineSrNo == currentMachineSrNo) {
            msg = 'Machine created successfully. Removed from the list of Unrecognised Machines.'
          } else
            msg = "Machine created successfully"
        }
        this.toaster.open({
          text: msg,
          type: 'success',
          position: 'top-right',
          duration: 10000
        });
        this.resetChanges(true);
        if (this.levelNumber == 8) {
          this.router.navigate(['/machine']);
        } else if (this.levelNumber != 1 && this.levelNumber != 4) {
          this.router.navigate(['/entity']);
        }

        // GD2-4421
        let ingredientsFormGroup = propositionType == PropositionMasterEnum.CoffeeCounter ? this.entityConfigurationFormGroup
          : propositionType == PropositionMasterEnum.Capsules ? this.capsuleEntityConfigurationFormGroup
            : propositionType == PropositionMasterEnum.Autonomous ? this.autonomousEntityConfigurationFormGroup
              : propositionType == this.propositionMasterEnum.Concession ? this.concessionEntityConfigurationFormGroup
                : this.entityConfigurationFormGroup;
        this.entityService.entityExistingData['level-' + this.levelNumber][this.configTab][this.selectedIndex.toString()]['cupsize'] = ingredientsFormGroup.value;
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  getEntityPropositions() {
    // GD2-3636
    this.spinner.show()
    let currentEntityId = (this.levelNumber == 7 && this.screenType == 'add') ? this.parentEntityId : this.entityId;
    this.entityService.getEntityPropositions(currentEntityId).subscribe(response => {
      let propositionType = '';
      if (response && response['data']) {
        this.propositionSetup = response['data'];

        if (this.levelNumber == 1 || this.levelNumber == 4) {
          this.counterTopEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase() && x.isEnabled).length > 0;
          this.capsulesEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() && x.isEnabled).length > 0;
          this.autonomousEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase() && x.isEnabled).length > 0;
          this.concessionEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase() && x.isEnabled).length > 0;

          // GD2-4422 AC 1
          if (this.levelNumber == 4 && !this.counterTopEnabled && !this.capsulesEnabled && !this.autonomousEnabled && !this.concessionEnabled) {
            this.counterTopEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase() && x.isParentEnabled).length > 0;
            this.capsulesEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() && x.isParentEnabled).length > 0;
            this.autonomousEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase() && x.isParentEnabled).length > 0;
            this.concessionEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase() && x.isParentEnabled).length > 0;
            this.propositionSetup.forEach(x => {
              if ((x.name == PropositionMasterEnum.CoffeeCounter && this.counterTopEnabled)
                || (x.name == PropositionMasterEnum.Capsules && this.capsulesEnabled)
                || (x.name == PropositionMasterEnum.Autonomous && this.autonomousEnabled)
                || (x.name == PropositionMasterEnum.Concession && this.concessionEnabled)
              ) {
                x.isEnabled = true;
              }
            });
          }

          const counterTopEnabledParent = this.propositionSetup.filter(x => x.name.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase() && x.isParentEnabled).length > 0
          const capsulesEnabledParent   = this.propositionSetup.filter(x => x.name.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() && x.isParentEnabled).length > 0
          const autonomousEnabledParent = this.propositionSetup.filter(x => x.name.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase() && x.isParentEnabled).length > 0
          const concessionEnabledParent = this.propositionSetup.filter(x => x.name.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase() && x.isParentEnabled).length > 0

          if(counterTopEnabledParent)
            propositionType = PropositionMasterEnum.CoffeeCounter;
          else if (capsulesEnabledParent)
            propositionType = PropositionMasterEnum.Capsules;
          else if (autonomousEnabledParent)
            propositionType = PropositionMasterEnum.Autonomous;
          else if (concessionEnabledParent)
            propositionType = PropositionMasterEnum.Autonomous;
        }
        else if ((this.levelNumber == 7 || this.levelNumber == 8) && this.screenType != 'add') {
          if(this._isWareHouseMultiProposition)
          {
            const machinePropositionId = this.entityService.entityExistingData['level-'+this.levelNumber]['tab-0']['propositionTypeId'];

            const currentMachineProposition = this.propositionSetup.filter(x => x.propositionTypeId == machinePropositionId)[0];
            if (!currentMachineProposition) {
              propositionType = '';
            } else {
              this.counterTopEnabled = currentMachineProposition.name?.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase();
              this.capsulesEnabled = currentMachineProposition.name?.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase();
              this.autonomousEnabled = currentMachineProposition.name?.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase();
              this.concessionEnabled = currentMachineProposition.name?.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase();

              propositionType = (currentMachineProposition != null) ? currentMachineProposition.name : '';
            }
          }
          else
          {
            this.counterTopEnabled =  response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.CoffeeCounter.toLowerCase()  && x.isEnabled  && x.isParentEnabled).length > 0;

            this.capsulesEnabled =  response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Capsules.toLowerCase() && x.isEnabled  && x.isParentEnabled).length > 0;

            this.autonomousEnabled =  response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Autonomous.toLowerCase() && x.isEnabled  && x.isParentEnabled).length > 0;

            this.concessionEnabled =  response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase() && x.isEnabled  && x.isParentEnabled).length > 0;

            propositionType = (response['data'].filter(x => x.isEnabled).length > 0) ? response['data'].filter(x => x.isEnabled)[0].name : '';
          }
        }
        else if ((this.levelNumber == 7 || this.levelNumber == 8) && this.sitePropositionTypeId != '') { //GD2-4423
          this.propositionSetup = this.propositionSetup.filter(x => x.propositionTypeId == this.sitePropositionTypeId?.toLowerCase());
          if (this.propositionSetup.length) {
            this.propositionSetup[0].isEnabled = true;
            if (this.propositionSetup[0].name == PropositionMasterEnum.CoffeeCounter)
              this.counterTopEnabled = true;
            else if (this.propositionSetup[0].name == PropositionMasterEnum.Capsules)
              this.capsulesEnabled = true;
            else if (this.propositionSetup[0].name == PropositionMasterEnum.Autonomous)
              this.autonomousEnabled = true;
            else if (this.propositionSetup[0].name == PropositionMasterEnum.Concession)
              this.concessionEnabled = true;
          }

          propositionType = this.counterTopEnabled ? PropositionMasterEnum.CoffeeCounter
            : this.capsulesEnabled ? PropositionMasterEnum.Capsules
              : this.autonomousEnabled ? PropositionMasterEnum.Autonomous
                : this.concessionEnabled ? this.propositionMasterEnum.Concession
                  : PropositionMasterEnum.CoffeeCounter;
        }
        this.enableDisableFormControls();
      }
      this.isLoaded = true;

      let propositionTypeId = this.propositionSetup.filter(x => x.name == propositionType)[0].propositionTypeId;

      this.activePropositionType = propositionType;
      this.getIngredientcupsize(propositionTypeId);
    }, err => {
      this.spinner.hide();
    });
  }

  // GD2-4421 start
  getSelectedPropositionTypeIds() {
    this.propositionSetup.forEach(x => {
      if ((x.name == PropositionMasterEnum.CoffeeCounter && this.counterTopEnabled)
        || (x.name == PropositionMasterEnum.Capsules && this.capsulesEnabled)
        || (x.name == PropositionMasterEnum.Autonomous && this.autonomousEnabled)
        || (x.name == PropositionMasterEnum.Concession && this.concessionEnabled)
      ) {
        x.isEnabled = true;
      } else {
        x.isEnabled = false;
      }
    });
    return this.propositionSetup.filter(x => x.isEnabled).map(x => x.propositionTypeId);
  }

  onLowAdminChange() {
    this.updateMachineModelTypeValidator();
  }

  updateMachineModelTypeValidator(){
    if(this.lowAdminEnabled) {
      this.entityLowAdminFormGroup.controls['machineModelType'].setValidators(Validators.required);
    }
    else {
      this.entityLowAdminFormGroup.controls['machineModelType'].clearValidators();
      this.entityLowAdminFormGroup.controls['machineModelType'].setErrors(null)
    }

    this.entityLowAdminFormGroup.controls['machineModelType'].updateValueAndValidity();
  }

  getMachineModelTypeList() {
    let propositionTypeId = this.propositionSetup.filter(x => x.name == PropositionMasterEnum.Capsules)[0].propositionTypeId
    this.machineService.getMachineModelTypeLookup('',propositionTypeId)
    .subscribe(response => {
        if (response) {
            var schaererMachineTypes = ['M39HR','M704R','M707R'];

            this.machineModelTypes = response.data
            .filter(item => item.isActive === true && schaererMachineTypes.includes(item.schaererMachineType))
              .map(item => ({
                machineModelId: item.machineModelId,
                machineModelName: item.machineModelName,
             }));

            this.getMachineModelTypes();
          }
      }, err => {
        this.machineModelTypes = [];
    });
  }

  isSysAdminMarketLevel():boolean {
    return this.levelNumber == 1 && this.roleService.objRole.isSystemAdmin && this.lowAdminFT;
  }

  getMachineModelTypes() {
    this.entityService.getLowAdminProposition(this.entityId, this.activePropositionTypeId, parseFloat(this.levelNumber))
    .subscribe(response => {
        if (response) {
            this.lowAdminEnabled = response.isLowAdmin;
            this.selectedMachineModelTypes = this.machineModelTypes.filter(x => response.machineModelIds.includes(x.machineModelId));
          }
      }, err => {
        this.selectedMachineModelTypes = [];
    });
  }

  onProposionSelectionChange(selectedPropositionType, event) {
    if (!this.counterTopEnabled && !this.capsulesEnabled && !this.autonomousEnabled && !this.concessionEnabled) {
      this.toaster.open({
        text: 'Please enable at least one proposition type.',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      setTimeout(() => {
        this.resetPropositionTypes(selectedPropositionType, true);
      }, 100);
    }
    else {
      this.enableDisableFormControls(selectedPropositionType);
      if (event.checked) {
        if (selectedPropositionType == PropositionMasterEnum.Capsules) {//[GD2-3875]
          this.setCupSizeValidators();
        }
        else {
          this.cupSizeKeys.forEach(key => {//[GD2-3875]
            this.capsuleEntityConfigurationFormGroup.controls[key].setErrors(null);
          });
        }
      }
      else {
        if (selectedPropositionType == PropositionMasterEnum.Capsules) {//[GD2-3875]
          this.cupSizeKeys.forEach(key => {
            this.capsuleEntityConfigurationFormGroup.controls[key].setErrors(null);
          });
        }

        // GD2-4627 validation
        let obj = {
          'entityId': this.entityId,
          'levelNumber': this.levelNumber,
          'propositionTypeId': this.propositionSetup.filter(x => x.name == selectedPropositionType)[0].propositionTypeId
        };
        this.spinner.show();
        this.entityService.validatePropositionDisableforEntity(obj).subscribe(response => {
          this.spinner.hide();

          if (response && response?.status != 'NoPropositionActive') {
            let confirmBtn = response.status == 'Success' ? 'Yes'
              : response.status == 'ActiveSite' ? 'Review'
                : response.status == 'ActiveMachine' ? 'Ok'
                  : '';
            let cancelBtn = response.status == 'Success' ? 'No'
              : response.status == 'ActiveSite' ? 'Cancel'
                : '';
            if (response.status == 'ActiveSite') {
              this.reviewSites = response.sites;
              this.confirmSiteInactiveMessage = "The " + selectedPropositionType + " Proposition has been turned off for the " + response.entityName + " " + (this.levelNumber == 1 ? "market" : "customer")
                + ". As a result " + response.siteCount + " sites have been made inactive";
            }
            this.validationDialogTexts = {
              title: 'CONFIRMATION',
              successTitle: confirmBtn,
              cancelTitle: cancelBtn,
              validationMessage: response.validationMessage,
              validationResult: response.validationResult,
              status: response.status,
              showCancelButton: response.status != 'ActiveMachine'
            }
            this.openValidationDialog();
          }
        }, err => {
          this.spinner.hide();
        });
      }
    }
  }

  openValidationDialog(): void {
    const {key, component} = this.dialogService.openDialog();
    component.instance.title = this.validationDialogTexts.title;
    component.instance.description = this.validationDialogTexts.validationMessage;
    component.instance.primary = 'No';
    component.instance.secondary = 'Yes';
    const dialogSubs = new Subscription();
    dialogSubs.add(
      component.instance.primaryClick.subscribe(() => {
        if (this.onCancelValidationDialog(this.validationDialogTexts.status)){
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        }
      })
    );
    dialogSubs.add(
      component.instance.secondaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
        this.onConfirmValidationDialog();
      })
    );
  }

  innerTabChange() {
    this.activePropositionType = this.nextPropositionType;
    this.activePropositionTypeId = this.propositionSetup.filter(x => x.name == this.activePropositionType)[0].propositionTypeId;
    if (this.activePropositionType == PropositionMasterEnum.CoffeeCounter) { //GD2-5152
      this.getCoffeeTypeList();
      this.getMilkTypeList();
      this.getSyrupList();

      setTimeout(() => {
        this.getIngredientMasters();
      }, 100);
    } else if(this.activePropositionType == PropositionMasterEnum.Capsules && this.isSysAdminMarketLevel()){
      this.getMachineModelTypeList();
    }
    let showSpinner = !(this.activePropositionType == PropositionMasterEnum.CoffeeCounter);
    this.getIngredientcupsize(this.activePropositionTypeId, showSpinner);
    this.prevTabIndex = this.selectedIndex;
  }

  enableDisableFormControls(propositionType = '') {
    if (propositionType == PropositionMasterEnum.CoffeeCounter || propositionType == '') {
      if (this.counterTopEnabled) {
        for (var control in this.entityConfigurationFormGroup.controls) {
          this.entityConfigurationFormGroup.controls[control].enable();
        }
        for (var control in this.entityManageIngredientsFormGroup.controls) {
          this.entityManageIngredientsFormGroup.controls[control].enable();
        }
        for (var control in this.entityIngredientsSetupFormGroup.controls) {
          this.entityIngredientsSetupFormGroup.controls[control].enable();
        }
      }
      else {
        for (var control in this.entityConfigurationFormGroup.controls) {
          this.entityConfigurationFormGroup.controls[control].disable();
          this.entityConfigurationFormGroup.controls[control].markAsPristine();
          this.entityConfigurationFormGroup.controls[control].markAsUntouched();
        }
        for (var control in this.entityManageIngredientsFormGroup.controls) {
          this.entityManageIngredientsFormGroup.controls[control].disable();
          this.entityManageIngredientsFormGroup.controls[control].markAsPristine();
          this.entityManageIngredientsFormGroup.controls[control].markAsUntouched();
        }
        for (var control in this.entityIngredientsSetupFormGroup.controls) {
          this.entityIngredientsSetupFormGroup.controls[control].disable();
          this.entityIngredientsSetupFormGroup.controls[control].markAsPristine();
          this.entityIngredientsSetupFormGroup.controls[control].markAsUntouched();
        }
      }
    }
    if (propositionType == PropositionMasterEnum.Capsules || propositionType == '') {
      if (this.capsulesEnabled) {
        for (var control in this.capsuleEntityConfigurationFormGroup.controls) {
          this.capsuleEntityConfigurationFormGroup.controls[control].enable();
        }
      }
      else {
        for (var control in this.capsuleEntityConfigurationFormGroup.controls) {
          this.capsuleEntityConfigurationFormGroup.controls[control].disable();
          this.capsuleEntityConfigurationFormGroup.controls[control].markAsPristine();
          this.capsuleEntityConfigurationFormGroup.controls[control].markAsUntouched();
        }
      }
    }
    if (propositionType == PropositionMasterEnum.Autonomous || propositionType == '') {
      if (this.autonomousEnabled) {
        for (var control in this.autonomousEntityConfigurationFormGroup.controls) {
          this.autonomousEntityConfigurationFormGroup.controls[control].enable();
        }
      }
      else {
        for (var control in this.autonomousEntityConfigurationFormGroup.controls) {
          this.autonomousEntityConfigurationFormGroup.controls[control].disable();
          this.autonomousEntityConfigurationFormGroup.controls[control].markAsPristine();
          this.autonomousEntityConfigurationFormGroup.controls[control].markAsUntouched();
        }
      }
    }
    if (propositionType == PropositionMasterEnum.Concession || propositionType == '') {
      if (this.concessionEnabled) {
        this.azureService.isDisableConcessionLockdownPropertiesFT()
          .then(response => {
            this.disableConcessionLockdownPropertiesFT = response;
            this.shouldBlockFieldsForConcessions = this.levelNumber >= 4 && !this.disableConcessionLockdownPropertiesFT && (this.screenType == 'add' || (this.isGridOneIngested && !this.isConcessionMigrated));

            if (this.screenType != 'read') {
              this.controlsConcession(this.shouldBlockFieldsForConcessions);
            } else {
              this.disableAllCcontrols();
            }
        }).catch(() => {
            this.controlsConcession(true);
          })
      }
      else {
        this.disableAllCcontrols();
      }
    }
  }

  disableAllCcontrols(){
    for (var control in this.concessionEntityConfigurationFormGroup.controls) {
      this.concessionEntityConfigurationFormGroup.controls[control].disable();
      this.concessionEntityConfigurationFormGroup.controls[control].markAsPristine();
      this.concessionEntityConfigurationFormGroup.controls[control].markAsUntouched();
    }
  }

  controlsConcession(shouldBlock: boolean) {
    for (const control in this.concessionEntityConfigurationFormGroup.controls) {
      if (shouldBlock) {
        this.concessionEntityConfigurationFormGroup.controls[control].disable();
      } else {
        this.concessionEntityConfigurationFormGroup.controls[control].enable();
      }
    }
  }

  resetCupsLookups() {
    this.waffleCups = {
      Short: [] = [],
      Small: [] = [],
      Medium: [] = [],
      Large: [] = []
    }
    this.plasticCups = {
      Medium: [] = [],
      Large: [] = []
    }
    this.glassCups = {
      Short: [] = [],
      Small: [] = [],
      Medium: [] = [],
      Large: [] = []
    }
    this.crockeryCups = {
      Short: [] = [],
      Small: [] = [],
      Medium: [] = [],
      Large: [] = []
    }
  }

  onConfirmMovetoNextTab() {
    let resetCounterTopForm = false;
    if (this.activePropositionType == PropositionMasterEnum.CoffeeCounter) { //Reset manage ingredient section on move to other tab
      resetCounterTopForm = true;
    }
    setTimeout(() => {
      this.selectedIndex = this.nextTabIndex;
      this.innerTabChange();
      this.resetUnSavedPropositionTypeToggle();
      if (resetCounterTopForm) {
        this.enableDisableFormControls(PropositionMasterEnum.CoffeeCounter);
      }
    }, 50);
  }
  // GD2-4421 end

  // GD2-4627 start
  onConfirmValidationDialog(status = '') {
    if (status == 'ActiveSite' && this.reviewSites.length > 0) {
      this.roleService.setPopupScroll('section-reviewsites');
      if (!this.isOpenReviewSiteSidePane) {
        $(".sidebar-mini").addClass("filter-open");
        $("#section-reviewsites").addClass("show");
      }
      else {
        $(".sidebar-mini").removeClass("filter-open");
        $("#section-reviewsites").removeClass("show");
        $("#section-reviewsites").addClass("hide");
        this.roleService.resetPopupScroll();
      }
      setTimeout(() => {
        this.isOpenReviewSiteSidePane = !this.isOpenReviewSiteSidePane;
      }, this.roleService.scrollSetTimeoutTime);
    }
    else if (status == 'ActiveMachine') {
      this.resetPropositionTypes(this.activePropositionType, true);
    }
  }

  onCancelValidationDialog(status): boolean {
    if (status == 'CloseReviewSiteSidePane') {
      setTimeout(() => {
        this.isOpenReviewSiteSidePane = false;
      }, this.roleService.scrollSetTimeoutTime);

      $(".sidebar-mini").removeClass("filter-open");
      $("#section-reviewsites").removeClass("show");
      $("#section-reviewsites").addClass("hide");
      this.roleService.resetPopupScroll();
    }
    else {
      return true;
    }
    this.resetPropositionTypes(this.activePropositionType, true);
    return false;
  }

  resetPropositionTypes(propositionType: string, status: boolean) {
    if (propositionType == PropositionMasterEnum.CoffeeCounter) {
      this.counterTopEnabled = status;
    } else if (propositionType == PropositionMasterEnum.Capsules) {
      this.capsulesEnabled = status;
    } else if (propositionType == PropositionMasterEnum.Autonomous) {
      this.autonomousEnabled = status;
    } else if (propositionType == PropositionMasterEnum.Concession) {
      this.concessionEnabled = status;
    }
  }

  turnOffProposition() {
    this.isOpenReviewSiteSidePane = false;
    $(".sidebar-mini").removeClass("filter-open");
    $("#section-reviewsites").removeClass("show");
    $("#section-reviewsites").addClass("hide");
    this.roleService.resetPopupScroll();

    this.validationDialogTexts = {
      title: 'CONFIRMATION',
      successTitle: 'OK',
      cancelTitle: '',
      validationMessage: this.confirmSiteInactiveMessage,
      validationResult: true,
      status: 'ActiveSiteAcknowledge',
      showCancelButton: false
    }
    this.confirmSiteInactiveMessage = '';
    setTimeout(() => {
      this.openValidationDialog();
    }, 200);
  }
  // GD2-4627 end

  // GD2-4422 start
  checkPropositionEnableAtParentLevel(propositionType) {
    if (this.isLoaded) {
      if (this.propositionSetup.filter(x => x.name == propositionType).length > 0) {
        if (this.levelNumber == 1 || this.levelNumber == 4)
          return this.propositionSetup.filter(x => x.name == propositionType)[0].isParentEnabled;
        else if (this.levelNumber == 7 || this.levelNumber== 8) { //GD2-4423, GD2-4427
          if(this._isWareHouseMultiProposition){
            return this.activePropositionType.toLowerCase() == propositionType.toLowerCase();
          }

          return this.propositionSetup.filter(x => x.name == propositionType)[0].isEnabled;
        }
      }
      return false;
    }
    return true;
  }

  resetUnSavedPropositionTypeToggle() {
    this.propositionSetup.forEach(element => {
      if (element.name == PropositionMasterEnum.CoffeeCounter && element.isEnabled != this.counterTopEnabled)
        this.counterTopEnabled = element.isEnabled;
      else if (element.name == PropositionMasterEnum.Capsules && element.isEnabled != this.capsulesEnabled)
        this.capsulesEnabled = element.isEnabled;
      else if (element.name == PropositionMasterEnum.Autonomous && element.isEnabled != this.autonomousEnabled)
        this.autonomousEnabled = element.isEnabled;
      else if (element.name == PropositionMasterEnum.Concession && element.isEnabled != this.concessionEnabled)
        this.concessionEnabled = element.isEnabled;
    });
  }
  // GD2-4422 end

  showRebootSection() {
    const userPermission = this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isMarketAdmin ||
      this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3;
    const levelPermission = this.levelNumber == 8;
    const screenTypePermission = this.screenType != 'add';

    return userPermission && levelPermission && screenTypePermission;
  }

  requestRebootMachine(): void {
    const {key, component} = this.dialogService.openDialog();

    component.instance.title = 'Reboot Machine';
    component.instance.description = 'Are you sure you want to reboot this machine?';
    component.instance.primary = 'No';
    component.instance.secondary = 'Yes';
    const dialogSubs = new Subscription();
    dialogSubs.add(
      component.instance.primaryClick.subscribe(() => {
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
      })
    );
    dialogSubs.add(
      component.instance.secondaryClick.subscribe(() => {
        this.onConfirmMovetoNextTab();
        this.dialogService.closeDialog(key);
        dialogSubs.unsubscribe();
      })
    );
  }

  async onConfirmRebootMachine(dialogKey: string) {
    const rebootMachineResponse = this.machineService.rebootMachine(this.machineId);
    await rebootMachineResponse
      .toPromise()
      .then(response => {
        if (response.data) {
          if (response.data.sucessfull) {
            this.rebootMachineMessage = 'A reboot request has been sent to the machine.';
          } else {
            this.rebootMachineMessage = response.data.message;
          }
        }
    },
    err => {
      // console.log(err);
      this.rebootMachineMessage = 'Something went wrong with your request, try again in a few minutes';
    });
    this.rebootTooltipMessageDiv.nativeElement.style.display = 'flex';
    this.dialogService.closeDialog(dialogKey);
  }

  hasUnsavedEntityIngredientsSetup() {
    const rawForm = this.entityIngredientsSetupFormGroup.getRawValue()
    return Object.keys(this.initialFormValuesEntityIngredientsSetup).reduce((acc, key) => {
        if (this.initialFormValuesEntityIngredientsSetup[key] !== rawForm[key]) {
            return true
        }
        return acc
    }, false)
  }
  hasUnsavedEntityLowAdmin() {
    const rawForm = this.entityLowAdminFormGroup.getRawValue()
    return Object.keys(this.initialFormValuesEntityLowAdmin).reduce((acc, key) => {
        if (this.initialFormValuesEntityLowAdmin[key] !== rawForm[key]) {
            return true
        }
        return acc
    }, false)
  }
  hasUnsavedEntityManageIngredients() {
    return JSON.stringify(this.entityManageIngredientsFormGroup.getRawValue()) !== JSON.stringify(this.initialFormValuesEntityManageIngredients)
  }
  hasUnsavedCapsuleEntityConfiguration() {
    const rawForm = this.capsuleEntityConfigurationFormGroup.getRawValue()
    return Object.keys(this.initialFormValuesCapsuleEntityConfiguration).reduce((acc, key) => {
        if (this.initialFormValuesCapsuleEntityConfiguration[key] !== rawForm[key]) {
            return true
        }
        return acc
    }, false)
  }
  hasUnsavedAutonomousEntityConfiguration() {
    const rawForm = this.autonomousEntityConfigurationFormGroup.getRawValue()
    return Object.keys(this.initialFormValuesAutonomousEntityConfiguration).reduce((acc, key) => {
        if (this.initialFormValuesAutonomousEntityConfiguration[key] !== rawForm[key]) {
            return true
        }
        return acc
    }, false)
  }
  hasUnsavedConcessionEntityConfiguration() {
    const rawForm = this.concessionEntityConfigurationFormGroup.getRawValue()
    return Object.keys(this.initialFormValuesConcessionEntityConfiguration).reduce((acc, key) => {
        if (this.initialFormValuesConcessionEntityConfiguration[key] !== rawForm[key]) {
            return true
        }
        return acc
    }, false)
  }
  hasUnsavedEntityConfiguration() {
    const rawForm = this.entityConfigurationFormGroup.getRawValue()
    return Object.keys(this.initialFormValuesEntityConfiguration).reduce((acc, key) => {
        if (this.initialFormValuesEntityConfiguration[key] !== rawForm[key]) {
            return true
        }
        return acc
    }, false)
  }

  hasUnsavedChanges() {
    return [
      this.hasUnsavedEntityIngredientsSetup(),
      this.hasUnsavedEntityLowAdmin(),
      this.hasUnsavedEntityManageIngredients(),
      this.hasUnsavedCapsuleEntityConfiguration(),
      this.hasUnsavedAutonomousEntityConfiguration(),
      this.hasUnsavedConcessionEntityConfiguration(),
      this.hasUnsavedEntityConfiguration(),
    ].some(value => value);
  }
  resetChanges(save?: boolean) {
    if (save) {
      this.initialFormValuesEntityIngredientsSetup = this.entityIngredientsSetupFormGroup.getRawValue()
      this.initialFormValuesEntityLowAdmin = this.entityLowAdminFormGroup.getRawValue()
      this.initialFormValuesEntityManageIngredients = this.entityManageIngredientsFormGroup.getRawValue()
      this.initialFormValuesCapsuleEntityConfiguration = this.capsuleEntityConfigurationFormGroup.getRawValue()
      this.initialFormValuesAutonomousEntityConfiguration = this.autonomousEntityConfigurationFormGroup.getRawValue()
      this.initialFormValuesConcessionEntityConfiguration = this.concessionEntityConfigurationFormGroup.getRawValue()
      this.initialFormValuesEntityConfiguration = this.entityConfigurationFormGroup.getRawValue()
      return
    }
    this.entityIngredientsSetupFormGroup.patchValue(this.initialFormValuesEntityIngredientsSetup)
    this.entityLowAdminFormGroup.patchValue(this.initialFormValuesEntityLowAdmin)
    this.entityManageIngredientsFormGroup.patchValue(this.initialFormValuesEntityManageIngredients)
    this.capsuleEntityConfigurationFormGroup.patchValue(this.initialFormValuesCapsuleEntityConfiguration)
    this.autonomousEntityConfigurationFormGroup.patchValue(this.initialFormValuesAutonomousEntityConfiguration)
    this.concessionEntityConfigurationFormGroup.patchValue(this.initialFormValuesConcessionEntityConfiguration)
    this.entityConfigurationFormGroup.patchValue(this.initialFormValuesEntityConfiguration)
  }
  private SetFeatureFlagsConfigurations() {
    this.azureService.isLowAdminFT()
      .then(response => this.lowAdminFT = response);

    this.azureService.isEnableGridImprovementsFT()
      .then(response => this.enableGridImprovementsFT = response);
  }
}
