import { RoleModuleMasterConstant, RolePermissionsEnum, RoleScreenMasterConstant } from './services/role/role.service';
import { RouterModule, Routes } from '@angular/router';

import { AddEditMachineTypeComponent } from './components/configuration-machinetype/add-edit-machine-type/add-edit-machine-type.component';
import { AddEditTickerTapMessageComponent } from './components/ticker-tap/add-edit-ticker-tap-message/add-edit-ticker-tap-message.component';
import { AlarmListComponent } from './components/alarm-template/alarm-list/alarm-list.component';
import AlarmTemplateAddCanDeactivateGuard from './services/alarm-template/alarm-deactivate.guard';
import { AlarmTemplateAddComponent } from './components/alarm-template/alarm-template-add/alarm-template-add.component';
import { AlarmTemplateListComponent } from './components/alarm-template/alarm-template-list/alarm-template-list.component';
import { AuthService } from './services/auth/auth.service';
import { CallbackComponent } from './components/callback/callback.component';
import { CapsulesIngredientsComponent } from './components/capsules-ingredients/capsules-ingredients.component';
import { ConfigurationMachinetypeComponent } from './components/configuration-machinetype/configuration-machinetype.component';
import { ConfigurationPricecardComponent } from './components/configuration-pricecard/configuration-pricecard.component';
import { CuptypeDefinitionComponent } from './components/cuptype-definition/cuptype-definition.component';
import { EntityAddComponent } from './components/entity/entity-add/entity-add.component';
import EntityCanDeactivateGuard from './services/entity/entity-can-deactivate.guard';
import { EntityListComponent } from './components/entity/entity-list/entity-list.component';
import { ErrorPageComponent } from './components/error/error-page/error-page.component';
import { FileRepositoryPageComponent } from './components/remote-update-tasks/pages/file-repository/file-repository-page-remote-update';
import { LandingComponent } from './components/landing/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import MachineCanDeactivateGuard from './services/entity/machine-can-deactivate.guard';
import UnsavedChangesCanDeactivateGuard from './shared/unsaved-changes-can-deactivate.guard';
import { MachineListComponent } from './components/machines/machine-list/machine-list.component';
import { MasterConfigurationComponent } from './components/master-configuration/master-configuration.component';
import { NewTaskPageComponent } from './components/remote-update-tasks/pages/new-task/components/new-task-page-remote-update.component';
import { NgModule } from '@angular/core';
import { NotificationComponent } from './components/notification/notification.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PriceCardAddComponent } from './components/price-card/price-card-add/price-card-add.component';
import { PriceCardEntityMappingComponent } from './components/price-card/price-card-entity-mapping/price-card-entity-mapping.component';
import { PriceCardEntityMappingV2Component } from './components/price-card/price-card-entity-mapping-v2/price-card-entity-mapping-v2.component';
import { PriceCardListComponent } from './components/price-card/price-card-list/price-card-list.component';
import { PriceCardTemplateAddComponent } from './components/pricecard-template/pricecard-template-add/pricecard-template-add.component';
import { PriceCardTemplateListComponent } from './components/pricecard-template/pricecard-template-list/pricecard-template-list.component';
import { RemoteUpdateTaskSummaryComponent } from './components/remote-update-tasks/pages/summary/remote-update-task-summary.component';
import { RemoteUpdateTasksComponent } from './components/remote-update-tasks/remote-update-tasks.component';
import { SOXAuditLogsListComponent } from './components/sox-audit-logs/sox-audit-logs-list/sox-audit-logs-list.component';
import { SalesListComponent } from './components/sales/sales-list/sales-list.component';
import { SitelayoutComponent } from './components/sitelayout/sitelayout.component';
import { TickerTapMessageListComponent } from './components/ticker-tap/ticker-tap-message-list/ticker-tap-message-list.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UpdateHistoryComponent } from './components/sales/update-history/update-history.component';
import { UpdateVendsComponent } from './components/sales/update-vends/update-vends.component';
import { UserAddComponent } from './components/user/user-add/user-add.component';
import UserAuditCanDeactivateGuard from './services/user-audit/user-audit-deactivate.guard';
import { UserAuditListComponent } from './components/user-audit-list/user-audit-list/user-audit-list.component';
import UserCanDeactivateGuard from './services/user/user-deactivate.guard';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UserVerificationReviewComponent } from './components/user-verification-review/user-verification-review.component';
import { UploadFilePackagesComponent } from './components/remote-update-tasks/pages/file-repository/components/upload-file-packages/upload-file-packages.component';

import { AddDrinksSetupComponent } from './components/drinks-setup/pages/add-drinks-setup/add-drinks-setup-page';
import { EditDrinksSetupComponent } from './components/drinks-setup/pages/edit-drinks-setup/edit-drinks-setup-page';

import { DrinksSetupComponent } from './components/drinks-setup/components/drink-list/drinks-list.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthService],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [],
  },
  {
    path: 'callback',
    component: CallbackComponent,
    canActivate: [],
  },
  { path: 'layout', component: SitelayoutComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '', component: LandingComponent },
  { path: 'dashboard', redirectTo: '/machine', pathMatch: 'full' },
  {
    path: 'entity',
    component: EntityListComponent,
    canActivate: [AuthService],
  },
  {
    path: 'entity/add',
    component: EntityAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[0].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'entity/:entityId',
    component: EntityAddComponent,
    canActivate: [AuthService],
    canDeactivate: [EntityCanDeactivateGuard],
    data: {
      moduleIds: [RoleModuleMasterConstant[1].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.ReadWrite, RolePermissionsEnum.Read],
    },
  },
  {
    path: 'machine',
    component: MachineListComponent,
    canActivate: [AuthService],
  },
  {
    path: 'machine/add',
    component: EntityAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[0].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'machine/:machineId',
    component: EntityAddComponent,
    canActivate: [AuthService],
    canDeactivate: [MachineCanDeactivateGuard, UnsavedChangesCanDeactivateGuard],
    data: {
      moduleIds: [RoleModuleMasterConstant[1].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.ReadWrite, RolePermissionsEnum.Read],
    },
  },
  {
    path: 'price-card-template',
    component: PriceCardTemplateListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[10].RoleScreenId, RoleScreenMasterConstant[11].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'price-card-template/add',
    component: PriceCardTemplateAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[11].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'price-card-template/:drinkGroupTemplateId',
    component: PriceCardTemplateAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[10].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'drinks-setup',
    component: PriceCardTemplateListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[10].RoleScreenId, RoleScreenMasterConstant[11].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'drinks-setup/add',
    component: AddDrinksSetupComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[11].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'drinks-setup/edit/:id',
    component: EditDrinksSetupComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[10].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'price-card',
    component: PriceCardListComponent,
    canActivate: [AuthService],
  },
  {
    path: 'price-card/add',
    component: PriceCardAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[13].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'price-card/:priceCardVersionId',
    component: PriceCardAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[14].RoleScreenId, RoleScreenMasterConstant[15].RoleScreenId, RoleScreenMasterConstant[16].RoleScreenId, RoleScreenMasterConstant[17].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite, RolePermissionsEnum.Read],
    },
  },
  {
    path: 'price-card/:priceCardId/entity-mapping',
    component: PriceCardEntityMappingComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[16].RoleScreenId,
        RoleScreenMasterConstant[17].RoleScreenId,
        RoleScreenMasterConstant[18].RoleScreenId,
        RoleScreenMasterConstant[19].RoleScreenId,
        RoleScreenMasterConstant[20].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.ReadWrite, RolePermissionsEnum.Read],
    },
  },
  {
    path: 'price-card/:priceCardId/entity-mapping-v2',
    component: PriceCardEntityMappingV2Component,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[16].RoleScreenId,
        RoleScreenMasterConstant[17].RoleScreenId,
        RoleScreenMasterConstant[18].RoleScreenId,
        RoleScreenMasterConstant[19].RoleScreenId,
        RoleScreenMasterConstant[20].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.ReadWrite, RolePermissionsEnum.Read],
    },
  },
  {
    path: 'configuration',
    component: MasterConfigurationComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId, RoleModuleMasterConstant[17].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[9].RoleScreenId, RoleScreenMasterConstant[64].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite, RolePermissionsEnum.Read],
    },
  },
  {
    path: 'configuration/ingredients',
    component: ConfigurationPricecardComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[3].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[9].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'sales',
    component: SalesListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[7].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[30].RoleScreenId,
        RoleScreenMasterConstant[31].RoleScreenId,
        RoleScreenMasterConstant[32].RoleScreenId,
        RoleScreenMasterConstant[33].RoleScreenId,
        RoleScreenMasterConstant[34].RoleScreenId,
        RoleScreenMasterConstant[35].RoleScreenId,
        RoleScreenMasterConstant[36].RoleScreenId,
        RoleScreenMasterConstant[37].RoleScreenId,
        RoleScreenMasterConstant[38].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read],
    },
  },
  {
    path: 'sales/updatehistory',
    component: UpdateHistoryComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[7].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[39].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'sales/update-vends',
    component: UpdateVendsComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[7].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[39].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'sales/update',
    component: UpdateVendsComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[7].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[39].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'user',
    component: UserListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[5].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[23].RoleScreenId,
        RoleScreenMasterConstant[24].RoleScreenId,
        RoleScreenMasterConstant[25].RoleScreenId,
        RoleScreenMasterConstant[26].RoleScreenId,
        RoleScreenMasterConstant[27].RoleScreenId,
        RoleScreenMasterConstant[28].RoleScreenId,
        RoleScreenMasterConstant[29].RoleScreenId,
        RoleScreenMasterConstant[52].RoleScreenId,
        RoleScreenMasterConstant[53].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read],
    },
  },
  {
    path: 'user/add',
    component: UserAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[6].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[23].RoleScreenId,
        RoleScreenMasterConstant[24].RoleScreenId,
        RoleScreenMasterConstant[25].RoleScreenId,
        RoleScreenMasterConstant[26].RoleScreenId,
        RoleScreenMasterConstant[27].RoleScreenId,
        RoleScreenMasterConstant[28].RoleScreenId,
        RoleScreenMasterConstant[29].RoleScreenId,
        RoleScreenMasterConstant[52].RoleScreenId,
        RoleScreenMasterConstant[53].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'user/:userId',
    component: UserAddComponent,
    canActivate: [AuthService],
    canDeactivate: [UserCanDeactivateGuard],
    data: {
      moduleIds: [RoleModuleMasterConstant[4].RoleModuleId, RoleModuleMasterConstant[5].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[23].RoleScreenId,
        RoleScreenMasterConstant[24].RoleScreenId,
        RoleScreenMasterConstant[25].RoleScreenId,
        RoleScreenMasterConstant[26].RoleScreenId,
        RoleScreenMasterConstant[27].RoleScreenId,
        RoleScreenMasterConstant[28].RoleScreenId,
        RoleScreenMasterConstant[29].RoleScreenId,
        RoleScreenMasterConstant[52].RoleScreenId,
        RoleScreenMasterConstant[53].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'notifications',
    component: NotificationComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[8].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[40].RoleScreenId, RoleScreenMasterConstant[43].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'audit-logs',
    component: SOXAuditLogsListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[9].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[44].RoleScreenId,
        RoleScreenMasterConstant[45].RoleScreenId,
        RoleScreenMasterConstant[56].RoleScreenId,
        RoleScreenMasterConstant[57].RoleScreenId,
        RoleScreenMasterConstant[60].RoleScreenId,
        RoleScreenMasterConstant[61].RoleScreenId,
        RoleScreenMasterConstant[63].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'user-verification-review',
    component: UserVerificationReviewComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[10].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[46].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'user-audit',
    component: UserAuditListComponent,
    canDeactivate: [UserAuditCanDeactivateGuard],
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[16].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[62].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'alarm-template',
    component: AlarmTemplateListComponent,
    canActivate: [AuthService],
    canDeactivate: [AlarmTemplateAddCanDeactivateGuard],
    data: {
      moduleIds: [RoleModuleMasterConstant[13].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[49].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'alarm-template/add',
    component: AlarmTemplateAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[11].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[47].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'alarm-template/:alarmTemplateId',
    component: AlarmTemplateAddComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[12].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[48].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'alarms',
    component: AlarmListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[14].RoleModuleId, RoleModuleMasterConstant[15].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[50].RoleScreenId, RoleScreenMasterConstant[58].RoleScreenId, RoleScreenMasterConstant[59].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'alarms/:alarmId',
    component: AlarmListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[14].RoleModuleId, RoleModuleMasterConstant[15].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[50].RoleScreenId, RoleScreenMasterConstant[58].RoleScreenId, RoleScreenMasterConstant[59].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'configuration/machinetypes',
    component: ConfigurationMachinetypeComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[17].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[64].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read],
    },
  },
  {
    path: 'configuration/machinetype/add',
    component: AddEditMachineTypeComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[17].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[65].RoleScreenId, RoleScreenMasterConstant[66].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'configuration/machinetype/:machinemodelId',
    component: AddEditMachineTypeComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[17].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[65].RoleScreenId, RoleScreenMasterConstant[66].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'configuration/tickerTapMessages',
    component: TickerTapMessageListComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[18].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[67].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read],
    },
  },
  {
    path: 'configuration/tickerTapMessage/add',
    component: AddEditTickerTapMessageComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[18].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[68].RoleScreenId, RoleScreenMasterConstant[69].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'configuration/tickerTapMessage/:messageId',
    component: AddEditTickerTapMessageComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[18].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[68].RoleScreenId, RoleScreenMasterConstant[69].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'configuration/cupTypeDefinition',
    component: CuptypeDefinitionComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[19].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[70].RoleScreenId],
      permissionIds: [RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'configuration/propositionIngredients',
    component: CapsulesIngredientsComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[20].RoleModuleId],
      screenIds: [RoleScreenMasterConstant[71].RoleScreenId, RoleScreenMasterConstant[72].RoleScreenId],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'task-management-overview',
    component: RemoteUpdateTasksComponent,
    canActivate: [AuthService],
  },
  {
    path: 'task-management-overview/file-repository',
    component: FileRepositoryPageComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[0].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'task-management-overview/file-repository/upload-file',
    component: UploadFilePackagesComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[0].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'task-management-overview/new-task',
    component: NewTaskPageComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[0].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  {
    path: 'task-management-overview/:taskId',
    component: RemoteUpdateTaskSummaryComponent,
    canActivate: [AuthService],
    data: {
      moduleIds: [RoleModuleMasterConstant[0].RoleModuleId, RoleModuleMasterConstant[2].RoleModuleId],
      screenIds: [
        RoleScreenMasterConstant[1].RoleScreenId,
        RoleScreenMasterConstant[2].RoleScreenId,
        RoleScreenMasterConstant[3].RoleScreenId,
        RoleScreenMasterConstant[4].RoleScreenId,
        RoleScreenMasterConstant[5].RoleScreenId,
        RoleScreenMasterConstant[6].RoleScreenId,
        RoleScreenMasterConstant[7].RoleScreenId,
        RoleScreenMasterConstant[0].RoleScreenId,
      ],
      permissionIds: [RolePermissionsEnum.Read, RolePermissionsEnum.ReadWrite],
    },
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
