// GD2-3643
export enum CapsuleIngredientLookup
{
    BarCodeCombinationFilter = 1,
    MachineTypeFilter = 2,
    ButtonPressFilter = 3,
    DrinkProductFilter = 4,
    BarCodeCombinationList = 5,
    ProductNameList = 6,
    CupVolumeList = 7,
    DrinkProductList = 8,
    BlendTypeList = 9
}

export enum BarCodeIngredientLookup{
    CapsuleName = 1
}