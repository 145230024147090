import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EventsService {
  cookieName = environment.cookieName;
  isShowUpdateVendsPopup = false;
  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) { }

  getEventsDetailList(body){
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/getmachineeventdatalist', body, configHeader).pipe(map(response => {
      return response;
    }));
  }

    getEventNameLookup(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
        return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/geteventnamelist', body, configHeader).pipe(map(response => {
      return response;
    }));
  }

    getEventDescriptionLookup(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
        return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/geteventdescriptionlist', body, configHeader).pipe(map(response => {
      return response;
    }));
  }

  getEventTypeLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/geteventtypelist', configHeader).pipe(map(response => {
      return response;
    }));
  }

  getLastTradingHoursLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/getlosttradinghourslist', configHeader).pipe(map(response => {
      return response;
    }));
    }

    getEventSiteNameLookup() {
        var configHeader = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
            }
        };
        return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/geteventsitename', configHeader).pipe(map(response => {
            return response;
        }));
    }

  getEventStatusLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/geteventstatelist', configHeader).pipe(map(response => {
      return response;
    }));
  }

  getCurrentEventStatusLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/getcurrenteventstatelist', configHeader).pipe(map(response => {
      return response;
    }));
  }

    getEventLastRefreshDate(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
        return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/machinelastrefreshedDate', body, configHeader).pipe(map(response => {
      return response;
    }));

  }
}

