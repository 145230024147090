export enum EntityLevelNumber {
    Market = 1,
    Level2 = 2,
    Level3 = 3,
    Level35 = 3.5,
    Customer = 4,
    Level5 = 5,
    Level6 = 6,
    Site = 7,
    Machine = 8
  }