import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from './../../../environments/environment';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.css'],
	moduleId: 'logout'
})
export class LogoutComponent implements OnInit {
	constructor(
		private spinner: NgxSpinnerService,
		private authService:AuthService,
		private cookieService: CookieService
	) {}

	ngOnInit() {
    this.authService.currentUserToken = this.cookieService.get(environment.cookieName + 'access_token');
		this.spinner.show();
    this.authService.logOut();
	}
}


