import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ResumeSuspendMachineResponse } from './dtos/suspend-machine-response';
import { MachineRouterModuleInformation } from './dtos/machine-router-module-information';
import { Observable } from 'rxjs';
import { AzureService } from '../azure/azure.service';
import { RequestLogs } from '../../interfaces/machine/request-logs';
import { MachineLog } from '../../interfaces/machine/machine-log';

export interface RequestConfigInterface {
  headers: {
    'Content-Type': string;
    Authorization: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class MachineService {
  cookieName = environment.cookieName;

  constructor(private cookieService: CookieService, private httpClient: HttpClient, private azureService: AzureService) {
  }

  getMachineList(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machine/getmachinelist', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  addMachineModelDetails(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machinemodel', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getmachineeventdatalookup(obj) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machineeventdata/getmachineeventdatalookup', obj, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMarketLookup(machineId = '', propositionTypeIds = '', isMoveMachine = 'false') {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {
        machineId: machineId,
        propositionTypeIds: propositionTypeIds,
        isMoveMachine: isMoveMachine,
      },
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/market', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getPartnerLookup(marketId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {
        parentEntityId: marketId,
      },
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/partner', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getSiteLookup(parnterId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {
        parentEntityId: parnterId,
      },
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/site', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getSiteLookupByParents(parnterIds) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };

    var body = {
      parentEntityIds: parnterIds,
      levelNumber: 7,
    };

    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/master/getentitiesbyparents', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineTypeLookup(propositionTypeId, selectedMarketId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {
        propositionTypeId: propositionTypeId,
        selectedMarketId: selectedMarketId,
      },
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machinemodellookup', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineModelTypeLookup(parentId = '', propositionTypeId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {
        siteId: parentId,
        propositionTypeId: propositionTypeId,
      },
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machinemodellookup', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineModelDetails(machineId, machineModelId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {
        machineId: machineId,
        machineModelId: machineModelId,
      },
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machinemodel', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineStatusLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {},
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/machine/getmachinestatus', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getConnectivityStatusLookup() {
    let configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };

    let reqParameter = Object.assign(configHeader);
    return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/getconnectivitystatus', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getWorkingStatusLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {},
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/machine/getmachineworkingstatus', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getCommissionningStatusLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {},
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/machine/getmachinecommissioningstatus', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineDetailsWithForm(machineId, parentEntityId, moduleAction = '') {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: { moduleAction: moduleAction },
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    if (parentEntityId == '') {
      return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machine/' + machineId, reqParameter).pipe(
        map((response) => {
          return response;
        }),
      );
    } else {
      return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machine/' + machineId + '/' + parentEntityId, reqParameter).pipe(
        map((response) => {
          return response;
        }),
      );
    }
  }

  getBrandTypeList() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.get<any>(environment.baseUrl + '/api/v1/master/brandType', configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineMoveList(filters) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: filters,
      timestamp: Date.now(),
    };
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/movemachine', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getLookup(route) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + route, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getUnrecognisedMachineList(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machine/getunrecognizedmachinelist', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  assignMakretToUnrecognisedMachine(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.put<any>(environment.apiUrl.machine + '/api/v1/machine/assignmarkettounrecognized', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineGroupByPropositionType(propositionTypeId = null) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    let route = '/api/v1/machinemodels/getmachinetypes';
    if (propositionTypeId != null && propositionTypeId != '' && propositionTypeId != undefined) {
      route = route + '/' + propositionTypeId;
    }

    return this.httpClient.get<any>(environment.apiUrl.machine + route, configHeader).pipe(
      map((response) => {
        // need to change later
        return response;
      }),
    );
  }

  async getUniqueMachineName(machineName: string, schaererMachineType: string, propositionTypeName: string): Promise<string> {
    if (machineName == '') {
      machineName = 'NA';
    }
    if (!machineName) {
      machineName = '';
    }

    switch (propositionTypeName) {
      case 'Capsules':
      case 'Autonomous':
      case 'Concession':
        return machineName;
      default:
        if (!schaererMachineType || schaererMachineType.length == 0) {
          return machineName;
        } else {
          return schaererMachineType + '-' + machineName;
        }
    }
  }

  suspendMachine(machineId, suspendReason) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };

    var body = {
      suspendReason: suspendReason,
    };

    return this.httpClient.put<ResumeSuspendMachineResponse>(environment.apiUrl.machine + '/api/v2/machine/' + machineId + '/suspend', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  resumeMachine(machineId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };

    return this.httpClient.put<ResumeSuspendMachineResponse>(environment.apiUrl.machine + '/api/v2/machine/' + machineId + '/resume', null, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineModelList(machineModelName, propositionType) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    const parameter = {
      params: {
        machineModelName: machineModelName,
        propositionType: propositionType,
      },
    };
    var reqParameter = Object.assign(configHeader, parameter);
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v2/machinemodels', reqParameter).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineMoveEntities(machineid, marketid, customerid, entitylevel) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    var body = {
      machineId: machineid,
      marketId: marketid,
      customerId: customerid,
      entityLevel: entitylevel,
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machine/move/entities', body, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  rebootMachine(machineId) {
    const configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
    return this.httpClient.put<any>(environment.apiUrl.machine + '/api/v3/machine/' + machineId + '/reboot', null, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getMachineRouterModuleInformation(machineId: string): Observable<MachineRouterModuleInformation> {
    const configHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };

    const route = `/api/v1/machineRouterModuleInformation/${machineId}`;

    return this.httpClient.get<MachineRouterModuleInformation>(environment.apiUrl.machine + route, configHeader).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  requestMachineLogs(machineId: string, data: RequestLogs): Observable<any> {
    const configHeader = this.generateRequestConfig();
    const route = `/api/v3/machine/${machineId}/logs`;
    return this.httpClient.post(environment.apiUrl.machine + route, data, configHeader);
  }

  getMachineLogs(machineId: string): Observable<MachineLog[]> {
    const configHeader = this.generateRequestConfig();
    const route = `/api/v3/machine/${machineId}/logs`;
    return this.httpClient.get<MachineLog[]>(environment.apiUrl.machine + route, configHeader);
  }

  private generateRequestConfig(): RequestConfigInterface {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
      },
    };
  }
}
