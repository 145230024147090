import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';

@Pipe({
  name: 'localDateChangePipe'
})
export class LocalDatePipe implements PipeTransform {
  //convert utc to local datetime
  transform(value: string): string {
    var utcTime = moment.utc(value).toDate();
    let localTime = moment(utcTime).local().format('YYYY-MM-DD HH:mm');
    return localTime;
  }

}
