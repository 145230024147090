import { ChangeDetectorRef, Component, OnInit, TemplateRef, VERSION, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { AlarmEventCauseLostTradingHoursEnum } from 'src/app/enums/alarmEventCauseLostTradingHours.enum';
import { AlarmEventEscalationLevelNotificationMethods } from 'src/app/enums/alarmEventEscalationLevelNotificationMethods.enum';
import { AlarmEventEscalationsEnum } from 'src/app/enums/alarmEventEscalations.enum';
import { AlarmEventTypesEnum } from 'src/app/enums/alarmEventTypes.enum';
import { EventFilterTypesEnum } from 'src/app/enums/eventFilterTypes.enum';
import { AlarmTemplateService } from 'src/app/services/alarm-template/alarm-template.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { MachineService } from 'src/app/services/machine/machine.service';
import { RoleService } from 'src/app/services/role/role.service';
import { UserService } from 'src/app/services/user/user.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-alarm-template-list',
  templateUrl: './alarm-template-list.component.html',
  styleUrls: ['./alarm-template-list.component.css']
})
export class AlarmTemplateListComponent implements OnInit {
  panelOpenState = false;
  name = "Angular " + VERSION.major;
  content = "";

  config:any = {
    extraPlugins: "autogrow",
    resize_enabled: false,
    height: 80,
    removePlugins: "resize",
    toolbar: [
			[ 'Link', 'Unlink' ],
			[ 'Bold', 'Italic' ]
		],
    linkShowTargetTab : false,
    linkShowLinkInfoTab : false,
    removeButtons:'Cut,Copy,Paste,Undo,Redo,Anchor',
    versionCheck: false
  };

  alarmTemplateList = [];
  propositionType = '';
  tabIndex = 0;
  isPageDataLoad = false;
  isApiCalling = false;
  isConcession = false;
  eventFormGroup: FormGroup;
  languageFormGroup: FormGroup;
  isShowFilter = false;
  drawerfilter: boolean = false;
  isFilterClear: boolean = false;
  isFilterApply: boolean = false;
  eventList: any = [];
  recordsTotal = 0;
  pageIndex = 0;
  escalationLevels = [1,2,3,4];
  hideSMSEmailBodyofLevel = [3,4];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    searchText: '',
    sortBy: 'eventCode asc'
  };
  filter:any = {
    search:'',
    eventIds: [],
    eventCodes: [],
    eventCategoryTypes: [],
    causesLostTradingHours: [],
    deferTimeMins: [],
    machineTypes: [],
    friendlyNames: [],
    commonEventCodes: [],
    eventThresholdMins: [],
    alarmEscalations: []
  };
  eventMasterId = '';
  alarmTemplateId = '';
  machineTypes = [];
  @ViewChild(MatTabGroup) tabs: MatTabGroup;

  alarmEventCauseLostTradingHoursList:any = [];
  filterAlarmEventCauseLostTradingHoursList:any = [];
  alarmEventEscalationsList:any = [];
  alarmEventTypesList:any = [];
  displayedColumns: string[] = ['eventId', 'eventCode','friendlyName', 'commonEventCode',  'machineModelName','description','eventThresholdMins', 'eventCategoryType','causesLostTradingHours','alarmEscalation','deferTimeMins','eventOrigin',
  'escalationLevel1','notificationsNumberEscalationLevel1','notificationsDurationLevel1Mins','level1TextMessageCount','level1EmailMessageCount',
  'escalationLevel2','notificationsNumberEscalationLevel2','notificationsDurationLevel2Mins','level2TextMessageCount','level2EmailMessageCount',
  'escalationLevel3','notificationsNumberEscalationLevel3','notificationsDurationLevel3Mins',
  'escalationLevel4','notificationsNumberEscalationLevel4','notificationsDurationLevel4Mins',
  'action'];

  dropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "machineModelId",
    textField: "machineModelName",
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1
  };
  lookupDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "value",
    textField: "text",
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1
  };
  singleSelectLookupDropdownSettings = {
    singleSelection: true,
    defaultOpen: false,
    idField: "value",
    textField: "text",
    enableCheckAll: false,
    allowSearchFilter: false,
    itemsShowLimit: 1
  };

  languages:[];
  languageMasterId = '';
  languagesList: any = [];
  languagesDisplayedColumns: string[] = ['languageName','emailSubject','eventMessage','languageMasterId'];
  alarmEventCauseLostTradingHoursEnum = AlarmEventCauseLostTradingHoursEnum;
  isFilter = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  filterlookups:any = {};
  dialogTexts = {
      successTitle: '',
      cancelTitle: '',
      message: '',
      deviceName: '',
      status: '',
      title: '',
      type: '',
      tabindex: 0
  }
  nextRoute = '';
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  selectedLevelForMsgBody = 0;
  selectedTypeForMsgBody = '';
  isEditedLanguageIndex = -1;
  isLanguageEdit = false;
  isShowEditorValidation = false;
  isPreviewScreen = false;
  hasOnlyReadAccess = false;
  fromMessageCount = false;

  selectedEventIdforDelete = '';
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private alarmTemplateService:AlarmTemplateService,
    private entityService:EntityService,
    private spinner:NgxSpinnerService,
    public roleService:RoleService,
    private router: Router,
    private toaster: Toaster,
    private dialog: MatDialog,
    private machineService:MachineService,
    private userService:UserService) {
      let that = this;
      $.each( EventFilterTypesEnum, function( key, value ) {
        if(Number.isInteger(value)){
          that.filterlookups[key]=[];
        }
      });
      this.createLookupFromEnum();
  }
  isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
    return (buttonToggleGroup.value || []).indexOf(id) !== -1;
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
    $('.cke_contents').css({
      'height': '150px',
      'max-height': '150px'
    });
    $('.cke_wysiwyg_frame').css({
      'display': 'block',
    });
    $('.cke_wysiwyg_frame').contents().find('body').css({
      'max-height': '120px',
      'min-height': '120px',
      'overflow': 'auto',
      'margin':'0',
      'padding':'15px',
    });
  }
  ngOnInit(): void {
    if (!this.roleService.objRole.isSystemAdmin && !this.roleService.objRole.isGlobalMarketAdmin && !this.roleService.objRole.isGridR && !this.roleService.objRole.isMarketAdmin && !this.roleService.objRole.isMarketR){
      this.router.navigate(['/unauthorized']);
    }else{
      if (this.roleService.objRole.isMarketAdmin)
        this.hasOnlyReadAccess = true;
      localStorage.removeItem("isTemplateEdit");
      this.getLanguageLookup();
      this.getAlarmTemplateList();
      this.createEventForm();

      this.languageFormGroup = new FormGroup({
        'languageMasterId':new FormControl('', [Validators.required]),
        'eventMessage':new FormControl('', [Validators.required]),
        'eventMessageEmail':new FormControl('', [Validators.required]),
        'emailSubject':new FormControl('', [Validators.required]),
      });
    }
  }
  ngAfterViewInit(): void {
    // Just to be sure
    if (!this.tabs) {
        throw Error('ViewChild(MatTabGroup) tabs; is not defined.');
    }

    this.tabs._handleClick = (tab, header, index) => {
        if (index != this.tabIndex) {
            if (!tab.disabled) {

                if (!this.isFilter) { //Market level setup tab
                    this.getConfirmationBox('tabchanged', index);
                } else {
                    this.tabIndex = index;
                }
            }
        }
    };
  }
  getLanguageLookup(){
    this.entityService.getLanguageLookup().subscribe(response => {
      if(response){
        this.languages  = response['data'];
      }
    }, err => {
      let errors = err.error;
      if(Array.isArray(errors)){
        errors.forEach(element => {
          this.roleService.showToasterMsg(element.msg,'danger');
        });
      }
    });
  }
  createLookupFromEnum(){
    let that = this;
    this.filterAlarmEventCauseLostTradingHoursList.push({value:'',text:'All'});
    $.each( AlarmEventCauseLostTradingHoursEnum, function( key, value ) {
      if(Number.isInteger(value)){
        that.alarmEventCauseLostTradingHoursList.push({value:value,text:key});
        that.filterAlarmEventCauseLostTradingHoursList.push({value:value,text:key});
      }
    });
    $.each( AlarmEventEscalationsEnum, function( key, value ) {
      if(Number.isInteger(value)){
        that.alarmEventEscalationsList.push({value:value,text:key});
      }
    });
    $.each( AlarmEventTypesEnum, function( key, value ) {
      if(Number.isInteger(value)){
        that.alarmEventTypesList.push({value:value,text:key});
      }
    });
  }
  getAlarmTemplateList(){
    this.spinner.show();
    this.alarmTemplateService.getAlarmTemplateList().subscribe(response => {
      if(response && response.data && response.data.length && Array.isArray(response.data)){
        this.alarmTemplateList = response.data;
        if(localStorage.getItem("fromEditTemplateScreen")){
          this.alarmTemplateId = localStorage.getItem("fromEditTemplateScreen");
          localStorage.removeItem('fromEditTemplateScreen');
          this.tabIndex =  this.alarmTemplateList.findIndex(x => x.alarmTemplateId ===this.alarmTemplateId);

        }else{
          this.alarmTemplateId = response.data[0]['alarmTemplateId'];
        }
        this.getMachineModelTypes();
        //this.getTemplatesRelavantData();
      }else{
        this.isPageDataLoad = true;
        this.spinner.hide();
      }

    });
  }
  formReset(){
    this.eventFormGroup.reset();
    this.eventFormGroup.markAsUntouched();
    this.eventFormGroup.controls['deferTimeMins'].enable();
    this.escalationLevels.forEach(levelNumber=>{
      this.eventFormGroup.patchValue({['escalationLevel'+levelNumber]:true});
      this.eventFormGroup.controls['notificationsNumberEscalationLevel'+levelNumber].enable();
      this.eventFormGroup.controls['notificationsDurationLevel'+levelNumber+'Mins'].enable();
      if(!this.hideSMSEmailBodyofLevel.includes(levelNumber)){
        this.eventFormGroup.controls['level'+levelNumber+'TextMessageBody'].enable();
        this.eventFormGroup.controls['level'+levelNumber+'EmailMessageBody'].enable();
      }
    });
  }
  getTemplatesRelavantData(){
    this.formReset();
    this.isFilter = true;
    this.getEventList();
    this.getFilterLookup();
  }
  getFilterLookup(){
    let that = this;
    $.each( EventFilterTypesEnum, function( key, value ) {
      if(Number.isInteger(value)){
       that.filterlookups[key] = [];
        that.alarmTemplateService.getFilterLookupByType(that.alarmTemplateId, value).subscribe(response => {
          if(response){
            that.filterlookups[key]  = response['data'];
          }
        }, err => {
          let errors = err.error;
          if(Array.isArray(errors)){
            errors.forEach(element => {
              that.roleService.showToasterMsg(element.msg,'danger');
            });
          }
        });
      }
    });
  }
  onTabChanged(event){
    this.tabIndex = event.index;
    this.alarmTemplateId = this.alarmTemplateList[event.index]['alarmTemplateId'];
    this.getMachineModelTypes();
    this.getTemplatesRelavantData();
    this.panelOpenState = false;
    this.changeValidatorsConcessionMachine();
  }

  changeValidatorsConcessionMachine(){
    if(this.isConcession){
      this.eventFormGroup.controls['eventId'].setValidators([Validators.required,Validators.maxLength(200)]);
      this.eventFormGroup.controls['eventOrigin'].setValidators([Validators.required,Validators.maxLength(200)]);
    }
    else{
      let alphaNumericPattern = "^[0-9a-zA-Z]*$";
      this.eventFormGroup.controls['eventId'].setValidators([Validators.required,Validators.maxLength(200),Validators.pattern(alphaNumericPattern)]);
      this.eventFormGroup.controls['eventOrigin'].setValidators([]);
    }
    this.eventFormGroup.controls['eventId'].updateValueAndValidity();
  }

  enableDisableLevelInputs(levelNumber, type = ''){
    if(!this.eventFormGroup.controls['escalationLevel'+levelNumber].value){
      this.eventFormGroup.controls['notificationsNumberEscalationLevel'+levelNumber].disable();
      this.eventFormGroup.controls['notificationsDurationLevel'+levelNumber+'Mins'].disable();
      if(!this.hideSMSEmailBodyofLevel.includes(levelNumber)){
        this.eventFormGroup.controls['level'+levelNumber+'TextMessageBody'].disable();
        this.eventFormGroup.controls['level'+levelNumber+'EmailMessageBody'].disable();
      }
    }else{
      if(type != 'view') {
        this.eventFormGroup.controls['notificationsNumberEscalationLevel'+levelNumber].enable();
        this.eventFormGroup.controls['notificationsDurationLevel'+levelNumber+'Mins'].enable();
      }
      if(!this.hideSMSEmailBodyofLevel.includes(levelNumber)){
        this.eventFormGroup.controls['level'+levelNumber+'TextMessageBody'].enable();
        this.eventFormGroup.controls['level'+levelNumber+'EmailMessageBody'].enable();
      }
    }
  }
  click(type,element=null){
      if (type == 'save') {
      this.isApiCalling = true;
      this.spinner.show();
      this.eventFormGroup.patchValue({alarmTemplateId:this.alarmTemplateId});
      if(this.eventFormGroup.status === 'INVALID'){
        this.toaster.open({
          text: 'Please provide valid input for all the highlighted fields',
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
        if(!this.eventFormGroup.value['machineType']){
          this.eventFormGroup.patchValue({machineType:[]})
        }
        this.eventFormGroup.markAllAsTouched();
        this.spinner.hide();
        this.isApiCalling = false;
      }else{
        let body = this.eventFormGroup.value;
        let mTypes = [];
        body['machineType'].forEach(element => {
          mTypes.push(element['machineModelId']);
        });
        body['machineType'] = mTypes;
        if(this.eventMasterId){
          body['eventMasterId'] = this.eventMasterId;
        }
        $.each( this.filter, function( key, value ) {
          let filterName:any = key;
          delete  body['filter-'+filterName];
        });
        body['levelMessages'] = [];
        this.escalationLevels.forEach(levelNumber=>{
          if(this.eventFormGroup.controls['escalationLevel'+levelNumber].value && !this.hideSMSEmailBodyofLevel.includes(levelNumber)){
          body['levelMessages'] = body['levelMessages'].concat(this.eventFormGroup.controls['level'+levelNumber+'TextMessageBody'].value,this.eventFormGroup.controls['level'+levelNumber+'EmailMessageBody'].value);
          }
        });
        this.alarmTemplateService.addEvent(body).subscribe(response => {
          this.isApiCalling = false;
          this.alarmTemplateList[this.tabIndex]['updatedBy'] =  response.modifiedBy;
          response.modifiedDate = response.modifiedDate.replace('Z','');
          this.alarmTemplateList[this.tabIndex]['updatedDate'] =  response.modifiedDate;
          if(this.eventMasterId){
            this.eventMasterId = '';
            this.roleService.showToasterMsg("Event has been updated Successfully",'success');
          }else{
            this.roleService.showToasterMsg("Event has been added Successfully",'success');
            }
          localStorage.removeItem("isTemplateEdit");
          this.getTemplatesRelavantData();
        }, err => {
          this.isApiCalling = false;
          this.spinner.hide();
          let errors = err.error;
          if(Array.isArray(errors)){
            errors.forEach(element => {
              this.roleService.showToasterMsg(element.msg,'danger');
            });
          }
        });
    }
    }else if(type == 'cancel'){
      if(this.hasOnlyReadAccess)
        this.onConfirm('', this.tabIndex);
      else
        this.getConfirmationBox('', this.tabIndex);
    } else if (type == 'edit' || type == 'view') {
      $('.cke_1_contents').addClass('h-80');
      if(type == 'edit') {
        localStorage.setItem("isTemplateEdit", 'true');
      }
      this.isFilter = false;
      this.eventMasterId = element.eventMasterId;
      this.spinner.show();
      this.alarmTemplateService.getEventById(this.eventMasterId).subscribe(response => {
        this.spinner.hide();
        delete response.data['machineType'];
        this.eventFormGroup.patchValue(response.data);
        if(response.data.levelMessages.length){
          this.escalationLevels.forEach(levelNumber=>{
            if(!this.hideSMSEmailBodyofLevel.includes(levelNumber)){
              let emailData = response.data.levelMessages.filter(el => el['levelNumber'] == levelNumber && el['notificationMethod'] == AlarmEventEscalationLevelNotificationMethods.Email);
              if(emailData.length){
                this.eventFormGroup.controls['level'+levelNumber+'EmailMessageBody'].patchValue(emailData);
              }

              let smsData = response.data.levelMessages.filter(el => el['levelNumber'] == levelNumber && el['notificationMethod'] == AlarmEventEscalationLevelNotificationMethods.SMS);
              if(smsData.length){
                this.eventFormGroup.controls['level'+levelNumber+'TextMessageBody'].patchValue(smsData);
              }
            }
          })
        }
        if(this.eventFormGroup.value['alarmEscalation'] != AlarmEventEscalationsEnum.Defer){
          this.eventFormGroup.controls['deferTimeMins'].disable();
        }else{
          this.eventFormGroup.controls['deferTimeMins'].enable();
        }
        let mTypes = [];
        response.data['machineModelType'].forEach(machineModelId => {
          let mt = this.machineTypes.filter(el => el.machineModelId == machineModelId);
          if(mt.length)
            mTypes.push({machineModelId:machineModelId,machineModelName: mt[0]['machineModelName']})
        });

        this.eventFormGroup.patchValue({machineType:mTypes});
        // GD2-2416
        if(type=='view') {
          Object.keys(this.eventFormGroup.controls).forEach(key => {
            this.eventFormGroup.controls[key].disable();
          });
          this.eventFormGroup.controls['machineType'].disable();
        }
        this.escalationLevels.forEach(levelNumber=>{
          if(this.eventFormGroup.value['alarmEscalation'] == AlarmEventEscalationsEnum.None){
            this.eventFormGroup.patchValue({['escalationLevel'+levelNumber]:false});
          }
          this.enableDisableLevelInputs(levelNumber, type);
        });

      }, err => {
        this.spinner.hide();
        let errors = err.error;
        if(Array.isArray(errors)){
          errors.forEach(element => {
            this.roleService.showToasterMsg(element.msg,'danger');
          });
        }
      });
    }else if(type == 'addEvent' || type == 'filter'){
      this.eventMasterId = '';
      this.isFilter = !this.isFilter;
      if(this.isFilter){
        let that = this;
        $.each( this.filter, function( key, value ) {
          if(key != 'search'){
            that.filter[key] = [];
          }
        });
        this.getEventList();
      }
      localStorage.setItem("isTemplateEdit", 'true');
      if(type == 'filter')
        this.formReset();
    }else if(type == 'apply'){
      this.getEventList();
    }else if(type == 'clear'){
      let that = this;
      $.each( this.filter, function( key, value ) {
        if(key != 'search'){
          that.filter[key] = [];
        }
      });
      that.getEventList();
    }
  }
  onEditorFocus(){
    this.isShowEditorValidation = this.isShowEditorValidation;
  }
  addLanguage(){
    this.isShowEditorValidation = true;
    if(this.languageFormGroup.status === 'INVALID'){
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      this.languageFormGroup.markAllAsTouched();
    }else{
      this.isShowEditorValidation = false;
      if(this.isEditedLanguageIndex != -1){
        this.languagesList[this.isEditedLanguageIndex]["eventMessage"]= (this.selectedTypeForMsgBody == 'Email')?this.languageFormGroup.controls['eventMessageEmail'].value:this.languageFormGroup.controls['eventMessage'].value;
        this.languagesList[this.isEditedLanguageIndex]["emailSubject"]= this.languageFormGroup.controls['emailSubject'].value;
        this.resetEditlanguage();
      }else{
        let isLanguagesExists = this.languagesList.filter(el => el['languageMasterId'] == this.languageFormGroup.controls['languageMasterId'].value);
        if(isLanguagesExists.length){
          this.toaster.open({
            text: 'Selected language already exists',
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        }else{
          let languagesList = this.languagesList;
          let languageDetails = this.languages.filter(el => el['masterDataId'] == this.languageFormGroup.controls['languageMasterId'].value);

          languagesList.push({
            "levelNumber": this.selectedLevelForMsgBody,
            "languageName":languageDetails[0]['text'],
            "languageMasterId": this.languageFormGroup.controls['languageMasterId'].value,
            "eventMessage": (this.selectedTypeForMsgBody == 'Email')?this.languageFormGroup.controls['eventMessageEmail'].value:this.languageFormGroup.controls['eventMessage'].value,
            "emailSubject": this.languageFormGroup.controls['emailSubject'].value,
            "notificationMethod": (this.selectedTypeForMsgBody == 'Email')?AlarmEventEscalationLevelNotificationMethods.Email:AlarmEventEscalationLevelNotificationMethods.SMS
          });
          this.languagesList = [];

          setTimeout(() => {
            this.languagesList = languagesList;
          }, 100);
          this.resetLanguageForm();
        }
      }
    }
  }
  cancelEditLanguage(){
    this.isShowEditorValidation = false;
    this.isPreviewScreen = false;
    this.resetEditlanguage();
    this.resetLanguageForm();
  }
  resetEditlanguage(){
    this.isEditedLanguageIndex = -1;
    this.languageFormGroup.controls['languageMasterId'].enable();
    this.resetLanguageForm();
  }
  editMsgBody(index, type){
    this.isPreviewScreen = (type == 'view');
    this.isEditedLanguageIndex = index == undefined ? this.isEditedLanguageIndex : index;
    index = index == undefined ? this.isEditedLanguageIndex : index;
    if (type == 'edit') {
      this.languageFormGroup.controls['eventMessage'].enable();
      this.languageFormGroup.controls['eventMessageEmail'].enable();
      this.languageFormGroup.controls['emailSubject'].enable();
    }
    this.isShowEditorValidation = true;
    // this.isEditedLanguageIndex = index;
    this.languageFormGroup.patchValue({
      languageMasterId:this.languagesList[index]['languageMasterId'],
      eventMessage:this.languagesList[index]['eventMessage'],
      eventMessageEmail:this.languagesList[index]['eventMessage'],
      emailSubject:this.languagesList[index]['emailSubject']
    });

    this.languageFormGroup.markAllAsTouched();
    if(this.selectedTypeForMsgBody == 'Text'){
    let languageDetails = this.languages.filter(el => el['masterDataId'] == this.languageFormGroup.controls['languageMasterId'].value);
      this.languageFormGroup.get('eventMessage').clearValidators();
      this.languageFormGroup.get('eventMessageEmail').clearValidators();
      if(languageDetails[0]['text'] == 'Chinese') {
        this.languageFormGroup.controls['eventMessage'].setValidators([Validators.required,Validators.maxLength(parseInt(this.userService.defaultUIConfiuraitions.eventChineseSMSMaxLength))]);
      }else{
        this.languageFormGroup.controls['eventMessage'].setValidators([Validators.required,Validators.maxLength(parseInt(this.userService.defaultUIConfiuraitions.eventEnglishSMSMaxLength))]);
      }
    }else{
      this.languageFormGroup.get('eventMessage').clearValidators();
      this.languageFormGroup.controls['eventMessageEmail'].setValidators([Validators.required]);

    }
    this.languageFormGroup.controls['eventMessage'].updateValueAndValidity();
    this.languageFormGroup.controls['eventMessageEmail'].updateValueAndValidity();
    this.languageFormGroup.controls['languageMasterId'].disable();
    if (type == 'view') {
      this.languageFormGroup.controls['eventMessage'].disable();
      this.languageFormGroup.controls['eventMessageEmail'].disable();
      this.languageFormGroup.controls['emailSubject'].disable();
      if (this.selectedTypeForMsgBody != 'Text') {
        this.getEventEmailPreview()
      }
    }
  }
  resetLanguageForm(){
    this.isPreviewScreen = false;
    this.languageFormGroup.reset();
    if(!this.hasOnlyReadAccess) {
      Object.keys(this.languageFormGroup.controls).forEach(key => {
        this.languageFormGroup.controls[key].enable();
      });
    } else {
      Object.keys(this.languageFormGroup.controls).forEach(key => {
        this.languageFormGroup.controls[key].disable();
      });
    }
    this.setEmailPreviewData('');
    this.languageFormGroup.controls['languageMasterId'].patchValue('');
  }
  saveLanguage(){
    let englishLanguageDetails = this.languagesList.filter(el => el['languageName'] == this.userService.defaultUIConfiuraitions.eventRequiredLanguageName);
    if(!this.languagesList.length || !englishLanguageDetails.length){
      this.toaster.open({
        text: 'It is mandatory that you enter the English version of the notification message that is to be sent when this alarm is triggered. Please enter this before continuing',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }else{
      this.getConfirmationBox('saveLanguages', this.tabIndex);
    }
  }
  saveLanguageWithEvent(){
     this.eventFormGroup.controls['level'+this.selectedLevelForMsgBody+this.selectedTypeForMsgBody+'MessageBody'].patchValue(this.languagesList)
      this.resetLanguageForm();
      this.changefilter();
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
  }
  createEventForm(){
    let numberPattren =  "^[0-9]*$";
    let alphaNumericPattern = "^[0-9a-zA-Z]*$";
    this.eventFormGroup = new FormGroup({
      'alarmTemplateId':new FormControl('', [Validators.required]),
      'eventId': new FormControl('', [Validators.required,Validators.maxLength(200),Validators.pattern(alphaNumericPattern)]),
      'eventCode':new FormControl('', [Validators.required,Validators.maxLength(100)]),
      'eventOrigin':new FormControl(''),
      'friendlyName':new FormControl('', [Validators.required,Validators.maxLength(200)]),
      'commonEventCode':new FormControl('', [Validators.required,Validators.maxLength(100)]),
      'machineType':new FormControl(null, [Validators.required]),
      'description':new FormControl('', [Validators.maxLength(2500)]),
      'eventThresholdMins':new FormControl('', [Validators.required,Validators.max(89280),Validators.pattern(numberPattren)]),
      'eventCategoryType':new FormControl('', [Validators.required]),
      'causesLostTradingHours':new FormControl('', [Validators.required]),
      'alarmEscalation':new FormControl('', [Validators.required]),
      'deferTimeMins':new FormControl('', [Validators.required,Validators.max(2880),Validators.pattern(numberPattren)]),
    });


    this.escalationLevels.forEach(i=>{
      this.eventFormGroup.addControl('escalationLevel'+i,new FormControl(true, [Validators.required]));
      this.eventFormGroup.addControl('notificationsNumberEscalationLevel'+i ,new FormControl('', [Validators.required,Validators.min(1),Validators.max(10),Validators.pattern(numberPattren)]),);
      this.eventFormGroup.addControl('notificationsDurationLevel'+i+'Mins' ,new FormControl('', [Validators.required,Validators.max(89280),Validators.pattern(numberPattren)]));
      if(!this.hideSMSEmailBodyofLevel.includes(i)){
        this.eventFormGroup.addControl('level'+i+'TextMessageBody' ,new FormControl('', [Validators.required]));
        this.eventFormGroup.addControl('level'+i+'EmailMessageBody' ,new FormControl('', [Validators.required]));
      }
    });
    let that = this;
    $.each( this.filter, function( key, value ) {
      let filterName:any = key;
      that.eventFormGroup.addControl('filter-'+filterName,new FormControl(true));
    });
  }
  sortData(e) {
    e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
    this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
    this.params.pageNumber = 1;
    this.getEventList();
  }
  clearSearch() {
    this.filter.search = '';
    this.params.pageNumber = 1
    this.getEventList();
  }
  getEventList(fromClear = undefined) {
    this.spinner.show();
    if (!this.isFilterClear) {
      this.isShowFilter = false;
    }
    this.isFilterClear = false;
    let obj = {};

    this.isFilterApply = false;

    obj['searchText'] = this.filter.search;
    let that = this;

    $.each( this.filter, function( key, value ) {
     if(that.filter[key] && that.filter[key].length){
       if(key != 'search'){
          let ids = [];
          that.filter[key].forEach(e=>{
            if(e.value || e.machineModelId)
              ids.push((e.value)?e.value:e.machineModelId);
          })
          if(ids.length)
            obj[key] =ids;
        }
      }
    });

    if (this.params.sortBy != undefined) { obj['orderByColumn'] = this.params.sortBy };

    obj['alarmTemplateId']= this.alarmTemplateId;
    this.alarmTemplateService.getEventList(obj).subscribe(response => {
      this.isPageDataLoad = true;

      this.recordsTotal = response ?.params ?.count;
      this.spinner.hide();
      this.isPageDataLoad = true;
      if (response && response['data'].length) {
        this.eventList = response['data']
      } else {
        this.eventList = [];
      }
    }, err => {
      this.isPageDataLoad = true;
      this.spinner.hide();
      this.eventList = [];
      let errors = err.error;
      if(Array.isArray(errors)){
        errors.forEach(element => {
          this.roleService.showToasterMsg(element.msg,'danger');
        });
      }
    });
  }
  getMachineModelTypes() {
    let propositionTypeId = this.alarmTemplateList.find(x => x.alarmTemplateId == this.alarmTemplateId)?.propositionTypeId;
    this.propositionType = this.alarmTemplateList.find(x => x.alarmTemplateId == this.alarmTemplateId)?.propositionType;
    this.isConcession = this.propositionType == "Concession";
    this.machineService.getMachineModelTypeLookup('',propositionTypeId).subscribe(response => {
        if (response) {
            this.machineTypes = response['data'];
          }
    }, err => {
        this.machineTypes = [];
    });
  }
  escalationLevelToggleChange(e,levelNumber){
    if(e.checked){
      this.eventFormGroup.controls['notificationsNumberEscalationLevel'+levelNumber].enable();
      this.eventFormGroup.controls['notificationsDurationLevel'+levelNumber+'Mins'].enable();
      if(!this.hideSMSEmailBodyofLevel.includes(levelNumber)){
        this.eventFormGroup.controls['level'+levelNumber+'TextMessageBody'].enable();
        this.eventFormGroup.controls['level'+levelNumber+'EmailMessageBody'].enable();
      }
    }else{
      this.eventFormGroup.controls['notificationsNumberEscalationLevel'+levelNumber].disable();
      this.eventFormGroup.controls['notificationsDurationLevel'+levelNumber+'Mins'].disable();
      if(!this.hideSMSEmailBodyofLevel.includes(levelNumber)){
        this.eventFormGroup.controls['level'+levelNumber+'TextMessageBody'].disable();
        this.eventFormGroup.controls['level'+levelNumber+'EmailMessageBody'].disable();
      }
    }
  }
  alarmEscalationChange(val){
    if(val == AlarmEventEscalationsEnum.None){
      this.escalationLevels.forEach(levelNumber=>{
          this.eventFormGroup.patchValue({['escalationLevel'+levelNumber]:false});
          this.eventFormGroup.controls['escalationLevel'+levelNumber].disable();
          this.enableDisableLevelInputs(levelNumber);

        });
        this.eventFormGroup.controls['deferTimeMins'].disable();
    }else{
      if(val == AlarmEventEscalationsEnum.Immediate){
        this.eventFormGroup.controls['deferTimeMins'].patchValue(0);
        this.eventFormGroup.controls['deferTimeMins'].disable();
      }else{
        this.eventFormGroup.controls['deferTimeMins'].enable();
      }
      this.escalationLevels.forEach(levelNumber=>{
        this.eventFormGroup.controls['escalationLevel'+levelNumber].enable();
        this.enableDisableLevelInputs(levelNumber);
      });
    }

  }
  getConfirmationBox(type: string, tabindex: number) {
    let msg = 'All unsaved changes will be lost. Are you sure you want to exit?';
    if(type == 'saveLanguages'){
      msg = 'Do you want to save your changes?';
    }
    this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: msg,
        deviceName: '',
        status: '',
        title: 'Confirmation',
        type: type,
        tabindex: tabindex
    }
    const eventPopupModel = new MatDialogConfig();
    eventPopupModel.height = 'auto';
    eventPopupModel.width = '670px';
    eventPopupModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, eventPopupModel);
  }
  onConfirm(type: string, tabindex: number) {
    if(type == 'saveLanguages'){
        this.spinner.show();
        setTimeout(() => {
          this.saveLanguageWithEvent();
          this.dialogRef.close();
        }, 1000);
    }else if(type == 'delete'){
      this.spinner.show();
      this.alarmTemplateService.deleteEventByById(this.selectedEventIdforDelete).subscribe(response => {
        this.spinner.hide();
        this.selectedEventIdforDelete = '';
        this.roleService.showToasterMsg("Event has been deleted Successfully",'success');
        if(this.eventList.length <= 1){
          this.filter= {
            search:'',
            eventIds: [],
            eventCodes: [],
            eventCategoryTypes: [],
            causesLostTradingHours: [],
            deferTimeMins: [],
            machineTypes: [],
            friendlyNames: [],
            commonEventCodes: [],
            eventThresholdMins: [],
            alarmEscalations: []
          };
        }
        this.getEventList();
        this.getFilterLookup();
        this.dialogRef.close();
      }, err => {
        this.spinner.hide();
        let errors = err.error;
        if(Array.isArray(errors)){
          errors.forEach(element => {
            this.roleService.showToasterMsg(element.msg,'danger');
          });
        }
      });
    }else{
      let previousTabIndex = this.tabIndex;
      this.eventMasterId = '';
      if(!this.hasOnlyReadAccess)
        this.dialogRef.close();
      localStorage.removeItem("isTemplateEdit");
      this.isFilter = true;
      this.formReset();
      if (type == 'tabchanged') {
          this.tabIndex = tabindex
      }
      if (this.nextRoute) {
          this.router.navigate([this.nextRoute]);
      } else {
          this.router.navigate(['/alarm-template']);
          let that = this;
          if(previousTabIndex == tabindex){
            $.each( this.filter, function( key, value ) {
              if(key != 'search'){
                that.filter[key] = [];
              }
            });
            this.getEventList();
          }
      }
    }
  }
  onCancel() {
    this.selectedEventIdforDelete = '';
    this.dialogRef.close();
  }
  async canDeactivate(nextRoute): Promise<void> {
      this.nextRoute = nextRoute;
      this.click('cancel');
  }
  changefilter(level=0,type='',isEdit=false,levelMessages=[],fromMessageCount=false) {
    this.spinner.show();
    if (!this.drawerfilter) {
      if(type == 'Text'){
        this.languagesDisplayedColumns = ['languageName','eventMessage','languageMasterId'];
        this.languageFormGroup.get('emailSubject').clearValidators();
        this.languageFormGroup.controls['emailSubject'].updateValueAndValidity();

        this.languageFormGroup.get('eventMessageEmail').clearValidators();
        this.languageFormGroup.controls['eventMessageEmail'].updateValueAndValidity();

        this.languageFormGroup.controls['eventMessage'].setValidators([Validators.required]);
        this.languageFormGroup.controls['eventMessageEmail'].updateValueAndValidity();
      }else{
        this.isShowEditorValidation = false;
        this.languagesDisplayedColumns = ['languageName','emailSubject','languageMasterId'];
        this.languageFormGroup.controls['emailSubject'].setValidators([Validators.required]);
        this.languageFormGroup.controls['emailSubject'].updateValueAndValidity();

        this.languageFormGroup.get('eventMessage').clearValidators();
        this.languageFormGroup.controls['eventMessage'].updateValueAndValidity();

        this.languageFormGroup.controls['eventMessageEmail'].setValidators([Validators.required]);;
        this.languageFormGroup.controls['eventMessageEmail'].updateValueAndValidity();
      }
    }
    if(levelMessages.length){
      let method = AlarmEventEscalationLevelNotificationMethods.Email;
      if(type == 'Text'){
        method = AlarmEventEscalationLevelNotificationMethods.SMS;
      }

      this.languagesList = levelMessages.filter(el => el['levelNumber'] == level && el['notificationMethod'] == method);
      if(this.languagesDisplayedColumns.includes('languageMasterId')){
        this.languagesDisplayedColumns.pop();
      }
    }else{
      if(!this.languagesDisplayedColumns.includes('languageMasterId')){
        this.languagesDisplayedColumns.push('languageMasterId');
      }
    }
    if(level){
      this.selectedLevelForMsgBody = level;
      this.selectedTypeForMsgBody = type;
    }
    if(this.hasOnlyReadAccess)
      isEdit = false;

    this.roleService.setPopupScroll('section-message-count');
    this.roleService.setPopupScroll('section-message');

    if (!this.drawerfilter) {
      $(".sidebar-mini").addClass("filter-open");
      if(this.eventFormGroup.controls['level'+this.selectedLevelForMsgBody+''+this.selectedTypeForMsgBody+'MessageBody'].value){
        let list = this.eventFormGroup.controls['level'+this.selectedLevelForMsgBody+''+this.selectedTypeForMsgBody+'MessageBody'].value
        this.languagesList = [];
        list.forEach(element => {
          this.languagesList.push({
          "levelNumber": element['levelNumber'],
          "languageName":element['languageName'],
          "languageMasterId": element['languageMasterId'],
          "emailSubject": element['emailSubject'],
          "eventMessage": element['eventMessage'],
          "eventMessageEmail": element['eventMessage'],
          "notificationMethod": element['notificationMethod']
        });
      });
      }
    }
    else {
      this.resetLanguageForm();
      $(".sidebar-mini").removeClass("filter-open");
      this.roleService.resetPopupScroll();
    }
    if(this.hasOnlyReadAccess){
      Object.keys(this.languageFormGroup.controls).forEach(key => {
        this.languageFormGroup.controls[key].disable();
      });
    }

    setTimeout(() => {
    this.drawerfilter = !this.drawerfilter;
    this.isLanguageEdit = isEdit;
    this.fromMessageCount = fromMessageCount;
    this.spinner.hide();
    if (!this.drawerfilter) {
      setTimeout(() => {
      this.selectedLevelForMsgBody = 0;
      this.selectedTypeForMsgBody = '';
      this.languagesList = [];
      this.resetEditlanguage();
      }, 100);
    }
    }, this.roleService.scrollSetTimeoutTime);
  }
  changeLanguage(){
    if(this.selectedTypeForMsgBody == 'Text' && this.languageFormGroup.controls['languageMasterId'].value){
      let languageDetails = this.languages.filter(el => el['masterDataId'] == this.languageFormGroup.controls['languageMasterId'].value);
      this.languageFormGroup.get('eventMessage').clearValidators();

      if(languageDetails[0]['text'] == 'Chinese') {
        this.languageFormGroup.controls['eventMessage'].setValidators([Validators.required,Validators.maxLength(parseInt(this.userService.defaultUIConfiuraitions.eventChineseSMSMaxLength))]);
      }else{
        this.languageFormGroup.controls['eventMessage'].setValidators([Validators.required,Validators.maxLength(parseInt(this.userService.defaultUIConfiuraitions.eventEnglishSMSMaxLength))]);
      }
      this.languageFormGroup.controls['eventMessage'].updateValueAndValidity();

      this.languageFormGroup.get('eventMessageEmail').clearValidators();
      this.languageFormGroup.controls['eventMessageEmail'].updateValueAndValidity();
    }else{
      this.languageFormGroup.get('eventMessage').clearValidators();
      this.languageFormGroup.controls['eventMessage'].updateValueAndValidity();

      this.languageFormGroup.controls['eventMessageEmail'].setValidators([Validators.required]);
      this.languageFormGroup.controls['eventMessageEmail'].updateValueAndValidity();
    }
  }
  openDialog(type: string = '', element = {}): void {
    this.selectedEventIdforDelete = element['eventMasterId'];
    this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'Are you sure want to delete this event?',
        deviceName: '',
        status: '',
        title: 'Confirmation',
        type: type,
        tabindex: 0
    }
    const eventPopupModel = new MatDialogConfig();
    eventPopupModel.height = 'auto';
    eventPopupModel.width = '670px';
    eventPopupModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, eventPopupModel);
  }

  getEventEmailPreview() {
    var escalationLevel = this.selectedLevelForMsgBody == 1 ? "Level 1" : "Level 2";
    this.alarmTemplateService.getEventEmailPreview(escalationLevel,this.languageFormGroup.controls['languageMasterId'].value).subscribe(data => {
      if (data.data != null && data.data != '' && data.data != undefined){
        data.data = data.data.replace(/\n/gi,"");
        data.data = data.data.replace(/\r/gi,"");
        data.data = data.data.replace('<a href="#"','javascript:void(0)'); // to restict click on mail preview
        data.data = data.data.replace("{emailbody}",this.languageFormGroup.controls.eventMessageEmail.value);
        this.languageFormGroup.controls.eventMessageEmail.patchValue(data.data);
        this.setEmailPreviewData(data.data);
      }
    });
  }

  setEmailPreviewData(data) {
    var iframe:any = document.getElementById('emailPreview1'),
    iframedoc = iframe?.contentDocument || iframe?.contentWindow.document;
    if (iframe != null) {
      iframedoc.body.innerHTML = data;
      iframe.height = data.length > 0 ? "500px" : "150px";
    }
  }
}
