import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { DialogComponent } from '@costa-coffee/pattern-lib';

@Injectable({
  providedIn: 'root',
})
export class CcDialogService {

  private dialogs: { [key: string]: ComponentRef<DialogComponent> } = {};

  constructor(
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {
  }

  openDialog(dialogId?: string): { key: string, component: ComponentRef<DialogComponent> } {
    while (!dialogId || Object.keys(this.dialogs).includes(dialogId)) {
      dialogId = `dialog-${Math.round(Math.random() * 10000)}`;
    }
    this.dialogs[dialogId] = this.resolver.resolveComponentFactory(DialogComponent).create(this.injector);
    this.appRef.attachView(this.dialogs[dialogId].hostView);
    const domElem = (this.dialogs[dialogId].hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    document.body.classList.add('overflow-hidden');
    this.dialogs[dialogId].instance.visible = true;
    return { key: dialogId, component: this.dialogs[dialogId] };
  }

  closeDialog(dialogId: string): void {
    this.dialogs[dialogId].instance.visible = false;
    this.appRef.detachView(this.dialogs[dialogId].hostView);
    this.dialogs[dialogId].destroy();
    delete this.dialogs[dialogId];
    if (Object.keys(this.dialogs).length === 0) {
      document.body.classList.remove('overflow-hidden');
    }
  }

  closeAll(): void {
    Object.keys(this.dialogs).forEach(key => {
      this.closeDialog(key);
    });
  }
}
