import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RemoteUpdateCancelTaskRequest } from '../../dtos/remote-update-cancel-task-request';
import { RemoteUpdateCancelTaskResponse } from '../../dtos/remote-update-cancel-task-response';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { configHeaderRemoteUpdate } from '../config/config-header-remote-update';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class RemoteUpdateCancelTaskService {
  constructor(private httpClient: HttpClient,
    private cookieService: CookieService,
    ) {}

  cookieName: string = environment.cookieName

  execute(body: RemoteUpdateCancelTaskRequest): Observable<RemoteUpdateCancelTaskResponse> {
  
  var configHeader = {
			headers: {
				'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
				'auth_custom': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

    return this.httpClient.put<RemoteUpdateCancelTaskResponse>(environment.apiUrl.remoteUpdate + "/v1/remoteupdatetask/cancel", body, configHeader)
      .pipe(map(response => response ));
  }
}
