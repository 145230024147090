
export enum PriceCardTypeEnum
{
    Normal = 0,
    Free = 1,
    Test = 2,
}

    export class PriceCardTypeLookupConstant
    {
        Normal = "Normal";
        Free ="Free";
        Test = "Test";
    }