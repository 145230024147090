import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TableMachineSelectionComponent } from './components/table-machine-selection/table-machine-selection.component';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { PackageSelectItem } from 'src/app/components/remote-update-tasks/dtos/get-package-select-list.model';
import { RemoteUpdateTaskType } from 'src/app/components/remote-update-tasks/enums/remote-update-task-type.enum';
import { DialogComponent } from '@costa-coffee/pattern-lib';
import { Subscription } from 'rxjs';
import { CcDialogService } from '../../../../../../services/ui/cc-dialog.service';

@Component({
  selector: 'app-step-machine-selection',
  templateUrl: './step-machine-selection.component.html',
  styleUrls: ['./step-machine-selection.component.css', './../new-task-step-common.css'],
})
export class StepMachineSelectionComponent implements OnInit {
  @ViewChild(TableMachineSelectionComponent, { static: false }) tableMachineSelectionComponent: TableMachineSelectionComponent;
  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();
  @Input() package: PackageSelectItem = {} as PackageSelectItem;

  constructor(private dialogService: CcDialogService) {}

  ngOnInit(): void {}

  isCertificateManagement = false;

  public loadMachine() {
    this.isCertificateManagement = this.newTaskRequest.TaskType === RemoteUpdateTaskType.CertificateManagement;
    setTimeout(() => {
      this.tableMachineSelectionComponent.clearMachineCheckeds();
      this.tableMachineSelectionComponent.fetchMachineDataAPI(this.newTaskRequest, this.package);
    }, 10);
  }

  nextStep() {
    if (this.tableMachineSelectionComponent.hasMachineSelected()) {
      const machinesSelected = this.tableMachineSelectionComponent.getMachinesSelected();
      this.newTaskRequest.Machines = Array.from(machinesSelected);
      this.nextStepEvent.emit();
    } else {
      const {key, component} = this.dialogService.openDialog();
      component.instance.title = 'Selection Error';
      component.instance.description = 'Error with machine selection, please select at least one machine.';
      component.instance.primary = 'Ok';
      const dialogSubs = new Subscription();
      dialogSubs.add(
        component.instance.primaryClick.subscribe(() => {
          this.dialogService.closeDialog(key);
          dialogSubs.unsubscribe();
        })
      );
    }
  }

  backStep() {
    this.backStepEvent.emit();
  }
}
