import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoleService } from 'src/app/services/role/role.service';
import { DropdownOptions } from 'src/app/components/dropdown-drawer/dtos/dropdown-options';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { DropDrownDrawerModel } from './dtos/dropdown-drawer.model';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-dropdown-drawer',
  templateUrl: './dropdown-drawer.component.html',
})
export class DropdownDrawerComponent implements OnInit {
  @Input() title: string;
  @Input() eventType: string;
  @Input() dropdownOptions: DropdownOptions;
  @Input() hasSaveButton: boolean = true;
  @Input() hasCancelButton: boolean = true;

  @Output() onDrawer = new EventEmitter<string>();
  @Output() onConfirm = new EventEmitter<string>();
  @Output() onCancel = new EventEmitter<string>();

  drawerName: string;
  isDrawerEnabled: boolean = false;
  dropDownDrawerFormGroup: RxFormGroup;
  dropDownDrawerModel = new DropDrownDrawerModel();
  selectedOptionPropertyName: string = 'optionSelected';

  constructor(public roleService: RoleService, private formBuilder: RxFormBuilder, private toaster: Toaster) {}

  ngOnInit() {
    this.drawerName = 'section-' + this.eventType + '-drawer';
    this.dropDownDrawerFormGroup = this.formBuilder.formGroup(this.dropDownDrawerModel) as RxFormGroup;
  }

  openDrawer() {
    if (!this.isDrawerEnabled) {
      this.roleService.setPopupScroll(this.drawerName);
      $('.sidebar-mini').addClass('filter-open');
      setTimeout(() => {
        this.onDrawer.emit();
      }, this.roleService.scrollSetTimeoutTime);
      this.isDrawerEnabled = !this.isDrawerEnabled;
    }
  }

  closeDrawer() {
    if (this.isDrawerEnabled) {
      this.roleService.setPopupScroll(this.drawerName);
      $('.sidebar-mini').removeClass('filter-open');
      this.roleService.resetPopupScroll();
      this.isDrawerEnabled = !this.isDrawerEnabled;
    }
  }

  save() {
    if (this.dropDownDrawerFormGroup.status === 'INVALID' || !this.dropDownDrawerFormGroup.get(this.selectedOptionPropertyName).value) {
      this.dropDownDrawerFormGroup.markAllAsTouched();
      this.showToaster();
      return;
    }
    this.onConfirm.emit(this.dropDownDrawerFormGroup.value[this.selectedOptionPropertyName]);
  }

  resetSelectedValue() {
    this.dropDownDrawerFormGroup.get(this.selectedOptionPropertyName).reset();
    this.dropDownDrawerFormGroup.get(this.selectedOptionPropertyName).markAsUntouched();
  }

  cancel() {
    this.onCancel.emit(this.dropDownDrawerFormGroup.value[this.selectedOptionPropertyName]);
  }

  showToaster() {
    this.toaster.open({
      text: 'Please provide valid input for the highlighted fields',
      type: 'danger',
      position: 'top-right',
      duration: 10000,
    });
  }
}
