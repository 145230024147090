import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleEnum, RoleService } from 'src/app/services/role/role.service';
import { FAILURE_LOG_TYPES, METHOD_TYPES, SOXAuditLogsService } from 'src/app/services/sox-audit-logs/sox-audit-logs.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { AlertConfirmDialogModel } from '../../common/alert-confirm-dialog/alert-confirm.dialog.model';
import { AzureService } from 'src/app/services/azure/azure.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  screenType = 'add';
  userId: string = '';
  addUserFormGroup: FormGroup;
  entityList: Observable<string[]>;
  entities: any = [];
  userRoleList: any = [];
  entityId: string = '';
  radioObj: any = {
    isActive: true,
  };
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  currentUserId : string = '';
  public cookieName = environment.cookieName;
  isEditEnabled : boolean = true;
  nextRoute = '';
  isDisplayEntity : boolean = true;
  hasAuth = true;
  infoConfirmDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
  @ViewChild("infoUserLimitExceedTemplate") infoUserLimitExceedTemplate: TemplateRef<any>;
  updateUserRole: string = '';
  @ViewChild("infoAuditorAccountChangeTemplate") infoAuditorAccountChangeTemplate: TemplateRef<any>;
  infoAuditorAccountChangeDataModel: AlertConfirmDialogModel = new AlertConfirmDialogModel();
  isOpenEmailLogDialog = false;
  displayedEmailUpdateLogsColumns: string[] = ['emailLog'];
  emailUpdateHistory: any = [];
  hasEmailLogHistoryLoad = false;
  userAutoGeneratedId: string = '';
  enableGridImprovementsFT: boolean;
  showEntityOrRecordName: string = 'Entity';

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toaster: Toaster,
    public roleService: RoleService,
    private dialog: MatDialog,
    public authService: AuthService,
    private UserService: UserService,
    private http: HttpClient,
    private msalService: MsalService,
    private cookieService: CookieService,
    private soxAuditLogsService:SOXAuditLogsService,
    private azureService: AzureService) {
    this.activatedRoute.params.subscribe(params => {
      if (params.userId) {
        this.userId = params.userId;
        this.screenType = 'edit';
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();

    if(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorGRIDR || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel1R || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel2R || this.roleService.objRole.isUserAuditorLevel3 || this.roleService.objRole.isUserAuditorLevel3R) {
      this.router.navigate(['/unauthorized']);
    }
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.screenType)
        this.screenType = queryParams.screenType;
    });
    if(this.screenType == 'edit'){
      localStorage.setItem("isUserEdit",'true');
    }
    if ((this.screenType == 'edit' || this.screenType == 'add') && this.roleService.objRole.isGridR){
      this.router.navigate(['/unauthorized']);
      this.hasAuth = false;
    }
    if(this.hasAuth){
      this.currentUserId = this.cookieService.get(this.cookieName + 'userId');
      if(this.userId.toString().toUpperCase() == this.currentUserId.toString().toUpperCase()){
        this.isEditEnabled = false;
      }
      this.getRoleLookup();
      let validations = [];
      validations.push(Validators.required);
      validations.push(this.requireMatch('entityId',this));
      let userNamePattern = "^[0-9a-zA-Z'-]*$"; //Pattern for allowed Alphanumeric, Apostrophe (') and Hyphen (-) as per story:GD2-1838
      this.addUserFormGroup = new FormGroup({});
      this.addUserFormGroup.addControl('firstName', new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50),Validators.pattern(userNamePattern)]));
      this.addUserFormGroup.addControl('lastName', new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50),Validators.pattern(userNamePattern)]));
      this.addUserFormGroup.addControl('organisationName', new FormControl('', [Validators.maxLength(50)]));
      this.addUserFormGroup.addControl('email', new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(254), Validators.pattern("[A-Za-z0-9.'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}")]));
      this.addUserFormGroup.addControl('role', new FormControl('', [Validators.required]));
      this.addUserFormGroup.addControl('entity', new FormControl('', validations));
      this.addUserFormGroup.addControl('userId', new FormControl(this.userId, []));
      let isActive = { value: this.radioObj['isActive'], disabled: false }
      this.addUserFormGroup.addControl('isActive', new FormControl(isActive));
      this.addUserFormGroup.addControl('roleType', new FormControl({value : '', disabled : true}));
      if (this.screenType == 'read') {
        Object.keys(this.addUserFormGroup.controls).forEach(key => {
          this.addUserFormGroup.controls[key].disable();
        });
      }
      if (this.screenType == 'edit') {
        // this.addUserFormGroup.controls['email'].disable();
      } else{
        this.addUserFormGroup.controls['entity'].disable();
      }

    }
  }

  private async SetFeatureFlagsConfigurations() {
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
    this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
  }

  getEntityLookup(value, patchValue:boolean = false) {
    this.addUserFormGroup.controls['entity'].setValue('');
    this.addUserFormGroup.controls['roleType'].setValue('');
    if(value != '' && value != null && value != undefined){
      var roleTypeValue = this.userRoleList.find(x => x.roleId == value);
      if (roleTypeValue != null && roleTypeValue?.roleType != '') {
        this.addUserFormGroup.controls['roleType'].setValue(roleTypeValue.roleType);
      }

      if(this.userRoleList != null && this.userRoleList.length > 0){
      var obj = this.userRoleList.filter(x => x.roleId == value);
      if(obj != null){
        if(obj[0].name == RoleEnum.SystemAdmin || obj[0].name == RoleEnum.GridR || obj[0].name == RoleEnum.GlobalMarketAdmin || obj[0].name == RoleEnum.UserAuditorGRID || obj[0].name == RoleEnum.UserAuditorGRIDR){
          this.isDisplayEntity = false;
          this.addUserFormGroup.controls["entity"].clearValidators();
          this.addUserFormGroup.controls["entity"].updateValueAndValidity();
        } else {
          this.isDisplayEntity = true;
          let validations = [];
          validations.push(Validators.required);
          validations.push(this.requireMatch('entityId',this));
          this.addUserFormGroup.controls["entity"].setValidators(validations);
          this.addUserFormGroup.controls["entity"].updateValueAndValidity();
          this.addUserFormGroup.controls["entity"].markAsUntouched();
        }
      }
    }
    if(this.isDisplayEntity){
      if(this.screenType != 'read'){
        this.addUserFormGroup.controls['entity'].enable();
      }
    this.UserService.getEntityByRoleIdLookup(value).subscribe(response => {
      if (response && response['data'].length) {
        this.entities = response['data'];
        this.entityList = this.addUserFormGroup.controls['entity'].valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        if(patchValue)
        this.addUserFormGroup.controls['entity'].setValue(this.entityId);
        else
            this.addUserFormGroup.controls['entity'].setValue(null);
      } else {
        this.entities = [];
        this.entityList = this.addUserFormGroup.controls['entity'].valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
          this.addUserFormGroup.controls['entity'].setValue(null);
      }
    }), err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    };
   }
  } else{
    this.addUserFormGroup.controls['entity'].disable();
    this.entities = [];
    this.entityList = this.addUserFormGroup.controls['entity'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.addUserFormGroup.controls['entity'].setValue(null);
  }
  }

  private _filter(value: string): string[] {
    const filterValue = value == null ? null: value.toLowerCase();

    return this.entities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getRoleLookup() {
    let isFromAddScreen = false;
    if(this.screenType != 'read'){
      isFromAddScreen = true;
    }
    this.UserService.getRoleLookup(isFromAddScreen).subscribe(response => {
      if (response && response['data'].length) {
        this.userRoleList = response['data'];
        if (this.userId != '') {
          this.getUserById();
        }
      }
    }), err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    };
  }

  getUserById() {
    this.spinner.show();
    this.UserService.getUserById(this.userId).subscribe(response => {
      if (response && response['data']) {
        this.addUserFormGroup.patchValue({
          "firstName": response['data'].firstName,
          "lastName": response['data'].lastName,
          "organisationName": response['data'].organisationName,
          "email": response['data'].email,
          "role": response['data'].roleId,
          "entity": response['data'].entityId,
          "isActive": response['data'].isActive
        });
        this.userAutoGeneratedId = response['data'].userAutoGeneratedId;
        this.updateUserRole = response['data'].role;
        this.addUserFormGroup.markAllAsTouched();
        this.entityId = response['data'].entityId;
        this.radioObj.isActive = response.data.isActive;
        if (this.screenType == 'edit' && (response['data'].role == RoleEnum.SystemAdmin || response['data'].role == RoleEnum.GlobalMarketAdmin)) {
          localStorage.removeItem("isUserEdit");
          this.router.navigate(['/unauthorized']);
        }
        if ((this.screenType == 'edit' || this.screenType == 'read') && this.roleService.objRole.isCustomerAdmin && (response['data'].role == RoleEnum.SystemAdmin || response['data'].role == RoleEnum.GlobalMarketAdmin || response['data'].role == RoleEnum.GridR || response['data'].role == RoleEnum.MarketAdmin || response['data'].role == RoleEnum.MarketR)){
          localStorage.removeItem("isUserEdit");
          this.router.navigate(['/unauthorized']);
        }
        if(this.screenType == 'read' && (response['data'].role == RoleEnum.SystemAdmin || response['data'].role == RoleEnum.GlobalMarketAdmin)){
          this.isEditEnabled = false;
        }
        this.getEntityLookup(response['data'].roleId, true);

      }
      this.spinner.hide();
    }), err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    };
  }
  saveUserAPI(token) {
    if (this.screenType != 'edit') {
    var body = {
      "firstName": this.addUserFormGroup.controls['firstName'].value,
      "lastName": this.addUserFormGroup.controls['lastName'].value,
      "organisationName": this.addUserFormGroup.controls['organisationName'].value,
      "email": this.addUserFormGroup.controls['email'].value,
      "roleId": this.addUserFormGroup.controls['role'].value,
      "entityId": this.isDisplayEntity == true ? this.addUserFormGroup.controls['entity'].value : null,
      "entity": null,
      "isActive": true
    }
    this.UserService.saveUser(body,token).then(res => res.subscribe(response => {
            if (response) {
              this.toaster.open({
                text: 'User added successfully',
                type: 'success',
                position: 'top-right',
                duration: 10000
              });
            }
            this.spinner.hide();
            localStorage.removeItem("isUserEdit");
            this.router.navigateByUrl('/user');
          }, err => {
            this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        if (!errors.length && (err.status == 0 || err.status == 500 || err.status == 502)) {
            this.toaster.open({
                text: 'Something went wrong. Please try again after some time.',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
            this.router.navigate(['/user']);
        }
        else {
          errors.forEach(element => {
              if (element.code && element.code == 'CustomerAdminLimitExceed') {
                  this.onCustomerAdminLimitExceeded(element.msg);
              }
              else {
                  this.toaster.open({
                      text: element.msg,
                      type: 'danger',
                      position: 'top-right',
                      duration: 10000
                  });
              }
          });
        }
       }));
  }
 else {
  var obj = {
    "userId": this.addUserFormGroup.controls['userId'].value,
    "firstName": this.addUserFormGroup.controls['firstName'].value,
    "lastName": this.addUserFormGroup.controls['lastName'].value,
    "email": this.addUserFormGroup.controls['email'].value,
    "organisationName": this.addUserFormGroup.controls['organisationName'].value,
    "roleId": this.addUserFormGroup.controls['role'].value,
    "entityId": this.isDisplayEntity == true ? this.addUserFormGroup.controls['entity'].value : null,
    "isActive": this.addUserFormGroup.controls['isActive'].value
  }
  this.UserService.updateUser(obj,this.userId, token).then(res => res.subscribe(response => {
    if (response) {
      this.toaster.open({
        text: 'User updated successfully',
        type: 'success',
        position: 'top-right',
        duration: 10000
          });
  }
    this.spinner.hide();
    localStorage.removeItem("isUserEdit");
    this.router.navigateByUrl('/user');
  }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (!errors.length && (err.status == 0 || err.status == 500 || err.status == 502)) {
          this.toaster.open({
              text: 'Something went wrong. Please try again after some time.',
              type: 'danger',
              position: 'top-right',
              duration: 10000
          });
          this.router.navigate(['/user']);
      }
      else {
          errors.forEach(element => {
              if (element.code && element.code == 'CustomerAdminLimitExceed') {
                  this.onCustomerAdminLimitExceeded(element.msg);
              }
              else {
                  this.toaster.open({
                      text: element.msg,
                      type: 'danger',
                      position: 'top-right',
                      duration: 10000
                  });
              }
          });
      }
  }));
  }
}
  saveUser() {
    this.addUserFormGroup.markAllAsTouched();
    if(this.addUserFormGroup.status === 'INVALID'){
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });

    }else{

    this.spinner.show();
      const account = this.msalService.instance.getAllAccounts()[0];
      const accessTokenRequest = {
        scopes: [environment.msal.apiscope],
        account: account
      }

    var me = this;
    this.msalService.instance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
      if (accessTokenResponse != null && accessTokenResponse.accessToken != '') {
          this.saveUserAPI(accessTokenResponse.accessToken);

        } else {
        this.spinner.hide();
          this.toaster.open({
            text: "Invalid token",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          this.msalService.logoutRedirect();
          this.authService.removeAllCookies();
          localStorage.clear();
        }
      }, err => {
        this.soxAuditLogsService.addFailureLog({
          statusCode: 500,
          auditLogAction: (this.screenType != 'add')?METHOD_TYPES['PUT']:METHOD_TYPES['POST'],
          id : (this.screenType != 'add')?this.userId:null,
          FirstName : this.addUserFormGroup.controls['firstName'].value,
          LastName: this.addUserFormGroup.controls['lastName'].value,
          organisationName: this.addUserFormGroup.controls['organisationName'].value,
          roleId : this.addUserFormGroup.controls['role'].value,
          entityId : (this.addUserFormGroup.controls['entity'].value)?this.addUserFormGroup.controls['entity'].value:null,
          email :this.addUserFormGroup.controls['email'].value,
          isActive : (this.screenType != 'add')?this.addUserFormGroup.controls['isActive'].value:null

      },FAILURE_LOG_TYPES.user).subscribe();
        setTimeout(() => {
        this.spinner.hide();
        this.msalService.logoutRedirect();
        this.authService.removeAllCookies();
        localStorage.clear();
        }, 200);
      });
  }
  }

  displayFn(): (id: number) => string {
    return (id: number) => {
      if (id) {
        if (this.entities.find(obj => obj.entityId == id)) {
          let obj = this.entities.find(obj => obj.entityId == id);
          if (Object.keys(obj).length) {
            return obj.name
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }

  cancelUser(){
    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
      deviceName: '',
      status: '',
      title: 'Confirmation',
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }

  onConfirm() {
    this.dialogRef.close();
    localStorage.removeItem("isUserEdit");
    if(this.nextRoute){
      this.router.navigate([this.nextRoute]);
    }else{
      this.router.navigate(['/user']);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  backUser(){
    this.router.navigate(['/user']);
  }

  EditUser() {
    this.screenType = 'edit';
    this.getRoleLookup();
    Object.keys(this.addUserFormGroup.controls).forEach(key => {
      if(key != 'roleType'){
      this.addUserFormGroup.controls[key].enable();
      }
    });
  }

  async canDeactivate(nextRoute): Promise<void> {
    this.nextRoute = nextRoute;
    this.cancelUser();
  }

  requireMatch = (uniqueId,options) => {
      return (control: FormControl) => {
        const selection: any = control.value;
        if (selection != '') {
          var lst = [];
          if (options['entityList'] != undefined && options['entityList'] != null) {
            options['entityList'].subscribe(x => {
              lst = x;
            })
            var obj = lst.filter(x => x.entityId == selection);
            if (obj == null || obj.length == 0) {
              return { requireMatch: true };
            }
            else {
              return null;
            }
          } else {
            return null;
          }
        } else { return null; }
  }
  }

  onCustomerAdminLimitExceeded(message) {
    const limitExceedDialog = new MatDialogConfig();
    limitExceedDialog.height = 'auto';
    limitExceedDialog.width = '670px';
    limitExceedDialog.disableClose = true;
    this.infoConfirmDataModel = new AlertConfirmDialogModel();
    this.infoConfirmDataModel.action = "Information";
    this.infoConfirmDataModel.dialogId = "customer-limit-exceed";
    this.infoConfirmDataModel.okButtonName = "Ok";
    this.infoConfirmDataModel.isVisibleCancel = false;
    this.infoConfirmDataModel.title = 'ALERT';
    this.infoConfirmDataModel.message = message;
    this.dialogRef = this.dialog.open(this.infoUserLimitExceedTemplate, limitExceedDialog);
  }

  onConfirmLimit() {
    this.dialogRef.close();
  }

  onAuditorAccountSetToInactive() {
    // GD2-2520 AC 13,14
    if (this.screenType == 'edit' && this.updateUserRole != '' && this.updateUserRole != null && !this.radioObj['isActive']
        && (this.updateUserRole == RoleEnum.UserAuditorGRID || this.updateUserRole == RoleEnum.UserAuditorGRIDR || this.updateUserRole == RoleEnum.UserAuditorLevel1 || this.updateUserRole == RoleEnum.UserAuditorLevel1R || this.updateUserRole == RoleEnum.UserAuditorLevel2 || this.updateUserRole == RoleEnum.UserAuditorLevel2R || this.updateUserRole == RoleEnum.UserAuditorLevel3 || this.updateUserRole == RoleEnum.UserAuditorLevel3R)) {
      const accountChangeDialog = new MatDialogConfig();
      accountChangeDialog.height = 'auto';
      accountChangeDialog.width = '670px';
      accountChangeDialog.disableClose = true;
      this.infoAuditorAccountChangeDataModel = new AlertConfirmDialogModel();
      this.infoAuditorAccountChangeDataModel.action = "Information";
      this.infoAuditorAccountChangeDataModel.dialogId = "auditor-account-changed";
      this.infoAuditorAccountChangeDataModel.okButtonName = "Ok";
      this.infoAuditorAccountChangeDataModel.isVisibleCancel = false;
      this.infoAuditorAccountChangeDataModel.title = 'ALERT';
      this.infoAuditorAccountChangeDataModel.message = 'The account you are attempting to set to inactive is part of the User Audit process. Please ensure you have at least one active User Auditor before setting this account to inactive';
      this.dialogRef = this.dialog.open(this.infoAuditorAccountChangeTemplate, accountChangeDialog);
    }
  }

  clickEmailLogs(type){
    this.roleService.setPopupScroll('section-user-emaillog');
    if (type == "open") {
      $(".sidebar-mini").addClass("filter-open");
      setTimeout(() => {
        this.isOpenEmailLogDialog = true;
        this.getUserEmailUpdateHistory();
      }, this.roleService.scrollSetTimeoutTime);
    }
    else {
      $(".sidebar-mini").removeClass("filter-open");
      this.roleService.resetPopupScroll();
      setTimeout(() => {
        this.isOpenEmailLogDialog = false;
      }, this.roleService.scrollSetTimeoutTime);
    }
  }

  getUserEmailUpdateHistory() {
    this.hasEmailLogHistoryLoad = false;
    this.spinner.show();
    this.UserService.getUserEmailUpdateHistory(this.userId).subscribe(response => {
      this.hasEmailLogHistoryLoad = true;
      if (response && response.data){
        this.emailUpdateHistory = response.data;
      } else {
        this.emailUpdateHistory = [];
      }
      this.spinner.hide();
    },  err => {
      this.hasEmailLogHistoryLoad = true;
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }
}
