import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { EntityAddComponent } from "src/app/components/entity/entity-add/entity-add.component";

@Injectable()
export default class EntityCanDeactivateGuard
    implements CanDeactivate<EntityAddComponent> {
    canDeactivate(component,_route: ActivatedRouteSnapshot, _state:           
        RouterStateSnapshot,nextState?: RouterStateSnapshot) {  
            let  selectedEntityModel = localStorage.getItem("selectedEntityModel");
            let moveEntityModel = localStorage.getItem("moveEntityModel");
            if(moveEntityModel && selectedEntityModel != undefined && moveEntityModel != undefined && !localStorage.getItem('backBtnClicked'))
                return component.canDeactivate(nextState.url);
            else
                return true;
    }
}


