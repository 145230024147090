import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { UserAddComponent } from "src/app/components/user/user-add/user-add.component";

@Injectable()
export default class UserCanDeactivateGuard
    implements CanDeactivate<UserAddComponent> {
    canDeactivate(component,_route: ActivatedRouteSnapshot, _state:           
        RouterStateSnapshot,nextState?: RouterStateSnapshot) {  
            let  selectedModel = localStorage.getItem("isUserEdit");
            if(selectedModel != undefined)
                return component.canDeactivate(nextState.url);
            else
                return true;            
    }
}


