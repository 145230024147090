import { PropositionMasterEnum } from "../enums/propositionMaster.enum";

export class EntityConstant {
    autonomousMachineTypeList = [
      "CAMBRIDGE COFFEE SOUL (Unbranded)",
      "AK Soul",
      "CAMBRIDGE LEGACY"
      ];

    autonomousPropositionTypeList = [
      "Autonomous"
    ]

    fieldUniqueNames = {
      machineCommissioningStatus: "machineCommissioningStatus"
    }

    hasMacAddress = [
      PropositionMasterEnum.Autonomous,
      PropositionMasterEnum.Capsules,
      PropositionMasterEnum.Concession
    ]
}
