import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CapsuleIngredientService {
  cookieName = environment.cookieName;

  constructor(
    private cookieService: CookieService,
		private httpClient: HttpClient
  ) { }

  GetCapsuleIngredients(body) {
    var configHeader = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
		}
	};		

	const parameter = {
		params: body,
		timestamp: Date.now()
	};
	var reqParameter = Object.assign(parameter, configHeader);
	return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/capsuleingredients', reqParameter).pipe(map(response => {
		return response;
	}));
  }
  
  SaveCapsuleIngredient(body: any) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/savecapsuleingredients', body, configHeader).pipe(map(response => {
      return response;
    }));
  }

  GetCapsuleIngredientLookups(type) {
    var configHeader = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
		}
	};
	const parameter = {
		timestamp: Date.now()
	};
	var reqParameter = Object.assign(parameter, configHeader);
	return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/capsuleingredientlookups/' + type, reqParameter);
  }

  GetMachineModelByPropositionTypeCode(code) {
	var configHeader = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
		}
	};
	const parameter = {
		timestamp: Date.now()
	};
	var reqParameter = Object.assign(parameter, configHeader);
	return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/machinemodelbypropositioncode/' + code, reqParameter);
	}

	GetCupVolumes(body) {//[GD2-3702]
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: body,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/getcupvolumes', reqParameter).pipe(map(response => {
			return response;
		}));
	}
	AddUpdateCupVolume(body: any) {//[GD2-3702]
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/addupdatecupvolume', body, configHeader).pipe(map(response => {
			return response;
		}));
	}

  GetBarcodeCombinationList(body) {
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};		

		const parameter = {
			params: body,
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/getBarcodeCombinationList', reqParameter).pipe(map(response => {
			return response;
		}));
  }
  
  SaveBarcodeCombination(body: any) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/saveBarcodeCombination', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  
  BarcodeCombinationLookup(type) {
    var configHeader = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
		}
	};
	const parameter = {
		timestamp: Date.now()
	};
	var reqParameter = Object.assign(parameter, configHeader);
	return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/barcodeCombinationLookup/' + type, reqParameter);
  }


  //GD2-3704 start
  GetProductName(body) {
    var configHeader = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
		}
	};		

	const parameter = {
		params: body,
		timestamp: Date.now()
	};
	var reqParameter = Object.assign(parameter, configHeader);
	return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/productname', reqParameter).pipe(map(response => {
		return response;
	}));
  }

  AddUpdateProductName(body: any) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/productname', body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  //GD2-3704 end

  deleteCapsuleIngredient(id) { //GD2-3643
	var configHeader = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')			
		}
	};
	return this.httpClient.delete<any>(environment.apiUrl.entity + '/api/v1/capsuleingredient/' + id, configHeader).pipe(map(response => {
		return response;
	}));
 }
}
