import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { GetDrinkSelectionModel, GetDrinkSelectionTaskTypeEntities } from '../../dtos/get-drink-select.model';


@Injectable({
  providedIn: 'root'
})
export class TaskPropertiesService {

  cookieName = environment.cookieName;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService) {
  }

  getDrinksByMarketState(taskProperties) {

    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    const body: GetDrinkSelectionModel = {
      Level1: this.mapEntitiesLevel(taskProperties.Level1),
      Level2: this.mapEntitiesLevel(taskProperties.Level2),
      Level3: this.mapEntitiesLevel(taskProperties.Level3)
    };

    return this.httpClient.post<any>(environment.apiUrl.entity + '/api/v1/drinkgrouptemplates/getdrinksbyselectedmarkets', body, configHeader);
  }

  private mapEntitiesLevel(level: any) {
    return level.map(l => <GetDrinkSelectionTaskTypeEntities>{
      id: l.id,
      parentEntityId: l.parentEntityId,
    });
  }
}
