import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DrinkSetupStrings } from 'src/app/enums/drinkSetupStrings.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { RoleService } from 'src/app/services/role/role.service';

@Component({
  selector: 'app-price-card-information',
  templateUrl: './price-card-information.component.html',
  styleUrls: ['./price-card-information.component.css']
})
export class PriceCardInformationComponent implements OnInit {
  drinkSetupName: string = "";
  stepForm: FormGroup;
  templateAutocomplete: Observable<string[]>;
  @Output() clickButton = new EventEmitter<any>();
  templates: any = [];
  radioObj: any = {
    isEditable: true,
  };
  screenType = 'add';  
  constructor(
    public roleService: RoleService,
    public priceCardService: PriceCardService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private azureService: AzureService
  ) { }

  ngOnInit(): void {
    this.SetFeatureFlagsConfigurations();

    this.activatedRoute.params.subscribe(params => {
      if (params.priceCardVersionId) {
        this.screenType = 'edit';
      }
      this.createFormGroup();
    });

  }
  getPriceCardInformation() {
    if (this.screenType = 'edit') {
      this.spinner.show();
    }
    this.priceCardService.getPriceCardInformation(this.priceCardService.priceCardDetails.priceCardVersionId).subscribe(response => {
      if(response && response.data){       
        this.stepForm.patchValue({
          "priceCardName": response.data.priceCardName,
          "drinkGroupTemplateId": response.data.updatedDrinkGroupTemplateId,
          "isEditable": response.data.isEditable
        });
        if (this.screenType == 'edit') {
          if (!response.data.isEditable && this.roleService.objRole.isMarketAdmin) {
            this.router.navigate(['/unauthorized']);
          }

          this.stepForm.controls['priceCardName'].disable();
          this.stepForm.controls['drinkGroupTemplateId'].disable();
        }
        this.radioObj.isEditable = response.data.isEditable;
        this.priceCardService.priceCardDetails.drinkGroupTemplateId = response.data.drinkGroupTemplateId;
        this.priceCardService.priceCardDetails.priceCardName = response.data.priceCardName;
        if (this.screenType = 'edit') {
          this.spinner.hide();
        }      
      }
    });
  }
  getTemplateLookup() {​​​​​​​
    let isOutDated='false';
    let isSystemGenerated='false'
    let isActive ='true'
    if(this.screenType != 'add'){​​​​​​​
      isOutDated= '';
      isSystemGenerated='';
      isActive ='';
    }​​​​​​​
    this.priceCardService.getDrinkGroupTemplates(isSystemGenerated,isActive,isOutDated).subscribe(response => {​​​​​​​
      this.templates = response['data'];
      this.templateAutocomplete = this.stepForm.controls['drinkGroupTemplateId'].valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

      if (this.priceCardService.priceCardDetails.priceCardVersionId != '') {
        this.getPriceCardInformation()
      }
    }​​​​​​​);
  }​​​​​​​
  displayFn(): (id: number) => string {
    return (id: number) => {
      if (id) {
        if (this.templates.find(obj => obj.drinkGroupTemplateId == id)) {
          let obj = this.templates.find(obj => obj.drinkGroupTemplateId == id);
          if (Object.keys(obj).length) {
            return obj.templateName
          } else {
            return '';
          }
        } else {
          return '';
        }
      }
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.templates.filter(option => option.templateName.toLowerCase().includes(filterValue));
  }
  onChangeTemplate() {
    if (this.priceCardService.priceCardDetails.priceCardVersionId != '' && this.stepForm.controls['drinkGroupTemplateId'].value != '') {
      this.toaster.open({
        text: "Changing the template would clear all the information associated with previously filled in steps",
        type: 'warning',
        position: 'top-right',
        duration: 10000
      });
    }
  }
  createFormGroup() {
    this.stepForm = new FormGroup({});
    this.stepForm.addControl('priceCardName', new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]));
    this.stepForm.addControl('drinkGroupTemplateId', new FormControl('', [Validators.required, this.requireMatch()]));

    //let isEditableVal = { value: this.radioObj['isEditable'], disabled: false }
    // if (this.screenType == 'edit') {
    //   isEditableVal.disabled = true;
    // }
    //this.stepForm.addControl('isEditable', new FormControl(isEditableVal));
    this.getTemplateLookup();
  }
  submitForm(type) {
    if (type != 'next') {
      this.clickButton.next({ stepKey: 'information', buttonType: type })
    } else {
      this.stepForm.markAllAsTouched();    
    if(this.stepForm.status === 'INVALID'){
      this.toaster.open({
        text: 'Please provide valid input for all the highlighted fields',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
      
    }else{     
        let body = {
          priceCardName: this.stepForm.controls['priceCardName'].value,
          drinkGroupTemplateId: this.stepForm.controls['drinkGroupTemplateId'].value,
          priceCardVersionId: this.priceCardService.priceCardDetails.priceCardVersionId,
          isEditable: true,
        }
        this.spinner.show();
        this.priceCardService.addPriceCardInformation(body).subscribe(response => {
          this.spinner.hide();
          this.priceCardService.priceCardDetails.priceCardVersionId = response.priceCardVersionId;
          this.priceCardService.priceCardDetails.drinkGroupTemplateId = this.stepForm.controls['drinkGroupTemplateId'].value;
          this.priceCardService.priceCardDetails.priceCardName = this.stepForm.controls['priceCardName'].value;
          this.priceCardService.getDrinkGroupTemplateById(this.priceCardService.priceCardDetails.drinkGroupTemplateId).subscribe(response => {
            this.priceCardService.drinkGroupTemplatePropositionType = response['data'].drinkGroupTemplatePropositionType;
          });
          this.clickButton.next({ stepKey: 'information', buttonType: type, 'needReset': response.needReset, 'isPrimaryCoffeeChanged': response.isPrimaryCoffeeChanged, 'isPrimaryMilkChanged': response.isPrimaryMilkChanged });
        }, err => {
          if (err) {
            this.spinner.hide();
            let errors = err.error;
            errors.forEach(element => {
              this.toaster.open({
                text: element.msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
              if(element.msg == "Oops! Something went wrong."){
                this.router.navigate(['/price-card']);
              }
            });
          }
        })
      }
    }
 
  }
  requireMatch = () => {
    return (control: FormControl) => {
      const selection: any = control.value;
      if (selection != '') {
        var obj = this.templates.filter(x => x.drinkGroupTemplateId == selection);
        if (obj == null || obj.length == 0) {
          return { requireMatch: true };
        }
        else {
          return null;
        }
      } else { return null; }
    }
  }

  private SetFeatureFlagsConfigurations() {
    this.azureService.isEnableDrinkSetupFT()
     .then(isDrinkSetup => 
        this.drinkSetupName = isDrinkSetup ? DrinkSetupStrings.DrinkSetup : DrinkSetupStrings.PriceCardTemplate
      ).catch(() =>
        this.drinkSetupName = DrinkSetupStrings.PriceCardTemplate
      );
 }
}
