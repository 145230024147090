import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
export type StatusVariation = 'primary' | 'blue' | 'secondary' | 'alert';

@Component({
  selector: 'app-status-column',
  templateUrl: './status-column.component.html',
  styleUrls: ['./status-column.component.css']
})

export class StatusColumnComponent implements OnChanges {
  @Input() text: string;
  @Input() variation: StatusVariation;

  // Define class conditions object
  classConditions: {[key: string]: boolean} = {};

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the variation input property has changed
    if (changes.variation) {
      // Update class conditions based on the new variation value
      this.updateClassConditions();
    }
  }

  private updateClassConditions(): void {
    this.classConditions = {
      [`status-column__chip--${this.variation}`]: true,
    };
  }
}
