import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { RoleService } from 'src/app/services/role/role.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { CupTypePriorityEnum } from 'src/app/enums/cupTypePriority.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-cuptype-definition',
    templateUrl: './cuptype-definition.component.html',
    styleUrls: ['./cuptype-definition.component.css']
})
export class CuptypeDefinitionComponent implements OnInit {
    isEdit = false;
    cupTypePriorityList: any[] = [];
    cupTypePriorityEnumList: any = [];
    cupTypePriorityColumns: string[] = ['serviceDelivery', 'waffle', 'plastic', 'glass_Crockery', 'rowNo'];
    selectedIndex = 0;
    updatePriorityFormGroup: FormGroup;
    lookupDropdownSettings = {
        singleSelection: true,
        defaultOpen: false,
        idField: "value",
        textField: "text",
        enableCheckAll: false,
        allowSearchFilter: true,
        itemsShowLimit: 1
    };
    constructor(
        public roleService: RoleService,
        private router: Router,
        private entityService: EntityService,
        private spinner: NgxSpinnerService,
        private toaster: Toaster
    ) {
        this.createLookupFromEnum();
    }

    ngOnInit(): void {
        if (!this.roleService.objRole.isSystemAdmin) {
            this.router.navigate(['/unauthorized']);
        }
        this.updatePriorityFormGroup = new FormGroup({});
        this.updatePriorityFormGroup.addControl('waffle', new FormControl(''));
        this.updatePriorityFormGroup.addControl('plastic', new FormControl(''));
        this.updatePriorityFormGroup.addControl('glass_crockery', new FormControl(''));

        this.getCupTypePriority();
    }
    getCupTypePriority() {
        this.spinner.show();
        this.cupTypePriorityList = [];
        this.entityService.getCupTypePriority().subscribe(response => {
            this.spinner.hide();
            if (response && response['data'].length) {
                this.cupTypePriorityList = response['data'];
            }
        },
            err => {
                this.spinner.hide();
                let errors = err.error;
                errors.forEach(element => {
                    this.toaster.open({
                        text: element.msg,
                        type: 'danger',
                        position: 'top-right',
                        duration: 10000
                    });
                });
            });
    }
    updatePriority(row) {
        this.spinner.show();
        var wafflePriority = this.updatePriorityFormGroup.controls['waffle'].value;
        var plasticPriority = this.updatePriorityFormGroup.controls['plastic'].value;
        var glass_crockeryPriority = this.updatePriorityFormGroup.controls['glass_crockery'].value;
        var priorityList = [];
        if (!priorityList.includes((wafflePriority == '') ? 0 : wafflePriority)) { priorityList.push((wafflePriority == '') ? 0 : wafflePriority); }
        if (!priorityList.includes((plasticPriority == '') ? 0 : plasticPriority)) { priorityList.push((plasticPriority == '') ? 0 : plasticPriority); }
        if (!priorityList.includes((glass_crockeryPriority == '') ? 0 : glass_crockeryPriority)) { priorityList.push((glass_crockeryPriority == '') ? 0 : glass_crockeryPriority); }

        if (wafflePriority == '' && plasticPriority == '' && glass_crockeryPriority == '') {
            this.spinner.hide();
            return this.toaster.open({
                text: 'Priority must be set for serve option',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else if ((wafflePriority == plasticPriority && wafflePriority != '' && plasticPriority != '')
            || (plasticPriority == glass_crockeryPriority && plasticPriority != '' && glass_crockeryPriority != '')
            || (wafflePriority == glass_crockeryPriority && wafflePriority != '' && glass_crockeryPriority != '')
        ) {
            this.spinner.hide();
            return this.toaster.open({
                text: 'Unable to set the same priority for cup type against Serve Option',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else if (!priorityList.includes(1)) {//else if (!this.checkIfConsecutive(priorityList.sort())) {
            this.spinner.hide();
            //if (wafflePriority != '') { this.updatePriorityFormGroup.controls["waffle"].setErrors({ 'incorrect': true }); }
            //if (plasticPriority != '') { this.updatePriorityFormGroup.controls["plastic"].setErrors({ 'incorrect': true }); }
            //if (glass_crockeryPriority != '') { this.updatePriorityFormGroup.controls["glass_crockery"].setErrors({ 'incorrect': true }); }
            return this.toaster.open({
                text: 'Priority 1 must be set against any one of the serve option.',
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        }
        else {
            let priorityBody = {}
            priorityBody = {
                "serviceDelivery": row.serviceDelivery,
                "temperature": row.temperature,
                "waffle": wafflePriority,
                "plastic": plasticPriority,
                "glass_Crockery": glass_crockeryPriority
            };
            this.entityService.updateCupTypePriority(priorityBody).subscribe(response => {
                if (response) {
                    var objIndex = this.cupTypePriorityList.findIndex((obj => obj.rowNo == row.rowNo));
                    this.cupTypePriorityList[objIndex].waffle = wafflePriority;
                    this.cupTypePriorityList[objIndex].plastic = plasticPriority;
                    this.cupTypePriorityList[objIndex].glass_Crockery = glass_crockeryPriority;
                    this.isEdit = false;
                    this.spinner.hide();
                    this.toaster.open({
                        text: "Priority updated successfully",
                        type: 'success',
                        position: 'top-right',
                        duration: 10000
                    });
                }
            },
                err => {
                    this.spinner.hide();
                    let errors = err.error;
                    errors.forEach(element => {
                        this.toaster.open({
                            text: element.msg,
                            type: 'danger',
                            position: 'top-right',
                            duration: 10000
                        });
                    });
                });
        }
    }
    editPriority(row) {
        this.updatePriorityFormGroup.patchValue({
            "waffle": (row.waffle) != null ? row.waffle : '',
            "plastic": (row.plastic) != null ? row.plastic : '',
            "glass_crockery": row.glass_Crockery != null ? row.glass_Crockery : ''
        });

        this.selectedIndex = row.rowNo;
        this.isEdit = true;
    }
    createLookupFromEnum() {
        let that = this;
        $.each(CupTypePriorityEnum, function (key, value) {
            if (Number.isInteger(value)) {
                that.cupTypePriorityEnumList.push({ value: value, text: value });
            }
        });
    }
    checkIfConsecutive(Arr) {
        let isCnsc = true;
        for (var st in Arr) {
            if (Arr[parseInt(st) + 1] - Arr[parseInt(st)] > 1 && !isNaN(Arr[parseInt(st) + 1] - Arr[parseInt(st)])) {
                isCnsc = false;
            }
        }
        return isCnsc;
    }
}
