import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LookupModelList } from 'src/app/components/pricecard-template/pricecard-template-add/pricecard-template-add.model';
import { environment } from './../../../environments/environment';
@Injectable({
	providedIn: 'root'
})
export class PriceCardTemplateService {
	cookieName = environment.cookieName;
	constructor(private cookieService: CookieService,
		private httpClient: HttpClient) { }

	getPriceCardTemplateList(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/drinkgrouptemplate/search', body, configHeader).pipe(map(response => {
			return response;
		}));

	}


	public getMarketLookup(): Observable<LookupModelList> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/market', configHeader)
	}

	getMasterLookup(body,orderBy="text"): Observable<LookupModelList> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		let lookUpObject = {
			"search": "",
			"configurationType": body,
			"pageNo": 1,
			"pageSize": -1,
			"orderBy": orderBy
		}
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/master/masterConfiguration', lookUpObject, configHeader)
	}

	getStatusLookup() {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.apiUrl.entity + '/api/v1/master/machine/getmachinestatus', reqParameter).pipe(map(response => {
			return response;
		}));
	}

	deletePriceCardTemplate(id) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		return this.httpClient.delete<any>(environment.baseUrl + '/api/v1/drinkgrouptemplate/' + id, configHeader).pipe(map(response => {
			return response;
		}));
	}

	savePriceCardTemplate(body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		body['route']='pc-templage'
		return this.httpClient.post<any>(environment.baseUrl + '/api/v1/drinkgrouptemplate', body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	updatePriceCardTemplate(drinkGroupTemplateId,body) {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		body['route']='pc-templage'
		return this.httpClient.put<any>(environment.baseUrl + '/api/v1/drinkgrouptemplate/' + drinkGroupTemplateId, body, configHeader).pipe(map(response => {
			return response;
		}));
	}

	getDrinkGroupTemplatesById(templateId): Observable<any> {
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
		return this.httpClient.get<any>(environment.baseUrl + '/api/v1/drinkgrouptemplate/' + templateId, reqParameter)
	}
}
