import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DrinkDto, DrinkState } from 'src/app/components/remote-update-tasks/services/task-properties/dto/drink-dto';

@Component({
  selector: 'app-task-properties-card',
  templateUrl: './task-properties-card.component.html',
  styleUrls: ['./task-properties-card.component.css']
})
export class TaskPropertiesCardComponent {

  @Input() drink: DrinkDto;
  @Input() id: number;
  @Output() drinkStateChanged = new EventEmitter<DrinkDto>();

  private static readonly drinkStateInfo = {
    [DrinkState.NoChange]: { nextState: DrinkState.Enabled, cssClass: "drink-item-neutral" },
    [DrinkState.Enabled]: { nextState: DrinkState.Disabled, cssClass: "drink-item-enabled" },
    [DrinkState.Disabled]: { nextState: DrinkState.NoChange, cssClass: "drink-item-disabled" }
  };

  public get cssClass() {
    return TaskPropertiesCardComponent.drinkStateInfo[this.drink.state].cssClass;
  }

  constructor() { }
  drinkClick() {
    this.drink.state = TaskPropertiesCardComponent.drinkStateInfo[this.drink.state].nextState;
    this.drinkStateChanged.emit(this.drink);
  }
}
