import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlarmTemplateService {
  cookieName = environment.cookieName;
 
  constructor( 
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) { }

  getEventList(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machine/getmachinealarmtemplateeventlist',body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getAlarmList(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.alarmProcessor + '/api/v1/alarms',body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  exportAlrarmData(data) {
		var configHeader = {
		  headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
		  }
		};
		return this.httpClient.post<any>
		(environment.apiUrl.alarmProcessor + '/api/v1/alarmsexport',data, configHeader)
		.pipe(map(response => {
		  return response;
		}))
	}
  getEventById(id) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/templateevent/'+id, configHeader).pipe(map(response => {
        return response;
      }));
  }
  getAlarmFilterLookupByType(type,machineId=null) {
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};
		const parameter = {
			params: {},
			timestamp: Date.now()
		};	
    if(machineId){
      parameter.params['machineId']=machineId;
    }
    var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.alarmProcessor + '/api/v1/alarmLookup/'+type, reqParameter).pipe(map(response => {
        return response;
      }));
    }
  getFilterLookupByType(alarmTemplateId,type) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/alarmtemplateeventLookup/'+alarmTemplateId+'/'+type, configHeader).pipe(map(response => {
        return response;
      }));
    }
  addEvent(body) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/templateevent',body, configHeader).pipe(map(response => {
      return response;
    }));
  }
  getAlarmTemplateList() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/alarmtemplates', configHeader).pipe(map(response => {
      return response;
    }));
  }
  propositionTypeLookup() {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
		  }
    };
	  return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/propositiontype', configHeader).pipe(map(response => {
      return response;
    }));
  }
  getAlarmTemplateById(id){
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				}
		}
		return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/alarmtemplate/' + id, configHeader)
	}
  addAlarmTemplate(body){
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				}
		}
		return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/alarmtemplate/',body, configHeader)
	}
  updateAlarmTemplateById(id,body){
    body['alarmTemplateId']=id;
		var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				}
		}
		return this.httpClient.put<any>(environment.apiUrl.machine + '/api/v1/alarmtemplate/',body, configHeader)
  }
  closeAlarm(body) {

      var configHeader = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
          }
      }
      return this.httpClient.post<any>(environment.apiUrl.alarmProcessor + '/api/v1/closealarm/', body, configHeader)
  }
  deleteEventByById(id){
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				}
		}
		return this.httpClient.delete<any>(environment.apiUrl.machine + '/api/v1/templateevent/'+id, configHeader)
  }

  getEscalationNotificationDetails(body) {
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token'),
				}
		}
    return this.httpClient.post<any>(environment.apiUrl.alarmProcessor + '/api/v1/getescalationnotificationdetails', body, configHeader)
  }

  exportAlarmEscalationData(data) {
		var configHeader = {
		  headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
		  }
		};
		return this.httpClient.post<any>
		(environment.apiUrl.alarmProcessor + '/api/v1/getescalationnotificationdetailsexport',data, configHeader)
		.pipe(map(response => {
		  return response;
		}))
	}

  resetMachineAlarmFilters() {
    localStorage.removeItem('machineAlarmFilter');
    localStorage.removeItem('fromMachineDetailTab');
  }

  getEventEmailPreview(escalationLevel,languageId) {
    var configHeader = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
      }
    };
    const parameter = {
			params: {
				'escalationLevel': escalationLevel,
				'languageId': languageId
			},
			timestamp: Date.now()
		};
		var reqParameter = Object.assign(parameter, configHeader);
    return this.httpClient.get<any>(environment.apiUrl.alarmProcessor + '/api/v1/geteventemailpreview', reqParameter).pipe(map(response => {
        return response;
    }));
  }
}
