import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { RemoteUpdateTaskLevelNames } from '../../dtos/remote-update-task-level-name';

@Injectable({
  providedIn: 'root'
})
export class RemoteUpdateTaskExportService {

  constructor(
    private cookieService: CookieService,
    private http: HttpClient
  ) { }

	cookieName: string = environment.cookieName

  downloadReport(id: number): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
				'auth_custom': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
    };

    return this.http.get(environment.apiUrl.remoteUpdate + "/v1/remoteupdatetask/" + id + "/report", options);
  }

   downloadMarketSelectionReport(remoteUpdateTaskLevelNames: RemoteUpdateTaskLevelNames): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token')
			}
    };

    var levelNames = JSON.stringify(remoteUpdateTaskLevelNames);

    return this.http.post(environment.apiUrl.remoteUpdate + "/v1/remoteupdatetask/report/marketselection", levelNames ,options);
  }
}
