export class SOXAuditLogConstant{
    entityLevelList= [
        {entityLevelId:"1",entityLevelName:"Market"},
        {entityLevelId:"2",entityLevelName:"Level2"},
        {entityLevelId:"3",entityLevelName:"Level3"},
        {entityLevelId:"4",entityLevelName:"Customer"},
        {entityLevelId:"5",entityLevelName:"Level5"},
        {entityLevelId:"6",entityLevelName:"Level6"},
        {entityLevelId:"7",entityLevelName:"Site"},
        {entityLevelId:"8",entityLevelName:"Machine"}
      ];
      logTypeList= [
        {logType:"User Account Logs",logValue:4},
        {logType:"Entity Logs",logValue:1},
        {logType:"Ingredient Configuration Logs",logValue:2},
        {logType:"Price Card Logs",logValue:3},
        {logType:"Price Card Templates Logs",logValue:8},
        {logType:"Sales Discrepancy Logs",logValue:5},
        {logType:"SOX Verifications Logs",logValue:6},
        {logType:"System Updates Logs",logValue:7},
        {logType:"User Audit Logs",logValue:9}
      ];

      drinkSetup = { logType: "Drink Setup Logs", logValue: 10 }
}


export enum EntityLevel
{
  Market = 1,
  Level2 = 2,
  Level3 = 3,
  Customer = 4,
  Level5 = 5,
  Level6 = 6,
  Site = 7,
  Machine = 8,
}
