import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";

@Component({
  selector: "app-new-task-body-remote-update",
  templateUrl: "./body-new-task.component.html",
  styleUrls: ["./../../../../remote-update-tasks.component.css"],
})
export class NewTaskBodyComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.document.body.classList.add('remote-update-new-task-global-styles');
  }

  ngOnDestroy() {
    this.document.body.classList.remove('remote-update-new-task-global-styles');
  }

}
