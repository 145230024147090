import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MachineTypeService {

    cookieName = environment.cookieName;
    constructor(private cookieService: CookieService,
      private httpClient: HttpClient) { }

    GetMachineGroups() {
      var configHeader = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
        }
      };
      return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machinegroup', configHeader).pipe(map(response => {
        return response;
      }));
    }

    GetmachineModels(reqParams) {
      var configHeader = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
        }
      };
      const parameter = {
        params: reqParams,
        timestamp: Date.now()
      };
      var reqParameter = Object.assign(parameter, configHeader)

      return this.httpClient.get<any>(environment.apiUrl.machine + '/api/v1/machinemodels', reqParameter).pipe(map(response => {
        return response;
      }));
    }

    AddUpdateMachineType(body) {
      var configHeader = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
        }
      };
      return this.httpClient.post<any>(environment.apiUrl.machine + '/api/v1/machinemodels/upsert', body, configHeader).pipe(map(response => {
        return response;
      }));
    }
}
