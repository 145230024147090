
export enum MasterLookupEnum
{
    TimeZone = 1,
    Country = 2,
    Currency = 3,
    Market = 4,
    Partner = 5,
    Site = 6,
    BrandType = 7,
    PriceCard = 8,
    ParentEntity = 9,
    CupSize = 10,
    Syrup = 11,
    Milk = 12,
    Coffee = 13,
    Suggestion = 14,
    MachineCommissioningStatus = 15,
    MachineStatus = 16,
    MachineWorkingStatus = 17,
    MachineTemplateType = 18,
    Drink = 19
}

    export class MasterLookupConstant
    {
        Market = "market";
        Partner = "partner";
        Site = "site";
        BrandType = "brandtype";
        PriceCard = "pricecard";
        ParentEntity = "parententity";
        CupSize = "cupsize";
        Syrup = "syrup";
        Milk = "milk";
        Coffee = "coffee";
        Suggestion = "suggestion";
        MachineCommissioningStatus = "machinecommissioningstatus";
        MachineStatus = "machinestatus";
        MachineWorkingStatus = "machineworkingstatus";
        MachineTemplateType = "machinetemplatetype";
        Drink = "drink"
    }