import { RemoteUpdateEntityDeployLevel } from "../enums/remote-update-entity-deploy-level.enum";
import { RemoteUpdatePropositionType } from "../enums/remote-update-proposition-type.enum";
import { RemoteUpdateTaskDeploymentType } from "../enums/remote-update-task-deployment-type.enum";
import { TaskProperty } from "../enums/remote-update-task-properties";
import { RemoteUpdateTaskStatus } from "../enums/remote-update-task-status.enum";
import { RemoteUpdateTaskType } from "../enums/remote-update-task-type.enum";
import { DrinkDto } from "../services/task-properties/dto/drink-dto";
import { RemoteUpdateTaskTypeEntities } from './get-remote-update-task-type.model';

export class CreateRemoteUpdateTaskRequest {
  TaskType: RemoteUpdateTaskType;
  TaskStatus?: RemoteUpdateTaskStatus;
  DeploymentType?: RemoteUpdateTaskDeploymentType;
  PropositionType: RemoteUpdatePropositionType;
  PropositionTypeId?: string;
  Package: string;
  PackageId?: number;
  ScheduleOn: string;
  EntityDeployLevel: RemoteUpdateEntityDeployLevel;
  CreatedBy: string;
  TotalOfMachines?: number;
  Machines?: string[];
  MachinesTemp?: {
    machineId: string,
    certExpiryDate: string,
    certStatus: string	
  }[];

  Level1?: RemoteUpdateTaskTypeEntities[];
  Level2?: RemoteUpdateTaskTypeEntities[];
  Level3?: RemoteUpdateTaskTypeEntities[];

  ScreenSelectedLevel1?: RemoteUpdateTaskTypeEntities[];
  ScreenSelectedLevel2?: RemoteUpdateTaskTypeEntities[];
  ScreenSelectedLevel3?: RemoteUpdateTaskTypeEntities[];
  DrinkList: DrinkDto[];
  TaskProperty: 'Renew' | 'Revoke';
}
