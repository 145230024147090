export enum DrinksSetupEntityLevel {
    Market = "Market",
    Level2 = "Level2",
    Level3 = "Level3",
    Level35 = "Level35",
    Customer = "Customer",
    Level5 = "Level5",
    Level6 = "Level6",
    Site = "Site",
    Machine = "Machine"
  }