import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {BehaviorSubject, combineLatest, iif, merge, Observable, of, pipe, ReplaySubject, Subject} from "rxjs";
import {distinctUntilChanged, map, mapTo, scan, skip, startWith, takeUntil, tap} from "rxjs/operators";
import { TemplateNameModel } from './template-name.model';
import { RequestGetDrinkGroupTemplate } from '../../services/request-add-drink-group-template';
import { DrinkSetupPropositionTypeService } from '../../services/drink-setup-proposition-type.service';
import { SelectedItem } from '../market-country-selection/selected-item';
import { DrinkSetupPropositionTypeEnum } from '../../models/drink-setup-proposition-type.model';

type CCInputVariant = 'default' | 'success' | 'warning' | 'error';

@Component({
  selector: 'app-template-name',
  templateUrl: './template-name.component.html',
  styleUrls: ['./template-name.component.css']
})
export class TemplateNameComponent implements OnInit, OnDestroy, OnChanges {
  private readonly minTemplateNameLength = 6;
  private readonly maxTemplateNameLength = 150;

  constructor(private drinkSetupPropositionTypeService: DrinkSetupPropositionTypeService){

  }

  templateName: FormControl = new FormControl('', Validators.compose([
    Validators.pattern(/^[\w(][\w ()/-]+[\w)]$/i),
    Validators.minLength(this.minTemplateNameLength),
    Validators.maxLength(this.maxTemplateNameLength)]));
    
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  // Source Streams
  readonly whenTemplateNameFocusOut$: Subject<boolean> = new Subject<boolean>();
  readonly whenValidationRequested$: Subject<boolean> = new Subject<boolean>();
  
  // Presentation Streams
  protected templateNameInputVariant$: Observable<CCInputVariant>;
  protected templateNameMessage$: Observable<string>;
  protected templateNameShowMessage$: Observable<boolean>;

  public isActive: boolean;
  protected toggled$: Subject<void> = new Subject<void>();
  protected toggledChange$: Observable<boolean>;

  @Input() drinkGroupTemplateForEdit: RequestGetDrinkGroupTemplate = null;
  @Input() isEdit: boolean = false;

  // Source Streams
  readonly selectedPropositionType$: ReplaySubject<SelectedItem> = new ReplaySubject<SelectedItem>(1);

  // Output Streams
  @Output('template-name-changed') readonly templateNameChanged = new EventEmitter<TemplateNameModel>();
  protected readonly isValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  drinkSetupPropositionTypes: any[] = [];

  ngOnInit(): void {
    // Intermediate Streams
    // --------------------

    this.drinkSetupPropositionTypeService.getPriceCardTemplatePropositionList().subscribe((response) =>{
      if(response && response.data){
        this.drinkSetupPropositionTypes = response.data;
      }
    });

    this.toggledChange$ = of(true);

    const templateNameIsChanged$ = this.templateName.valueChanges.pipe(
      skip(1),
      mapTo(true),
    );

    const templateNameIsTouched$ = merge(
      this.whenTemplateNameFocusOut$,
      this.whenValidationRequested$,
      templateNameIsChanged$);

    const templateNameIsValid$ = this.templateName.statusChanges.pipe(
      map(v => v === 'VALID'),
    );

    const templateNameError$ = combineLatest([
      templateNameIsValid$,
      this.templateName.valueChanges
    ]).pipe(
      map(([v1, v2]) => {
        if (v1) return '';
        if (v2 == null || v2.length == 0) return 'This field is required.';
        if (v2[0] == ' ') return 'This field should not start with space.';
        if (v2[v2.length - 1] == ' ') return 'This field should not end with space.';
        if (v2.length < this.minTemplateNameLength) return `This field requires at least ${this.minTemplateNameLength} characters.`;
        if (v2.length > this.maxTemplateNameLength) return `This field must not exceed ${this.maxTemplateNameLength} characters.`;
        else return 'This field is invalid.';
      })
    );

    // Presentation Streams
    // --------------------
    this.templateNameInputVariant$ = combineLatest([
      templateNameIsValid$,
      templateNameIsTouched$
    ]).pipe(
      map(([v1,]) => v1 ? 'default' : 'error'),
      startWith('default'),
      map(v => v as CCInputVariant)
    );

    this.templateNameMessage$ = combineLatest([
      templateNameError$,
      templateNameIsTouched$
    ]).pipe(
      map(([v1,]) => v1)
    );

    this.templateNameShowMessage$ = combineLatest([
      templateNameError$,
      templateNameIsTouched$
    ]).pipe(
      map(([v1,]) => v1.length > 0),
      startWith(false)
    );

    this.toggled$.subscribe((e) => {
      this.isActive = !this.isActive;
      this.templateNameChanged.emit({
        name: this.templateName.value,
        propositionType: this.drinkGroupTemplateForEdit.drinkGroupTemplatePropositionType,
        isActive: this.isActive,
      });
    })

    // Output Streams
    // --------------
    combineLatest([
      this.templateName.valueChanges,
      this.selectedPropositionType$,
      this.toggledChange$
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([name, propositionType, isActive]) => {
          this.setDrinkGroupPropositionType(propositionType.id as DrinkSetupPropositionTypeEnum); 
          return <TemplateNameModel>{
            name: name,
            propositionType: propositionType.id,
            isActive: isActive
          };
        })
      )
      .subscribe(this.templateNameChanged);

    templateNameIsValid$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$))
      .subscribe(this.isValid);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.drinkGroupTemplateForEdit) {
      this.isActive = this.drinkGroupTemplateForEdit?.isActive;
      this.toggledChange$ = iif(
        () => this.drinkGroupTemplateForEdit != null,
        this.toggled$.pipe(
          scan((state, _) => !state, this.drinkGroupTemplateForEdit?.isActive ?? false),
          startWith(this.drinkGroupTemplateForEdit?.isActive ?? false)),
        of(true));
    }
  }

  validate(): boolean {
    this.whenValidationRequested$.next(true);
    return this.isValid.value;
  }

  setDrinkGroupPropositionType(drinkGroupPropositionType: DrinkSetupPropositionTypeEnum)
  {
    this.drinkSetupPropositionTypeService.setDrinkSetupPropositionType(drinkGroupPropositionType);
  }
}
