import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PropositionMasterEnum } from 'src/app/enums/propositionMaster.enum';
import { AzureService } from 'src/app/services/azure/azure.service';
import { EntityService } from 'src/app/services/entity/entity.service';
import { RolePermissionsEnum, RoleService } from 'src/app/services/role/role.service';
declare var $: any
@Component({
  selector: 'app-trade-hours',
  templateUrl: './trade-hours.component.html',
  styleUrls: ['./trade-hours.component.css'],
  providers: [DatePipe]
})
export class TradeHoursComponent implements OnInit {
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  @Output() editScreen = new EventEmitter<string>();
  start_time: any = new Date();
  end_time: any = new Date();
  tradehours: any = [];
  siteTradehours: any = [];
  hasTradeHoursSetup = false;
  isEditSlot = false;

  dialogRef: MatDialogRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
    type: ''
  }
  isNextStep : boolean;
  selected_slot_index = -1;
  selectedDays: any = {};
  entityId: any;
  levelNumber = 7;
  parentlevelNumber = 0;
  parentEntityId = '';
  marketId = '';
  screenType = 'add';
  drawerfilter = false;
  isPropositionConcession = false;
  daysDisplayName = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday'
  };
  selectedCloneDays = [];
  shouldBlockFieldsForConcessions = false;
  @Output() selectedIndexInfo = new EventEmitter<number>();
  @Output() getTradeHoursSetup = new EventEmitter<boolean>();

  @Input()
  set isWareHouseMultiProposition(value: any) {
    this._isWareHouseMultiProposition = value == 'true';
  }
  _isWareHouseMultiProposition : boolean = false;
  disableLockdownFieldsForConcessionsFT: boolean = false;

  @Input() isGridOneIngested: boolean;
  @Input() isConcessionMigrated: boolean;
  constructor(
    private datePipe: DatePipe,
    private toaster: Toaster,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private spinner: NgxSpinnerService,
    public entityService: EntityService,
    public roleService : RoleService,
    private azureService: AzureService
  ) {
    activatedRoute.params.subscribe(params => {
      if (params.entityId) {
        this.entityId = params.entityId;
      }
    });
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.marketId)
        this.marketId = queryParams.marketId

      if (queryParams.parentEntityId)
        this.parentEntityId = queryParams.parentEntityId;

      if (queryParams.screenType) {
        if(this.roleService.objRole.isSiteSupportL1 || this.roleService.objRole.isSiteSupportL2 || this.roleService.objRole.isSiteSupportL3){
          this.screenType = 'read';
        } else
          this.screenType = queryParams.screenType;
      }

      if (queryParams.parentlevelNumber)
        this.parentlevelNumber = queryParams.parentlevelNumber
    });
  }
  ngOnInit(): void {
    this.SetFeatureFlagsConfigurations();
    this.entityService.tradehours = [];
    this.entityService.selected_slot_index = -1;
  }

  private shouldBlockFieldsForConcessionPromise() {
    this.getEntityPropositionAsPromise().then((isPropositionConcession) => {
      if(isPropositionConcession){
        this.shouldBlockFieldsForConcessions = !this.disableLockdownFieldsForConcessionsFT && this.isGridOneIngested && !this.isConcessionMigrated;

        if(this.shouldBlockFieldsForConcessions){
          this.isEditSlot = false;
        }
      }

      this.setDefaultTime();
      this.getSiteTradeHours();
    });
  }

  private SetFeatureFlagsConfigurations() {
    this.azureService.isDisableConcessionLockdownPropertiesFT()
      .then(response => {
        this.disableLockdownFieldsForConcessionsFT = response;
        this.shouldBlockFieldsForConcessionPromise();
      });
  }

  private getEntityPropositionAsPromise() : Promise<boolean> {
    return new Promise((resolve) => {
      if(this.entityId === undefined) {
        resolve(false);
      }
      else {
        this.entityService.getEntityPropositions(this.entityId).subscribe(response => {
          const hasPropositionConcessionEnabled = response['data'].filter(x => x.name.toLowerCase() == PropositionMasterEnum.Concession.toLowerCase() && x.isEnabled).length > 0;
          const hasOtherPropositionEnabled = response['data'].filter(x => x.name.toLowerCase() != PropositionMasterEnum.Concession.toLowerCase() && x.isEnabled).length > 0;
          const isPropositionConcession = hasPropositionConcessionEnabled && !hasOtherPropositionEnabled;

          resolve(isPropositionConcession);
        }, err => {});
      }
    });
}

  changefilter() {
    //this.drawerfilter = !this.drawerfilter;
    if (this.entityService.cloneDrawerfilter) {
      $(".sidebar-mini").addClass("filter-open")
    } else {
      $(".sidebar-mini").removeClass("filter-open")
    }
  }
  setDefaultTradeHoursSetup(){
    let start_time = new Date();
        let end_time = new Date();

        start_time.setHours(0, 0, 0, 0);
        end_time.setHours(23, 59, 0, 0);
        let i = 0;
        this.entityService.entityExistingData['level-'+this.levelNumber]['tab-1'] = [];
        this.entityService.entityLatestData['level-'+this.levelNumber]['tab-1']= [];
        for (let i = 0; i < 7; i++) {
          let obj = {
            "day": i, "slots": [{ "start_time": start_time, "end_time": end_time, isDefault: true }], "isDayOff": false, "isEdit": false
          }
          this.entityService.tradehours.push(obj);

          // -- GD2-1348 bug start --//
          this.entityService.entityExistingData['level-'+this.levelNumber]['tab-1'].push({day: obj.day,slots:[{ "start_time": start_time, "end_time": end_time, isDefault: true }],
            isDayOff: obj.isDayOff,
            isEdit: obj.isEdit});
          this.entityService.entityLatestData['level-'+this.levelNumber]['tab-1'].push({day: obj.day,slots:[{ "start_time": start_time, "end_time": end_time, isDefault: true }],
            isDayOff: obj.isDayOff,
            isEdit: obj.isEdit});
          // -- GD2-1348 bug End --//
        }
        this.getTradeHoursSetup.emit(false);
  }
  getSiteTradeHours() {
    if(this.screenType == 'add'){
      if(this.entityService.addEntityOnHoldData['level-7']['tab-1']['tradeHours']){
        this.hasTradeHoursSetup = true;
        this.getTradeHoursSetup.emit(true);
        let temp = [];
        let i = 0;
        this.entityService.addEntityOnHoldData['level-7']['tab-1']['tradeHours'].forEach(element => {
          let obj = {
            "day": element.dayOfWeek, "slots": [], "isDayOff": element.isDayOff, "isEdit": false
          }
          let j = 0;
          if (!element['slots'].length) {
            temp.push(obj);
            if (i === (this.entityService.addEntityOnHoldData['level-7']['tab-1']['tradeHours'].length - 1)) {
              this.entityService.tradehours = temp;

              this.setExistingData(temp); //GD2-1348 bug
            }
          } else {
            element['slots'].forEach(slot => {
              let start_time = new Date();
              let end_time = new Date();
              let start_time_split = slot.startTime.split(':');
              let end_time_split = slot.endTime.split(':');
              start_time.setHours(parseInt(start_time_split[0]), parseInt(start_time_split[1]), 0, 0);
              end_time.setHours(parseInt(end_time_split[0]), parseInt(end_time_split[1]), 0, 0);
              obj.slots.push({ "start_time": start_time, "end_time": end_time });
              if (j === (element['slots'].length - 1)) {
                temp.push(obj);
                if (i === ( this.entityService.addEntityOnHoldData['level-7']['tab-1']['tradeHours'].length - 1)) {
                  this.entityService.tradehours = temp;

                  this.setExistingData(temp); //GD2-1348 bug
                }
              }
              j++;
            });
          }
          i++;
        });
      } else {
        this.setDefaultTradeHoursSetup();
      }
    } else {
      this.spinner.show();
      this.entityService.getSiteTradeHours(this.entityId).subscribe(response => {
        if (!response) {
          this.setDefaultTradeHoursSetup();
          //this.setDefaultSetup();
        } else {
          this.hasTradeHoursSetup = true;
          this.getTradeHoursSetup.emit(true);
          let temp = [];
          let i = 0;
          this.entityService.timeZoneValue = response?.params?.timeZoneValue;
          if(response.data.tradeHours.length>0) {
            response.data.tradeHours.forEach(element => {
            let obj = {
              "day": element.dayOfWeek, "slots": [], "isDayOff": element.isDayOff, "isEdit": false
            }
            let j = 0;
            if (!element['slots'].length) {
              temp.push(obj);
              if (i === (response.data.tradeHours.length - 1)) {
                this.entityService.tradehours = temp;

                this.setExistingData(temp); //GD2-1348 bug
              }
            } else {
              element['slots'].forEach(slot => {
                let start_time = new Date();
                let end_time = new Date();
                let start_time_split = slot.startTime.split(':');
                let end_time_split = slot.endTime.split(':');
                start_time.setHours(parseInt(start_time_split[0]), parseInt(start_time_split[1]), 0, 0);
                end_time.setHours(parseInt(end_time_split[0]), parseInt(end_time_split[1]), 0, 0);
                obj.slots.push({ "start_time": start_time, "end_time": end_time });
                if (j === (element['slots'].length - 1)) {
                  temp.push(obj);
                  if (i === (response.data.tradeHours.length - 1)) {
                    this.entityService.tradehours = temp;

                    this.setExistingData(temp); //GD2-1348 bug
                  }
                }
                j++;
              });
            }
            i++;
            });
          }
          else {
            this.setDefaultTradeHoursSetup();
          }
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        if (err) {
          if (!err.error) {
            this.toaster.open({
              text: 'Something went wrong. Please try again.',
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
            this.router.navigate(['/entity']);
            this.spinner.hide();
          } else {
            let errors = err.error;
            errors.forEach(element => {
              this.toaster.open({
                text: element.msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
            });
          }
        }
      });
    }
  }
  setExistingData(e){
    this.entityService.entityExistingData['level-'+this.levelNumber]['tab-1'] = [];
    this.entityService.entityLatestData['level-'+this.levelNumber]['tab-1']= [];
    e.forEach(tabElement => {
      if(!tabElement['slots'].length){
        this.entityService.entityExistingData['level-'+this.levelNumber]['tab-1'].push({day: tabElement.day,slots:tabElement.slots,
          isDayOff: tabElement.isDayOff,
          isEdit: tabElement.isEdit});
        this.entityService.entityLatestData['level-'+this.levelNumber]['tab-1'].push({day: tabElement.day,slots:tabElement.slots,
          isDayOff: tabElement.isDayOff,
          isEdit: tabElement.isEdit});
      }else{
        let j = 0;
        let slotArr = [];
        tabElement['slots'].forEach(slot => {
          slotArr.push({start_time:slot.start_time,end_time:slot.end_time});
          if (j === (tabElement['slots'].length - 1)) {
            this.entityService.entityExistingData['level-'+this.levelNumber]['tab-1'].push({day: tabElement.day,slots:slotArr,
              isDayOff: tabElement.isDayOff,
              isEdit: tabElement.isEdit});
            this.entityService.entityLatestData['level-'+this.levelNumber]['tab-1'].push({day: tabElement.day,slots:slotArr,
              isDayOff: tabElement.isDayOff,
              isEdit: tabElement.isEdit});
          }
          j++;
        });
      }
    });
  }
  setDefaultTime() {
    this.start_time = new Date();
    this.end_time = new Date();

    this.start_time.setHours(0, 0, 0, 0);
    this.end_time.setHours(23, 59, 0, 0);
  }
  click(type, i = 0, slot_i = 0) {
    if (type == 'edit') {
      this.setDefaultTime();
      //----To store previous value -----//
      this.selectedDays = {
        day: this.entityService.tradehours[i]['day'],
        slots: [],
        isDayOff: this.entityService.tradehours[i]['isDayOff'],
        isEdit: this.entityService.tradehours[i]['isEdit'],
      }
      this.entityService.tradehours[i]['slots'].forEach(element => {
        this.selectedDays['slots'].push({
          start_time: element.start_time,
          end_time: element.end_time
        });
      });
      //----To store previous value -----//

      this.resetEdit(i);
      if (!this.entityService.tradehours[i]['slots'].length) {
        // this.entityService.tradehours[i]['slots'].push({
        //   start_time: this.start_time,
        //   end_time: this.end_time
        // });
        this.entityService.tradehours[i]['isDayOff'] = false;
      }
    } else if (type == 'save') { //clicked save button for day
      this.saveSlots(i);
    } else if (type == 'add') { //clicked add slot button
      this.addSlot(i);
    } else if (type == 'remove') {
      if (this.entityService.tradehours[i]['slots'].length === 1) {
        let areAllDayOff = true;
        let index = 1;
        this.entityService.tradehours.forEach(element => {
          if (element.day != this.entityService.tradehours[i]['day'] && !element.isDayOff) {
            areAllDayOff = false;
          }
          if (index === this.entityService.tradehours.length) {
            if (areAllDayOff) {
              this.openDialog('last_slot_day_off', {}, i);
            } else {
              this.openDialog('last_slot_remove', {}, i);
            }
          }
          index++;
        });
      } else {
        this.entityService.tradehours[i]['slots'].splice(slot_i, 1);
      }
    } else if (type == 'submit') { //clicked save button for add/update site tradehours for all days
      this.setSiteTradeHours();
    } else if (type == 'cancel') { //clicked form cancel button
      if (this.screenType == 'read') {
        this.router.navigate(['/entity']);
      } else {
        if(this.hasTradeHoursSetup){
          this.openDialog('formEdit_cancel');
        }else{
          this.openDialog('form_cancel');
        }
      }
    } else if (type == 'cancel_slot') {
      this.entityService.tradehours[i] = this.selectedDays;
      this.isEditSlot = false;
      this.entityService.tradehours[i]['isEdit'] = false;
    } else if (type == 'day_off') {
      let areAllDayOff = true;
      let index = 1;
      this.entityService.tradehours.forEach(element => {
        if (element.day != this.entityService.tradehours[i]['day'] && !element.isDayOff) {
          areAllDayOff = false;
        }
        if (index === this.entityService.tradehours.length) {
          if (areAllDayOff) {
            this.openDialog('last_day_off', {}, i);
          } else {
            this.openDialog('day_off', {}, i);
          }
        }
        index++;
      });
    } else if (type == 'edit_screen') {
      this.screenType = 'edit';
      this.router.navigate(['/entity/', this.entityId], { queryParams: { screenType: 'edit', parentEntityId: this.parentEntityId, tabIndex: 1, nextLevelNumber: this.levelNumber, marketId: this.marketId, parentlevelNumber: this.parentlevelNumber } });
      this.editScreen.next();
    } else if (type == 'clone') {
      this.roleService.setPopupScroll('section-tradehours');
      $(".sidebar-mini").addClass("filter-open")
      setTimeout(() => {
        this.selected_slot_index = i;
        this.entityService.selected_slot_index = i;
        this.entityService.cloneDrawerfilter = !this.entityService.cloneDrawerfilter;
      }, this.roleService.scrollSetTimeoutTime);
      this.changefilter();
    } else if (type == 'clone_submit') {
      this.selectedCloneDays.forEach(element => {
        this.entityService.tradehours[element]['slots'] = this.entityService.tradehours[this.selected_slot_index]['slots'];
        this.entityService.tradehours[element]['isDayOff'] = this.entityService.tradehours[this.selected_slot_index]['isDayOff']
      });
      this.selected_slot_index = -1;
      this.changefilter();
      this.selectedCloneDays = [];
    } else if (type == 'clone_cancel') {
      this.selected_slot_index = -1;
      this.changefilter();
    } else if (type == 'clone_day') {
      if (this.selectedCloneDays.includes(i)) {
        const index = this.selectedCloneDays.indexOf(i);
        if (index > -1) {
          this.selectedCloneDays.splice(index, 1);
        }
      } else {
        this.selectedCloneDays.push(i);
      }
    }
  }

saveEntityAndTradeHours(){
  if(this.screenType == 'add' && this._isWareHouseMultiProposition){

    if(this.levelNumber == 7){
      this.spinner.show();

      this.saveEntity().then((response) =>
       this.saveTradehours(response)
        .then(() => {
          if(this.screenType == 'add' && this.levelNumber == 7){
              this.toaster.open({
                text: "Site has been added successfully",
                type: 'success',
                position: 'top-right',
                duration: 10000
              });

              this.router.navigate(['/entity']);
          }
          this.spinner.hide();
        })
        .catch(err =>{
          console.log(err);
          this.spinner.hide();
        })
      ).catch(err =>{
        console.log(err);
        this.spinner.hide();
      });
    }
  }
}

async saveEntity(){
  return new Promise<any>((resolve, reject) => {
    let payload = this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-0'];
    this.entityService.addEntity(payload).
    then((res) => res.subscribe(response => {
      resolve(response);
    },err=>{
      reject(0);
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (!errors.length && (err.status == 0 || err.status == 500 || err.status == 502)) {
        this.toaster.open({
            text: 'Something went wrong. Please try again after some time.',
            type: 'danger',
            position: 'top-right',
            duration: 10000
        });
        if (this.levelNumber == 7)
          this.router.navigate(['/entity']);
        else if (this.levelNumber == 8)
          this.router.navigate(['/machine']);
      }
      else {
        errors.forEach(element => {
            this.toaster.open({
                text: element.msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
            });
        });
      }
    }));
  });
}

async saveTradehours(entityResponse){
  return new Promise<any>((resolve, reject) => {
    let payload = this.entityService.addEntityOnHoldData['level-' + this.levelNumber]['tab-1'];
    payload['entityId'] = entityResponse;
    this.entityService.addSiteTradeHours(payload).subscribe(response => {
      resolve(response);
    },err=>{
      reject(0);
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
          this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
          });
      });
    });
  });
}

  openDialog(type: string = '', element = {}, slot_i = -1): void {
    if (type == 'last_day_off' || type == "last_slot_day_off") {
      this.spinner.show();
      let moduleAction:any = (this.screenType == 'edit')?RolePermissionsEnum.ReadWrite:RolePermissionsEnum.Read;

      if (this.screenType == 'add' && (this.entityId == null || this.entityId == undefined || this.entityId == "")) {
        //START Add site - tradehours setup
        this.spinner.hide();
        this.selected_slot_index = slot_i;
        var siteData: any = this.entityService.addEntityOnHoldData['level-7']['tab-0'];
        if (Object.keys(siteData).length && siteData?.formResponse?.length && siteData?.formResponse?.filter(x => x.fieldUniqueName == 'siteAccountStatus')[0].value?.toLowerCase() == 'active') {
          this.toaster.open({
            text: 'You are unable to remove trade hours from an active site.',
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        } else {
          this.dialogTexts = {
            successTitle: 'Yes',
            cancelTitle: 'No',
            message: 'Are you sure you want this day to show as closed?',
            deviceName: '',
            status: '',
            title: 'Confirmation',
            type: type
          }
          const entityMoveDataModel = new MatDialogConfig();
          entityMoveDataModel.height = 'auto';
          entityMoveDataModel.width = '670px';
          entityMoveDataModel.disableClose = true;
          this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
        }
        //END Add site - tradehours setup
      } else {
        this.entityService.getEntityDetailsWithForm(this.entityId,this.parentEntityId,moduleAction).subscribe(response => {
          this.spinner.hide();
          if(!response || !response.data || response.data.entityStatus == undefined){
            this.toaster.open({
              text: 'Something went wrong. Please try again.',
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          }else if(response.data && response.data.entityStatus == true){
            this.toaster.open({
              text: 'You are unable to remove trade hours from an active site.',
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          }else{
            this.selected_slot_index = slot_i;
            if(type == 'last_day_off'){
              this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                message: 'Are you sure you want this day to show as closed?',
                deviceName: '',
                status: '',
                title: 'Confirmation',
                type: type
              }
            }else{
              this.dialogTexts = {
                successTitle: 'Yes',
                cancelTitle: 'No',
                      message: 'This is the last available trade hours slot, removing this will mark the day as closed. Are you sure you want to continue?',
                deviceName: '',
                status: '',
                title: 'Confirmation',
                type: type
              }
            }
            const entityMoveDataModel = new MatDialogConfig();
            entityMoveDataModel.height = 'auto';
            entityMoveDataModel.width = '670px';
            entityMoveDataModel.disableClose = true;
            this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);

        }
        }, err => {
          this.spinner.hide();
          if (err) {
            if (!err.error) {
              this.toaster.open({
                text: 'Something went wrong. Please try again.',
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
              this.router.navigate(['/entity']);
              this.spinner.hide();
            } else {
              let errors = err.error;
              errors.forEach(element => {
                this.toaster.open({
                  text: element.msg,
                  type: 'danger',
                  position: 'top-right',
                  duration: 10000
                });
              });
            }
          }
        });
      }
    }else{

    if (type == 'form_cancel') {
      let message = 'All unsaved changes will be lost. Are you sure you want to cancel?';
      if(this.screenType != 'add'){
        message = 'This new site has already been created in the system, however the trade hours and Ingredients configuration still needs to be set up for this site. You will be unable to add machines to this site until this has been done. If you are unable to complete this now, then please continue with Cancel and you can return later to add Trade Hours and Ingredients. Are you sure you want to cancel?'
      }
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        //message: 'If you are currently unable to set the correct trade-hours, then please continue with Cancel and you can return later to add them. You will be unable to add any machines to this site until this has been completed. Are you sure you want to cancel trade hours setup?',
        message: message,
        deviceName: '',
        status: '',
        title: 'Unsaved Changes',
        type: type
      }
    }else if (type == 'formEdit_cancel') {
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
        deviceName: '',
        status: '',
        title: 'Unsaved Changes',
        type: type
      }
    } else if (type == 'last_slot_remove') {
      this.selected_slot_index = slot_i;
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'This is the last available trade hours slot, removing this will mark the day as closed. Are you sure you want to continue?',
        deviceName: '',
        status: '',
        title: 'Confirmation',
        type: type
      }
    } else if (type == 'dafault_setup') {
      this.selected_slot_index = slot_i;
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'You are saving the default trade hours setup of 24 hours working for all 7 days. Are you sure you want to save this?',
        deviceName: '',
        status: '',
        title: 'Confirmation',
        type: type
      }
    } else if (type == 'day_off') {
      this.selected_slot_index = slot_i;
      this.dialogTexts = {
        successTitle: 'Yes',
        cancelTitle: 'No',
        message: 'Are you sure you want this day to show as closed?',
        deviceName: '',
        status: '',
        title: 'Confirmation',
        type: type
      }
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);

    }
  }
  setSiteTradeHours(isNext = false) {
    this.isNextStep = isNext;
    if (!this.hasTradeHoursSetup) {
      this.setDefaultTime();
      let index = 0
      let isDefaultSetup = true;
      this.entityService.tradehours.forEach(element => {
        if (element['slots'].length) {
          if (element['slots'].length > 1 || this.datePipe.transform(element['slots'][0]['start_time'], 'HH:mm') != this.datePipe.transform(this.start_time, 'HH:mm') || this.datePipe.transform(element['slots'][0]['end_time'], 'HH:mm') != this.datePipe.transform(this.end_time, 'HH:mm')) {
            isDefaultSetup = false;
          }
        } else {
          isDefaultSetup = false;
        }
        if ((index + 1) === this.entityService.tradehours.length) {
          if (isDefaultSetup) {
            this.openDialog('dafault_setup');
          } else {
            this.setBodySiteTradeHours(isNext);
            console.log('No isDefaultSetup');
          }
        }
        index++;
      });
    } else {
      this.setBodySiteTradeHours(isNext);
    }
  }
  setBodySiteTradeHours(isNext = false) {
    let body = {
      "entityId": this.entityId,
      "tradeHours": []
    }
    let index = 0
    this.entityService.tradehours.forEach(element => {
      let obj = {
        "dayOfWeek": element.day, "slots": [], "isDayOff": element.isDayOff
      }
      let j = 0;
      if (!element['slots'].length) {
        body.tradeHours.push(obj);
        if ((index + 1) === this.entityService.tradehours.length) {
          this.updateSiteTradeHours(body,isNext);
        }
      } else {
        element['slots'].forEach(slot => {
          obj.slots.push({ "startTime": this.datePipe.transform(slot.start_time, 'HH:mm'), "endTime": this.datePipe.transform(slot.end_time, 'HH:mm') });
          if (j === (element['slots'].length - 1)) {
            body.tradeHours.push(obj);
            if ((index + 1) === this.entityService.tradehours.length) {
              this.updateSiteTradeHours(body,isNext);
            }
          }
          j++;
        });
      }
      index++;
    });
  }
  updateSiteTradeHours(body,isNext = false) {
    if(this.screenType == 'add'){
      this.entityService.isValidatedTradehoursTab = true;
      this.entityService.addEntityOnHoldData['level-7']['tab-1'] = body;

      if(!this._isWareHouseMultiProposition){
        this.selectedIndexInfo.emit(2);
      }
      else{
        this.saveEntityAndTradeHours();
      }

    }else{
      this.spinner.show();
      let msg = "Trade hours setup has been added successfully";
      if (this.hasTradeHoursSetup) {
        msg = "Trade hours setup has been updated successfully";
      }
      this.entityService.addSiteTradeHours(body).subscribe(response => {
        this.hasTradeHoursSetup = true;

        //to reset existing data GD2-1348
        this.setExistingData(this.entityService.tradehours)
        //to reset existing data GD2-1348

        this.getTradeHoursSetup.emit(true);
        this.toaster.open({
          text: msg,
          type: 'success',
          position: 'top-right',
          duration: 10000
        });
        if(isNext){

          this.selectedIndexInfo.emit(2);
        } else {
        this.router.navigate(['/entity']);
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        let errors = err.error;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      });
    }
  }
  onConfirm(type: string = 'form_cancel') {
    this.dialogRef.close();
    if (type == 'form_cancel' || type == 'formEdit_cancel') {
      this.router.navigate(['/entity']);
    } else if (type == 'last_slot_remove') {
      this.entityService.tradehours[this.selected_slot_index]['isDayOff'] = true;
      this.entityService.tradehours[this.selected_slot_index]['slots'] = [];
      this.resetEdit(this.selected_slot_index);
    } else if (type == 'day_off' || type == 'last_day_off' || type == 'last_slot_day_off') {
      this.entityService.tradehours[this.selected_slot_index]['isDayOff'] = true;
      this.entityService.tradehours[this.selected_slot_index]['slots'] = [];
      if (type == 'last_slot_day_off') {
        this.entityService.tradehours[this.selected_slot_index]['isEdit'] = false;
        this.isEditSlot = false;
        //this.resetEdit(this.selected_slot_index);
      }
    } else if (type == 'dafault_setup') {
      this.setBodySiteTradeHours(this.isNextStep);
    }
  }
  resetEdit(i) {
    this.entityService.tradehours[i]['isEdit'] = !this.entityService.tradehours[i]['isEdit'];
    this.isEditSlot = !this.isEditSlot;
  }
  onCancel() {
    this.dialogRef.close();
  }
  addSlot(i) {
    if (this.start_time < this.end_time) {
      this.entityService.tradehours[i]['slots'].push({
        start_time: this.start_time,
        end_time: this.end_time
      });
      this.entityService.tradehours[i]['isDayOff'] = false;
      this.entityService.tradehours[i]['slots'] = this.entityService.tradehours[i]['slots'].sort(function (a, b) { return a.start_time - b.start_time; });
      this.setDefaultTime();
    } else {
      this.toaster.open({
        text: 'Opening hours must precede closing hours, please check your entered trade-hours.',
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
  }
  saveSlots(i) {
    let index = 0;
    let minTime: any = '';
    let maxTime: any = '';
    let isValidSlot = true;
    if (!this.entityService.tradehours[i]['slots'].length) {
      this.resetEdit(i);
      this.entityService.tradehours[i]['isDayOff'] = true;
    } else {
      const indexOfDefaultSlot = this.entityService.tradehours[i]['slots'].map(e => e.isDefault).indexOf(true);
      if (indexOfDefaultSlot != -1 && this.entityService.tradehours[i]['slots'].length > 1) {
        this.entityService.tradehours[i]['slots'].splice(indexOfDefaultSlot, 1);
      }
      this.entityService.tradehours[i]['slots'].forEach(element => {
        if (minTime == '' && maxTime == '') {
          minTime = element.start_time;
          maxTime = element.end_time;
        } else {
          let start_time = new Date(element.start_time.getTime() + 1 * 60000);
          let end_time = new Date(element.end_time.getTime() + 1 * 60000);
          if ((minTime <= start_time && start_time <= maxTime) ||
            (minTime <= end_time && end_time <= maxTime)) {
            isValidSlot = false;
          } else {
            if (element.start_time < minTime) {
              minTime = element.start_time;
            }
            if (element.end_time > maxTime) {
              maxTime = element.end_time;
            }
          }
        }

        if ((index + 1) == this.entityService.tradehours[i]['slots'].length) {
          if (!isValidSlot) {
            this.toaster.open({
              text: 'Overlapping of trade hours detected. Please amend before saving.',
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          } else {
            this.resetEdit(i);
          }
        }
        index++;
      })
    }
  }
}
