import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentDateAdapterOptions, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_LOCALE, ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { LocalDatePipe } from 'src/app/pipe/local-date-pipe.component';
import { RoleService } from 'src/app/services/role/role.service';
import { TickerTapService } from 'src/app/services/ticker-tap/ticker-tap.service';
import { CustomNgxDatetimeAdapter } from '../../sox-audit-logs/sox-audit-logs-list/customngx.datetime.adapter';

declare var $: any;
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const CustomNgxDatetimeAdapterFactory = () => {
  let _options: NgxMatMomentDateAdapterOptions = { strict: false, useUtc: false }
    return new CustomNgxDatetimeAdapter("", _options);
  };
@Component({
  selector: 'app-add-edit-ticker-tap-message',
  templateUrl: './add-edit-ticker-tap-message.component.html',
  styleUrls: ['./add-edit-ticker-tap-message.component.css'],
  providers: [
    {
      provide: NgxMatDateAdapter,
      useFactory: CustomNgxDatetimeAdapterFactory,
      deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: MY_FORMATS },
    LocalDatePipe
  ],
})
export class AddEditTickerTapMessageComponent implements OnInit {

  title = "Add Message";
  screenType = "add";
  messageScheduleForm: FormGroup;
  messageId: string = '';
  minStartDate = '';
  minEndDate = '';
  maxStartDate = '';
  maxEndDate = '';
  messageConfig:any = {
    extraPlugins: "autogrow",
    resize_enabled: false,
    height: 80,
    removePlugins: "resize",
    toolbar: [
			[ 'Link', 'Unlink' ],
			[ 'Bold', 'Italic' ]		
		],
    linkShowTargetTab : false,
    linkShowLinkInfoTab : false,
    removeButtons:'Cut,Copy,Paste,Undo,Redo,Anchor',
    versionCheck: false
  };
  color: ThemePalette = 'primary';
  isShowEditorValidation = false;
  dialogRef: MatDialogRef<any>;
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }
  updateStartDate = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    public roleService: RoleService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private tickerTapService: TickerTapService,
    private dialog: MatDialog,
    private localDatePipe: LocalDatePipe
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.messageId) {
        this.messageId = params.messageId;
        this.screenType = "edit";
      }
    });

    if (this.screenType == 'edit')
      this.getTickerTapMessageById();
  }

  ngOnInit(): void {
    this.title = this.screenType == "add" ? "Add Message" : "Edit Message";
    if (!this.roleService.objRole.isSystemAdmin) {
      this.router.navigate(['/unauthorized']);
    }
    
    this.messageScheduleForm = new FormGroup({
      message: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      startDateTime: new FormControl({disabled: true}, [Validators.required]),
      endDateTime: new FormControl({disabled: true}, [Validators.required])
    });

    if (this.screenType == 'add') {     
      this.minStartDate = this.setMinStartDate(moment());  // set localdatetime initially
      this.minEndDate = this.setMinEndDate(this.minStartDate);
      this.messageScheduleForm.controls['startDateTime'].patchValue(this.minStartDate);
      this.messageScheduleForm.controls['endDateTime'].patchValue(this.minEndDate);
    }

    $("input[formcontrolname='hour']").prop("disabled", true);
    $("input[formcontrolname='minute']").prop("disabled", true);
  }

  getTickerTapMessageById() {
    this.spinner.show();
    let obj = {};
    obj['pageNo'] = 1;
    obj['pageSize'] = 1;
    obj['orderByColumn'] = '';
    obj['isActive'] = true;
    obj['tickerTapId'] = this.messageId;

    this.tickerTapService.getTickerTapMessages(obj).subscribe(result => {
        this.spinner.hide();
        if (result && result['data'].length) {
          this.messageScheduleForm.patchValue({
            "message": result['data'][0].htmlMessage,
            "startDateTime": this.localDatePipe.transform(result['data'][0].startDateTime), //convert date to localtime
            "endDateTime": this.localDatePipe.transform(result['data'][0].endDateTime), //convert date to localtime
          });

          if (moment(result['data'][0].startDateTime).format('YYYY-MM-DD HH:mm') <= moment().utc().format('YYYY-MM-DD HH:mm')) {
            this.messageScheduleForm.controls['startDateTime'].disable();
            this.updateStartDate = false;
          }
        }
    }, err => {
      this.spinner.hide();
        let errors = err.errors == undefined ? err.error : err.errors;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
    });
  }

  saveTickerTapMessage() {
    this.isShowEditorValidation = true;
    this.messageScheduleForm.markAllAsTouched();
    let startDate = moment(this.messageScheduleForm.controls['startDateTime'].value).utc().format('YYYY-MM-DD HH:mm'); //convert local to utc time
    let endDate = moment(this.messageScheduleForm.controls['endDateTime'].value).utc().format('YYYY-MM-DD HH:mm'); //convert local to utc time
    let message = this.messageScheduleForm.controls["message"].value;

    if ((this.messageId == null || this.messageId == '' || this.updateStartDate) && startDate != null && startDate != undefined && startDate != '' && startDate <= moment().utc().format('YYYY-MM-DD HH:mm')) {
      this.toaster.open({
        text: "Start Date & Time must be future date & time",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } 
    else if (startDate != null && startDate != undefined && startDate != '' && endDate != null && endDate != undefined && endDate != '' && endDate <= startDate) {
      this.toaster.open({
        text: "End Date & Time must be greater than from Start Date & Time",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    } 
    else if (endDate != null && endDate != undefined && endDate != '' && endDate <= moment().utc().format('YYYY-MM-DD HH:mm')) {
      this.toaster.open({
        text: "End Date & Time must be future date & time",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else if (!this.validateString(message)) {
      this.toaster.open({
        text: "Message should be in single line only",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else {
      let messageWithoutHtml = this.removeHTML(this.formatMessage(this.messageScheduleForm.controls.message?.value));

      if (messageWithoutHtml?.length > 250) {
          this.toaster.open({
            text: "Maximum characters limit of 250.",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
      }
      else if (this.messageScheduleForm.status === 'INVALID' && !this.messageScheduleForm.controls.message?.errors?.maxlength) { // bypass message maxlength validation as above if condition handle this validation
        this.toaster.open({
          text: 'Please provide valid input for all the highlighted fields',
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      } 
      else {
        this.spinner.show();
          var body = {
              "message": messageWithoutHtml,
              "startDateTime": startDate,
              "endDateTime": endDate,
              "tickerTapId": this.messageId,
              "htmlMessage": this.formatMessage(message)
          };

        this.tickerTapService.addUpdateTickerTapMessage(body).subscribe(response => {
          if (response) {
            var message = (this.messageId != null && this.messageId != '') ? "Message updated successfully" : "Message added successfully";
            this.toaster.open({
              text: message,
              type: 'success',
              position: 'top-right',
              duration: 10000
            });
          }
          this.spinner.hide();
          this.router.navigateByUrl('/configuration/tickerTapMessages');
        }, err => {
          this.spinner.hide();
          let errors = err.errors == undefined ? err.error : err.errors;
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        });
      }
    }
  }

  onEditorFocus() {
    this.isShowEditorValidation = this.isShowEditorValidation;     
  }

  cancel() {
    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
      deviceName: '',
      status: '',
      title: 'Confirmation',
    }
    
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }

  onDateChange(type: string) {
    if (type == 'startDate')
      this.minEndDate = this.messageScheduleForm.controls['startDateTime'].value;
    else
      this.maxStartDate = this.messageScheduleForm.controls['endDateTime'].value;
  }

  onConfirm(){
    this.dialogRef.close();
    this.router.navigate(['/configuration/tickerTapMessages']);
  }

  onCancel() {
    this.dialogRef.close();
  }

  setMinStartDate(date) {
    //set nearest 5th minute
    const reminder = 5 - (date.minutes() % 5);
    return moment(date).add(reminder, "minutes").format('YYYY-MM-DD HH:mm');
  }

  setMinEndDate(date) {
    //set startdate + 5 minute
    return moment(date).add(5, "minutes").format('YYYY-MM-DD HH:mm');
  }

  formatMessage(message) {
    var aTag = (message.match('target=') || []).length;
    if (aTag == null || aTag <= 0)
      message = message.replace("<a ", "<a target='_blank' ");
      
    return message.replace("\n", "").replace("<p>", "").replace("</p>", "").replace("&nbsp;", " ").replace("&lt;", "<").replace("&gt;", ">").replace("&amp;", "&").replace("&quot;", '"').replace("&#039;", "'");
  }

  validateString(message) {
    // message should not have new line/p tag
    var pTags = (message.match(/<p>/g) || []).length;
    var brTags = (message.match(/<br>/g) || []).length;
    var br1Tags = (message.match(/<br\/>/g) || []).length;
    var br2Tags = (message.match(/<br \/>/g) || []).length;
    if((pTags != null && pTags > 1) || (brTags != null && brTags > 0) || (br1Tags != null && br1Tags > 0) || (br2Tags != null && br2Tags > 0))
      return false;
    else return true;
  }

  restrictEnterKey() { return false; }

  removeHTML(str){ 
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  disableTimeMinuteFields() {
    setTimeout(() => {    
      $(".mat-datepicker-popup :input").attr("readonly", true);
    }, 10);
  }
}
