import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { MachineService } from 'src/app/services/machine/machine.service';
import { RoleService } from 'src/app/services/role/role.service';
import { MachineMoveDataModel } from './machine-move.model';
declare var $: any;

@Component({
  selector: 'app-machine-move',
  templateUrl: './machine-move.component.html',
})
export class MachineMoveComponent implements OnChanges, OnInit {
  selectedEntityId: string;
  @Input() machineMoveDataModel: MachineMoveDataModel;
  @Output() onConfirm = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<string>();
  @Input() machineMoveShow: boolean = false;

  brandTypeList: any[] = [];
  markets: any[] = [];
  marketsCompleteList: any[] = [];
  customers: any[] = [];
  sites: any[] = [];
  sitesCompleteList: any[] = [];

  filter = {
    search: '',
    brandType: '',
    moveType: '',
    market: null,
    customer: null,
    site: null,
  };

  marketDropDownSettings = {
    singleSelection: true,
    defaultOpen: false,
    idField: 'marketId',
    textField: 'marketName',
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1,
  };
  customerDropDownSettings = {
    singleSelection: true,
    defaultOpen: false,
    idField: 'customerId',
    textField: 'customerName',
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1,
  };
  siteDropDownSettings = {
    singleSelection: true,
    defaultOpen: false,
    idField: 'siteId',
    textField: 'siteName',
    enableCheckAll: false,
    allowSearchFilter: true,
    itemsShowLimit: 1,
  };
  showEntitiesSelection = false;
  disableMoveButton: boolean = true;
  disableDropDownBrandType: boolean = true;

  constructor(private spinner: NgxSpinnerService, private machineService: MachineService, private toaster: Toaster, public roleService: RoleService) {}

  ngOnInit() {
    this.getBrandTypeList();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'machineMoveShow': {
            let requestBody = this.machineMoveDataModel.requestBody;
            if (requestBody != undefined && requestBody != null && requestBody != '') {
              this.selectedEntityId = undefined;
              this.filter = {
                search: '',
                brandType: '',
                moveType: '',
                market: null,
                customer: null,
                site: null,
              };

              this.showEntitiesSelection = false;

              this.loadMarkets();

              if (!this.roleService.objRole.isSystemAdmin) {
                this.showEntitiesSelection = true;
              }
            }
          }
        }
      }
    }
  }

  getBrandTypeList() {
    this.machineService.getBrandTypeList().subscribe((response) => {
      this.brandTypeList = [];

      if (response['data'].length) {
        this.brandTypeList = response['data'];
      }
    });
  }

  clickOnCancel() {
    this.selectedEntityId = undefined;
    this.filter = {
      search: '',
      brandType: '',
      moveType: '',
      market: null,
      customer: null,
      site: null,
    };

    if (this.roleService.objRole.isSystemAdmin) {
      this.showEntitiesSelection = false;
    }
  }

  clickOnClose() {
    $('.sidebar-mini').removeClass('filter-open');
    this.onCancel.emit();
    this.filter.search = '';
    this.filter.brandType = '';
  }

  clickOnSave() {
    $('.sidebar-mini').removeClass('filter-open');
    if (this.filter.site == null || this.filter.site == 0) {
      this.toaster.open({
        text: 'Please select site where you want to move',
        type: 'danger',
        position: 'top-right',
        duration: 10000,
      });

      return;
    }

    const siteSelected = this.sitesCompleteList.filter((it) => it.siteId == this.filter.site[0].siteId)[0];

    if (!siteSelected.siteHasPriceCard || !siteSelected.siteHasTradeHours) {
      this.toaster.open({
        text: 'Can not move machine to the selected site as it might be Inactive / does not have trade hours setup / does not have a price card allocated.',
        type: 'danger',
        position: 'top-right',
        duration: 10000,
      });

      return;
    }

    const machineId = this.machineMoveDataModel.requestBody.machineId;
    const nextLevelNumber = 8;
    const marketId = this.filter.market[0].marketId;
    const movedToCrossMarket = this.filter.moveType.toLowerCase() != 'within-market';

    this.onConfirm.emit({
      machineId: machineId,
      nextLevelNumber: nextLevelNumber,
      parentEntityId: siteSelected.siteId,
      marketId: marketId,
      parentlevelNumber: 7,
      movedToCrossMarket: movedToCrossMarket,
    });
  }

  changeBrandType(event) {
    this.filter.brandType = event.value;

    if (event && event.value && event.value != '') {
      this.sites = this.sitesCompleteList.filter((it) => it.siteBrandType.toLowerCase() == event.value.toLowerCase());
      this.filter.site = null;
      this.disableMoveButton = true;
    } else {
      this.sites = this.sitesCompleteList;
    }
  }

  onMoveTypeChange(event) {
    this.filter.market = {};
    this.filter.customer = {};
    this.filter.site = {};
    this.showEntitiesSelection = true;
    this.filter.moveType = event.value;
    this.filter.brandType = '';
    this.disableDropDownBrandType = true;
    this.disableMoveButton = true;

    if (this.filter.moveType.toLowerCase() == 'cross-market') {
      this.markets = this.marketsCompleteList.filter((it) => it.marketId != this.machineMoveDataModel.requestBody.marketId);
    } else {
      this.markets = this.marketsCompleteList.filter((it) => it.marketId == this.machineMoveDataModel.requestBody.marketId);
      this.filter.market = this.markets;
      this.loadPartners();
    }
  }

  loadMarkets() {
    this.spinner.show();
    this.machineService.getMachineMoveEntities(this.machineMoveDataModel.requestBody.machineId, null, null, 1).subscribe(
      (response) => {
        if (response != null) {
          this.markets = response['data'];
          this.marketsCompleteList = response['data'];
        }

        if (!this.roleService.objRole.isSystemAdmin) {
          if (this.marketsCompleteList.length == 1) {
            this.filter.market = this.marketsCompleteList;
            this.spinner.hide();
            this.loadPartners();
          }
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.toaster.open({
          text: 'Something went wrong when loading Markets. Please contact System Administrator.',
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });

        console.log('Move Machine - Error Loading markets. ', err);
        this.spinner.hide();
      },
    );
  }

  loadPartners() {
    this.customers = [];
    if (this.filter.market == null || this.filter.market.length == 0) return;

    this.spinner.show();

    this.machineService.getMachineMoveEntities(this.machineMoveDataModel.requestBody.machineId, this.filter.market[0].marketId, null, 4).subscribe(
      (response) => {
        if (response != null) {
          this.customers = response['data'];
        }

        if (this.customers.length == 1) {
          this.filter.customer = this.customers;
          this.spinner.hide();
          this.loadSiteBelongToCustomer();
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.toaster.open({
          text: 'Something went wrong when loading Customers. Please contact System Addministrator.',
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });

        console.log('Move  Machine - Error Loading Customers. ', err);
        this.spinner.hide();
      },
    );
  }

  loadSiteBelongToCustomer() {
    this.sites = [];

    if (this.filter.customer == null || this.filter.customer.length == 0) return;

    this.disableDropDownBrandType = false;

    this.spinner.show();

    this.machineService.getMachineMoveEntities(this.machineMoveDataModel.requestBody.machineId, null, this.filter.customer[0].customerId, 7).subscribe(
      (response) => {
        if (response != null) {
          this.sites = response['data'];
          this.sitesCompleteList = response['data'];
        }

        if (this.sites.length == 1) {
          this.filter.site = this.sites;
          this.disableMoveButton = false;
        }

        this.spinner.hide();
      },
      (err) => {
        this.toaster.open({
          text: 'Something went wrong when loading Sites. Please contact System Addministrator.',
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });

        console.log('Move  Machine - Error Loading Site. ', err);
        this.spinner.hide();
      },
    );
  }

  onDeSelectMarket() {
    this.filter.customer = {};
    this.filter.site = {};
    this.customers = [];
    this.sites = [];
  }

  onDeSelectCustomer() {
    this.filter.site = {};
    this.sites = [];
    this.disableDropDownBrandType = true;
  }

  onSelectSite() {
    this.disableMoveButton = false;
  }

  onDeSelectSite() {
    this.disableMoveButton = true;
  }
}
