import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { DrinkSetupPropositionTypeEnum, DrinkSetupPropositionTypeList } from "src/app/components/drinks-setup/models/drink-setup-proposition-type.model";

@Injectable({
	providedIn: 'root'
})
export class DrinkSetupPropositionTypeService {

    private _drinkGroupPropositionTypeChanged: BehaviorSubject<DrinkSetupPropositionTypeEnum> = new BehaviorSubject(DrinkSetupPropositionTypeEnum.All);
    public readonly drinkGroupPropositionTypeChanged: Observable<DrinkSetupPropositionTypeEnum> = this._drinkGroupPropositionTypeChanged.asObservable();

    private priceCardTemplatePropositionData: DrinkSetupPropositionTypeList = {
        data: [
            { "name": "All" , "id":  DrinkSetupPropositionTypeEnum.All, "isDisabled": false },
            { "name": "Concession Specific" , "id": DrinkSetupPropositionTypeEnum.ConcessionSpecific, "isDisabled": false },
        ]
    };

    getPriceCardTemplatePropositionList(): Observable<DrinkSetupPropositionTypeList> {
        return of(this.priceCardTemplatePropositionData);
    }

    setDrinkSetupPropositionType(propositionType: DrinkSetupPropositionTypeEnum) {
        this._drinkGroupPropositionTypeChanged.next(propositionType);
    }
}
   