import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { RemoteUpdateMachineDetails } from './dto/remote-update-machine-details';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
	providedIn: 'root'
})
export class RemoteUpdateService {

	constructor(
		private httpClient: HttpClient,
		private cookieService: CookieService
	) { }

	cookieName: string = environment.cookieName

	getRemoteUpdateMachineDetails(machineId: string) {

		const configHeader = {
			headers: {
				'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
			}
		};
		
		return this.httpClient
			.get<RemoteUpdateMachineDetails>(environment.apiUrl.remoteUpdate + `/v1/machines/${machineId}/getdetail`, configHeader)
			.pipe(map(response => {
				return response;
			}));
	}
}