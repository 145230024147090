import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PriceCardTypeLookupConstant } from 'src/app/enums/pricecardtype.enum';
import { EntityService } from 'src/app/services/entity/entity.service';
import { RoleService } from 'src/app/services/role/role.service';
import { SalesService } from 'src/app/services/sales/sales.service';

declare var $: any;

@Component({
  selector: 'app-update-history',
  templateUrl: './update-history.component.html',
  styleUrls: ['./update-history.component.css']
})
export class UpdateHistoryComponent implements OnInit {


  displayedColumns: string[] = ['amendmentId', 'discrepancyType', 'totalCount', 'initiationDate', 'completionDate', 'updatedBy', 'status', 'actions'];
  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  updateHistoryList: any = []
  recordsTotal = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    searchText: '',
    sortBy: 'InitiationDate desc'
  };
  filter = {
    search: ''
  }
  isPageDataLoad: boolean = false;
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'History'
  };
  blob: any;
  
  constructor(private spinner: NgxSpinnerService, private entityService: EntityService, public roleService: RoleService, public priceCardTypeConstant: PriceCardTypeLookupConstant,
    private salesService: SalesService, private router: Router, public dialog: MatDialog, private toaster: Toaster, public httpClient: HttpClient) { }

  ngOnInit() {
    this.getSalesUpdateHistory();
  }

  sortData(e) {
    e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
    this.params.sortBy = (e.active != '' && e.direction != '' ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
    this.params.pageNumber = 1;
    this.getSalesUpdateHistory();
  }

  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    this.getSalesUpdateHistory();
  }

  clearSearch() {
    this.filter.search = '';
    this.getSalesUpdateHistory();
  }

  getSalesUpdateHistory(fromClear = undefined) {
    this.spinner.show();
    if (fromClear == "search") {
      this.params.pageNumber = 1;
    }
    let obj = {};
    if (this.filter.search != '') { obj['search'] = this.filter.search; };
    if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
    if (this.params.sortBy != undefined) {
      obj['orderByColumn'] = this.params.sortBy
    };

    this.salesService.getSalesUpdateHistory(obj).subscribe(response => {
      this.recordsTotal = response?.params?.count;
      this.spinner.hide();
      this.isPageDataLoad = true;
      if (response && response['data'].length) {
        this.updateHistoryList = response['data']
      } else {
        this.updateHistoryList = [];
      }

    }, err => {
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  export(filename: string, base64: string, contentType: string) {
    var filetype = contentType; //base64.split(';')[0].split(':')[1];
    // var b64data = base64.split(',')[1]
    let byteCharacters = atob(base64);
    let byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    this.blob = new Blob([byteArray], { type: filetype });
    FileSaver.saveAs(this.blob, filename);
    $('.ajax-investmentUpload-request').hide();
  }

  async exportData(amendmentId) {
    if (amendmentId != null && amendmentId != '') {
      this.spinner.show();
      await this.salesService.downloadamendmentjobhistory(amendmentId).subscribe(async response => {
        if (response != null) {
           this.export(response.fileDownloadName,response.fileContents,response.contentType);
          
          this.spinner.hide();

        }
      }, err => {
        this.spinner.hide();
        let errors = err.error;
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      });
    } else {
      this.toaster.open({
        text: "File not found",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
  }

  back() {
    this.router.navigate(['/sales'], { queryParams: { tabIndex: 1 } })
  }

  blobToString(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

}
