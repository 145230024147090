export const MachineTypeComponents:any = [{
  key:'Coffee Bean Hoppers',
  controlName:'coffeeBeanHopper',
  values:{
    'COSTA 1100S CLUB (Branded)':['Single Hopper','Dual Hopper'],  
    'COSTA 1500SP (Branded)':['Single Hopper','Dual Hopper'],
    'COSTA SOUL (Branded)':['Single Hopper','Dual Hopper'],
  }
},
{
  key:'Hot Water Spout',
  controlName:'hotWaterSpout',
  values:{   
    'COSTA 1100S CLUB (Branded)':['No Hot Water Spout','Hot Water Spout'],
    'COSTA 1500SP (Branded)':['No Hot Water Spout','Hot Water Spout'],
    'COSTA SOUL (Branded)':['No Hot Water Spout','Hot Water Spout'],
  }
},
{
  key:'Lockable Coffee bean hopper',
  controlName:'lockableCoffeeBeanHopper',
  values:{
    'COSTA 1100S CLUB (Branded)':['Yes','No'],
    'COSTA 1500SP (Branded)':['Yes'],
    'COSTA SOUL (Branded)':['Yes','No'],
  }
},
{ 
  key:'Milk Hoppers',
  controlName:'secondFreshMilk',
  values:{
    'COSTA 1100S CLUB (Branded)':['Single Milk'],   
    'COSTA 1500SP (Branded)':['Single Milk'],
    'COSTA SOUL (Branded)':['Single Milk','Dual Milk'],
  }
},
{
  key:'Water Connection',
  controlName:'waterConnection',
  values:{
    'COSTA 1100S CLUB (Branded)':['Fixed Water Connection','Integrated Water Tank'],    
    'COSTA 1500SP (Branded)':['Fixed Water Connection','Integrated Water Tank'],
    'COSTA SOUL (Branded)':['Fixed Water Connection'],
  }
},
{
  key:'Syrup',
  controlName:'syrup',
  values:{
    'COSTA 1100S CLUB (Branded)':['None','Manual Flavour Station'],    
    'COSTA 1500SP (Branded)':['None','Manual Flavour Station','Integrated Flavour station (under counter)','Integrated Flavour station (side unit)'],
    'COSTA SOUL (Branded)':['None','Manual Flavour Station','Integrated Flavour station (under counter)','Integrated Flavour station (side unit)'],
  }
},
{
  key:'Vending Unit (Payment System Housing)',
  controlName:'vendingUnit',
  values:{
    'COSTA 1100S CLUB (Branded)':['None','Payment System'],   
    'COSTA 1500SP (Branded)':['None','Payment System'],
    'COSTA SOUL (Branded)':['None','Payment System'],
  }
},
{
  key:'Ice Capable (Separate Ice Machine)',
  controlName:'iceCapable',
  values:{
    'COSTA 1100S CLUB (Branded)':['No Ice'],   
    'COSTA 1500SP (Branded)':['No Ice','Separate Ice Machine'],
    'COSTA SOUL (Branded)':['No Ice','Separate Ice Machine'],
  },  
}];