import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Toaster } from "ngx-toast-notifications";
import { AuthService } from "src/app/services/auth/auth.service";
import { EntityService } from "src/app/services/entity/entity.service";
import { RoleService } from "src/app/services/role/role.service";
import { EntityConstant } from "src/app/constant/entity.constants";
import { MachineService } from "src/app/services/machine/machine.service";
import { PropositionMasterEnum } from "src/app/enums/propositionMaster.enum";
import { AzureService } from "src/app/services/azure/azure.service";
import { RemoteUpdateService } from "src/app/services/remote-update/remote-update.service";
import { RemoteUpdateMachineDetails } from "src/app/services/remote-update/dto/remote-update-machine-details";
import { MachineRouterModuleInformation } from "src/app/services/machine/dtos/machine-router-module-information";

@Component({
  selector: "app-machine-data",
  templateUrl: "./machine-data.component.html",
  styleUrls: ["./machine-data.component.css"],
})
export class MachineDataComponent implements OnInit {
  machineData: any = {};
  allMachineDetails: any = {};
  machineId = "";
  hasPageLoad = false;
  connectivityStatusHtmlClass = ""
  routerStatusHtmlClass = ""
  entityConstant = new EntityConstant();
  autonomousMachinesFT: boolean;
  globalChangesFT: boolean;
  enableMachineDataFT: boolean;
  propositionMasterEnum = PropositionMasterEnum;
  macAddress: string;
  remoteUpdateMachineDetails: RemoteUpdateMachineDetails;
  machineRouterModuleInformationData?: MachineRouterModuleInformation = {};
  routerStatusText: string = '-';

  private static readonly TOASTER_POSITION = "top-right";
  private static readonly TOASTER_DURATION = 10000;
  private static readonly TOASTER_TYPE_DANGER = "danger";

  constructor(
    private spinner: NgxSpinnerService,
    public entityService: EntityService,
    public machineService: MachineService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toaster: Toaster,
    public authService: AuthService,
    public roleService: RoleService,
    public azureService: AzureService,
    public remoteUpdateService: RemoteUpdateService
  ) {
    activatedRoute.params.subscribe((params) => {
      if (params.entityId) {
        this.machineId = params.entityId;
      }
      if (params.machineId) {
        this.machineId = params.machineId;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();
    this.getMachineData();
  }

  setRouterMacAddressForAutonomous() {
    this.spinner.show();
    this.machineService.getMachineDetailsWithForm(this.machineId, "").subscribe(
      (response) => {
        this.spinner.hide();
        if (response) {
          this.allMachineDetails = response["data"];
          const routerModuleMacAddress = this.allMachineDetails.machineRouterModuleInformation?.macAddress ?? "";
          this.macAddress = this.formatMacAddress(routerModuleMacAddress);
        } else {
          this.showToaster("Something went wrong. Please try again.");
        }
      },
      (err) => this.handleError(err)
    );
  }

  getMachineData() {
    this.entityService.getMachineData(this.machineId).subscribe(
      (response) => {
        this.hasPageLoad = true;
        this.spinner.hide();
        if (response) this.machineData = response;

        if (!this.globalChangesFT) {
          this.machineData.connectivityStatus == "Connected" ? this.machineData.communicationState = "ONLINE"
          : this.machineData.connectivityStatus == "Disconnected" ? this.machineData.communicationState= "OFFLINE"
          : this.machineData.communicationState = ""
        }

        this.machineData.connectivityStatus == "Connected" ? this.connectivityStatusHtmlClass = "connected"
        : this.machineData.connectivityStatus == "Disconnected" ? this.connectivityStatusHtmlClass = "disconnected"
        : this.connectivityStatusHtmlClass = "not-connected"

        if (this.entityConstant.hasMacAddress.includes(this.machineData.propositionType)) {
          this.handleMachineMacAddress();
        }

        if (this.enableMachineDataFT)
          this.getRemoteUpdateMachineDetails();

        if(this.machineData.propositionType == this.propositionMasterEnum.Concession && !this.enableMachineDataFT) {
          this.getRemoteUpdateMachineDetails();
        }

        if(this.machineData.propositionType == this.propositionMasterEnum.Concession && this.enableMachineDataFT) {
          this.getMachineRouterModuleInformationData();
        }
      },
      (err) => {
        this.hasPageLoad = true;
        this.spinner.hide();
        let errors = err.error;
        errors.forEach((element) => {
          this.toaster.open({
            text: element.msg,
            type: "danger",
            position: "top-right",
            duration: 10000,
          });
        });
      }
    );
  }

  private handleMachineMacAddress() {
    const { propositionType, macAddress } = this.machineData;

    if (propositionType === PropositionMasterEnum.Autonomous || propositionType === PropositionMasterEnum.Capsules) {
      this.setRouterMacAddressForAutonomous();
    } else {
      this.macAddress = this.formatMacAddress(macAddress ?? "");
    }
  }

  gotoSite(machineData) {
    this.router.navigate(["/entity/", machineData.siteId], {
      queryParams: {
        nextLevelNumber: 7,
        screenType: "read",
        parentEntityId: machineData.siteParentEntityId,
        marketId: machineData.marketId,
        parentlevelNumber: 7,
      },
    });
  }

  private async SetFeatureFlagsConfigurations() {
    this.autonomousMachinesFT = await this.azureService.isAutonomousMachinesFT();
    this.globalChangesFT = await this.azureService.isGlobalChangesFT();
    this.enableMachineDataFT = await this.azureService.isEnableMachineDataFT();
  }

  formatMacAddress(value: string): string {
    if(value === undefined ||
       value === null ||
       value.length < 12 ||
       value.length > 12)
      return value;

    const formatedValue = value
      .replace(/[^0-9A-Fa-f]/g, '')
      .replace(/(.{2})/g, '$1-')
      .toUpperCase()
      .slice(0, 17);

    return formatedValue;
  }

  getRemoteUpdateMachineDetails(){
    this.remoteUpdateService.getRemoteUpdateMachineDetails(this.machineId).subscribe(
      (response) => {
        this.remoteUpdateMachineDetails = response;
      },
      (err) => {
        let errors = err.error;
        errors.forEach((element) => {
          this.toaster.open({
            text: element.msg,
            type: "danger",
            position: "top-right",
            duration: 10000,
          });
        });
      }
    );
  }

  getMachineRouterModuleInformationData(): void {
    this.spinner.show();
    this.machineService.getMachineRouterModuleInformation(this.machineId).subscribe(
      (response) => {
        this.spinner.hide();
        this.machineRouterModuleInformationData = response;
        this.setCustomRouterStatus();
      },
      (err) => {
        this.spinner.hide();
      }
    )
  }

  setCustomRouterStatus(): void {
    if (this.machineRouterModuleInformationData?.routerStatus !== null && this.machineRouterModuleInformationData?.routerStatus !== undefined) {
      if (this.machineRouterModuleInformationData.routerStatus) {
        this.routerStatusText = 'Online';
        this.routerStatusHtmlClass = 'router-status router-online';
      } else {
        this.routerStatusText = 'Offline';
        this.routerStatusHtmlClass = 'router-status router-offline';
      }
    } else {
      this.routerStatusText = '-';
      this.routerStatusHtmlClass = '';
    }
  }

  private showToaster(message: string) {
    this.toaster.open({
      text: message,
      type: MachineDataComponent.TOASTER_TYPE_DANGER,
      position: MachineDataComponent.TOASTER_POSITION,
      duration: MachineDataComponent.TOASTER_DURATION,
    });
  }

  private handleError(err: any) {
    const errors = err.error || [];
    errors.forEach((element: any) => {
      this.showToaster(element.msg);
    });
  }
}
