import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { MachineTypeService } from 'src/app/services/machine-type/machine-type.service';
import { RoleService } from 'src/app/services/role/role.service';

@Component({
  selector: 'app-configuration-machinetype',
  templateUrl: './configuration-machinetype.component.html',
  styleUrls: ['./configuration-machinetype.component.css']
})
export class ConfigurationMachinetypeComponent implements OnInit {

  recordsTotal = 0;
  isPageDataLoad = false;
  machinetypes: any = [];
  displayedColumns: string[] = ["propositionType", "machineGroupName", "machineModelName", "machineModelType", "machineModelCode", "schaererMachineCode", "schaererMachineType", "actions"];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    sortBy: 'SequenceNo',
    sortOrder: 'asc'
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public roleService: RoleService, private router: Router, private machineTypeService: MachineTypeService,
    private spinner: NgxSpinnerService, private toaster: Toaster) { }

  ngOnInit(): void {
    if (!this.roleService.objRole.isSystemAdmin) {
      this.router.navigate(['/unauthorized']);
    }
    this.getMachineTypes();
  }

  getMachineTypes() {
    this.spinner.show();
    let obj = {};
    if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber; }
    if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize; }
    if (this.params.sortBy != undefined) { obj['sortBy'] = this.params.sortBy; }
    if (this.params.sortOrder != undefined) { obj['sortOrder'] = this.params.sortOrder; }

    this.machineTypeService.GetmachineModels(obj).subscribe(response => {
      this.spinner.hide();
      this.isPageDataLoad = true;
      if (response && response['data']) {
        this.machinetypes = response['data'];
        this.recordsTotal = this.machinetypes[0].totalCount;
      } else {
        this.recordsTotal = 0;
        this.machinetypes = [];
      }
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (Array.isArray(errors)) {
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }

  pageChange(e) {
    this.params.pageNumber = (e.pageIndex > 0 ? (e.pageIndex + 1) : 1);
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    this.getMachineTypes();
  }

  sortData(e) {
    e.direction = (e.active == 'isActive' && e.direction != '' ? (e.direction == 'asc' ? 'desc' : 'asc') : e.direction);
    this.params.pageNumber = 1;
    this.params.sortBy = (e.active != '' && e.direction != '' ? e.active : (e.active != '' && e.direction == '') ? e.active : 'cells_name');
    this.params.sortOrder = (e.direction != '' ? e.direction : (e.direction == '') ? 'asc' : 'asc');
    this.getMachineTypes();
  }
}
