import { Component, OnInit } from '@angular/core';

import { RoleService } from 'src/app/services/role/role.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-remote-update-tasks',
  templateUrl: './remote-update-tasks.component.html',
  styleUrls: ['./remote-update-tasks.component.css'],
})
export class RemoteUpdateTasksComponent implements OnInit {
  constructor(public roleService: RoleService, private router: Router) { }
  ngOnInit(): void {
    if (!(this.roleService.objRole.isSystemAdmin || this.roleService.objRole.isMarketAdmin || this.roleService.objRole.isLevel2Admin || this.roleService.objRole.isLevel3Admin)) {
      this.router.navigate(['/unauthorized']);
    }
  }
}
