import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { ToastConfig, Toaster, ToastType } from "ngx-toast-notifications";
import {FormControl, FormGroup, FormBuilder} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-sitelayout',
  templateUrl: './sitelayout.component.html',
  styleUrls: ['./sitelayout.component.css']
})
export class SitelayoutComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['Lemon'];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any[] = [];
  selectedItems: any[] = [];
  cityList: any[] = [];
  selectedCities: any[] = [];
  dropdownSettings: any = {};
  filter = {
    city:'',
    cities : ''
}
myControl = new FormControl();
options: string[] = ['One', 'Two', 'Three'];
filteredOptions: Observable<string[]>;

myForm:FormGroup;
  constructor(private toaster:Toaster,private fb: FormBuilder) {
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
   }
   add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }
  focusFunction(){
    console.log('myControl',this.myControl.value);
    if(!this.myControl.value){
      this.myControl.patchValue('');
    }
    
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

 
  

  private _filterr(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
    this.cities = [
      { item_id: 1, item_text: 'New Delhi' },
      { item_id: 2, item_text: 'Mumbai' },
      { item_id: 3, item_text: 'Bangalore' },
      { item_id: 4, item_text: 'Pune' },
      { item_id: 5, item_text: 'Chennai' },
      { item_id: 6, item_text: 'Navsari' }
  ];
  this.filteredOptions = this.myControl.valueChanges
  .pipe(
    startWith(''),
    map(value => this._filterr(value))
  );
  this.selectedItems = [{ item_id: 4, item_text: 'Pune' }, { item_id: 6, item_text: 'Navsari' }];
  this.cityList = [
    {
      item_id: 1,
      item_text: "India",
      image: "http://www.sciencekids.co.nz/images/pictures/flags96/India.jpg",
    },
    {
      item_id: 2,
      item_text: "Spain",
      image: "http://www.sciencekids.co.nz/images/pictures/flags96/Spain.jpg",
    },
    {
      item_id: 3,
      item_text: "United Kingdom",
      image:
        "http://www.sciencekids.co.nz/images/pictures/flags96/United_Kingdom.jpg",
    },
    {
      item_id: 4,
      item_text: "Canada",
      image:
        "http://www.sciencekids.co.nz/images/pictures/flags96/Canada.jpg",
      
    },
    {
      item_id: 5,
      item_text: "Israel",
      image:
        "http://www.sciencekids.co.nz/images/pictures/flags96/Israel.jpg",
    },
    {
      item_id: 6,
      item_text: "Brazil",
      image:
        "http://www.sciencekids.co.nz/images/pictures/flags96/Brazil.jpg",
    },
    {
      item_id: 7,
      item_text: "Barbados",
      image:
        "http://www.sciencekids.co.nz/images/pictures/flags96/Barbados.jpg",
    },
    {
      item_id: 8,
      item_text: "Mexico",
      image:
        "http://www.sciencekids.co.nz/images/pictures/flags96/Mexico.jpg",
    },
  ];
  this.selectedCities = [
    {
      item_id: 1,
      item_text: "India",
      image: "http://www.sciencekids.co.nz/images/pictures/flags96/India.jpg",
    },
    {
      item_id: 5,
      item_text: "Israel",
      image:
        "http://www.sciencekids.co.nz/images/pictures/flags96/Israel.jpg",
    },
  ];
  this.dropdownSettings = {
    "singleSelection": false,
    "defaultOpen": false,
    "idField": "item_id",
    "textField": "item_text",
    "selectAllText": "Select All",
    "unSelectAllText": "UnSelect All",
    "enableCheckAll": true,
    "itemsShowLimit": 3,
    "allowSearchFilter": true
  };
    this.toaster.open({
      text: "warning",
      type: 'warning',//'danger','success'
      position: 'top-right',
      duration:68000
    });
    this.toaster.open({
      text: "Market Added successfully",
      type: 'success',//'danger','success'
      position: 'top-right',
      duration:68000
    });
    this.toaster.open({
      text: "Market Added successfully",
      type: 'info',//'danger','success'
      position: 'top-right',
      duration:68000
    });
    this.toaster.open({
      text: "Entity name is already exists",
      type: 'danger',//'danger','success'
      position: 'top-right',
      duration:68000
    });
    this.myForm = this.fb.group({
      city: [this.selectedItems]
  });
  }
  // ngAfterViewInit(){    
  //   if($.AdminAVNET.layout != undefined)
  //   {
  //     $.AdminAVNET.layout.fix();
  //   }
      
  // }
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
}
onSelectAll(items: any) {
    console.log('onSelectAll', items);
}
toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
}

handleLimitSelection() {
    if (this.limitSelection) {
        this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
        this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
}
get getItems() {
  return this.cityList.reduce((acc, curr) => {
    acc[curr.item_id] = curr;
    return acc;
  }, {});
}
}
