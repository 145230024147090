import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { DrinkDto } from 'src/app/components/remote-update-tasks/services/task-properties/dto/drink-dto';
import { TaskPropertiesService } from 'src/app/components/remote-update-tasks/services/task-properties/task-properties.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateRemoteUpdateTaskRequest } from 'src/app/components/remote-update-tasks/dtos/create-remote-update-task.model';
import { catchError, map, take } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Component({
  selector: 'app-task-enable-drinks',
  templateUrl: './task-enable-drinks.component.html',
  styleUrls: ['./../../new-task-step-common.css', './task-enable-drinks.component.css']
})
export class TaskEnableDrinksComponent implements OnInit {

  @Output() nextStepEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() backStepEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() newTaskRequest: CreateRemoteUpdateTaskRequest = new CreateRemoteUpdateTaskRequest();

  drinksByMarket: DrinkDto[];

  sessionName: string = "DRINKS_BY_MARKET";
  sessionDurationInMinutes: number = 5;

  public showSnackBar: boolean = false;
  public snackBarHeader: string = '';
  public snackBarMessage: string = '';

  constructor(
    private taskPropertiesService: TaskPropertiesService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.loadProperties();
  }

  loadProperties() {
    this.getDrinksFromApi();
  }

  getDrinksFromApi() {
    if (this.isTaskRequestLoaded()) {
      this.spinner.show();
      this.taskPropertiesService.getDrinksByMarketState(this.newTaskRequest).pipe(
        map(response => response.data
          .map(item => <DrinkDto>{
            cupType: item.cupType,
            name: item.name,
            state: item.state,
            code: item.code
          })),
        take(1),
        catchError(e => {
          if (e.status === 404) {
            this.showSnackBar = true;
            this.snackBarMessage = "There are no drinks for selection.";
            return of([]);
          }

          return throwError(e);
        })
      ).subscribe({
        next: (items) => {
          this.showSnackBar = false;
          this.drinksByMarket = items
        },
        complete: () => this.spinner.hide()
      });
    }
  }

  private isTaskRequestLoaded() {
    return !!this.newTaskRequest?.Level1 ||
      !!this.newTaskRequest?.Level2 ||
      !!this.newTaskRequest?.Level3;
  }

  refreshDrink() {
    this.drinksByMarket.forEach(x => {
      x.state = 1;
    })
  }

  closeSnackBar() {
    this.showSnackBar = false;
  }

  backStep(): void {
    this.backStepEvent.emit();
  }

  nextStep() {
    this.newTaskRequest.DrinkList = this.drinksByMarket
    this.nextStepEvent.emit();
  }
}
