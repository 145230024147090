import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FormControl } from '@angular/forms';

@Component({
  selector: 'grid-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit{
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() id: string = '';
  @Input() label: string = '';
  @Input() btnLabel: string = '';
  @Input() btnTooltip: string = '';
  @Input() fileType: string = '';
  @Input() allowedFileType: Array<string>;
  @Input() fileSize: number;
  @Input() message: string = '';
  @Input() hasError: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isReadonly: boolean = false;
  @Input() control : FormControl;
  @Input() isDisabled : boolean = false;
  @Output() onFocusOut: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onFileChange: EventEmitter<File> = new EventEmitter<File>();

  selectedFile: File;
  fileName: string = '';

  ngOnInit(): void {
  }

  ngOnChanges() : void {
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const allowedTypes = this.allowedFileType;
    const maxFileSize = this.fileSize * 1024 * 1024; // XXXMB
    const fileType = file.name.substring(file.name.length - 4);

    if (file && allowedTypes.includes(fileType) && file.size <= maxFileSize) {
      this.selectedFile = file;
      this.fileName = file.name;
      this.hasError = false;
      this.onFileChange.emit(file);
      this.onFocusOut.emit(event);
      return;
    }

    this.hasError = true;
    this.selectedFile = null;
    this.fileName = '';

    this.control.setValue('');
    if (file.size > maxFileSize)
      this.message = `Upload only ${this.fileType} with a maximum of ${this.fileSize} Mb`
    else if(this.fileType != fileType)
      this.message = `Invalid file type. Only ${this.fileType} files are allowed.`;
  }

  selectFile() {
    // Simulate clicking the standard file upload button
    this.fileInput.nativeElement.click();
  }
}
