import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { RoleService } from 'src/app/services/role/role.service';
import { TickerTapService } from 'src/app/services/ticker-tap/ticker-tap.service';

@Component({
  selector: 'app-ticker-tap-message-list',
  templateUrl: './ticker-tap-message-list.component.html',
  styleUrls: ['./ticker-tap-message-list.component.css']
})
export class TickerTapMessageListComponent implements OnInit {

  tabIndex = 0;
  recordsTotal = 0;
  isPageDataLoad = false;
  activeMessages: any = [];
  expiredMessages: any = [];
  displayedColumns: string[] = ['message','startDateTime','endDateTime','actions'];
  displayedExpiredColumns: string[] = ['message','startDateTime','endDateTime'];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    sortByColumn: 'startDateTime asc',
    search: ''
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public roleService: RoleService,
    private router: Router,
    private tickerTapService: TickerTapService,
    private spinner: NgxSpinnerService,
    private toaster: Toaster
  ) { }

  ngOnInit(): void {
    if (!this.roleService.objRole.isSystemAdmin) {
      this.router.navigate(['/unauthorized']);
    }
    this.getActiveOrExpiredTickerTapMessages();
  }

  onTabChanged(event) {
    this.recordsTotal = 0;
    this.tabIndex = event.index;
    this.params = {
      pageNumber: 1,
      pageSize: this.pageSizeOptions[0],
      sortByColumn: this.tabIndex == 0 ? "startDateTime asc" : "endDateTime desc",
      search: ''
    }
    this.getActiveOrExpiredTickerTapMessages();
  }

  pageChange(event) {
    this.params.pageNumber = (event.pageIndex > 0 ? (event.pageIndex + 1) : 1);
    this.params.pageSize = (event.pageSize > 0 ? event.pageSize : this.pageSizeOptions[0]);
    this.getActiveOrExpiredTickerTapMessages();
  }

  sortData(event) {
    event.direction = (event.active == 'isActive' && event.direction != '' ? (event.direction == 'asc' ? 'desc' : 'asc') : event.direction);
    this.params.pageNumber = 1;
    this.params.sortByColumn = (event.active != '' && event.direction != '' ? event.active + ' ' + event.direction : (event.active != '' && event.direction == '') ? event.active + ' asc' : 'cells_name asc');
    this.getActiveOrExpiredTickerTapMessages();
  }

  getActiveOrExpiredTickerTapMessages() {
    this.spinner.show();
    let obj = {};
    if (this.params.pageNumber != undefined) { obj['pageNo'] = this.params.pageNumber };
    if (this.params.pageSize != undefined) { obj['pageSize'] = this.params.pageSize };
    if (this.params.sortByColumn != undefined) { obj['orderByColumn'] = this.params.sortByColumn };
    if (this.params.search != undefined) { obj['search'] = this.params.search };
    obj['isActive'] = this.tabIndex == 0;

    this.tickerTapService.getTickerTapMessages(obj).subscribe(result => {
      this.spinner.hide();
      this.isPageDataLoad = true;
      if (result && result['data']) {
        if (this.tabIndex == 0) {
          this.activeMessages = result['data'];
          this.activeMessages.forEach(data => {
            data.title = this.removeHtmlTagsFromString(data.message);
          });
        }
        else {
          this.expiredMessages = result['data'];
          this.expiredMessages.forEach(data => {
            data.title = this.removeHtmlTagsFromString(data.message);
          });
        }
        this.recordsTotal = result['params'].count ? result['params'].count : 0;;
      }
      else  {
        this.recordsTotal = 0;
        if (this.tabIndex == 0)
          this.activeMessages = [];
        else
          this.expiredMessages = [];
      }
    }, err => {
      this.spinner.hide();
      let errors = err.errors == undefined ? err.error : err.errors;
      if (Array.isArray(errors)) {
        errors.forEach(element => {
          this.toaster.open({
            text: element.msg,
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        });
      }
    });
  }

  removeHtmlTagsFromString(message)
  {
    if (message === null || message === '')
        return false;
    else
      message = message.toString();
    return message.replace(/<[^>]*>/g, '').replace("&nbsp;"," ");
  }

  clearSearch() {
    this.params.search = '';
    this.getActiveOrExpiredTickerTapMessages();
    }

    getActiveOrExpiredTickerTapMessagesFromSearchBox() {
        this.params.pageNumber = 1;
        this.getActiveOrExpiredTickerTapMessages();
    }
}
