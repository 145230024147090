
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-new-task-page-remote-update",
  templateUrl: "./new-task-page-remote-update.component.html",
  styleUrls: ["./../../../remote-update-tasks.component.css"],
})
export class NewTaskPageComponent implements OnInit {
  ngOnInit(): void {}

}
