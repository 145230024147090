import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-body-file-repository",
  templateUrl: "./body-file-repository.html",
  styleUrls: ["./../../../../remote-update-tasks.component.css"],
})
export class BodyFileRepositoryComponent implements OnInit {
  ngOnInit(): void {}
}
