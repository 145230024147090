import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HasSitesWithLowAdminEnabled } from 'src/app/models/has-sites-with-low-admin.model-enabled';
import { SiteLowAdminSetup } from 'src/app/models/site-low-admin-setup.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LowAdminService {
  cookieName = environment.cookieName;
  constructor(private cookieService: CookieService, private httpClient: HttpClient) {}

  getSiteLowAdminSetup(entityId: string): Observable<SiteLowAdminSetup> {
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

    return this.httpClient.get<SiteLowAdminSetup>(`${environment.baseUrl}/api/v1/lowadmin/${entityId}`, configHeader);
  }

  hasLowAdminEnableForAnySite(marketId: string): Observable<HasSitesWithLowAdminEnabled> {
    var configHeader = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.cookieService.get(this.cookieName + 'access_token')
			}
		};

    return this.httpClient.get<HasSitesWithLowAdminEnabled>(`${environment.baseUrl}/api/v1/lowadmin/${marketId}/hassites`, configHeader);
  }
}
