import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.authService.loginError || !this.authService.loginError.length) {
       this.authService.loginError = [{msg:"Something went wrong."}];
    }else{
        if(this.authService.loginError[0] && this.authService.loginError[0]['msg'] && this.authService.loginError[0]['msg'].toLowerCase()== "solution not found."){
        this.authService.loginError[0]['msg']="Something went wrong."
      }
    }
  }
  login() {
    this.router.navigate(['/login']);
  }
}
