import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface DayOfMonth{
  day: number,
  class: string
}

@Component({
  selector: 'app-calendar-remote-update',
  templateUrl: './calendar-remote-update.component.html',
  styleUrls: ['./calendar-remote-update.component.css']
})
export class CalendarRemoteUpdateComponent implements OnInit {

  @Input() id: string = "calendar-remote-update";
  @Input() date: Date;
  @Input() showTime: boolean = false;
  @Input() calendarName: string = "";
  @Input() width: string = "477px";

  currentDateLabel: string = "";
  currentTimeLabel: string = "";
  currentYear: number = 0;
  currentMonth: number = 0;
  currentDay: number = 0;

  daysOfMonth: DayOfMonth [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() : void {
      this.renderCalendar();
  }

  renderCalendar() {
    if(!this.date)
      return;
    this.currentYear = this.date.getFullYear();
    this.currentMonth = this.date.getMonth();
    this.currentDay = this.date.getDate();

    let firstDayofMonth = new Date(this.currentYear, this.currentMonth, 1).getDay(),
    lastDateofMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate(),
    lastDayofMonth = new Date(this.currentYear, this.currentMonth, lastDateofMonth).getDay(),
    lastDateofLastMonth = new Date(this.currentYear, this.currentMonth, 0).getDate();

    this.daysOfMonth = [];

    for (let i = firstDayofMonth; i > 0; i--) {
      this.daysOfMonth.push({day: lastDateofLastMonth - i + 1, class: 'inactive'});
    }

    for (let i = 1; i <= lastDateofMonth; i++) {
      let isSelectedDay = i === this.date.getDate()
                       && this.currentMonth === this.date.getMonth()
                       && this.currentYear === this.date.getFullYear()
                       ? "active" : "";

      this.daysOfMonth.push({day: i, class: isSelectedDay});
    }

    for (let i = lastDayofMonth; i < 6; i++) {
      this.daysOfMonth.push({day: i - lastDayofMonth + 1, class: 'inactive'});
    }

    this.currentDateLabel = `${this.currentDay} ${months[this.currentMonth]} ${this.currentYear}`;

    if(this.showTime){
      this.currentTimeLabel = this.date.toLocaleTimeString('en-US',{hour12:true,hour:'numeric',minute:'numeric'});
    }
  }
}
