import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import * as _moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { AppConstant } from 'src/app/app.constant';
import { cupSizeEnum } from 'src/app/enums/cupSize.enum';
import { cupTypeEnum } from 'src/app/enums/cupType.enum';
import { IngrreientsEnum } from 'src/app/enums/ingredients.enum';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { PropositionMasterEnum } from 'src/app/enums/propositionMaster.enum';
import { CupSizeSetupConfiguration } from 'src/app/models/cup-size-setup-configuration.model';
import { EntityService } from 'src/app/services/entity/entity.service';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
import { PriceCardTemplateService } from 'src/app/services/pricecard-template/pricecard-template.service';
import { RoleService } from 'src/app/services/role/role.service';
import { DiscrepancyTypes, SalesService } from 'src/app/services/sales/sales.service';
import { UserService } from 'src/app/services/user/user.service';
import { configurationDiscrepancyModel, CupSizeDiscrepancyModel, discrepancyModel, DrinkProductDiscrepancyModel, entityIngredientsModel, priceCardDiscrepancyModel } from '../configurationDiscrepancyModel';
//import { entityConfigurationModel, entityIngredientsModel } from '../entityConfigurationModel';
declare var $: any;
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-update-vends',
  templateUrl: './update-vends.component.html',
  styleUrls: ['./update-vends.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class UpdateVendsComponent implements OnInit {

  constructor(private formBuilder: RxFormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private priceCardService: PriceCardService,
    private toaster: Toaster,
    private dialog: MatDialog,
    private entityService: EntityService,
    public salesService: SalesService,
    private priceCardTemplateService: PriceCardTemplateService,
    public userService:UserService,
    public roleService:RoleService,
    private cdr: ChangeDetectorRef,
    private appConstant: AppConstant) {
    }

  @Input() isShow: boolean = false;
  sites: any[] = [];
  machines: any[] = [];
  configurationDiscrepancyFormGroup: RxFormGroup;
  discrepancyFormGroup: RxFormGroup;
  drinkProductDiscrepancyFormGroup: RxFormGroup;
  priceCardDiscrepancyFormGroup: RxFormGroup;
  cupSizeDiscrepancyFormGroup: RxFormGroup;
  entityManageIngredientsFormGroup: RxFormGroup;
  drinkProductDiscrepancyModel = new DrinkProductDiscrepancyModel();
  configurationDiscrepancyModel = new configurationDiscrepancyModel();
  discrepancyModel = new discrepancyModel();
  priceCardDiscrepancyModel = new priceCardDiscrepancyModel();
  cupSizeDiscrepancyModel = new CupSizeDiscrepancyModel();
  entityIngredientsModel = new entityIngredientsModel();
  submitButton = false;
  isInProcess = false;
  userDetails = {};
  cupSizeSetupConfiguration: CupSizeSetupConfiguration = new CupSizeSetupConfiguration();
  discrepancyTypes = DiscrepancyTypes;
  dropdownSettings: any = {
    "singleSelection": false,
    "defaultOpen": false,
    "idField": "masterDataId",
    "textField": "text",
    "selectAllText": "Select All",
    "unSelectAllText": "UnSelect All",
    "enableCheckAll": true,
    "itemsShowLimit": 2,
    "allowSearchFilter": true
  };
  entityDropdownSettings: any = {
    "singleSelection": true,
    "defaultOpen": false,
    "idField": "masterDataId",
    "textField": "text",
    "selectAllText": "Select All",
    "unSelectAllText": "UnSelect All",
    "enableCheckAll": false,
    "itemsShowLimit": 2,
    "allowSearchFilter": true
  };
  priceCardDropdownSettings: any = {
    "singleSelection": true,
    "defaultOpen": false,
    "idField": "priceCardId",
    "textField": "priceCardName",
    "selectAllText": "Select All",
    "unSelectAllText": "UnSelect All",
    "enableCheckAll": false,
    "itemsShowLimit": 2,
    "allowSearchFilter": true
  };
  waffleCups: any = {
    Short: [] = [],
    Small: [] = [],
    Medium: [] = [],
    Large: [] = []
  }
  plasticCups: any = {
    Medium: [] = [],
    Large: [] = []
    }
  glassCups: any = {
      Short: [] = [],
      Small: [] = [],
      Medium: [] = [],
      Large: [] = []
  }
  crockeryCups: any = {
        Short: [] = [],
        Small: [] = [],
        Medium: [] = [],
        Large: [] = []
    }
  filter: any = {
    discrepancyType: '',
    'l-1':[],
    'l-2':[],
    'l-3':[],
    'l-3_5':[],
    'l-4':[]
  }
  entityData = {
    'l-1':[],
    'l-2':[],
    'l-3':[],
    'l-3_5':[],
    'l-4':[]
  }
  cupSizeList: any = [];
  drinkProductList: any = [];
  syrupList: any = [];
  coffeeTypeList: any = [];
  milkTypeList: any = [];
  oldPriceCardList: any[] = [];
  newPriceCardList: any[] = [];
  minDate:any = moment().subtract(6, 'months');
  maxDate:any = moment();
  totalVends = 0;
  vendsList: any = [];
  ingredientColumns: any = [];
  machineTypeList: any = [];
  marketId= '';
  entityId = '';
  ingredientsType: any[] = [
    {
      "IngredientsName": "Coffee Types",
      "IngredientsType": "coffeeType"
    },
    {
      "IngredientsName": "Milk Types",
      "IngredientsType": "milkType"
    },
    {
      "IngredientsName": "Syrups",
      "IngredientsType": "syrup"
    }];
  filteredIngredientColumns: any[] = [];
  disableInputs = ['Primary Grinder','Syrup 1','Syrup 2'];
  disableInputsIds:any = {};
  columnsToDisplay = ['drink', 'cupSize','coffeeType', 'milkType', 'syrup','manufacturePrice','gridPrice','priceCardName', 'uniqueMachineNumber' ,'machineType','discrepencyType','salesReceivedDateTime'];  
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    deviceName: '',
    status: '',
    title: '',
  }
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  
  hasVendResult = false;
  hasLoadEntity = false;

  ngOnInit(): void {
    this.minDate = moment(this.minDate).startOf('day');
    this.entityManageIngredientsFormGroup = this.formBuilder.formGroup(this.entityIngredientsModel) as RxFormGroup;

    this.discrepancyModel = new discrepancyModel();
        this.discrepancyFormGroup = this.formBuilder.formGroup(this.discrepancyModel) as RxFormGroup;
  }

  discrepancyTypeChange(event) {
    this.totalVends = 0;
    
    if (event.value != '') {
      this.hasVendResult = false;
      this.hasLoadEntity = false;
      this.machines = [];
      if (event.value == 'Configuration') {
        this.configurationDiscrepancyModel = new configurationDiscrepancyModel();
        this.configurationDiscrepancyFormGroup = this.formBuilder.formGroup(this.configurationDiscrepancyModel) as RxFormGroup;
        this.configurationDiscrepancyFormGroup.controls['machineType'].patchValue('');
        this.getsalesdiscrepencysites('Configuration Discrepancy');
        this.getMachineTypeLookup();              
      } else if (event.value == 'Price Card') {
        this.priceCardDiscrepancyModel = new priceCardDiscrepancyModel();
        this.priceCardDiscrepancyFormGroup = this.formBuilder.formGroup(this.priceCardDiscrepancyModel) as RxFormGroup; 
        this.getsalesdiscrepencysites('Price Card Discrepancy');              
      } else if (event.value == 'Drink Product') {
        this.drinkProductDiscrepancyModel = new DrinkProductDiscrepancyModel();
        this.drinkProductDiscrepancyFormGroup = this.formBuilder.formGroup(this.drinkProductDiscrepancyModel) as RxFormGroup;
        this.getDrinkProductList();
        this.getsalesdiscrepencysites('Drink Product Discrepancy');
      } else if (event.value == 'Cup-Size') {
        this.cupSizeDiscrepancyModel = new CupSizeDiscrepancyModel();
        this.cupSizeDiscrepancyFormGroup = this.formBuilder.formGroup(this.cupSizeDiscrepancyModel) as RxFormGroup;
        this.getCupSizeList();
        this.getsalesdiscrepencysites('Cup-Size Discrepancy');
      }
    }
  }
  getPriceCardUnrecognizedVends() {
    this.vendsList = [];
    this.totalVends = 0;
    this.priceCardDiscrepancyFormGroup.controls['oldPriceCard'].patchValue([]);
    if(!this.priceCardDiscrepancyFormGroup.controls['siteId'].value.length){      
      this.oldPriceCardList = [];      
    }else{
      this.spinner.show();
      this.oldPriceCardList = [];
      let siteIds = [];
      this.priceCardDiscrepancyFormGroup.controls['siteId'].value.forEach(element => {
        siteIds.push(element.masterDataId);
      });  
      this.priceCardService.getPriceCardUnrecognizedVends({"isActive": true,siteIds:siteIds}).subscribe(response => {
        if (response && response['data'].length) {
          this.oldPriceCardList = response['data'];
          this.oldPriceCardList.unshift({marketId: null,
            noOfMappedEntities: null,
            priceCardId: null,
            priceCardName: "None",
            priceCardNumber: "",
            priceCardType: "Normal"});

        }else{
          this.oldPriceCardList = [{marketId: null,
            noOfMappedEntities: null,
            priceCardId: null,
            priceCardName: "None",
            priceCardNumber: "",
            priceCardType: "Normal"}]
        }
        this.spinner.hide();
      });
    }
  }
  getIngredientMasters() {
    this.entityService.getIngredientMasters(this.marketId).subscribe(response => {
      if (response && response['data'].length) {
        let coffeeList = response['data'].filter(x => x.ingredientType == IngrreientsEnum.coffeeType);
        this.entityIngredientsModel.coffeeType = coffeeList;
        let milkList = response['data'].filter(x => x.ingredientType == IngrreientsEnum.milkType);
        this.entityIngredientsModel.milkType = milkList;
        let syrupList = response['data'].filter(x => x.ingredientType == IngrreientsEnum.syrup);
        this.entityIngredientsModel.syrup = syrupList;
        this.entityManageIngredientsFormGroup.patchValue(
          this.entityIngredientsModel
        );
      }
    });
  }
  getSalesdiscrepancySelectAll(){
    if (this.filter.discrepancyType == 'Drink Product'){
      this.drinkProductDiscrepancyFormGroup.controls['siteId'].patchValue(this.sites);
      this.getSalesdiscrepancycount();
    }else if (this.filter.discrepancyType == 'Configuration') {
      this.configurationDiscrepancyFormGroup.controls['siteId'].patchValue(this.sites);
      this.getMachinesBySite();
    } else if (this.filter.discrepancyType == 'Price Card') {
      this.priceCardDiscrepancyFormGroup.controls['siteId'].patchValue(this.sites);
      this.getPriceCardUnrecognizedVends();
    }else{
      this.cupSizeDiscrepancyFormGroup.controls['siteId'].patchValue(this.sites);
      this.getSalesdiscrepancycount();
    }   
  }
  getSalesdiscrepancyDeSelectAll(type){
    if (this.filter.discrepancyType == 'Drink Product'){
        this.drinkProductDiscrepancyFormGroup.controls['siteId'].patchValue([]);
    }else if (this.filter.discrepancyType == 'Configuration') {
      this.configurationDiscrepancyFormGroup.controls['siteId'].patchValue([]);
      this.resetConfigurationForm();
    } else if (this.filter.discrepancyType == 'Price Card') {
      this.priceCardDiscrepancyFormGroup.controls['siteId'].patchValue([]);
      this.priceCardDiscrepancyFormGroup.controls['oldPriceCard'].patchValue([]);
      this.oldPriceCardList = [];
    }else{
      this.cupSizeDiscrepancyFormGroup.controls['siteId'].patchValue([]);
    }
    this.getSalesdiscrepancycount();
  }
  getSalesdiscrepancycount() {
    this.hasVendResult = false;
    this.spinner.show();    
    let body = {};
    let validBody = true;
    let inValidDate = false;
    if (this.filter.discrepancyType == 'Configuration') {
      if (!this.configurationDiscrepancyFormGroup.controls['siteId'].value.length) {
        validBody = false;
      } else {
        let machineIds = []; 
        let siteIds = [];      
        this.configurationDiscrepancyFormGroup.controls['siteId'].value.forEach(element => {
          siteIds.push(element.masterDataId);
        });      
        body = {
          "siteIds": siteIds,
          "machineId": machineIds,
          "priceCardId": "",
          "discrepancyType": this.filter.discrepancyType,
          "processingStatus": "",
          "vendStatus": "",
          "manufactureFromDate": "",
          "manufactureToDate": "",
          "gridReceivedFromDate": "",
          "gridReceivedToDate": "",
          "vendFromDate": "",
          "vendToDate": "",
          "pageNo": 1, "pageSize": -1, "orderByColumn": "drink asc"
        }
      }
    } else if (this.filter.discrepancyType == 'Price Card') {
      if (!this.priceCardDiscrepancyFormGroup.controls['oldPriceCard'].value.length) {
        validBody = false;
      } else {
        let siteIds = [];
        this.priceCardDiscrepancyFormGroup.controls['siteId'].value.forEach(element => {
          siteIds.push(element.masterDataId);
        }); 
        body = {
          "siteIds":siteIds,
          "discrepancyType": this.filter.discrepancyType,
          "priceCardId": this.priceCardDiscrepancyFormGroup.controls['oldPriceCard'].value[0]['priceCardId'],
          "pageNo": 1, "pageSize": -1
        }
      }
    } else if (this.filter.discrepancyType == 'Drink Product') {
      if (!this.drinkProductDiscrepancyFormGroup.controls['siteId'].value.length) {
        validBody = false;
      } else {
        if (this.drinkProductDiscrepancyFormGroup.controls['fromDate'].value && !this.drinkProductDiscrepancyFormGroup.controls['toDate'].value) {
          this.drinkProductDiscrepancyFormGroup.controls['toDate'].patchValue(moment());
        }
        if (!this.drinkProductDiscrepancyFormGroup.controls['fromDate'].value && this.drinkProductDiscrepancyFormGroup.controls['toDate'].value) {
          this.toaster.open({
            text: "Vend Received @ Grid from date cannot be empty",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        }else if (this.drinkProductDiscrepancyFormGroup.controls['fromDate'].value > this.drinkProductDiscrepancyFormGroup.controls['toDate'].value) {
          this.toaster.open({
            text: "Vend Received @ Grid to date must be greater than from date",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        } else if (this.drinkProductDiscrepancyFormGroup.controls['fromDate'].value && (this.drinkProductDiscrepancyFormGroup.controls['fromDate'].value < this.appConstant.sqlMinDate || this.drinkProductDiscrepancyFormGroup.controls['fromDate'].value > this.appConstant.sqlMaxDate)) {
          this.toaster.open({
            text: "Vend Received @ Grid from date is not valid",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        } else if (this.drinkProductDiscrepancyFormGroup.controls['toDate'].value && (this.drinkProductDiscrepancyFormGroup.controls['toDate'].value < this.appConstant.sqlMinDate || this.drinkProductDiscrepancyFormGroup.controls['toDate'].value > this.appConstant.sqlMaxDate)) {
          this.toaster.open({
            text: "Vend Received @ Grid to date is not valid",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        }
        let machineIds = []; 
        let siteIds = [];
        this.drinkProductDiscrepancyFormGroup.controls['siteId'].value.forEach(element => {
          siteIds.push(element.masterDataId);
        });      
        body = {
          "siteIds": siteIds,
          "machineId": machineIds,
          "discrepancyType": this.filter.discrepancyType,
          "gridReceivedFromDate": this.drinkProductDiscrepancyFormGroup.controls['fromDate'].value,
          "gridReceivedToDate": this.drinkProductDiscrepancyFormGroup.controls['toDate'].value,
          "pageNo": 1, "pageSize": -1
        }
      }
    } else if (this.filter.discrepancyType == 'Cup-Size') {
      if (!this.cupSizeDiscrepancyFormGroup.controls['siteId'].value.length) {
        validBody = false;
      } else {
        if (this.cupSizeDiscrepancyFormGroup.controls['fromDate'].value && !this.cupSizeDiscrepancyFormGroup.controls['toDate'].value) {
          this.cupSizeDiscrepancyFormGroup.controls['toDate'].patchValue(moment());
        }
        if (!this.cupSizeDiscrepancyFormGroup.controls['fromDate'].value && this.cupSizeDiscrepancyFormGroup.controls['toDate'].value) {
          this.toaster.open({
            text: "Vend Received @ Grid from date cannot be empty",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        }else if (this.cupSizeDiscrepancyFormGroup.controls['fromDate'].value > this.cupSizeDiscrepancyFormGroup.controls['toDate'].value) {
          this.toaster.open({
            text: "Vend Received @ Grid to date must be greater than from date",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        } else if (this.cupSizeDiscrepancyFormGroup.controls['fromDate'].value && (this.cupSizeDiscrepancyFormGroup.controls['fromDate'].value < this.appConstant.sqlMinDate || this.cupSizeDiscrepancyFormGroup.controls['fromDate'].value > this.appConstant.sqlMaxDate)) {
          this.toaster.open({
            text: "Vend Received @ Grid from date is not valid",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        } else if (this.cupSizeDiscrepancyFormGroup.controls['toDate'].value && (this.cupSizeDiscrepancyFormGroup.controls['toDate'].value < this.appConstant.sqlMinDate || this.cupSizeDiscrepancyFormGroup.controls['toDate'].value > this.appConstant.sqlMaxDate)) {
          this.toaster.open({
            text: "Vend Received @ Grid to date is not valid",
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
          inValidDate = true;
        }
        let machineIds = []; 
        let siteIds = [];
        this.cupSizeDiscrepancyFormGroup.controls['siteId'].value.forEach(element => {
          siteIds.push(element.masterDataId);
        });       
        body = {
          "siteIds": siteIds,
          "machineId": machineIds,
          "discrepancyType": this.filter.discrepancyType,
          "gridReceivedFromDate": this.cupSizeDiscrepancyFormGroup.controls['fromDate'].value,
          "gridReceivedToDate": this.cupSizeDiscrepancyFormGroup.controls['toDate'].value,
          "pageNo": 1, "pageSize": -1
        }
      }
    }
    if (!validBody || inValidDate) {
      this.spinner.hide();
      this.totalVends = 0;
    } else {
      
      //this.salesService.salesdiscrepancycount(body).subscribe(response => {
      this.salesService.getSalesDataDiscrepanciesListBySites(body).subscribe(response => {
        this.spinner.hide();
        if (response && response['params']) {
          this.vendsList = response['data'];
         this.totalVends = response['params']['count'];
        } else {
          this.vendsList = [];
          this.totalVends = 0;
        }
        this.hasVendResult = true;
      }, err => {
        this.hasVendResult = true;
        this.vendsList = [];
        this.totalVends = 0;
        this.spinner.hide();
        let errors = err.error;
        if (!Array.isArray(errors)) {
          this.toaster.open({
            text: 'Something went wrong. Please try again.',
            type: 'danger',
            position: 'top-right',
            duration: 10000
          });
        } else {
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        }
      });
    }
  }
  getIngredientcupsize(marketId, siteId) {
    this.waffleCups = {
      Short: [] = [],
      Small: [] = [],
      Medium: [] = [],
      Large: [] = []
    }
    this.plasticCups = {
      Medium: [] = [],
      Large: [] = []
      }
   this.glassCups = {
      Short: [] = [],
      Small: [] = [],
      Medium: [] = [],
      Large: [] = []
  }
  this.crockeryCups = {
        Short: [] = [],
        Small: [] = [],
        Medium: [] = [],
        Large: [] = []
    }
    this.entityService.getIngredientcupsize(this.filter['l-1'][0]['masterDataId'], this.filter['l-4'][0]['masterDataId'], null, 1, 4,false,'','',PropositionMasterEnum.CoffeeCounter.toString()).subscribe(response => {
      if (response && response['data'] && response['data'].cupValueDetails.length) {
        this.cupSizeSetupConfiguration = response['data'].cupSizeSetupConfigurationModel;
        Object.keys(cupTypeEnum).forEach(cupType => {
          Object.keys(cupSizeEnum).forEach(cupSize => {
            response['data'].cupValueDetails.filter(x => x.cupType == cupType && x.cupSize == cupSize).forEach(element => {
              element.cupValue = element.cupValue == 0 ? 'Not in Use' : (element.cupValue) + 'oz';

              var type = "";
              if (cupType == cupTypeEnum.Waffle) {
                type = 'waffleCups';
                this.waffleCups[element.cupSize].push(element);
              }
              else if (cupType == cupTypeEnum.Plastic) {
                type = 'plasticCups';
                this.plasticCups[element.cupSize].push(element);
              }
              else if (cupType == cupTypeEnum.Crockery) {
                type = 'crockeryCups';
                this.crockeryCups[element.cupSize].push(element);
              }
              else if (cupType == cupTypeEnum.Glass) {
                type = 'glassCups';
                this.glassCups[element.cupSize].push(element);
              }
              if (element.selectedCupValue == "00000000-0000-0000-0000-000000000000") {
                if (element.cupValue == 'Not in Use') {
                  this.configurationDiscrepancyFormGroup.patchValue({ [type + element.cupSize]: element.cupValueDetailId });
                }
              } else {
                this.configurationDiscrepancyFormGroup.patchValue({ [type + element.cupSize]: element.selectedCupValue });
              }
            });
          });
        });
      }
    });
  }
  getIngredientListByType(ingredientsType) {
    return this.filteredIngredientColumns.filter(x => x.ingredientType == ingredientsType);
  }
  getCupSizeList() {
    this.cupSizeList = [];
    this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.CupSize,'createdDate').subscribe(response => {
      if (response && response['data'].length) {
        this.cupSizeList = response['data'];
      }
    });
  }
  getDrinkProductList() {
    this.drinkProductList = [];
    this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Drink).subscribe(response => {
      if (response && response['data'].length) {
        this.drinkProductList = response['data'];
      }
    });
  }
  machineTypeChange(value) {
    if(value != ''){
      this.getIngredientcolumns(value,false);
    }
    else{
      this.ingredientColumns = [];
      this.filteredIngredientColumns = [];
    }
    // this.filteredIngredientColumns = this.ingredientColumns.filter(x => x.machineTypeId == value);
  }

  async getIngredientcolumns(machineTypeId,isPageLoad) {
    await this.entityService.getIngredientcolumns(machineTypeId, this.filter['l-1'][0]['masterDataId'], this.filter['l-4'][0]['masterDataId'], null,1, 4,false,this.filter['l-1'][0]['masterDataId'],'','',PropositionMasterEnum.CoffeeCounter.toString()).subscribe(response => {
      if (response && response['data'].length) {
        this.ingredientColumns = response['data'];
        if(isPageLoad){
          
        if(this.ingredientColumns.length  < 10){
          this.configurationDiscrepancyFormGroup.controls['machineType'].patchValue(this.ingredientColumns[0].machineTypeId);
          this.machineTypeChange(this.ingredientColumns[0].machineTypeId);
        }
      }
      this.disableInputsIds = {};
        this.ingredientColumns.forEach(element => {
          let validations = [];
          let val = { value: element.selectedColumnValue == null ? "" : element.selectedColumnValue,disabled:false };
          if(this.disableInputs.includes(element.columnName)){
            if(element.isMandatory){
            val.disabled = true;
            validations.push(Validators.required);
            this.disableInputsIds[element.ingredientColumnMasterId] = element.selectedColumnValue;
            }
          }
          this.configurationDiscrepancyFormGroup.addControl(element.ingredientColumnMasterId, new FormControl(val, validations));
        });
        if(machineTypeId != ''){
          this.filteredIngredientColumns = this.ingredientColumns;
        }
      }
      //this.spinner.hide();
    });
  }

  getSyrupList() {
    this.syrupList = [];
    this.priceCardTemplateService.getMasterLookup(MasterLookupEnum.Syrup).subscribe(response => {
      if (response && response['data'].length) {
        this.syrupList = response['data'];
      }
    });
  }
  isGuid(stringToTest) {
    if (stringToTest[0] === "{") {
        stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
}
  resetConfigurationForm(clearMachineType=true){
    if(clearMachineType){
      this.configurationDiscrepancyFormGroup.controls['machineType'].patchValue('');  
    }
    let formObj = this.configurationDiscrepancyFormGroup.controls;
    Object.keys(formObj).forEach(element => {    
      if(this.isGuid(element) && this.configurationDiscrepancyFormGroup.controls[element]){
        this.configurationDiscrepancyFormGroup.removeControl(element);   
      }
    });
  }

  getsalesdiscrepencysites(discrepancyType,levelNumber=1,entityType='',eventType='select') { 
    this.hasVendResult = false;   
    if(entityType == ''){
      this.entityData['l-1'] = [];
      this.entityData['l-2'] = [];
      this.entityData['l-3'] = [];
      this.entityData['l-3_5'] = [];
      this.entityData['l-4'] = [];
      this.filter['l-1'] = [];
      this.filter['l-2'] = [];
      this.filter['l-3'] = [];
      this.filter['l-3_5'] = [];
      this.filter['l-4'] = [];
      this.sites = [];
      this.oldPriceCardList = [];
    }    
    if(levelNumber == 1){
      this.entityData['l-1'] = [];
      this.filter['l-1'] = [];

      this.entityData['l-2'] = [];
      this.filter['l-2'] = [];
      
      this.entityData['l-3'] = [];
      this.filter['l-3'] = [];  
      
      this.entityData['l-3_5'] = [];
      this.filter['l-3_5'] = [];
      
      this.entityData['l-4'] = [];
      this.filter['l-4'] = []; 
    }else if(levelNumber == 2){
      this.entityData['l-2'] = [];
      this.filter['l-2'] = [];

      this.entityData['l-3'] = [];
      this.filter['l-3'] = [];  
      
      this.entityData['l-3_5'] = [];
      this.filter['l-3_5'] = [];
      
      this.entityData['l-4'] = [];
      this.filter['l-4'] = []; 
      if(discrepancyType == 'Configuration Discrepancy'){        
        this.marketId = (this.filter['l-1'].length)?this.filter['l-1'][0]['masterDataId']:''
        if(this.marketId && this.marketId!='')
        {
          this.getMarketMachineTypeLookup(this.marketId);
        }
      }
      
    }else if(levelNumber == 3){
      this.entityData['l-3'] = [];
      this.filter['l-3'] = [];

      this.entityData['l-3_5'] = [];
      this.filter['l-3_5'] = [];
      
      this.entityData['l-4'] = [];
      this.filter['l-4'] = [];   
    }else if(levelNumber == 3.5){
      this.entityData['l-3_5'] = [];
      this.filter['l-3_5'] = [];
      
      this.entityData['l-4'] = [];
      this.filter['l-4'] = []; 
    }else if(levelNumber == 4){
      this.entityData['l-4'] = [];
      this.filter['l-4'] = []; 
    }
    this.sites = [];
    this.vendsList = [];
    this.totalVends = 0;
    if(this.filter.discrepancyType == 'Price Card'){
      this.oldPriceCardList = [];
      this.priceCardDiscrepancyFormGroup.controls['oldPriceCard'].patchValue([]);      
    }else if (this.filter.discrepancyType == 'Configuration') {
      this.resetConfigurationForm();
    }else if (this.filter.discrepancyType == 'Drink Product') {
      this.drinkProductDiscrepancyFormGroup.controls['fromDate'].patchValue(null);
      this.drinkProductDiscrepancyFormGroup.controls['toDate'].patchValue(null);
    }else{
      this.cupSizeDiscrepancyFormGroup.controls['fromDate'].patchValue(null);
      this.cupSizeDiscrepancyFormGroup.controls['toDate'].patchValue(null);
    }
    if(eventType == 'select'){
      
      this.spinner.show();
      let parentId = (entityType != '')?this.filter[entityType][0]['masterDataId']:'';
      this.marketId = (this.filter['l-1'].length)?this.filter['l-1'][0]['masterDataId']:'';

      if(entityType == ''){
        if(this.roleService.objRole.isMarketAdmin){
          this.filter['l-1']=[{'masterDataId':this.userService.userDetails['marketId']}]
          parentId = this.userService.userDetails['entityGuid'];
          levelNumber = 2;
        } else if (this.roleService.objRole.isLevel2Admin){
          this.filter['l-1']=[{'masterDataId':this.userService.userDetails['marketId']}];
          this.filter['l-2']=[{'masterDataId':this.userService.userDetails['entityGuid']}]
          parentId = this.userService.userDetails['entityGuid'];
          levelNumber = 3;
        } else if (this.roleService.objRole.isLevel3Admin){
          this.filter['l-1']=[{'masterDataId':this.userService.userDetails['marketId']}];
          this.filter['l-2']=[{'masterDataId':this.userService.userDetails['parentId']}];
          this.filter['l-3']=[{'masterDataId':this.userService.userDetails['entityGuid']}]
          parentId = this.userService.userDetails['entityGuid'];
          levelNumber = 3.5;
        }
        if(levelNumber != 1){
          this.marketId = this.userService.userDetails['marketId'];          
        }
      }
      this.salesService.getsalesdiscrepencymasters({discrepancyType:discrepancyType,levelNumber :levelNumber,parentId:parentId,marketId:this.marketId}).subscribe(response => {
        if (response && response['masterList'].length) {
          if(response.currentLevelType != 7){
            let currentLevelType =  (response.currentLevelType == 3.5)?'3_5':response.currentLevelType;  
            this.entityData['l-'+currentLevelType] = response['masterList'];
          }else{
            this.sites = response['masterList'];
            if (this.filter.discrepancyType == 'Drink Product'){
              this.drinkProductDiscrepancyFormGroup.controls['siteId'].patchValue([]);
            }else if (this.filter.discrepancyType == 'Configuration') {
              this.configurationDiscrepancyFormGroup.controls['siteId'].patchValue([]);
            } else if (this.filter.discrepancyType == 'Price Card') {
              this.priceCardDiscrepancyFormGroup.controls['siteId'].patchValue([]);              
            }else{
              this.cupSizeDiscrepancyFormGroup.controls['siteId'].patchValue([]);
            }
          }
          this.spinner.hide();
        } else {
          this.sites = [];
          let currentLevelType =  (entityType == '3.5')?'3_5':entityType;  
          this.entityData['l-'+currentLevelType] = [];
          this.spinner.hide();
        }
        this.hasLoadEntity = true;
      }, err => {
        this.hasLoadEntity = true;
        this.spinner.hide();
        if(Array.isArray(err.error)){
          let errors = err.error;
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
          });
        }
      });
    }       
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  getMachinesBySite() {
    var siteIds = [];
    this.spinner.show(); 
    if (this.filter.discrepancyType == 'Configuration') {
      if (this.configurationDiscrepancyFormGroup.controls['siteId'].value.length) {        
        let siteDetails = this.sites.filter(el => el.masterDataId == this.configurationDiscrepancyFormGroup.controls['siteId'].value[0]['masterDataId']);
        this.marketId = siteDetails[0]['marketId'];
        this.entityId =  this.configurationDiscrepancyFormGroup.controls['siteId'].value[0]['masterDataId'];
        this.getIngredientMasters();
        this.getIngredientcupsize(siteDetails[0]['marketId'], this.configurationDiscrepancyFormGroup.controls['siteId'].value[0]['masterDataId']);
        siteIds.push(this.configurationDiscrepancyFormGroup.controls['siteId'].value[0]['masterDataId']);
        this.getIngredientcolumns("",true); 
      }else{
        this.resetConfigurationForm();
      }
      this.configurationDiscrepancyFormGroup.patchValue({selectedMachines:''});
    } else if (this.filter.discrepancyType == 'Drink Product') {
      if (this.drinkProductDiscrepancyFormGroup.controls['siteId'].value) {
        siteIds.push(this.drinkProductDiscrepancyFormGroup.controls['siteId'].value);
      }
      this.drinkProductDiscrepancyFormGroup.patchValue({selectedMachines:''});
    } else if (this.filter.discrepancyType == 'Cup-Size') {
      if (this.cupSizeDiscrepancyFormGroup.controls['siteId'].value) {
        siteIds.push(this.cupSizeDiscrepancyFormGroup.controls['siteId'].value);
      }
      this.cupSizeDiscrepancyFormGroup.patchValue({selectedMachines:''});
    }  
    this.getSalesdiscrepancycount();      
  }

  clickOnCancel() {
    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: 'All unsaved changes will be lost. Are you sure you want to cancel?',
      deviceName: '',
      status: '',
      title: 'Confirmation',
    }
    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }

  
  onConfirm() {
    this.dialogRef.close();
    this.router.navigate(['/sales'], { queryParams: { tabIndex: 1 } })
  }

  onCancel() {
    this.dialogRef.close();
  }

  getMachineTypeLookup() {
    this.entityService.getMachineType().subscribe(response => {
      if (response && response['data'].length) {
        this.machineTypeList = response['data'];
      }
    });
  }

  getMarketMachineTypeLookup(marketId) {
    this.entityService.getMarketMachineType(marketId).subscribe(response => {
      if (response && response['data'].length) {
        this.machineTypeList = response['data'];
      }
    });
  }

  showValidationMsg(type=''){
    this.toaster.open({
      text: 'Please provide valid input for all the highlighted fields.',
      type: 'danger',
      position: 'top-right',
      duration: 10000
    });
  }
  updateVends(type) {    
    this.isInProcess=true;
    if(type == ''){
      this.isInProcess=false;
      this.showValidationMsg();
      this.discrepancyFormGroup.markAllAsTouched();
    }else if(type == 'Price Card' && !this.priceCardDiscrepancyFormGroup.controls['oldPriceCard'].value.length){
      this.isInProcess=false;
      this.showValidationMsg();      
      this.priceCardDiscrepancyFormGroup.markAllAsTouched(); 
    }else if(type == 'Cup-Size' && !this.cupSizeDiscrepancyFormGroup.controls['siteId'].value.length){
      this.isInProcess=false;
      this.showValidationMsg();      
      this.cupSizeDiscrepancyFormGroup.markAllAsTouched();
    }else if(type == 'Drink Product' && !this.drinkProductDiscrepancyFormGroup.controls['siteId'].value.length){
      this.isInProcess=false;
      this.showValidationMsg();      
      this.drinkProductDiscrepancyFormGroup.markAllAsTouched();
    }else if(type == 'Configuration' && this.configurationDiscrepancyFormGroup.status === 'INVALID'){
      this.isInProcess=false;
      this.showValidationMsg();      
      this.configurationDiscrepancyFormGroup.markAllAsTouched();
    }else{
   
      let body:any = {  "discrepancyType": "", "discrepancyIds": []};
      if (type == 'Configuration'){
        body = {"discrepancyIds": [],"machineTypeId":this.configurationDiscrepancyFormGroup.controls['machineType'].value};
      }
      let i = 1;
      if(!this.vendsList.length){
        let msg = 'No vends found based on the selected site & dates';
        if(type == 'Configuration'){
          msg = 'No vends found based on the selected site';
        }else if(type == 'Price Card'){
          msg = 'No vends found based on the selected price card';
        }
        this.isInProcess=false;
        this.toaster.open({
          text: msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      }else{
        this.vendsList.forEach(element => {
          if (type == 'Price Card'){
            if(element.gridPrice != '' && element.gridPrice != null){
              body.discrepancyIds.push({discrepancyId:element.salesDiscrepencyId,solutionValue:element.gridPrice});
            }
          }else if (type == 'Drink Product'){
          if(element.drink != '' && element.drink != null){
              body.discrepancyIds.push({discrepancyId:element.salesDiscrepencyId,solutionValue:element.drink});
            }
          }else if (type == 'Cup-Size'){
            if(element.cupSize != '' && element.cupSize != null){
              body.discrepancyIds.push({discrepancyId:element.salesDiscrepencyId,solutionValue:element.cupSize});
            }
          }else{       
              body.discrepancyIds.push(element.salesDiscrepencyId);       
          }
          if (this.vendsList.length == i) {
            if (type == 'Configuration') {
              body['ingredientSetupData'] = [];
              body['discrepancyType'] = type;
              let temp = [];
              let formObj = this.configurationDiscrepancyFormGroup.value;
              let index = 0;
              Object.keys(this.disableInputsIds).forEach(element => {
                formObj[element] = this.disableInputsIds[element];
                index++;
              });
              Object.keys(formObj).forEach(element => {                
                if(!element.includes("l-")){
                  let cupsType = ['waffleCupsShort', 'waffleCupsSmall', 'waffleCupsMedium', 'waffleCupsLarge', 'plasticCupsMedium', 'plasticCupsLarge', 'crockeryCupsShort', 'crockeryCupsSmall', 'crockeryCupsMedium', 'crockeryCupsLarge', 'glassCupsShort', 'glassCupsSmall', 'glassCupsMedium', 'glassCupsLarge'];

                  let otherType = ['siteId', 'machineType'];
                  
                  if (cupsType.includes(element)) {
                    body['ingredientSetupData'].push({"ingredientColumnMasterId": null, "masterDateId": null, "cupValueDetailId": formObj[element] });
                  } else if (!otherType.includes(element)) {              
                    body['ingredientSetupData'].push({"ingredientColumnMasterId": element, "masterDateId": formObj[element],"cupValueDetailId": null});
                  }
              }
              });
            } else{ 
              body['discrepancyType'] = type;
            }        
            if(!body['discrepancyIds'].length){
              let msg = 'Please provide valid input.';
              if(['Price Card','Drink Product','Cup-Size'].includes(type)){
                if(type == 'Price Card'){
                  msg = "Please enter the price for atleast a single vend";
                }else{
                  let msgType = (type == 'Drink Product')?'drink':'cup size';
                  msg = "Please select a "+msgType+" for atleast a single vend";
                }                 
              }
              this.isInProcess=false;
              this.toaster.open({
                text: msg,
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
            }else{
              this.spinner.show();
              body['marketId'] = null;
              body['level2Id'] = null;
              body['level3Id'] = null;
              if(this.filter['l-1'].length)
                body['marketId'] = this.filter['l-1'][0]['masterDataId'];
              if(this.filter['l-2'].length)
                body['level2Id'] = this.filter['l-2'][0]['masterDataId'];
              if(this.filter['l-3'].length)
                body['level3Id'] = this.filter['l-3'][0]['masterDataId'];
              
            this.salesService.updateVendsByType(body, type).subscribe(response => {
              this.isInProcess=false;
              this.spinner.hide();
              if (!response) {
                this.toaster.open({
                  text: 'Something went wrong. Please try again.',
                  type: 'danger',
                  position: 'top-right',
                  duration: 10000
                });
              } else {
                if (!response.data) {
                  this.toaster.open({
                    text: response.message,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                  });
                } else {
                  this.toaster.open({
                    text: "Vends reprocessing initiated",
                    type: 'success',
                    position: 'top-right',
                    duration: 10000
                  });
                  this.router.navigate(['/sales'], { queryParams: { tabIndex: 1 } })
                }
              }
            }, err => {
              this.isInProcess=false;
              this.spinner.hide();
              let errors = err.error;
              if (!errors) {
                this.toaster.open({
                  text: 'Something went wrong. Please try again.',
                  type: 'danger',
                  position: 'top-right',
                  duration: 10000
                });
              } else {
                if(!Array.isArray(errors)){
                  this.toaster.open({
                    text: 'Something went wrong. Please try again.',
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                  });
                }else{
                errors.forEach(element => {
                  this.toaster.open({
                    text: element.msg,
                    type: 'danger',
                    position: 'top-right',
                    duration: 10000
                  });
                });
              }
              }
            });
          }
          }
          i++;
        });
      }
  }
  }

  onPriceFocusout(i) {
    if($('#TextBox-grid-price-'+i).val() != ''){
      $('#TextBox-grid-price-'+i).val(parseFloat($('#TextBox-grid-price-'+i).val()).toFixed(2));
    }
  }
}
