import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreatePackageMetadataResponse } from '../../dtos/create-package-metadata-response';
import { Observable } from 'rxjs';
import { CreatePackageMetadataRequest } from '../../dtos/create-package-metadata-request';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CreatePackageMetadataService {

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  Execute(request: CreatePackageMetadataRequest) : Observable<CreatePackageMetadataResponse> {

    var configHeader = {
			headers: {
        'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
			}
		};

		return this.httpClient.post<CreatePackageMetadataResponse>(
        environment.apiUrl.remoteUpdatePackage + "/v1/remoteupdatepackage",
        request,
        configHeader
      )
			.pipe(map(response => response));
	}
}
