import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { PriceCardService } from 'src/app/services/price-card/price-card.service';
@Component({
  selector: 'app-price-card-coffee-pricing',
  templateUrl: './price-card-coffee-pricing.component.html',
  styleUrls: ['./price-card-coffee-pricing.component.css']
})
export class PriceCardCoffeePricingComponent implements OnInit {
  @Output() clickButton = new EventEmitter<any>();
  drinks = [];
  cupSizes = [];

  selectedDrinks = [];

  displayedColumns: string[] = ['drinkGroupTemplateDetailName', 'drinkGroupTemplateCupDetailName','price', 'drinkGroupTemplateDetailId'];
  dataSource: any = [];

  primaryCoffeeDetails: any = {};
  primaryMilkDetails: any = {};
  isTouchedSubmit = false;
  dropdownSettings = {
    singleSelection: false,
    idField: 'drinkGroupTemplateDetailId',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  @ViewChild("confirmDialogTemplate") confirmDialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;
  selectedFiled: any;
  dialogTexts = {
    successTitle: '',
    cancelTitle: '',
    message: '',
    status: '',
    title: ''
  };
  priceFocusIn = {};
  constructor(
    public priceCardService: PriceCardService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getCoffeeLookup();
    this.getPriceCardDetails();
  }  
  getCoffeeLookup() {
    let templateId = this.priceCardService.priceCardDetails.drinkGroupTemplateId;
    this.priceCardService.getwrapperdetails(this.priceCardService.priceCardDetails.priceCardVersionId, { type: 'coffee' }).subscribe(response => {
      this.drinks = response['data']['drinkDetails'];
      if(this.drinks.length){
        this.drinks = this.sortByKey(this.drinks, 'name');//for sorting
      }
    });
  }
  getPriceCardDetails() {
    let priceCardVersionId = this.priceCardService.priceCardDetails.priceCardVersionId;
    this.priceCardService.getPriceCardDetails(priceCardVersionId, 'coffee').subscribe(response => {
      if (response) {
        this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = response['data'];
        this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList= this.priceCardService.sortGridByGroup(this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList);//for sorting
      }
    });
  }
  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  addDrink() {
    let list = [];
    let drintIndex = 0;
    let isValid = true;
    this.selectedDrinks.forEach(drink => {
      let cupIndex = 0;
      this.priceCardService.priceCardDetails.drinkGroups.cupsizes.forEach(cup => {
        let obj = {
          priceCardDrinkDetailId: '',
          drinkGroupTemplateDetailId: drink.drinkGroupTemplateDetailId,
          drinkGroupTemplateDetailName: drink.name,
          drinkGroupTemplateCupDetailId: cup.drinkGroupTemplateDetailId,
          drinkGroupTemplateCupDetailName: cup.name,
          primaryCoffeeId: this.primaryCoffeeDetails.drinkGroupTemplateDetailId,
          primaryCoffeeName: this.primaryCoffeeDetails.name,
          primaryMilkId: this.primaryMilkDetails.drinkGroupTemplateDetailId,
          primaryMilkName: this.primaryMilkDetails.name,
          price: '',
          sequenceNumber:cup.sequenceNumber
        };

        list.push(obj);
        let found = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.some(el => el.drinkGroupTemplateDetailId === drink.drinkGroupTemplateDetailId && el.drinkGroupTemplateCupDetailId === cup.drinkGroupTemplateDetailId);
        if (found) {
          isValid = false;
        }
        if (drintIndex === (this.selectedDrinks.length - 1)) {
          if (cupIndex === (this.priceCardService.priceCardDetails.drinkGroups.cupsizes.length - 1)) {
            if (!isValid) {
              this.toaster.open({
                text: 'Selected coffee type is already exists',
                type: 'danger',
                position: 'top-right',
                duration: 10000
              });
            } else {
              this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.concat(list);

              this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = this.priceCardService.sortGridByGroup(this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList);

              this.selectedDrinks = [];             
            }
          }
        }
        cupIndex++;
      });
      drintIndex++;
    });
  }
  submitForm(type) {
    this.isTouchedSubmit = true;
    if (type != 'next') {
      this.clickButton.next({ stepKey: 'coffee-pricing', buttonType: type });
    } else {
      let isFoundInValidPrice = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.filter(el => el.price == '');
      if(isFoundInValidPrice.length){
        this.toaster.open({
         text: 'Please complete pricing for highlighted Coffee Type(s)',
         type: 'danger',
         position: 'top-right',
         duration: 10000
       });
      }else{
      let body = {
        "priceCardVersionId": this.priceCardService.priceCardDetails.priceCardVersionId,
        "detailType": "coffee",
        "priceCardDetails": this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList
      }
      this.spinner.show();
      this.priceCardService.addPriceCardDetails(body).subscribe(response => {
        this.spinner.hide();
        this.getPriceCardDetails();
        this.clickButton.next({ stepKey: 'coffee-pricing', buttonType: type });
      }, err => {
        if (err && err.error) {
          this.spinner.hide();
          let errors = err.error;
          errors.forEach(element => {
            this.toaster.open({
              text: element.msg,
              type: 'danger',
              position: 'top-right',
              duration: 10000
            });
            if(element.msg == "Oops! Something went wrong."){
              this.router.navigate(['/price-card']);
            }
          });
        }
      });
    }
    }
  }
  onPriceFocusin(i) {
    if(this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price']){
      
      this.priceFocusIn[i]= this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price'];
      
      this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price']=this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price'].replace(/\./g,'')
      this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price'] = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price'].replace(/^0+/, '');
    }
  }
  onPriceFocusout(i) {
    this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['touched'] = true;
    if (this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price']) {
      
      this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price'] = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price']/100;
        
      this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price'] = parseFloat(this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList[i]['price']).toFixed(2);
      
    }
  }
  clickPrice(index){
    index++;
    if(this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList.length != index)
      document.getElementById("TextBox-coffee-price-"+index).focus();    
  }
  removeCoffee(i){
    let coffeeGroupsList = this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList;
    coffeeGroupsList.splice(i, 1);
     this.spinner.show();
     this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = [];
     setTimeout(() => {
       this.priceCardService.priceCardDetails.drinkGroups.coffeeGroupsList = coffeeGroupsList;
       setTimeout(() => {
         this.spinner.hide();
       }, 100);
     }, 10);
  }

  openDialog(element = {}) {
    this.selectedFiled = element;

    this.dialogTexts = {
      successTitle: 'Yes',
      cancelTitle: 'No',
      message: 'Are you sure you wish to delete your coffee type?',
      status: '',
      title: 'Confirmation'
    }

    const entityMoveDataModel = new MatDialogConfig();
    entityMoveDataModel.height = 'auto';
    entityMoveDataModel.width = '670px';
    entityMoveDataModel.disableClose = true;
    this.dialogRef = this.dialog.open(this.confirmDialogTemplate, entityMoveDataModel);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close();
    this.removeCoffee(this.selectedFiled);
  }
}







