import {SelectedItem} from "./selected-item";

export class SelectedMarketCountry {
  constructor(public market?: SelectedItem, public level2?: SelectedItem, public level3?: SelectedItem) {
    if (this.market == null)
      this.level2 = null;
    if (this.level2 == null)
      this.level3 = null;
  }
}
