import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MachineListFilter } from './machine-list-filter.model';

export const selectFilterState = createFeatureSelector<MachineListFilter>('machineListFilter');

export const selectFilters = createSelector(
  selectFilterState,
  (state: MachineListFilter) => state.filter
);

export const selectTempFilters = createSelector(
  selectFilterState,
  (state: MachineListFilter) => state.tempFilter
);

export const selectAdvancedFilters = createSelector(
  selectFilterState,
  (state: MachineListFilter) => state.advancedFilter
);

export const selectSortBy = createSelector(
  selectFilterState,
  (state: MachineListFilter) => state.sortBy
);