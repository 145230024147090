import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { EntityService } from 'src/app/services/entity/entity.service';
import { RoleService } from 'src/app/services/role/role.service';

@Component({
  selector: 'app-configuration-pricecard',
  templateUrl: './configuration-pricecard.component.html',
  styleUrls: ['./configuration-pricecard.component.css']
})
export class ConfigurationPricecardComponent implements OnInit {
  isApiCalling = false
  drinkList: any[] = [];
  coffeeList: any[] = [];
  milkList: any[] = [];
  syrupList: any[] = [];
  public currentPage = 0;
  filter = {
    search: ''
  }
  priceCardForm: FormGroup;
  drinkPriceCardForm: FormGroup;
  syruppriceCardForm: FormGroup;
  recordsTotal = 0;
  pageIndex = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  params = {
    pageNumber: 1,
    pageSize: this.pageSizeOptions[0],
    searchText: '',
    sortBy: 'createdDate desc',
    sortcolumn: 'createdDate',
    sortDirection: 'desc'
  };
  displayedColumns: string[] = ['text', 'value', 'createdDate', 'createdBy'];
  drinkDisplayedColumns: string[] = ['text', 'value', 'drinkType', 'hasSyrup', 'temperature','defaultEatInCupType','hasMilk', 'isShort', 'createdDate', 'createdBy'];
  dataSource: any = [];
  isPageDataLoad : boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  selectedIndex: number = 0;
  masterLookupEnum: typeof MasterLookupEnum;
  showForm : boolean = true;
  hasMilk = true;
  isShort = false;

  constructor(private entityService: EntityService,
    private toaster: Toaster,
    private spinner: NgxSpinnerService,
    public roleService:RoleService) { }

  ngOnInit(): void {
    this.masterLookupEnum = MasterLookupEnum;
    this.priceCardForm = new FormGroup({});
    this.drinkPriceCardForm = new FormGroup({});
    this.syruppriceCardForm = new FormGroup({});
    let pattern = "(?!^ +$)^.+$";
    this.priceCardForm.addControl('name', new FormControl('', [Validators.required, Validators.pattern(pattern)]));
    this.priceCardForm.addControl('code', new FormControl('', [Validators.required,Validators.minLength(2), Validators.maxLength(2), Validators.pattern(pattern)]));
    this.syruppriceCardForm.addControl('name', new FormControl('', [Validators.required, Validators.pattern(pattern)]));
    this.syruppriceCardForm.addControl('code', new FormControl('', [Validators.required,Validators.minLength(3), Validators.maxLength(3), Validators.pattern(pattern)]));
    this.drinkPriceCardForm.addControl('name', new FormControl('', [Validators.required, Validators.pattern(pattern)]));
    this.drinkPriceCardForm.addControl('code', new FormControl('', [Validators.required,Validators.minLength(4), Validators.maxLength(4), Validators.pattern(pattern)]));
    this.drinkPriceCardForm.addControl('drinkType', new FormControl('', [Validators.required]));
    this.drinkPriceCardForm.addControl('hasMilk', new FormControl(true, [Validators.required]));
    this.drinkPriceCardForm.addControl('isShort', new FormControl(true, [Validators.required]));
    this.drinkPriceCardForm.addControl('syrup', new FormControl('', [Validators.required]));
    this.drinkPriceCardForm.addControl('temperature', new FormControl('', [Validators.required]));
    this.drinkPriceCardForm.addControl('defaultEatInCupType', new FormControl('', [Validators.required]));
    this.bindList(MasterLookupEnum.Drink);
  }

  tabChange(event) {
    this.params.sortcolumn = 'createdDate';
    this.params.sortDirection = 'desc';
    this.selectedIndex = event.index;
    this.currentPage = 0;
    this.params.pageSize = this.pageSizeOptions[0];
    this.params.searchText = '';
    this.params.sortBy = 'createdDate desc';
    this.filter.search = '';
    this.priceCardForm.reset();
    this.priceCardForm.controls['name'].setErrors(null);
    this.priceCardForm.controls['code'].setErrors(null);
    this.drinkPriceCardForm.reset();
    this.drinkPriceCardForm.controls['name'].setErrors(null);
    this.drinkPriceCardForm.controls['code'].setErrors(null);
    this.drinkPriceCardForm.controls['drinkType'].setErrors(null);
    this.syruppriceCardForm.reset();
    this.syruppriceCardForm.controls['name'].setErrors(null);
    this.syruppriceCardForm.controls['code'].setErrors(null);
    if (this.selectedIndex == 0) {
      this.bindList(MasterLookupEnum.Drink);
    }
    else if (this.selectedIndex == 1) {
      this.bindList(MasterLookupEnum.Coffee);
    }
    else if (this.selectedIndex == 2) {
      this.bindList(MasterLookupEnum.Milk);
    }
    else if (this.selectedIndex == 3) {
      this.bindList(MasterLookupEnum.Syrup);
    }
   }

  add(configurationType) { 
    
    let validationMsg = 'Please provide valid input for all the highlighted fields';
    if(configurationType == MasterLookupEnum.Drink && this.drinkPriceCardForm.status === 'INVALID'){
      this.drinkPriceCardForm.markAllAsTouched();
      this.toaster.open({
        text: validationMsg,
        type: 'danger',
        position: 'top-right',
        duration: 10000
      }); 
         
    }else if((configurationType == MasterLookupEnum.Coffee || configurationType == MasterLookupEnum.Milk) && this.priceCardForm.status === 'INVALID'){
      this.priceCardForm.markAllAsTouched();
      this.toaster.open({
        text: validationMsg,
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }else if(configurationType == MasterLookupEnum.Syrup && this.syruppriceCardForm.status === 'INVALID'){
      this.syruppriceCardForm.markAllAsTouched();
      this.toaster.open({
        text: validationMsg,
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }else{
    this.isApiCalling = true;
    var name = "", code = "", drinkType = "", hasMilk = "", isShort = "", syrup = "", temperature = "", defaultEatInCupType = "";
    if (configurationType == MasterLookupEnum.Drink) {
      name = this.drinkPriceCardForm.controls['name'].value;
      code = this.drinkPriceCardForm.controls['code'].value;
      drinkType = this.drinkPriceCardForm.controls['drinkType'].value;
      hasMilk = this.drinkPriceCardForm.controls['hasMilk'].value;
      isShort = this.drinkPriceCardForm.controls['isShort'].value;
      syrup = this.drinkPriceCardForm.controls['syrup'].value;
      temperature = this.drinkPriceCardForm.controls['temperature'].value;
      defaultEatInCupType = this.drinkPriceCardForm.controls['defaultEatInCupType'].value;
    }
    else if (configurationType == MasterLookupEnum.Coffee || configurationType == MasterLookupEnum.Milk) {
      name = this.priceCardForm.controls['name'].value;
      code = this.priceCardForm.controls['code'].value;
    }
    else if (configurationType == MasterLookupEnum.Syrup) {
      name = this.syruppriceCardForm.controls['name'].value;
      code = this.syruppriceCardForm.controls['code'].value;
    }
    var body =
    {
      "configurationType": configurationType,
      "name": name,
      "code": code,
      "drinkType": drinkType,
      "hasMilk": hasMilk,
      "isShort": isShort,
      "hasSyrup": syrup,
      "temperature": temperature,
      "defaultEatInCupType": defaultEatInCupType
    }
    var msg = '';
    if (configurationType == MasterLookupEnum.Drink) {
      msg = 'Drink added successfully';
    }
    else if (configurationType == MasterLookupEnum.Coffee) {
      msg = 'Coffee types added successfully';
    }
    else if (configurationType == MasterLookupEnum.Milk) {
      msg = 'Milk type added successfully';
    }
    else if (configurationType == MasterLookupEnum.Syrup) {
      msg = 'Syrup added successfully';
    }
    this.entityService.addmasterConfiguration(body).subscribe(response => {
        
      if (response != '') {
        if (configurationType == MasterLookupEnum.Drink) {
          this.drinkPriceCardForm.reset();
          this.hasMilk = true;
          this.isShort = false;
        } else if (configurationType == MasterLookupEnum.Syrup) {
          this.syruppriceCardForm.reset();
         
        } else {
          this.priceCardForm.reset();
          
        }
        this.showForm = false;  
        setTimeout(() => { 
              this.showForm = true;
        }, 2);
        this.toaster.open({
          text: msg,
          type: 'success',
          position: 'top-right',
          duration: 10000
        });
        this.bindList(configurationType);
      }
      this.spinner.hide();
      this.isApiCalling = false;
    }, err => {
      this.isApiCalling = false;
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }
  }

  bindList(configurationType = 0) {
    this.isPageDataLoad = false;
    this.spinner.show();
    var body = {
      "search": this.filter.search,
      "configurationType": configurationType,
      "pageNo": this.currentPage + 1,
      "pageSize": this.params.pageSize,
      "orderBy": this.params.sortBy
    }
    this.entityService.getmasterConfiguration(body).subscribe(response => {
      this.spinner.hide();
      this.isPageDataLoad = true;
      if (response && response['data'].length) {
        if (configurationType == MasterLookupEnum.Drink) {
          this.drinkList = response['data'];
        }
        else if (configurationType == MasterLookupEnum.Coffee) {
          this.coffeeList = response['data'];
        }
        else if (configurationType == MasterLookupEnum.Milk) {
          this.milkList = response['data'];
        }
        else if (configurationType == MasterLookupEnum.Syrup) {
          this.syrupList = response['data'];
        }
      } else {
        if (configurationType == MasterLookupEnum.Drink) {
          this.drinkList = [];
        }
        else if (configurationType == MasterLookupEnum.Coffee) {
          this.coffeeList = [];
        }
        else if (configurationType == MasterLookupEnum.Milk) {
          this.milkList = [];
        }
        else if (configurationType == MasterLookupEnum.Syrup) {
          this.syrupList = [];
        }
      }
      this.recordsTotal = response?.params?.count;
    }, err => {
      this.spinner.hide();
      let errors = err.error;
      errors.forEach(element => {
        this.toaster.open({
          text: element.msg,
          type: 'danger',
          position: 'top-right',
          duration: 10000
        });
      });
    });
  }

  clearSearch(configurationType = 0) {
    this.currentPage = 0;
    this.filter.search = '';
    this.bindList(configurationType);
  }

  async sortData(e: Sort, configurationType = 0) {
    this.params.sortcolumn = e.active;
    this.params.sortDirection = e.direction;
    this.params.sortBy = (e.active != '' && e.direction != "" ? e.active + ' ' + e.direction : (e.active != '' && e.direction == '') ? e.active + ' asc' : 'cells_name asc');
    this.bindList(configurationType);
  }

  pageChange(e, configurationType = 0) {
    this.currentPage = e.pageIndex;
    this.params.pageSize = (e.pageSize > 0 ? e.pageSize : this.pageSizeOptions[0]);
    this.bindList(configurationType);
  }

  search(configurationType = 0) {
    this.currentPage = 0;
    if (configurationType == MasterLookupEnum.Drink) {
      this.bindList(MasterLookupEnum.Drink);
    }
    else if (configurationType == MasterLookupEnum.Coffee) {
      this.bindList(MasterLookupEnum.Coffee);
    }
    else if (configurationType == MasterLookupEnum.Milk) {
      this.bindList(MasterLookupEnum.Milk);
    }
    else if (configurationType == MasterLookupEnum.Syrup) {
      this.bindList(MasterLookupEnum.Syrup);
    }

    }
}
