import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { GetRemoteUpdateTask } from '../../dtos/get-remote-update-tasks.model';
import { GetRemoteUpdateTaskList } from '../../dtos/get-remote-update-tasks-list.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationSelection } from '../../dtos/pagination-select-option.model';
import { RemoteUpdateSortableField } from "../../enums/remote-update-sortable.field";
import { RemoteUpdateTaskExportCsvService } from '../../services/event-services/remote-update-task-export-csv.service';
import { RemoteUpdateTaskGetListService } from '../../services/get-list/remote-update-task-get-list.service';
import { RemoteUpdateTaskSearchService } from '../../services/event-services/remote-update-task-search.service';
import { RemoteUpdateTaskToggleSearchService } from '../../services/event-services/remote-update-task-toggle-search.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Toaster } from 'ngx-toast-notifications';
import { DOCUMENT } from '@angular/common';
import { AzureService } from 'src/app/services/azure/azure.service';

@Component({
  selector: 'app-table-remote-update',
  templateUrl: './table-remote-update.component.html',
  styleUrls: ['./../../remote-update-tasks.component.css', './table-remote-update.component.css'],
})
export class TableRemoteUpdateComponent implements OnInit, OnDestroy {
  taskId: string;
  taskType: string;
  proposition: string;
  packageName: string;
  packageVersion: string;
  scheduledOn: string;
  taskStatus: string;
  createdBy: string;
  updatedBy: string;
  level: string;
  success: string;
  machinesUpdateTask: string;
  successUpdates: string;
  pending: string;
  successDownloads: string;
  failDownloads: string;
  failUpdates: string;
  theadFilterToggle: boolean;
  remoteUpdateTasks: GetRemoteUpdateTaskList;
  columns: string[] = ["taskId", "taskType", "propositionType", "packageName", "package", "scheduledOn", "taskStatus", "createdBy", "updatedBy", "entityDeployLevel", "success", "machineUpdateTask", "successUpdates", "pending", "successDownloads", "failDownloads", "failUpdates"]
  orderByColumn: Map<string, boolean> = new Map<string, boolean>(this.columns.map(x => [x, null])).set("taskId", true);
  tableData: GetRemoteUpdateTask[];
  dtOptions: DataTables.Settings = {};
  selectedOption: string;
  options: PaginationSelection[] = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  exportToCsvSubscription: Subscription;
  toggleSearchHeaderSubscription: Subscription;
  searchdatatableSubscription: Subscription;
  page: number = 0;
  limit: number = 10;
  totalPages: number;
  queryParams: Map<string, string> = new Map<string, string>();
  enableGridImprovementsFT: boolean;
  showEntityOrRecordName: string = 'Entity';

  constructor(
    private remoteUpdateTaskGetListService: RemoteUpdateTaskGetListService,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private remoteUpdateTaskExportCsvService: RemoteUpdateTaskExportCsvService,
    private remoteUpdateTaskToggleSearchService: RemoteUpdateTaskToggleSearchService,
    private remoteUpdateTaskSearchTableService: RemoteUpdateTaskSearchService,
    private router: Router,
    private azureService: AzureService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnDestroy(): void {
    this.exportToCsvSubscription.unsubscribe();
    this.toggleSearchHeaderSubscription.unsubscribe();
    this.document.body.classList.remove('remote-update-task-management-overview');
  }

  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();

    this.exportToCsvSubscription = this.remoteUpdateTaskExportCsvService.getExportRemoteUpdateTasksToCsvEvent().subscribe(() => {
      this.exportCsv();
    });

    this.toggleSearchHeaderSubscription = this.remoteUpdateTaskToggleSearchService.getToggleRemoteUpdateHeaderFilter().subscribe(() => {
      this.toggleSearchBarTableHeader();
    });

    this.searchdatatableSubscription = this.remoteUpdateTaskSearchTableService.getSearchDatatableData().subscribe((searchText) => {
      this.searchAllTable(searchText);
    });
    this.getRemoteUpdateTaskList();
    this.document.body.classList.add('remote-update-task-management-overview');
  }

  private async SetFeatureFlagsConfigurations() {
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
    this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
  }

  setupTable(): void {
    this.dtOptions = {
      autoWidth: true,
      scrollX: true,
      lengthMenu: [10, 25, 50, 100],
      pageLength: this.limit,
      ordering: false,
      paging: true,
      dom: 't',
    };
  }

  getRemoteUpdateTaskList() {
    this.spinner.show();
    this.remoteUpdateTaskGetListService.getRemoteUpdateTaskList(this.page, this.limit, this.queryParams).subscribe(
      (response) => {
        this.remoteUpdateTasks = response;
        this.tableData = response.data;
        this.totalPages = Math.floor(response.params.count / this.limit);
        this.setupTable();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toaster.open({
          text: err.message,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
    );
  }

  setOrderByColumn(columnPosition: number) {
    let sortableFields = Object.keys(RemoteUpdateSortableField).map(k => k.toLowerCase());

    if (!sortableFields.includes(this.columns[columnPosition].toLowerCase())) {
      return;
    }

    // Avoid ordering multiple columns
    this.orderByColumn
      .forEach((_, key) => {
        if (key.toLowerCase() !== this.columns[columnPosition].toLowerCase()) {
          this.orderByColumn.set(key, null)
        }
      });

    this.orderByColumn.set(this.columns[columnPosition], !this.orderByColumn.get(this.columns[columnPosition]));
    this.queryParams.set("sort", this.columns[columnPosition]);
    this.queryParams.set("order", this.orderByColumn.get(this.columns[columnPosition]) ? "asc" : "desc");
    this.page = 0;
    this.getRemoteUpdateTaskList();
  }

  searchAllTable(searchText: string) {
    if(!searchText) {
      this.queryParams.delete("search");
      this.getRemoteUpdateTaskList();
      return;
    }

    this.queryParams.set("search", searchText);
    this.page = 0;
    this.getRemoteUpdateTaskList();
  }

  searchPerColumn(columnPosition: number, searchInput: string) {
    if (!searchInput) {
      this.queryParams.delete(this.columns[columnPosition]);
      this.getRemoteUpdateTaskList();
      return;
    }

    this.queryParams.set(this.columns[columnPosition], searchInput);
    this.page = 0;
    this.getRemoteUpdateTaskList();
  }

  goToNextPage() {
    if (this.totalPages > this.page) {
      this.page++;
      this.getRemoteUpdateTaskList();
    }
  }

  goToPreviousPage() {
    if (this.page > 0) {
      this.page--;
      this.getRemoteUpdateTaskList();
    }
  }

  goToFirstPage() {
    this.page = 0;
    this.getRemoteUpdateTaskList();
  }

  goToLastPage() {
    this.page = this.totalPages;
    this.getRemoteUpdateTaskList();
  }

  changePageLimit(limit: string) {
    if (limit) {
      this.limit = Number(limit);
      this.getRemoteUpdateTaskList();
    }
  }

  redirectToRemoteUpdateTaskPage(task: GetRemoteUpdateTask) {
    this.router.navigate(['task-management-overview', task.taskId]);
  }

  exportCsv() {
    this.spinner.show();
    this.remoteUpdateTaskGetListService.getRemoteUpdateTaskList(null, null, null).subscribe(
      (response) => {
        const CSV_EXTENSION = '.csv';
        const DATETIME = new Date().getTime().toString();

        const modifiedSheet = response.data.map((item) => {
          const newItem = {};
          for (const key in item) {
            if (key === 'entityDeployLevel' || key === 'recordDeployLevel') {
              const itemName = this.enableGridImprovementsFT ? 'recordDeployLevel' : 'entityDeployLevel';
              newItem[itemName] = item[key];
            }
            else
              newItem[key] = item[key];
          }
          return newItem;
        });

        const sheet = XLSX.utils.json_to_sheet(modifiedSheet);
        const csv = XLSX.utils.sheet_to_csv(sheet);
        FileSaver.saveAs(new Blob([csv]), `RemoteUpdateTasks_${DATETIME}${CSV_EXTENSION}`);
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toaster.open({
          text: err.message,
          type: 'danger',
          position: 'top-right',
          duration: 10000,
        });
      },
    );
  }

  toggleSearchBarTableHeader() {
    this.theadFilterToggle = !this.theadFilterToggle;
  }
}
