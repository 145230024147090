export enum DrinkSetupPropositionTypeEnum {
    All="All",
    ConcessionSpecific="ConcessionSpecific",
}

export class DrinkSetupPropositionType {
    name: string;
    id: DrinkSetupPropositionTypeEnum;
    isDisabled: boolean;
}

export class DrinkSetupPropositionTypeList{
    data: DrinkSetupPropositionType[];
}