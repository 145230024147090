import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MultiSelectComponent } from "@costa-coffee/pattern-lib";
import { DrinkSetupService } from "../../services/drink-setup.service";
import { MasterLookupEnum } from 'src/app/enums/masterlookup.enum';
import { RequestGetDrinkGroupTemplate } from '../../services/request-add-drink-group-template';
import {  map, publishReplay, refCount } from 'rxjs/operators';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { LookupModelList } from 'src/app/components/pricecard-template/pricecard-template-add/pricecard-template-add.model';
import { DrinkSetupPropositionTypeService } from '../../services/drink-setup-proposition-type.service';
import { DrinkSetupPropositionTypeEnum } from '../../models/drink-setup-proposition-type.model';

type CCInputVariant = 'default' | 'error';

@Component({
  selector: 'app-drink-properties',
  templateUrl: './drink-properties.component.html',
  styleUrls: ['./drink-properties.component.css']
})
export class DrinkPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('msSyrupType') private msSyrupType: MultiSelectComponent;
  @ViewChild('msCupSizeType') private msCupSizeType: MultiSelectComponent;

  @Output('selection-changed') selectionChanged: EventEmitter<SelectedDrinkProperties> = new EventEmitter<SelectedDrinkProperties>();

  @Input() drinkGroupTemplateForEdit: RequestGetDrinkGroupTemplate = null;

  coffeeTypeItems$: Observable<MultiSelect[]>;
  milkTypeItems$: Observable<MultiSelect[]>;
  syrupsTypeItems$: Observable<MultiSelect[]>;
  cupSizeTypeItems$: Observable<MultiSelect[]>;

  syrupsTypeSelectedItems: ListDrinkSelected[] = [];
  milkTypeSelectedItems: ListDrinkSelected[] = [];
  cupSizeTypeSelectedItems: ListDrinkSelected[] = [];
  coffeeTypeSelectedItems: ListDrinkSelected[] = [];

  cupSizeTypeList: MultiSelect[] = [];
  cupSizeTypeSelectedIdsList: string[] = [];
  private readonly requiredFieldMessage  = "This field is required.";
  private readonly requiredFieldPrimaryCoffeeMessage  = "Please select the primary coffee type.";
  private readonly requiredFieldPrimaryMilkMessage  = "Please select the primary milk type.";
  private readonly defaultValidVariant = "default";
  private readonly errorValidVariant = "error";

  coffeeTypeValidVariant: CCInputVariant = this.defaultValidVariant;
  coffeeTypeShowMessage: boolean = false;
  coffeeTypeCustomMessage: string = this.requiredFieldMessage;

  milkTypeValidVariant: CCInputVariant = this.defaultValidVariant;
  milkTypeShowMessage: boolean = false;
  milkTypeCustomMessage: string = this.requiredFieldMessage;

  cupSizeTypeValidVariant: CCInputVariant = this.defaultValidVariant;
  cupSizeTypeShowMessage: boolean = false;
  cupSizeTypeCustomMessage: string = this.requiredFieldMessage;

  public coffeePrimarySelected: string = "";
  public milkPrimarySelected: string = "";

  readonly selectedPrimaryCoffee$: ReplaySubject<string> = new ReplaySubject<string>(1);
  readonly selectedPrimaryMilk$: ReplaySubject<string> = new ReplaySubject<string>(1);

  private drinkGroupPropositionTypeSubscription$: Subscription;
  private readonly concessionSpecificCupSizeArray: string[] = ['Large', 'Medium'];

  constructor(
    private drinkSetupService: DrinkSetupService,
    private drinkSetupPropositionTypeService: DrinkSetupPropositionTypeService) { }

   ngOnInit() {
    this.getCoffeeDrinkList();
    this.getMilkDrinkList();
    this.getSyrupDrinkList();
    this.getCupSizeDrinkList();
    this.setRadioCoffeeType();
    this.setRadioMilkType();
    this.drinkGroupteTemplatePropositionTypeSubscriber();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.drinkGroupTemplateForEdit && changes.drinkGroupTemplateForEdit.currentValue) {
      this.coffeePrimarySelected = this.drinkGroupTemplateForEdit.primaryCoffeeType;
      this.milkPrimarySelected = this.drinkGroupTemplateForEdit.primaryMilkType;
      this.cupSizeTypeSelectedIdsList = this.drinkGroupTemplateForEdit.cupSizeList;
      this.populatePrimaryDrinks();
    }
  }

  private setRadioCoffeeType() {
    this.selectedPrimaryCoffee$.subscribe((coffee) => {
      this.coffeePrimarySelected = coffee;
      this.onClickRadioCoffeeType();
    })
  }

  private setRadioMilkType() {
    this.selectedPrimaryMilk$.subscribe((milk) => {
      this.milkPrimarySelected = milk;
      this.onClickRadioMilkType();
    })
  }

  validate(): boolean {
    let coffeeHasError =
      this.showSelectedErrorMessage(this.coffeeTypeSelectedItems, MasterLookupEnum.Coffee) ||
      this.showPrimarySelectedErrorMessage(this.coffeePrimarySelected, MasterLookupEnum.Coffee);
    let milkHasError =
      this.showSelectedErrorMessage(this.milkTypeSelectedItems, MasterLookupEnum.Milk) ||
      this.showPrimarySelectedErrorMessage(this.milkPrimarySelected, MasterLookupEnum.Milk);
    let cupSizeHasError = this.showSelectedErrorMessage(this.cupSizeTypeSelectedItems, MasterLookupEnum.CupSize);

    return !(coffeeHasError || milkHasError || cupSizeHasError);
  }

  resetStateDrinkFields(typeDrink: MasterLookupEnum){
    switch(typeDrink){
      case MasterLookupEnum.Coffee:{
        this.coffeeTypeValidVariant = this.defaultValidVariant;
        this.coffeeTypeShowMessage = false;
        this.coffeeTypeCustomMessage = this.requiredFieldMessage;
        break;
      }
      case MasterLookupEnum.Milk:{
        this.milkTypeValidVariant = this.defaultValidVariant;
        this.milkTypeShowMessage = false;
        this.milkTypeCustomMessage = this.requiredFieldMessage;
        break;
      }
      case MasterLookupEnum.CupSize:{
        this.cupSizeTypeValidVariant = this.defaultValidVariant;
        this.cupSizeTypeShowMessage = false;
        this.cupSizeTypeCustomMessage = this.requiredFieldMessage;
        break;
      }
    }
  }

  onChangeSyrup(usedItems: any[]) {
    this.syrupsTypeSelectedItems = usedItems as ListDrinkSelected[];
    this.selectionChangedEmit();
   }

  onChangeMilkType(usedItems: any[]) {
    this.milkTypeSelectedItems = usedItems as ListDrinkSelected[];
    this.populatePrimaryDrinks();

    if(this.showSelectedErrorMessage(this.milkTypeSelectedItems, MasterLookupEnum.Milk))
      return;

    if(this.showPrimarySelectedErrorMessage(this.milkPrimarySelected, MasterLookupEnum.Milk))
      return;

    this.resetStateDrinkFields(MasterLookupEnum.Milk);
    this.selectionChangedEmit();
   }

  onChangeCupSize(usedItems: any[]) {
    this.cupSizeTypeSelectedItems = usedItems as ListDrinkSelected[];

    if(this.showSelectedErrorMessage(this.cupSizeTypeSelectedItems, MasterLookupEnum.CupSize))
      return;

    this.resetStateDrinkFields(MasterLookupEnum.CupSize);
    this.selectionChangedEmit();
   }

  onChangeCoffeeType(usedItems: any[]) {
    this.coffeeTypeSelectedItems = usedItems as ListDrinkSelected[];
    this.populatePrimaryDrinks();

    if(this.showSelectedErrorMessage(this.coffeeTypeSelectedItems, MasterLookupEnum.Coffee))
      return;

    if(this.showPrimarySelectedErrorMessage(this.coffeePrimarySelected, MasterLookupEnum.Coffee))
      return;

    this.resetStateDrinkFields(MasterLookupEnum.Coffee);
    this.selectionChangedEmit();
   }

   onFocusOutCoffeeType(){
    if(this.showSelectedErrorMessage(this.coffeeTypeSelectedItems, MasterLookupEnum.Coffee))
      return;

    this.resetStateDrinkFields(MasterLookupEnum.Coffee);
  }

  onFocusOutMilkType(){
    if(this.showSelectedErrorMessage(this.milkTypeSelectedItems, MasterLookupEnum.Milk))
      return;

    this.resetStateDrinkFields(MasterLookupEnum.Milk);
  }

  onFocusOutCupSize(){
    if(this.showSelectedErrorMessage(this.cupSizeTypeSelectedItems, MasterLookupEnum.CupSize))
      return;

    this.resetStateDrinkFields(MasterLookupEnum.CupSize);
  }

  onClickRadioMilkType() {
    this.resetStateDrinkFields(MasterLookupEnum.Milk);
    this.populatePrimaryDrinks();
    this.selectionChangedEmit();
  }

  onClickRadioCoffeeType(){
    this.resetStateDrinkFields(MasterLookupEnum.Coffee);
    this.populatePrimaryDrinks();
    this.selectionChangedEmit();
  }

   showSelectedErrorMessage(drinkList: ListDrinkSelected[], typeDrink: MasterLookupEnum): boolean{
    if (drinkList.length <= 0){
      switch(typeDrink){
        case MasterLookupEnum.Coffee:{
          this.coffeeTypeValidVariant = this.errorValidVariant;
          this.coffeeTypeShowMessage = true;
          this.coffeeTypeCustomMessage = this.requiredFieldMessage;
          return true;
        }
        case MasterLookupEnum.Milk:{
          this.milkTypeValidVariant = this.errorValidVariant;
          this.milkTypeShowMessage = true;
          this.milkTypeCustomMessage = this.requiredFieldMessage;
          return true;
        }
        case MasterLookupEnum.CupSize:{
          this.cupSizeTypeValidVariant = this.errorValidVariant;
          this.cupSizeTypeShowMessage = true;
          this.cupSizeTypeCustomMessage = this.requiredFieldMessage;
          return true;
        }
      }
    }

    return false;
   }

   showPrimarySelectedErrorMessage(primaryDrink: string, typeDrink: MasterLookupEnum): boolean{
      let showError = true;
      switch(typeDrink){
        case MasterLookupEnum.Coffee:{
          this.coffeeTypeSelectedItems.forEach(coffee => {
            if (coffee.id === primaryDrink)
              showError = false;
          })
          if (showError) {
            this.coffeeTypeValidVariant = this.errorValidVariant;
            this.coffeeTypeShowMessage = true;
            this.coffeeTypeCustomMessage = this.requiredFieldPrimaryCoffeeMessage;
            return true;
          }
        }
        case MasterLookupEnum.Milk:{
          this.milkTypeSelectedItems.forEach(milk => {
            if (milk.id === primaryDrink)
                showError = false;
          })
          if(showError) {
            this.milkTypeValidVariant = this.errorValidVariant;
            this.milkTypeShowMessage = true;
            this.milkTypeCustomMessage = this.requiredFieldPrimaryMilkMessage;
            return true;
          }
        }
      }

    return false;
   }

  private populatePrimaryDrinks() {
    if(this.milkTypeSelectedItems.length) {
      this.milkTypeSelectedItems.forEach(milk => {
        milk.isPrimary = false;
        if (milk.id === this.milkPrimarySelected)
            milk.isPrimary = true;
      })
    }

    if(this.coffeeTypeSelectedItems.length) {
      this.coffeeTypeSelectedItems.forEach(coffee => {
        coffee.isPrimary = false;
        if (coffee.id === this.coffeePrimarySelected)
          coffee.isPrimary = true;
      })
    }
  }

  selectionChangedEmit() {
    this.selectionChanged.emit({
      coffee: this.coffeeTypeSelectedItems,
      milk: this.milkTypeSelectedItems,
      syrup: this.syrupsTypeSelectedItems,
      cupSize: this.cupSizeTypeSelectedItems
    });
  }

  removeItemSyrup(id: string): void {
    this.msSyrupType.removeItem(id);
  }

  removeItemCupSize(id: string): void {
    this.msCupSizeType.removeItem(id);
  }

  public getCoffeeDrinkList() {
    this.coffeeTypeItems$ = this.drinkSetupService.getMasterLookup(MasterLookupEnum.Coffee)
      .pipe(
        DrinkPropertiesComponent.convertMasterLookInMultiSelect,
        publishReplay(1),
        refCount()
      );
  }

  public getMilkDrinkList() {
    this.milkTypeItems$ = this.drinkSetupService.getMasterLookup(MasterLookupEnum.Milk)
      .pipe(
        DrinkPropertiesComponent.convertMasterLookInMultiSelect,
        publishReplay(1),
        refCount()
      );
  }

  public getSyrupDrinkList() {
    this.syrupsTypeItems$ = this.drinkSetupService.getMasterLookup(MasterLookupEnum.Syrup)
      .pipe(
        DrinkPropertiesComponent.convertMasterLookInMultiSelect
      );
  }

  public getCupSizeDrinkList() {
    this.drinkSetupService.getMasterLookup(MasterLookupEnum.CupSize)
      .pipe(
        DrinkPropertiesComponent.convertMasterLookInMultiSelect
      ).subscribe((result: MultiSelect[]) => {
        this.cupSizeTypeList = result;
        this.cupSizeTypeItems$ = of(result);
      });
  }
  
  public static convertMasterLookInMultiSelect(source: Observable<LookupModelList>) {
    return source
      .pipe(
        map(x => 
          x.data.map(item => ({
            id: item.masterDataId,
            name: item.text
          })),
      ));
  }

  private drinkGroupteTemplatePropositionTypeSubscriber() {
    this.drinkGroupPropositionTypeSubscription$ = 
      this.drinkSetupPropositionTypeService.drinkGroupPropositionTypeChanged.subscribe((_propositionType) => {
        if(this.cupSizeTypeList?.length > 0) {
          this.filterDrinkCupListByPropositionType(_propositionType);
        }
      });
  }

  private filterDrinkCupListByPropositionType(propositionType: DrinkSetupPropositionTypeEnum) {
    
    if (propositionType == DrinkSetupPropositionTypeEnum.ConcessionSpecific) {
      let filteredList = this.cupSizeTypeList.filter(cs => this.concessionSpecificCupSizeArray.includes(cs.name));
      this.updateCupSizeTypeItems(filteredList);
      this.cupSizeTypeSelectedItems = this.cupSizeTypeSelectedItems.filter(cs => this.concessionSpecificCupSizeArray.includes(cs.name));
    }
    else {
      this.updateCupSizeTypeItems(this.cupSizeTypeList);
    }

    if(!this.drinkGroupTemplateForEdit || (this.drinkGroupTemplateForEdit && this.cupSizeTypeSelectedIdsList == null)) {
      this.cupSizeTypeSelectedIdsList = this.cupSizeTypeSelectedItems.map(f => f.id.toString());
    }
  }

  private updateCupSizeTypeItems(list: MultiSelect[]) {
    this.cupSizeTypeItems$ = of(list);
  }

  ngOnDestroy(): void {
    this.drinkGroupPropositionTypeSubscription$.unsubscribe();
  }
}

export class MultiSelect{
  id: string;
  name: string;
}

export class ListDrinkSelected{
  id: string;
  name: string;
  isPrimary: boolean = false;
}

export class SelectedDrinkProperties{
  coffee: ListDrinkSelected[];
  milk: ListDrinkSelected[];
  syrup: ListDrinkSelected[];
  cupSize: ListDrinkSelected[];
}
