import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UploadPackageFileRequest } from '../../dtos/upload-package-file-request';
import { UploadPackageFileResponse } from '../../dtos/upload-package-file-response';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UploadPackageFileService {

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  Execute(request: UploadPackageFileRequest) : Observable<UploadPackageFileResponse> {
    const formData = new FormData();
    formData.append('fileData', request.fileData);

    var configHeader = {
			headers: {
        'Authorization': 'Bearer ' + this.cookieService.get(environment.cookieName + 'access_token'),
			}
		};

		return this.httpClient.post<UploadPackageFileResponse>(
        environment.apiUrl.remoteUpdatePackage + `/v1/file-upload/${request.packageId}/${request.userId}`,
        formData,
        configHeader
      )
			.pipe(map(response => response));
	}

}
