import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { TreeNode } from 'primeng/api';
import { EntityService } from 'src/app/services/entity/entity.service';
import { EntityMoveDataModel } from './entity-move.model';
import { AzureService } from 'src/app/services/azure/azure.service';

declare var $: any;

@Component({
  selector: 'app-entity-move',
  templateUrl: './entity-move.component.html',
})
export class EntityMoveComponent implements OnChanges, OnInit {
  selectedEntityId: string;
  selectedLevelNumber: string;
  @Input() entityMoveDataModel: EntityMoveDataModel;
  @Output() onConfirm = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<string>();
  @Input() entityMoveShow: boolean = false
  @ViewChild('tt') tree;
  entityList: TreeNode[] = []
  cols: any[] = []
  filter = {
    marketId: '',
    search: ''
  }
  isLoading = false;
  enableGridImprovementsFT: boolean = false;
  showEntityOrRecordName: string = 'Entity';

  constructor(
    private spinner: NgxSpinnerService,
    private entityService: EntityService,
    private toaster: Toaster,
    private azureService: AzureService) { }

  async ngOnInit(): Promise<void> {
    await this.SetFeatureFlagsConfigurations();
  }

  private async SetFeatureFlagsConfigurations() {
    this.enableGridImprovementsFT = await this.azureService.isEnableGridImprovementsFT();
    this.showEntityOrRecordName = this.enableGridImprovementsFT ? 'Record' : 'Entity';
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'entityMoveShow': {
            this.bindEntityList(changes[propName].currentValue)
          }
        }
      }
    }
  }
  bindEntityList(value) {
    if (value) {
      this.getEntityList();
    }
  }
  counter(i: number) {
    return new Array(i-1);
  }

  expandChildren(node: TreeNode) {
    if (node.children) {
      node.expanded = true;
      for (let cn of node.children) {
        this.expandChildren(cn);
      }
    }
  }
  getEntityList() {
    this.entityList = [];
    this.isLoading = true;
    this.selectedEntityId = "";
    this.spinner.show();
    let obj = {};
    let requestBody = this.entityMoveDataModel.requestBody;
    if (requestBody.marketId) { obj['marketId'] = requestBody.marketId };
    if (requestBody.entityId) { obj['entityId'] = requestBody.entityId };
    this.entityService.getEntityMoveList(obj).subscribe(response => {
      this.isLoading = false;
      this.spinner.hide();
      if (response['data'].length) {
        localStorage.setItem('moveEntityHasPartnerEntityBillTo',response.params['hasPartnerEntityBillTo'].toString());
        localStorage.setItem('moveEntityHasSiteEntitySellTo',response.params['hasSiteEntitySellTo'].toString());
        
        this.entityList = (response['data'][0]['data']['parentEntityId'] === null) ? response['data'][0]['children'] : response['data'];
        this.entityList.forEach(a => {
          this.expandChildren(a);
        })
      } else {
        this.entityService.removeMarketFieldVisibilityStorage();
        this.entityList = [];
      }

      this.cols = [
        { field: 'name', header: 'name', 'visible': true },
      ];
    });
  }

  clickOnClose() {
    $(".sidebar-mini").removeClass("filter-open")
    this.onCancel.emit();
    this.filter = {
      search: '',
      marketId: ''
    }
    this.tree.filterGlobal("", 'contains');
  }

  clickOnCancel() {
    this.selectedEntityId = undefined;
    this.selectedLevelNumber = undefined;
    this.filter = {
      search: '',
      marketId: ''
    }
    this.tree.filterGlobal("", 'contains');
  }


  clickOnSave() {
    $(".sidebar-mini").removeClass("filter-open")
    if (this.selectedEntityId == null || this.selectedEntityId == "") {
      this.toaster.open({
        text: "Please select entity where you want to move",
        type: 'danger',
        position: 'top-right',
        duration: 10000
      });
    }
    else {
      let entityId = this.entityMoveDataModel.requestBody.entityId;
      let nextLevelNumber = this.entityMoveDataModel.requestBody.nextLevelNumber;
      let marketId = this.entityMoveDataModel.requestBody.marketId;
      let levelNumber = this.entityMoveDataModel.requestBody.levelNumber;
      let parentLevelNumber = this.selectedLevelNumber;
      this.onConfirm.emit({ entityId: entityId, nextLevelNumber: nextLevelNumber, parentEntityId: this.selectedEntityId, marketId: marketId, parentlevelNumber: parentLevelNumber, levelNumber: levelNumber });
    }
  }


  toggleRowSelection(rowData, value) {
    if (value) {
      this.selectedEntityId = rowData.entityId;
      this.selectedLevelNumber = rowData.levelNumber
    }
    else {
      this.selectedEntityId = undefined;
      this.selectedLevelNumber = undefined;
    }
  }

  clearSearch() {
    this.filter.search = '';
    this.tree.filterGlobal("", 'contains');
  }

  selectedEntity(entityId) {
    this.selectedEntityId = entityId
  }
}
