import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { EntityAddComponent } from "src/app/components/entity/entity-add/entity-add.component";



@Injectable()
export default class MachineCanDeactivateGuard
    implements CanDeactivate<EntityAddComponent> {
    canDeactivate(component, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot,nextState?: RouterStateSnapshot) {
        let selectedEntityModel = localStorage.getItem("selectedMachineModel");
        let moveEntityModel = localStorage.getItem("moveMachineModel");
        if (selectedEntityModel != undefined && moveEntityModel != undefined && !localStorage.getItem('backBtnClicked'))
            return component.canMachineDeactivate(nextState.url);

        else
            return true;
    }
}
